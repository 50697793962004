<template>
    <div id="MyHome_Main">
        <Header />
        <SideMenu :isAdminLoggedIn="isAdminLoggedIn" @adminLoginRequested="AdminLogin" />
        <div id="myPage_Root">
            <div class="myPage_Wrapper">
                <div class="myPage_Content">
                    <div class="userInfo_Wrapper">
                        <h4>사용자 정보</h4>
                        <div class="info_Container">
                            <ul>
                                <li>이메일</li>
                                <li>이름</li>
                                <li>전화번호</li>
                                <li>회원 가입일</li>
                            </ul>

                            <ul>
                                <li><input type="email" readonly v-model="userEmail"></li>
                                <li><input type="text" readonly v-model="userName"></li>
                                <li>
                                    <input type="tel" readonly v-model="userPhone1">
                                    <input type="tel" readonly v-model="userPhone2">
                                    <input type="tel" readonly v-model="userPhone3">
                                </li>
                                <li><input type="text" v-model="userDate" readonly></li>
                            </ul>
                        </div>
                        <!-- <div class="button_Wrap">
                            <button @click="updateInfo" v-if="userCode == 0">저장</button>
                        </div> -->
                    </div>


                    <div class="managePW_Container" v-if="userCode == 0">
                        <div class="userPW_Wrapper">
                            <h4>비밀번호 관리</h4>
                            <div class="managePW_List">
                                <ul>
                                    <li>현재 비밀번호</li>
                                    <li>새 비밀번호</li>
                                    <li>새 비밀번호 확인</li>
                                </ul>

                                <ul>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_old"></li>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_new" @blur="validatePassword"></li>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_new_verify" @blur="validatePasswordMatch"></li>
                                </ul>
                            </div>
                            <div class="button_Wrap">
                                <button @click="updatePassword">저장</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <AdminLogin @childEvent="LoginChild" v-if="showAdminLogIn"/>
    </div>
</template>

<style>
@import url("../assets//css//myHome.css");
</style>

<script>
import Proto38DImage from '@/assets/images/thumbnails/Proto38D.png';
import Proto20SImage from '@/assets/images/thumbnails/Proto20S.png';
import Proto42D2Image from '@/assets/images/thumbnails/Proto42D2.png';
import Cookies from 'js-cookie';
import axios from 'axios';
import CryptoJS from "crypto-js";
import AdminLogin from './Login/AdminLogin.vue';
import SideMenu from '../components/essential/SideMenu.vue'
import Header from '../components/essential/headerTemplate.vue'
export default {
    props: {
        home: Object, // home 객체를 받는 prop
    },
    components: {
        AdminLogin,
        SideMenu,
        Header
    },

    data() {
        return {
            showAdminLogIn: false,
            isAdminLoggedIn: false,
            isAdminUser:true,
            homes: [
                {
                    name: "A안",
                    typeName: 'Proto38D',
                    isNameEditing: false,
                    newName: '',
                    showOption: false,
                    img: Proto38DImage,
                    copiedTypeName: "",
                    copyCount: 0,
                    isEmpty: false,
                    isNewProjectSlot: true, // 새 프로젝트 슬롯 여부
                },
                {
                    name: "B안",
                    typeName: 'Proto20S',
                    isNameEditing: false,
                    newName: '',
                    showOption: false,
                    img: Proto20SImage,
                    copiedTypeName: "",
                    copyCount: 0,
                    isEmpty: false,
                    isNewProjectSlot: true, // 새 프로젝트 슬롯 여부
                },
                {
                    name: "C안",
                    typeName: 'Proto42D2',
                    isNameEditing: false,
                    newName: '',
                    showOption: false,
                    img: Proto42D2Image,
                    copiedTypeName: "",
                    copyCount: 0,
                    isEmpty: false,
                    isNewProjectSlot: true, // 새 프로젝트 슬롯 여부
                },
                {
                    isEmpty: true,
                    isNewProjectSlot: true, // 새 프로젝트 슬롯 여부
                }, // + 새 프로젝트 슬롯 1
                {
                    isEmpty: true,
                    isNewProjectSlot: true, // 새 프로젝트 슬롯 여부
                }, // + 새 프로젝트 슬롯 1
            ],
            showMyPage: false,
            showOption: true,
            activeItem: null,
            typeName: "",
            curType: {},
            typeName: 'Proto30D',
            isNameEditing: false, // 이름 바꾸기 클릭시 나오는 input..
            newName: "", // 새로운 이름 저장할 변수
            isModalVisible: false,
            isSlotAlertVisible: false,
            //usedSlotCount:0 //사용중인 슬롯 갯수
            token: {},
            fileList: [],
            currentPage: 'myPage',
            userEmail: "",
            userName: "",
            userPhone: "",
            userDate: "",
            userPhone1: "",
            userPhone2: "",
            userPhone3: "",
            userCode: "",
            password_old: "",
            password_new: "",
            password_new_verify: "",
            userIdx: "",
        }
    },
    async beforeRouteEnter() {

    },
    async created() {
        //await this.getFileList();
        await this.getToken();
        await this.getUserInfo();
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
    },
    methods: {
        AdminLogin() {
            this.showAdminLogIn = true;
        },
        async getToken() {
            const cookie = Cookies.get('token');
            let data = {
                token: cookie == undefined ? '' : cookie
            }
            const res = await axios.post("/server/token_check", JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

            }
            else if (res.data._data["length"] == 0) {
                alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});      
                this.$router.push('/member/login');
            }
            else if (res.status == 200) {
                this.userIdx = res.data._data[0]["idx"];
                await this.getFileList();
                await this.getUserInfo();
                console.log("idx :" + this.userIdx);
            }
        },
        handleAdminLogin(success) {
            this.showAdminLogIn = false;
            if (success) {
                this.isAdminLoggedIn = true;
                this.$router.push('/manage');
            }
        },
        async getFileList() {
            const res = await axios.post("/server/read_file",
                `{ "key":"READ", "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            if (res.status == 200) {
                this.fileList = [...res.data._data];
                console.log(this.fileList)
            }
        },
        async getUserInfo() {
            console.log("getUserInfo");
            const res = await axios.post("/server/get_mypage",
                `{ "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            //(res => {
            this.userName = res.data._data[0]["NAME"];
            this.userEmail = res.data._data[0]["USER_EMAIL"];
            this.userPhone = res.data._data[0]["PHONE"];
            this.userPhone1 = this.userPhone.slice(0, 3);
            this.userPhone2 = this.userPhone.slice(3, 7);
            this.userPhone3 = this.userPhone.slice(7);
            this.userDate = res.data._data[0]["REGDATE"].split('T')[0];
            this.userCode = res.data._data[0]["CODE"];
            //})
        },
        getClonedTypeName(home) {
            if (home.showClone) {
                const nameParts = home.name.split(' - 복사본 ');
                if (nameParts.length + 1 > 1) {
                    return nameParts[0]; // Use the part before ' - 복사본 ' as the name
                } else {
                    return home.name;
                }
            } else {
                return home.name;
            }
        },
        //Home_Container (or Home_ContainerClone) 삭제..
        showDeleteModal(index) {
            // 해당 홈 컨테이너의 isModalVisible를 true로 초기화
            this.homes[index].isModalVisible = true;
        },
        confirmDelete(index) {
            if (typeof index !== 'undefined') {
                const homeToDelete = this.homes[index];
                let totalSlotCount = this.homes.filter(x => !x.isEmpty).length;
                if (homeToDelete.showClone) {
                    // 복제본을 삭제한 경우
                    const originalSlotIndex = this.homes.findIndex(home => home.typeName === homeToDelete.typeName);
                    if (originalSlotIndex !== -1) {
                        // 원본 슬롯으로 변경
                        this.homes[originalSlotIndex].showClone = false;
                        this.homes[originalSlotIndex].isNameEditing = false;
                        this.homes[originalSlotIndex].newName = '';
                        this.homes[originalSlotIndex].showOption = false;
                        this.homes[originalSlotIndex].copyCount--;
                        // 슬롯 삭제시 경고문 hide처리
                        this.isSlotAlertVisible = false;
                    }
                }
                // else if (homeToDelete.isNewProjectSlot) {
                //     // 새 프로젝트 슬롯을 삭제한 경우
                //     if (totalSlotCount <= 5) {
                //         // 슬롯 삭제시 경고문 hide처리
                //         this.isSlotAlertVisible = false;
                //     }
                // } 
                else {
                    // 기존 슬롯을 삭제한 경우
                    if (totalSlotCount <= 5) {
                        // 슬롯 삭제시 경고문 hide처리
                        this.isSlotAlertVisible = false;
                    }
                }
                // 삭제된 위치에 새 프로젝트 슬롯 추가
                const newProjectSlot = {
                    isEmpty: true,
                    isNewProjectSlot: true,
                };
                this.homes.splice(index, 1, newProjectSlot);
                this.homes[index].isModalVisible = false;
            }
        },
        cancelDelete(index) {
            this.homes[index].isModalVisible = false;
        },
        // Home_Container 복제..
        cloneHome(index, home) {
            const maxSlots = 5;
            const totalSlotCount = this.homes.filter(x => !x.isEmpty).length;

            if (totalSlotCount >= maxSlots) {
                this.isSlotAlertVisible = true;
                return;
            }

            if (home) {
                const currentIndex = this.homes.indexOf(home);
                const clonedHome = { ...home };
                clonedHome.isNameEditing = false;
                clonedHome.newName = "";
                clonedHome.showOption = false;
                clonedHome.isEmpty = false;
                clonedHome.isNewProjectSlot = false;

                if (!home.showClone) {
                    home.copyCount++;
                }


                if (home.copyCount === 1) {
                    clonedHome.name = `${home.name} - 복사본`;
                } else {
                    clonedHome.name = `${home.name} - 복사본 ${home.copyCount - 1}`;
                }
                //clonedHome.name = `${home.name} - 복사본${home.showClone ? '' : ` ${home.copyCount}`}`;

                // Find the topmost new project slot
                const topNewProjectSlot = this.homes.find(x => x.isEmpty && x.isNewProjectSlot);
                if (topNewProjectSlot) {
                    const newIndex = this.homes.indexOf(topNewProjectSlot);
                    this.homes.splice(newIndex + 1, 0, clonedHome); // Insert clonedHome below the top new project slot
                } else {
                    this.homes.push(clonedHome); // If no new project slot is found, add it to the end
                }

                this.removeUnusedNewProjectSlots();
            }
        },
        // 이름 바꾸기 클릭시 실행되는 input 편집모드 start
        startEditingName(home) {
            home.isNameEditing = !home.isNameEditing;
        },
        clickOption(home) {
            home.showOption = !home.showOption;
            // 다른 홈 컨테이너의 showOption 속성은 false로 초기화..
            this.homes.forEach((h) => {
                if (h !== home) {
                    //h.showClone = false;
                    h.showOption = false;
                }
            });
        },
        // 변경한 이름 저장..
        saveName(home) {
            // console.log(`New name for ${home.typeName}: ${home.newName}`);
            home.isNameEditing = false;
            home.showOption = false;
        },
        // OptionWrapper ul li
        toggleActive(event, action, home) {
            if (this.activeItem) {
                this.activeItem.classList.remove('active');
            }
            event.target.classList.add('active');
            this.activeItem = event.target;

            // '이름 바꾸기' 클릭 시 startEditingName 메서드 호출
            if (action === 'name') {
                this.startEditingName(home);
            }
        },
        // OptionWrapper를 클릭했을 때 home.showOption를 토글
        toggleOption(index) {
            this.homes[index].showOption = !this.homes[index].showOption;
        },
        // 외부 클릭 감지를 위한 이벤트 핸들러
        handleClickOutside(event) {
            if (!event.target.closest(".MoreBtn") && !event.target.closest(".OptionWrapper")) {
                this.homes.forEach((home) => {
                    home.showOption = false;
                });
            }
        },
        // 새 프로젝트 슬롯 중에서 비어있는 슬롯 제거
        removeUnusedNewProjectSlots() {
            const maxSlots = 5;
            const dataSlotCount = this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;
            const emptyNewProjectSlotCount = this.homes.filter(x => x.isEmpty && x.isNewProjectSlot).length;

            if (dataSlotCount <= maxSlots && emptyNewProjectSlotCount >= 0) {
                const indexToRemove = this.homes.findIndex(x => x.isEmpty && x.isNewProjectSlot);
                this.homes.splice(indexToRemove, 1);
            }
        },
        logout() {
            alert("로그아웃 합니다");
            if (this.userCode == 1) {
                console.log("카카오로그아웃");
                this.kakaoLogout();
            }
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});

            this.$router.push('/member/login');
            console.log("로그아웃합니다");
        },
        navigateToPage(page) {
            this.currentPage = page;
        },
        updateInfo() { // 마이페이지 회원 정보 업데이트
            var name = this.userName;
            var phonenumber = this.userPhone1 + this.userPhone2 + this.userPhone3;
            axios.post("/server/update_info",
                `{ "user_idx":"${this.userIdx}" , "name":"${name}", "phonenumber":"${phonenumber}" }`).then(res => {
                    if (res.data._result[0]["RESULTCD"] == "1") {
                        alert("회원정보가 수정되었습니다");
                    }
                })
        },
        updatePassword() { // 마이페이지 비밀번호 업데이트
            var hash_old = CryptoJS.SHA256(this.password_old).toString();
            var hash_new = CryptoJS.SHA256(this.password_new).toString();
            if (this.password_new != this.password_new_verify) {
                alert("비밀번호가 서로 일치하지 않습니다.");
                this.password_new = '';
                this.password_new_verify = '';
            }
            else {
                axios.post("/server/update_password",
                    `{ "user_idx":"${this.userIdx}" , "hashPw_old":"${hash_old}", "hashPw_new":"${hash_new}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                }).then(res => {
                        if (res.data._result[0]["RESULTCD"] == "2") {
                            alert("현재 비밀번호를 확인하세요.");
                        } else if (res.data._result[0]["RESULTCD"] == "1") {
                            alert("비밀번호가 변경되었습니다");
                            this.password_new = '';
                            this.password_new_verify = '';
                            this.password_old = '';
                        }
                    })
            }
        },
        kakaoLogout() {
            window.Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    console.log(response);
                    document.cookie = "token=" + "";
                    //alert("로그아웃 되었어용");
                },
                fail: function (error) {
                    console.log(error);
                },
            });
        },
        validatePassword() {
            const check = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])([a-zA-Z0-9!@#$%^&*()_+}{":;'?/>.<,]){8,}$/;
            console.log(this.password_new)
            if (!check.test(this.password_new)) {
                if (this.password_new !== '') {
                    alert("비밀번호를 올바른 형식으로 입력하세요.")
                }
                this.password_new = '';
                this.password_new_verify = '';
            }
        },

        // 패스워드 확인 유효성검사 (패스워드 값과 동일하지 않으면 X)
        validatePasswordMatch() {
            if (this.password_new !== this.password_new_verify) {
                alert("비밀번호가 일치 하지 않습니다.");
                this.password_new = '';
                this.password_new_verify = '';
            }
        },
        LoginChild() {
            this.showAdminLogIn = false;
        }

    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    computed: {
        availableSlotCount() {
            const maxSlots = 5;
            return maxSlots - this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;
        },

        // isSlotAlertVisible() {
        //     const maxSlots = 5;
        //     const dataSlotCount = this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;

        //     // 슬롯이 가득 찼을 때만 true를 반환
        //     return dataSlotCount >= maxSlots;
        // },
    },


}
</script>