import DockingEntity from "./DockingEntity";
import VectorHelper from "../Helper/VectorHelper";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import * as GUI from '@babylonjs/gui/2D';
import SpotItem from '../Items/SpotItem'
import * as HistoryCommand from "../Entities/historyCommands"
import CsgHelper from '../Helper/CsgHelper'

export default class DoorEntity extends DockingEntity{
    constructor()
    {
        super();
        this.isSimbol = true;
        this.crushMesh = null;
        this.crushMesh1f = null;
        this.crushMesh2f = null;
    }

    setEnable(instance,value)
    {
        const meshes = this.getDrawMeshes().concat(this.viewMeshes);
        const targets = meshes.filter(x=>x._scene === instance);
        targets.forEach(mesh=>{
            mesh.setEnabled(value);
        })

        this.crushMesh?.setEnabled(value);
        this.crushMesh1f?.setEnabled(value);
        this.crushMesh2f?.setEnabled(value);

        this.crushMesh?.spot?.setEnabled(value);
        this.crushMesh1f?.spot?.setEnabled(value);
        this.crushMesh2f?.spot?.setEnabled(value);
    }

    getWallThickness()
    {
        return this.parent.getWallThickness();
    }

    async drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const info = mesh.info; 
            if(info.ctgrName === 'Box')
            {
                const oriCsg = BABYLON.CSG.FromMesh(mesh);
                const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
                const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
                m1f.info = info;
                m2f.info = info;
                this.drawMeshes1f.push(m1f);
                this.drawMeshes2f.push(m2f);
                m1f.position.y =2.2;
                m2f.position.y =2.2;
            }
        });

        const d1f = await this.createSimbol(this.drawScene1f.instance,this.drawMeshes1f);
        d1f.info = this.info;
        d1f.entity = this;
        d1f.setEnabled(true);
        const d2f = await this.createSimbol(this.drawScene2f.instance,this.drawMeshes2f);
        d2f.info = this.info;
        d2f.entity = this;
        d2f.setEnabled(true);
        this.drawMeshes1f.push(d1f);
        this.drawMeshes2f.push(d2f);
    }

    loadImage(url) {
        return new Promise((resolve, reject) => {
            const image = new Image();
        
            image.onload = () => {
                resolve(image);
            };
        
            image.onerror = (error) => {
                reject(error);
            };
        
            image.src = url;
        });
    }

    async createSimbol(sceneIns,meshes)
    {
        const doorName = (this.info.ctgrName+this.info.typeName.replace(/[0-9]/g,""))
        const texturePath = `${this.viewScene.viewer.$DOOR_PATH}${doorName}/img/texture.png`;
        const image = await this.loadImage(texturePath);

        const box = meshes.find(x=>x.info.ctgrName === 'Box');
        box.isVisible = false;
        const boxInfo = box.getBoundingInfo();
        let width = (boxInfo.boundingBox.extendSize.x * 2).toFixed(2);
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const vFlip = this.info.flipType[1];
        const hFlip = this.info.flipType[3];

        const doorMat = new BABYLON.StandardMaterial(doorName,sceneIns);
        const doorTexture = new BABYLON.Texture(texturePath,sceneIns);
        doorTexture.minFilter = BABYLON.Texture.TRILINEAR_SAMPLINGMODE;
        doorMat.diffuseTexture = doorTexture;
        doorMat.specularColor = new BABYLON.Color3(0, 0, 0); 
        doorMat.specularPower = 0;
        doorMat.backFaceCulling = false;
        doorMat.diffuseTexture.hasAlpha = true;

        if(this.parent)
        {
            const thickness = this.parent.getWallThickness() / 2;
            if(this.parent.wallType == "Horizontal")
            {
                const height = (image.height*width)/image.width;
                const doorPlane = BABYLON.MeshBuilder.CreatePlane('doorPlane',{ width: width, height: height }, sceneIns);
                doorPlane.material = doorMat;
                doorPlane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
                doorPlane.position = box.position.clone();
                doorPlane.position.y = 3.2;
                doorPlane.position.z = this.parent.getOriginPos().z;
                
                if(vFlip === '1')
                {
                    doorPlane.position.z += height/2-thickness;
                }
                else
                {
                    doorPlane.position.z -= height/2-thickness;
                }
    
                if(vFlip === '1' && hFlip === '1')
                {
                    doorPlane.scaling.x = -1;
                    doorPlane.scaling.y = -1;
                }
                else if(hFlip === '1')
                {
                    doorPlane.scaling.x = -1;
                }
                else if(vFlip === '1')
                {
                    doorPlane.scaling.y = -1;
                }
                return doorPlane;
            }
            else
            {
                const height = (image.height*depth)/image.width;
                const doorPlane = BABYLON.MeshBuilder.CreatePlane('doorPlane',{ width: depth, height: height }, sceneIns);
                doorPlane.material = doorMat;
                doorPlane.rotation = new BABYLON.Vector3(Math.PI / 2, Math.PI / 2, 0);
                doorPlane.position = box.position.clone();
                doorPlane.position.y = 3.2;
                doorPlane.position.x = this.parent.getOriginPos().x;
                
                if(vFlip === '1')
                {
                    doorPlane.position.x += height/2-thickness;
                }
                else
                {
                    doorPlane.position.x -= height/2-thickness;
                }
    
                if(vFlip === '1' && hFlip === '1')
                {
                    doorPlane.scaling.x = -1;
                    doorPlane.scaling.y = -1;
                }
                else if(hFlip === '1')
                {
                    doorPlane.scaling.x = -1;
                }
                else if(vFlip === '1')
                {
                    doorPlane.scaling.y = -1;
                }
                return doorPlane;
            }
        }
        else
        {
            const height = (image.height*width)/image.width;
            const doorPlane = BABYLON.MeshBuilder.CreatePlane('doorPlane',{ width: width, height: height }, sceneIns);
            doorPlane.material = doorMat;
            doorPlane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
            doorPlane.position = box.position.clone();
            doorPlane.position.y = 3.2;
            return doorPlane;
        }
    }

    createRootMesh(pos = null)
    {
        try
        {
            const meshes = [];
            this.viewMeshes.filter(x=>x.info.ctgrName != 'Box').forEach(o=>{
                CsgHelper.bakeTransform(o);
                meshes.push(o);
            });
    
            const mergeMesh = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);

            mergeMesh.info = this.info;
            mergeMesh.moduleEntity = this.moduleEntity;
            mergeMesh.parent = this.moduleEntity?.viewRootMesh;
            
            CsgHelper.bakeTransform(mergeMesh);

            const box = this.viewMeshes.find(x=>x.info.ctgrName === 'Box');
            mergeMesh.position = box.position.clone();
            mergeMesh.alwaysSelectAsActiveMesh = true;
            //mergeMesh.showBoundingBox = true;

            if(pos)
            {
                mergeMesh.position = pos;
            }

            // CsgHelper.initialMatrix(mergeMesh)
            // mergeMesh.parent = this.moduleEntity?.viewRootMesh;

            this.viewMeshes = [];
            this.viewMeshes.push(mergeMesh)
            this.viewMeshes.push(box)
        }
        catch(err)
        {
            console.error(err);
        }
    }

    getButtonData()
    {
        return [
            {
                type:'module',
                press:this.selectModule
            },{
                type:'door-mirror-h',
                press:this.mirrorh
            },{
                type:'door-mirror-v',
                press:this.mirrorv
            },
            // {
            //     type:'copy',
            //     press:this.copy
            // },
            {
                type:'delete',
                press:this.delete
            },
        ];
    }

    createContextPanel(scene)
    {
        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        this.contextPanels.push(panels.toolbar);

        var box = this.getBoundingBox2d(scene);
        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    mirrorv(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorvCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        var wallType = entity.parent.getWorldWallType().type;
        var wallLine = entity.parent.getWallLine();

        if(wallType == "Vertical")
        {
            meshes2d.forEach(o => 
            {
                o.scaling.y = -o.scaling.y;

                var pos2d = o.position.clone();
                pos2d.y = 0;

                var projectPosition = VectorHelper.calcNearestPointOnLine(wallLine.start, wallLine.end, pos2d);
                var dir = BABYLON.Vector3.Normalize(projectPosition.subtract(pos2d));
                o.position = VectorHelper.reflectToPoint(o.position, projectPosition, dir);
            });
            meshes3d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;

                var pos2d = o.position.clone();
                pos2d.y = 0;

                var projectPosition = VectorHelper.calcNearestPointOnLine(wallLine.start, wallLine.end, pos2d);
                var dir = BABYLON.Vector3.Normalize(projectPosition.subtract(pos2d));
                o.position = VectorHelper.reflectToPoint(o.position, projectPosition, dir);
            });
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.y = -o.scaling.y;

                var pos2d = o.position.clone();
                pos2d.y = 0;

                var projectPosition = VectorHelper.calcNearestPointOnLine(wallLine.start, wallLine.end, pos2d);
                var dir = BABYLON.Vector3.Normalize(projectPosition.subtract(pos2d));
                o.position = VectorHelper.reflectToPoint(o.position, projectPosition, dir);
            });
            meshes3d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;

                var pos2d = o.position.clone();
                pos2d.y = 0;

                var projectPosition = VectorHelper.calcNearestPointOnLine(wallLine.start, wallLine.end, pos2d);
                var dir = BABYLON.Vector3.Normalize(projectPosition.subtract(pos2d));
                o.position = VectorHelper.reflectToPoint(o.position, projectPosition, dir);
            });
        }
    }

    mirrorh(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorhCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        var wallType = entity.parent.getWorldWallType().type;
        var entityCenter = entity.getSceneMeshes(scene)[1].position.clone();

        if(wallType == "Vertical")
        {
            meshes2d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;

                var rotatePoint = VectorHelper.reflectToPoint(o.position.clone(), entityCenter, new BABYLON.Vector3(0, 0, 1));
                o.position = rotatePoint;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;

                var rotatePoint = VectorHelper.reflectToPoint(o.position.clone(), entityCenter, new BABYLON.Vector3(0, 0, 1));
                //o.position = rotatePoint;
            });
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;

                var rotatePoint = VectorHelper.reflectToPoint(o.position.clone(), entityCenter, new BABYLON.Vector3(1, 0, 0));
                o.position = rotatePoint;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;

                var rotatePoint = VectorHelper.reflectToPoint(o.position.clone(), entityCenter, new BABYLON.Vector3(1, 0, 0));
                //o.position = rotatePoint;
            });
        }
    }

    setDrawFlipType()
    {
        const v = Number(this.info.flipType[1]);
        const h = Number(this.info.flipType[3]);

        for(let vm of this.getDrawMeshes())
        {
            if(v === 1)
            {
                vm.scaling.y *= -1;
            }
            if(h === 1)
            {
                vm.scaling.x *= -1;
            }
        }
    }

    setViewFlipType()
    {
        const v = Number(this.info.flipType[1]);
        const h = Number(this.info.flipType[3]);

        for(let vm of this.viewMeshes)
        {
            if(v === 1)
            {
                vm.scaling.z *= -1;
            }
            if(h === 1)
            {
                vm.scaling.x *= -1;
            }
        }
    }

    setModuleParent()
    {
        if(!this.moduleEntity)
            return;

        this.drawMeshes1f.forEach(x=>x.parent = this.moduleEntity.draw1fRootMesh);
        this.drawMeshes2f.forEach(x=>x.parent = this.moduleEntity.draw2fRootMesh);
        this.viewMeshes.forEach(x=>x.parent = this.moduleEntity.viewRootMesh);
        
    }

    viewMeshToDrawMeshTrasfrom()
    {
        const drawDoorMeshes = this.getDrawMeshes().filter(o=>o.id === 'doorPlane');
        const drawBoxMeshes = this.getDrawMeshes().filter(o=>o.info.ctgrName === 'Box');
        const viewMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Door')
        const viewBoxMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Box')

        const vbox = viewMesh.getBoundingInfo().boundingBox;
        const vz = vbox.extendSize.z * 2;

        for(let drawDoorMesh of drawDoorMeshes)
        {
            drawDoorMesh.position.x = viewMesh.position.x;
            drawDoorMesh.position.z = viewMesh.position.z;
            drawDoorMesh.rotation.y = viewMesh.rotation.y;

            const dbox = drawDoorMesh.getBoundingInfo().boundingBox;
            const dy = dbox.extendSize.y *2;
            
            const gap = vz - dy;
            if(drawDoorMesh.rotation.y === 0)
            {
                drawDoorMesh.position.z -= gap/2 * drawDoorMesh.scaling.y * this.moduleEntity.viewRootMesh.scaling.z;
            }
            else
            {
                drawDoorMesh.position.x += gap/2 * drawDoorMesh.scaling.y * this.moduleEntity.viewRootMesh.scaling.x;
            }
        }

        for(let drawBoxMesh of drawBoxMeshes)
        {
            drawBoxMesh.position.x = viewBoxMesh.position.x;
            drawBoxMesh.position.z = viewBoxMesh.position.z;
            drawBoxMesh.rotation.y = viewBoxMesh.rotation.y;
        }
    }

    drawMeshToViewMeshTrasfrom()
    {
        const drawDoorMesh = this.getDrawMeshes().find(o=>o.id === 'doorPlane');
        const drawBoxMesh = this.getDrawMeshes().find(o=>o.info.ctgrName === 'Box');
        const viewMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Door')
        const viewBoxMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Box')
        const vbox = viewMesh.getBoundingInfo().boundingBox;
        const vz = vbox.extendSize.z * 2;
        const dbox = drawDoorMesh.getBoundingInfo().boundingBox;
        const dy = dbox.extendSize.y *2;
        const gap = vz - dy;

        viewMesh.scaling.x = drawDoorMesh.scaling.x;
        viewMesh.scaling.z = drawDoorMesh.scaling.y;
        viewBoxMesh.scaling = drawBoxMesh.scaling;
        viewMesh.rotation.y = drawDoorMesh.rotation.y;
        viewBoxMesh.rotation.y = drawBoxMesh.rotation.y;
        viewMesh.position = drawDoorMesh.position.clone();
        viewMesh.position.y = this.info.height/1000;
        viewBoxMesh.position = drawBoxMesh.position.clone();
        viewBoxMesh.position.y = this.info.height/1000;
        
        const sin = Number(Math.sin(viewMesh.rotation.y).toFixed(2));
        const cos = Number(Math.cos(viewMesh.rotation.y).toFixed(2));

        const wallType = this.parent.getWorldWallType().type;
        const rootScale = this.parent.moduleEntity.viewRootMesh.scaling.clone();
        if(this.parent.getWorldWallType().type === 'Vertical')
        {
            if(this.parent.wallType != wallType)
            {
                viewMesh.position.z -= ((gap/2 + this.info.vh/4000) * -cos)*rootScale.z;
            }
            else
            {
                viewMesh.position.x -= ((gap/2 + this.info.vh/4000) * sin)*rootScale.x;
            }
        }
        else
        {
            if(this.parent.wallType != wallType)
            {
                viewMesh.position.x -= ((gap/2 + this.info.vh/4000) * -sin)*rootScale.x;
            }
            else
            {
                viewMesh.position.z -= ((gap/2 + this.info.vh/4000) * cos)*rootScale.z;
            }
        }
        
        this.viewMeshes.forEach(x=>x.parent = this.moduleEntity.viewRootMesh)
    }
}