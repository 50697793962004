<template>
    <div class="bottomInfo">
        <ul>
            <li>
                <hgroup class="mainTit">
                    <h2>Contact</h2>
                    <h1>02-2154-4311</h1>
                    <p>XiGEIST.Official@gmail.com</p>
                </hgroup>
<!-- 
                <div>
                    <hgroup class="mainTit">
                        <h2>Inquiry</h2>
                        <a href="/">상담문의</a>
                    </hgroup>
                </div> -->
            </li>

            <li>
                <hgroup class="mainTit">
                    <h2>Office</h2>
                    <p>서울특별시 서초구 남부순환로 2477</p>
                    <a href="https://map.kakao.com/?urlX=504695&urlY=1106589&q=%EC%84%9C%EC%9A%B8%20%EC%84%9C%EC%B4%88%EA%B5%AC%20%EB%82%A8%EB%B6%80%EC%88%9C%ED%99%98%EB%A1%9C%202477" class="mapIcon" target="_blank"></a>
                </hgroup>
            </li>

            <li>
                <hgroup class="mainTit">
                    <h2>Sample House</h2>
                    <p>충남 당진시 면천면 면천로 1139</p>
                    <a href="https://map.kakao.com/?map_type=TYPE_MAP&q=%EC%B6%A9%EB%82%A8+%EB%8B%B9%EC%A7%84%EC%8B%9C+%EB%A9%B4%EC%B2%9C%EB%A9%B4+%EB%A9%B4%EC%B2%9C%EB%A1%9C+1139&urlLevel=2&urlX=436258&urlY=919338" class="mapIcon" target="_blank"></a>
                </hgroup>
            </li>

            <li>
                <hgroup class="mainTit">
                    <h2>Inquiry</h2>
                    <a href="https://www.xigeist.com/contact/inquiryWrite" class="moreBtn style01">상담문의</a>
                </hgroup>
            </li>
        </ul>

    </div>
</template>

<style scoped>
    @import url('../../assets/css/LogIn/bottomInfo.css');
</style>