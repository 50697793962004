import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import { Vector3 } from '@babylonjs/core/Legacy/legacy';
import SpotItem from './SpotItem'

export default class TopSpotItem extends SpotItem
{
    constructor(target,instance,wallType,is2d = false)
    {
        super(target,instance,wallType,is2d);
        this.direction = new Vector3(0,1,0);
        this.guideVisible = false;
        this.includedOnlyMeshes = [instance.getMeshByName('spotBox')]
        this.isOtherThrough = true;
    }

    setSpotLightProjectionMatrix(){
        const bb = this.target.getHierarchyBoundingVectors()
        const size = bb.max.subtract(bb.min)
        if(this.is2d)
        {
            size.y = 6
        }
        const center = this.target.getBoundingInfo().boundingBox.centerWorld.clone();

        this._projectionTextureProjectionLightMatrix = BABYLON.Matrix.OrthoOffCenterLH(
            size.x/2 + center.x ,
            -size.x/2 + center.x,
            size.z/2 - center.z ,
            -size.z/2 - center.z, 0,1
        );

        //가이드 라인
        const p1 = new BABYLON.Vector3(-size.x/2 + center.x, center.y, -size.z/2 - center.z);
        const p2 = new BABYLON.Vector3(-size.x/2 + center.x, center.y, size.z/2 - center.z);
        const p3 = new BABYLON.Vector3(size.x/2 + center.x,  center.y, size.z/2 - center.z);
        const p4 = new BABYLON.Vector3(size.x/2 + center.x,  center.y, -size.z/2 - center.z);
        const points = [p1,p2,p3,p4,p1];
        const line = BABYLON.MeshBuilder.CreateLines("lines", { points }, this.instance);
        line.color = new BABYLON.Color3(0,0,1)
        line.scaling.z = -1;
        line.isVisible = this.guideVisible;
        line.position.y = 4;

        this.guideLine = line;
        
        this._projectionTextureProjectionLightDirty = false;
        this._projectionTextureDirty = true;
    }

    update(target)
    {
        this.target = target;
        this.guideLine?.dispose();
        this.setSpotLightProjectionMatrix();
    }
}