import DockingEntity from "./DockingEntity";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";

export default class OpeningEntity extends DockingEntity{
    constructor()
    {
        super();
        this.isSimbol = true;
    }

    getButtonData()
    {
        return [
            {
                type:'module',
                press:this.selectModule
            },{
                type:'delete',
                press:this.delete
            },
        ];
    }

    createContextPanel(scene)
    {
        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        this.contextPanels.push(panels.toolbar);

        var box = this.getBoundingBox2d(scene);
        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const info = mesh.info;
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            m1f.info = info;
            m2f.info = info;
            m1f.isVisible = false;
            m2f.isVisible = false;
            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);
        });

        const o1f = this.createSimbol(this.drawScene1f.instance,this.drawMeshes1f);
        o1f.forEach(x=>x.info = this.info)
        //o1f.info = this.info;
        const o2f = this.createSimbol(this.drawScene2f.instance,this.drawMeshes2f);
        o2f.forEach(x=>x.info = this.info)
        //o2f.info = this.info;

        this.drawMeshes1f[0].dispose();
        //this.drawMeshes1f[0] = o1f;
        this.drawMeshes1f[0] = o1f[0];
        this.drawMeshes1f.push(o1f[1]);

        this.drawMeshes2f[0].dispose();
        //this.drawMeshes2f[0] = o2f;
        this.drawMeshes2f[0] = o2f[0];
        this.drawMeshes2f.push(o2f[1]);

        this.drawMeshes=[];
        this.drawMeshes = this.drawMeshes1f.concat(this.drawMeshes2f);
    }

    createSimbol(sceneIns,meshes)
    {
        const box = meshes[0];
        box.isVisible = false;
        const boxInfo = box.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;
        const length = width > depth ? width : depth;
        
        const opPlane = BABYLON.MeshBuilder.CreatePlane(null,{ width: length, height: 0.14 }, sceneIns);
        opPlane.material = sceneIns.getMaterialByName('OpenningMat');
        opPlane.rotation = new BABYLON.Vector3(Math.PI / 2, box.rotation.y, 0);
        opPlane.position = box.position.clone();
        opPlane.position.y = 3.2;
        // opPlane.showBoundingBox = true;
    
        const dashBox = this.createDashBox(opPlane,sceneIns);
        return dashBox;
    }

    createDashBox(plane,sceneIns)
    {
        const width = plane.getBoundingInfo().boundingBox.extendSize.x * 2;
        const height = plane.getBoundingInfo().boundingBox.extendSize.y * 2;
    
        const p1 = new BABYLON.Vector3(-width/2,height/2, 0);
        const p2 = new BABYLON.Vector3(width/2,height/2, 0);
        const p3 = new BABYLON.Vector3(width/2,-height/2, 0);
        const p4 = new BABYLON.Vector3(-width/2,-height/2, 0);

        let dashNb = 20;
        const dashRate = 30;

        if(width > height)
        {
            dashNb = width*dashRate;
        }
        else
        {
            dashNb = height*dashRate;
        }

        const dashedLines = BABYLON.MeshBuilder.CreateDashedLines(null,{points: [p1,p2,p3,p4,p1], dashSize: 1, gapSize: 1, dashNb: dashNb}, sceneIns);
        dashedLines.color = new BABYLON.Color4(0.439, 0.447, 0.455, 1);
        //dashedLines.color = new BABYLON.Color4(1, 0, 0, 1);
        dashedLines.rotation = plane.rotation.clone();
        dashedLines.position = plane.position.clone();
        dashedLines.entity = this;
        plane.entity = this;
        return [plane,dashedLines];
    }
}