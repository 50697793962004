import EntityBase from "./EntityBase";

export default class CornerEntity extends EntityBase{
    constructor()
    {
        super();
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            
            m1f.info = mesh.info;
            m2f.info = mesh.info;
            
            m1f.material = this.drawScene1f.instance.getMaterialByName('WallMat');
            m2f.material = this.drawScene2f.instance.getMaterialByName('WallMat');

            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);
        });
    }

    getViewPosition()
    {
        return this.viewMeshes[0].absolutePosition;
    }

    dispose()
    {
        for(let m of this.drawMeshes1f)
        {
            m.dispose();
        }
        for(let m of this.drawMeshes2f)
        {
            m.dispose();
        }
        for(let m of this.viewMeshes)
        {
            m.dispose();
        }

        if(this.moduleEntity)
        {
            this.moduleEntity.Entities = this.moduleEntity.Entities.filter(x => x != this);
            this.moduleEntity.Corners = this.moduleEntity.Corners.filter(x => x != this);
        }
    }
}