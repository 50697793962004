import { Vector3 } from "@babylonjs/core";

async function loadModuleFromData(entity,data,sceneLoader)
{
    const m = sceneLoader.EntityLoader.modules.find(x=>x.guid === data.guid);
    
    const newModule = await sceneLoader.makeLoadModule(data);
    if(entity.isModule)
    {
        entity = newModule;
    }
    else
    {
        for(let e of newModule.Entities)
        {
            if(e.info.guid === entity.info.guid)
            {
                entity = e;
                break;
            }
        }
    }

    const equlModuleCommands = sceneLoader.history.filter(x=>x.entity?.guid === data.guid)
    for(let c of equlModuleCommands)
    {
        c.entity = newModule;
    }

    const equlModuleEntityCommands = sceneLoader.history.filter(x=>!x.entity.isModule).filter(x=>x.entity?.moduleEntity.guid === data.guid)
    for(let c of equlModuleEntityCommands)
    {
        for(let e of newModule.Entities)
        {
            if(e.info.guid === c.entity.info.guid)
            {
                c.entity = e;
                break;
            }
        }
    }
    if(m)
    {
        m.dispose();
    }
    sceneLoader.drawScene1f.updateWall();
}

function getLocatedScene(entity)
{
    if(entity.isModule)
    {
        return entity.locatedScene; 
    }
    return entity.moduleEntity.locatedScene;
}

export class CreateCommand{
    constructor(entity,data)
    {
        this.entity = entity;
        this.data = data;
        this.sceneLoader = entity.viewScene.viewer.$SceneLoader;
    }

    async undo()
    {
        this.entity.dispose();
    }

    async redo()
    {
        await loadModuleFromData(this.entity,this.data,this.sceneLoader)
    }
}

export class DelteCommand{
    constructor(entity,data)
    {
        this.entity = entity;
        this.data = data;
        this.sceneLoader = entity.viewScene.viewer.$SceneLoader;
    }

    async undo()
    {
        await loadModuleFromData(this.entity,this.data,this.sceneLoader)
    }

    async redo()
    {
        this.entity.dispose();
    }
}

export class MoveCommand{
    constructor(entity,dataA)
    {
        this.entity = entity;
        this.dataA = dataA;
        this.dataB = null;
        this.sceneLoader = entity.viewScene.viewer.$SceneLoader;
    }

    async undo()
    {
        await loadModuleFromData(this.entity,this.dataA,this.sceneLoader)
        getLocatedScene(this.entity).removeAllSelectedMesh()
    }

    async redo()
    {
        await loadModuleFromData(this.entity,this.dataB,this.sceneLoader)
        getLocatedScene(this.entity).removeAllSelectedMesh()
    }
}

export class RotateCommand{
    constructor(entity)
    {
        this.entity = entity;
    }

    async undo()
    {
        this.entity.isHistoryWork = true;
        this.entity.rotateccw(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }

    async redo()
    {
        this.entity.isHistoryWork = true;
        this.entity.rotate(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }
}

export class RotateccwCommand{
    constructor(entity)
    {
        this.entity = entity;
    }

    async undo()
    {
        this.entity.isHistoryWork = true;
        this.entity.rotate(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }

    async redo()
    {
        this.entity.isHistoryWork = true;
        this.entity.rotateccw(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }
}

export class MirrorvCommand{
    constructor(entity)
    {
        this.entity = entity;
    }

    async undo()
    {
        this.entity.isHistoryWork = true;
        this.entity.mirrorv(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }

    async redo()
    {
        this.entity.isHistoryWork = true;
        this.entity.mirrorv(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }
}

export class MirrorhCommand{
    constructor(entity)
    {
        this.entity = entity;
    }

    async undo()
    {
        this.entity.isHistoryWork = true;
        this.entity.mirrorh(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }

    async redo()
    {
        this.entity.isHistoryWork = true;
        this.entity.mirrorh(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }
}

export class DoorSwapCommand{
    constructor(entity)
    {
        this.entity = entity;
    }

    async undo()
    {
        this.entity.isHistoryWork = true;
        this.entity.doorSwap(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }

    async redo()
    {
        this.entity.isHistoryWork = true;
        this.entity.doorSwap(getLocatedScene(this.entity),this.entity)
        getLocatedScene(this.entity).removeAllSelectedMesh()
        this.entity.isHistoryWork = false;
    }
}

