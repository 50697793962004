<template>
    <div id="Result_Wrapper">
        <div id="Result_Body">
            <div class="Result_Container">
                <hgroup>
                    <h1>이메일 찾기</h1>
                    <p>이메일 찾기<br>
                        본인인증을 통해 이메일을 찾으실 수 있습니다.</p>
                </hgroup>

                <div v-if="email == ''">
                    <p>
                        가입된 회원 정보가 없습니다.
                    </p>
                </div>

                <div v-if="email != ''">
                    <p>
                        <span>회원님의 이메일은<br>
                            <strong>{{ email }} 입니다.</strong>
                        </span>
                    </p>
                    <p>
                        <!-- <span>
                            <strong>{{ joindate0 }}</strong>
                        </span> -->
                        <span>가입일<br>
                            <strong>{{ joindate }}</strong>
                        </span>
                    </p>
                </div>

                <div class="FindPWBtnArea">
                    <button>
                        <router-link to="/member/login">로그인</router-link>
                    </button>
                    <router-link to="/member/changePW1" class="findPassWord" v-if="email != ''">비밀번호 변경</router-link>
                </div>
            </div>
        </div>
        <bottomInfo />
        <Footer />
    </div>
    <div>
        <!-- <childPage @update="handleMessage" /> -->
        <!-- <childPage @setInput = "setInput">{{ phone }}</childPage> -->
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/findEmailResult.css');
</style>

<script>
import axios from 'axios';
import bottomInfo from '../Login/bottomInfo.vue'
import Footer from '../Login/joinStep1Footer.vue'

import childPage from '../Login/childPage.vue'

export default {
    components: {
        bottomInfo: bottomInfo,
        Footer: Footer,
        childPage
    },
    data() {
        return {
            email: "",
            phone: "",
            param1: "",
            param2: "",
            joindate: "",
            code: "",
        }
    },
    mounted() {
        //console.log("test");
        this.param1 = this.$route.query.param1;
        this.param2 = this.$route.query.param2;

        console.log(this.param1);
        console.log(this.param2);

        this.findEmail();
    },
    methods: {

        findEmail() {
            var str;
            let data = {
                birthdate: this.param1,
                phonenumber: this.param2,
                code: 0
            }
            axios.post("/server/find_email", JSON.stringify(data), {
            //axios.post("http://localhost:12008/find_email", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log(res.data._data);
                if(res.data._data["length"] != 0) {
                    this.email = res.data._data[0]["USER_EMAIL"];
                    this.phone = res.data._data[0]["PHONE"];
                    this.joindate = res.data._data[0]["REGDATE"].split('T')[0];
                }
                else this.email = "";
                console.log(this.email);
                console.log(this.phone );
                console.log(this.joindate);
            })
        }
    }
}
</script>