<template>
  <div id="babylonViewer" ref="babylonViewer"
      :style="{gridTemplateRows : `calc(50% + ${vBarPosition}px) calc(50% - ${vBarPosition}px)`, gridTemplateColumns : `calc(50% + ${hBarPosition}px) calc(50% - ${hBarPosition}px)`}">
    
    <div id="sizeCtrl"
      @mousemove="sizeCtrlMouseMoveEvent($event)">
      <div id="horizontalCtrl" :style="{left : `calc(50% - 0.2rem + ${hBarPosition}px)`, display:horizontalCtrlDisplay}"
      @mousedown="horizontalCtrlMouseDownEvent($event)"
      @mouseup="horizontalCtrlMouseUpEvent($event)">
      </div>
      <div id="verticalCtrl" :style="{top : `calc(50% - 0.2rem + ${vBarPosition}px)`, display:verticalCtrlDisplay}"
        @mousedown="verticalCtrlMouseDownEvent($event)"
        @mouseup="verticalCtrlMouseUpEvent($event)">
        <img id="change2dBtn1" class="change2dBtn" :style="{left : `calc(${is3DActive?25:50}% + ${hBarPosition/2}px - 0.75rem)`}" @click="view2dChange" src="../assets/images/icons/underLay.png"/>
        <img id="change2dBtn2" v-if="is3DActive" class="change2dBtn" :style="{left : `calc(75% + ${hBarPosition/2}px - 0.75rem)`}" @click="view2dChange" src="../assets/images/icons/underLay.png"/>
      </div>
    </div>

    <div id="screen2dContainer" ref="c2d" :style="{display : c2dDisplay,gridTemplateRows : `calc(50% + ${vBarPosition}px) calc(50% - ${vBarPosition}px)` , gridColumn : `${c2dGridColumn} /span ${c2dGridColumnSpan}`}">
      <div class="Floor_Btn2">
        <div class="checkbox-wrapper-10">
          <input class="tgl tgl-flip" id="cb5" type="checkbox" v-model="if2d1f" :checked="if2d1f" :disabled="!if2d2f"  />
          <label class="tgl-btn btn2d1f" data-tg-off="1층" data-tg-on="1층" for="cb5"></label>
        </div>
        <div class="checkbox-wrapper-10">
          <input class="tgl tgl-flip" id="cb6" type="checkbox" v-model="if2d2f" :checked="if2d2f" :disabled="!if2d1f"  />
          <label class="tgl-btn btn2d2f" data-tg-off="2층" data-tg-on="2층" for="cb6"></label>
        </div>
      </div>
      <canvas id="scene2d2f" @mousemove="sizeCtrlMouseMoveEvent($event)" :style="{gridRow : `${c2d2fGridRow} /span ${c2d2fGridRowSpan}`, display:c2d2fDisplay}" touch-action="none" ref="scene2d2f"></canvas>
      <canvas id="scene2d1f" @mousemove="sizeCtrlMouseMoveEvent($event)" :style="{gridRow : `${c2d1fGridRow} /span ${c2d1fGridRowSpan}`, display:c2d1fDisplay}" touch-action="none" ref="scene2d1f"></canvas>
    </div>
    <div id="screen3dContainer" :style="{display : c3dDisplay,gridColumn: `${c3dGridColumn} /span ${c3dGridColumnSpan}`}">
      <div class="Floor_Btn3">
        <div class="checkbox-wrapper-10">
          <input class="tgl tgl-flip" id="cb7" type="checkbox" v-model="if3d1f" :checked="if3d1f" :disabled="!if3d2f"  />
          <label class="tgl-btn btn3d1f" data-tg-off="1층" data-tg-on="1층" for="cb7"></label>
        </div>
        <div class="checkbox-wrapper-10">
          <input class="tgl tgl-flip" id="cb8" type="checkbox" v-model="if3d2f" :checked="if3d2f" :disabled="!if3d1f" />
          <label class="tgl-btn btn3d2f" data-tg-off="2층" data-tg-on="2층" for="cb8"></label>
        </div>
        <div class="checkbox-wrapper-10">
          <input class="tgl tgl-flip" id="cb9" type="checkbox" v-model="isShowRoof" :checked="isShowRoof" @click="toggleRoof"/>
          <label class="tgl-btn btn3dRoof roofBtn" data-tg-off="지붕" data-tg-on="지붕" for="cb9"></label>
        </div>
      </div>
      <canvas id="scene3d" @mousemove="sizeCtrlMouseMoveEvent($event)" touch-action="none" ref="scene3d"></canvas>
    </div>
    
    <!-- <div class="ViewAll"><img src="../assets/images/icons/viewAll.png" alt=""></div>

    <div class="zoom_Div">
      <p class="z_in"><img src="../assets/images/icons/z-in.png" alt=""></p>
      <p class="z_out"><img src="../assets/images/icons/z-out.png" alt=""></p>
    </div> -->
  </div>
</template>

<style scoped>
@import url('../assets/css/checkBox.css');

#screen2dContainer{
  display: grid;
  grid-column: 1;
  grid-row: 1/span 2;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: white;
  position: relative;
}

#screen3dContainer{
  display: block;
  grid-row: 1/span 2;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: white;
  position: relative;
}
.change2dBtn{
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: -0.5rem;
  z-index: 10;
  cursor: pointer;
  filter: contrast(100%);
}

.change2dBtn:hover{
  filter: contrast(90%);
}


.Floor_Btn2,
.Floor_Btn3 {
  position: absolute;
  width: 150px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-self: center;
  justify-self: end;
  justify-content: space-between;
  z-index: 30;
  right: 15px;
  top: 1%;
}
.Floor_Btn2 {
  width:100px;
  border:1px solid #d9d9d9;
  border-radius:2px;

}
#sizeCtrl{
    grid-column: 1/span 2;
    grid-row: 1 / span 2;
    position: relative;
}

#horizontalCtrl{
    height: 100%;
    background: #D0CCC1;
    width: 0.4rem;
    position: absolute;
    z-index: 4;
}

#verticalCtrl{
    width: 100%;
    background: #D0CCC1;
    height: 0.4rem;
    position: absolute;
    z-index: 2;
}

#horizontalCtrl:hover
{
  background: rgb(88, 88, 88);
  cursor: col-resize;
}

#verticalCtrl:hover{
  background: rgb(88, 88, 88);
  cursor: row-resize;
}

.bgC1 {
  background-color: #006a84; 
}

.bgC2 {
  background-color: #006a84; 
}
#scene2d1f{
  width: 100%;
  height: 100%;
  grid-column: 1;
  grid-row: 2 /span 1;
  z-index: 1;
  background: white;
}
#scene2d2f{
  width: 100%;
  height: 100%;
  grid-column: 1;
  grid-row: 1 /span 1;
  z-index: 1;
  background: white;
}
#scene3d{
  width: 100%;
  height: 100%;
  z-index: 3;
  background: white;
}
#babylonViewer
{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: grid;
}
.zoom_Div {
  border-radius:5px;
  background:#f4f5f6;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-self: center;
  align-items: center;
  width: 50px;
  border:3px solid #ccc;
  grid-column: 1;
  justify-self: end;
  grid-row: 5;
}
.z_in {
  grid-row:1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom:4px solid #ccc;
  cursor: pointer;
}
.z_out {
  height: 100%;
  grid-row: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.z_in img {
  height: 25px;
}
.z_out img {
  width: 25px;
}
.ViewAll {
  grid-row: 3;
  grid-column: 1;
  justify-self: end;
  width: 50px;
  background:#f4f5f6;
  border:3px solid #ccc;
  border-radius:5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.First,.Second {
  width: 45%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius:5px;
  cursor: pointer;
}
.Second,
.First {
  color:#696d6f;
  background:#f5f5f5;
  padding: 5% 0;
  border:3px solid #ccc;
}

.First:hover, .Second:hover{
  background:#d8d8d8;
}
canvas{
  outline: none;
}
</style>

<script>
export default {
  name: 'BabylonViewer',
  props: {
    command : {},
    if3dRoof:false,
  },
  data() {
    return {
      isShowRoof:true, //지붕 버튼
      isShowFlatRoof:false, //평지붕 버튼
      isHbarClick : false,
      hBarPosition : 0,
      hBarOrgPosition : 0,
      isVbarClick : false,
      vBarPosition : 0,
      vBarOrgPosition : 0,
      if2d1f : true,
      if2d2f : true,
      if3d1f : true,
      if3d2f : true,
      btnActColor : '#d8d8d8',
      btnNonColor : '#f5f5f5',
      c2dGridColumn : 1,
      c2dGridColumnOrigin : 1,
      c2dGridColumnSpan : 1,
      c2d1fGridRow : 2,
      c2d1fGridRowSpan : 1,
      c2d1fDisplay : 'block',
      c2d2fGridRow : 1,
      c2d2fGridRowSpan : 1,
      c2d2fGridColumnSpan : 1,
      c2d2fDisplay : 'block',
      c3dDisplay : 'block',
      c3dGridColumn : 2,
      c3dGridColumnSpan : 1,
      c3dGridColumnOrigin : 2,
      change2dPos : 25,
      c2dDisplay : 'grid',
      horizontalCtrlDisplay : 'block',
      verticalCtrlDisplay : 'block',
      loadModuleName : '',
      is3DActive : true,
      doors : [],
      windows : [],
    }
  },

  watch:{
    command()
    {
      if(this.command.oder === "CreateModule")
      {
        if(this.command.moduleName != this.loadModuleName)
        {
          this.loadModuleName = this.command.moduleName;
          this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
          this.$SceneLoader.CreateModule(this.command.moduleName,this.command.Path,this.command.horizontal,this.command.vertical);
        }
      }
      else if(this.command.oder === "CreateWindow")
      {
        if(this.command.name != this.loadModuleName)
        {
          this.loadModuleName = this.command.data.name;
          this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
          this.$SceneLoader.CreateWindow(this.command.data);
        }
      }
      else if(this.command.oder === "CreateDoor")
      {
        if(this.command.data.name != this.loadModuleName)
        {
          this.loadModuleName = this.command.data.name;
          this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
          this.$SceneLoader.CreateDoor(this.command.data);
        }
      }
      else if(this.command.oder === "CreateUnit")
      {
        if(this.command.name != this.loadModuleName)
        {
          this.loadModuleName = this.command.name;
          this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
          this.$SceneLoader.CreateUnit(this.command.name,this.command.Path);
        }
      }
      else if(this.command.oder === "CreateOpening")
      {
        if(this.command.name != this.loadModuleName)
        {
          this.loadModuleName = this.command.name;
          this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
          this.$SceneLoader.CreateOpening();
        }
      }
      else if(this.command.oder === "ViewChange")
      {
        this.viewUpdate(this.command);
        this.$nextTick(() => {
          this.$SceneLoader.Resize();
        });
      }
      else if(this.command.oder === 'ViewPosChange')
      {
        this.viewChange(this.command);
        this.$nextTick(() => {
          this.$SceneLoader.Resize();
        });
      }
      else if(this.command.oder === 'CreateWall')
      {
        this.$SceneLoader.drawScene1f.removeAllSelectedMesh();
        this.$SceneLoader.CreateWall();
      }
      else if (this.command.oder === 'isShowFlatRoof') 
      {
        this.isShowFlatRoof = this.command.isShowFlatRoof;
        this.toggleRoof();
      }
    },
    if2d1f(newValue, oldValue)
    {
      if(newValue)
      {
        this.c2d1fDisplay = 'block';
        this.verticalCtrlDisplay = 'block'
        this.c2d2fGridRow = 1;
        this.c2d2fGridRowSpan = 1;
      }
      else
      {
        this.c2d1fDisplay = 'none';
        this.c2d2fGridRow = 1;
        this.c2d2fGridRowSpan = 2;
        this.verticalCtrlDisplay = 'none'
      }
      this.$nextTick(() => {
        this.$SceneLoader.Resize();
      });
    },
    if2d2f(newValue, oldValue)
    {
      if(newValue)
      {
        this.c2d2fDisplay = 'block';
        this.verticalCtrlDisplay = 'block'
        this.c2d1fGridRow = 2;
        this.c2d1fGridRowSpan = 1;
      }
      else
      {
        this.c2d2fDisplay = 'none';
        this.c2d1fGridRow = 1;
        this.c2d1fGridRowSpan = 2;
        this.verticalCtrlDisplay = 'none'
      }
      this.$nextTick(() => {
        this.$SceneLoader.Resize();
      });
    },
    if3d1f(newValue, oldValue)
    {
      const targets = this.$SceneLoader.EntityLoader.modules.filter(x=>x.info.floor === '1F');
      targets.forEach(target => {
        target.viewRootMesh.setEnabled(newValue);
      });
      setTimeout(() => {
        this.$SceneLoader.drawScene1f.updateWall();
      }, 100);
    },
    if3d2f(newValue, oldValue)
    {
      const targets = this.$SceneLoader.EntityLoader.modules.filter(x=>x.info.floor === '2F');
      targets.forEach(target => {
        target.viewRootMesh.setEnabled(newValue);
      });
      setTimeout(() => {
        this.$SceneLoader.drawScene1f.updateWall();
      }, 100);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initailScenes();
    });
  },
  methods: {
    toggleRoof() {
      this.$nextTick(() => {
        setTimeout(()=>{
            this.$SceneLoader.isShowRoof = this.isShowFlatRoof && this.isShowRoof;
            this.$SceneLoader.updateSceneRoof();
          },100)
        });
    },
    initailScenes()
    {
      if(this.$SceneLoader.drawScene1f || this.$SceneLoader.drawScene2f || this.$SceneLoader.viewScene)
      {
        location.reload();
      }
      this.$SceneLoader.drawScene1f = this.$SceneLoader.LoadScene(true,this.$refs.scene2d1f,this,"1F");
      this.$SceneLoader.drawScene2f = this.$SceneLoader.LoadScene(true,this.$refs.scene2d2f,this,"2F");
      this.$SceneLoader.viewScene = this.$SceneLoader.LoadScene(false,this.$refs.scene3d,this);
      this.$SceneLoader.drawScenesInit();
      window.addEventListener('resize',()=>{
        this.$nextTick(() => {
          this.$SceneLoader.Resize();
          this.hBarOrgPosition = 0;
          this.vBarOrgPosition = 0;
        });
      })
    },
    setDoorData(data)
    {
      this.doors = [...data];
    },
    setWindowData(data)
    {
      this.windows = [...data];
    },
    horizontalCtrlMouseDownEvent(e)
    {
      e.preventDefault();
      this.isHbarClick = true;
      if(this.hBarOrgPosition === 0)
      {
        const rect = this.$refs.babylonViewer.getBoundingClientRect();
        this.hBarOrgPosition = rect.width/2 + rect.left;
      }
    },
    horizontalCtrlMouseUpEvent(e)
    {
      e.preventDefault();
      if(this.isHbarClick)
      {
        this.isHbarClick = false;
      }
    },
    verticalCtrlMouseDownEvent(e)
    {
      e.preventDefault();
      this.isVbarClick = true;
      if(this.vBarOrgPosition === 0)
      {
        const rect = this.$refs.babylonViewer.getBoundingClientRect();
        this.vBarOrgPosition = rect.height/2 + rect.top;
      }
    },
    verticalCtrlMouseUpEvent(e)
    {
      e.preventDefault();
      if(this.isVbarClick)
      {
        this.isVbarClick = false;
      }
    },
    sizeCtrlMouseMoveEvent(e)
    {
      e.preventDefault();
      if(this.isHbarClick)
      {
        if(e.clientX - 343 <220)
        {
          this.hBarPosition = this.hBarPosition;
          this.isHbarClick = false;
        }
        else if(e.clientX - 343 - this.hBarOrgPosition >220)
        {
          this.hBarPosition = this.hBarPosition;
          this.isHbarClick = false;
        }
        else
        {
          this.hBarPosition = (e.clientX - this.hBarOrgPosition);
        }
        this.$SceneLoader.Resize();
      }
      if(this.isVbarClick)
      {
        // if(e.clientY - 130 <120)
        // {
        //   this.vBarPosition = this.vBarPosition;
        //   this.isVbarClick = false;
        // }
        // else if(e.clientY - 130 - this.vBarOrgPosition >120)
        // {
        //   this.vBarPosition = this.vBarPosition;
        //   this.isVbarClick = false;
        // }
        // else{
        //   this.vBarPosition = (e.screenY - this.vBarOrgPosition);
        // }
        // console.log(this.vBarOrgPosition)
        this.vBarPosition = (e.clientY - this.vBarOrgPosition);
        this.$SceneLoader.Resize();
      }
    },
    viewUpdate(command)
    {
      const is2DActive = command.is2DActive;
      const is3DActive = command.is3DActive;
      
      this.is3DActive = is3DActive&&is2DActive;

      if(is2DActive && is3DActive)
      {
        this.c2dDisplay = 'grid'
        this.c3dDisplay = 'block'
        this.c2dGridColumn = this.c2dGridColumnOrigin;
        this.c3dGridColumn = this.c3dGridColumnOrigin;
        this.c2dGridColumnSpan = 1;
        this.c3dGridColumnSpan = 1;
        this.horizontalCtrlDisplay = 'block'
      }
      else if(is2DActive)
      {
        if(this.c2dGridColumnOrigin === 1)
        {
          this.c2dDisplay = 'grid'
          this.c3dDisplay = 'none'
          this.c3dGridColumn = 2;
          this.c2dGridColumnSpan = 2;
          this.c3dGridColumnSpan = 1;
          this.horizontalCtrlDisplay = 'none'
        }
        else
        {
          this.c2dDisplay = 'none'
          this.c3dDisplay = 'block';
          this.c3dGridColumn = 1;
          this.c2dGridColumnSpan = 1;
          this.c3dGridColumnSpan = 2;
          this.horizontalCtrlDisplay = 'none'
        }
      }
      else if(is3DActive)
      {
        if(this.c2dGridColumnOrigin === 1)
        {
          this.c2dDisplay = 'none'
          this.c3dDisplay = 'block';
          this.c3dGridColumn = 1;
          this.c2dGridColumnSpan = 1;
          this.c3dGridColumnSpan = 2;
          this.horizontalCtrlDisplay = 'none'
        }
        else
        {
          this.c2dDisplay = 'grid'
          this.c3dDisplay = 'none'
          this.c3dGridColumn = 2;
          this.c2dGridColumnSpan = 2;
          this.c2dGridColumn = 1;
          this.c3dGridColumnSpan = 1;
          this.horizontalCtrlDisplay = 'none'
        }
      }
    },
    viewChange(command)
    {
      this.c2dGridColumnOrigin = command.pos2d;
      this.c3dGridColumnOrigin = command.pos3d;
      this.c2dGridColumn = this.c2dGridColumnOrigin;
      this.c3dGridColumn = this.c3dGridColumnOrigin;
    },
    view2dChange()
    {
      const tempRow = this.c2d2fGridRow;
      this.c2d2fGridRow = this.c2d1fGridRow;
      this.c2d1fGridRow = tempRow;

      this.$nextTick(() => {
        this.$SceneLoader.Resize();
      });
    }
  },
}
</script>