<template>

</template>

<script>
export default {

data() {
    
},
mounted() {
    //window.location.href = 'http://localhost:64596/checkplus_main_find.asp';
    window.location.href = this.$AUTHURL + 'checkplus_main_find.asp';
},
methods: {
}

}
</script>