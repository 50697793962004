<template>
    <footer>
        <div id="FooterWrapper">
            <div class="FooterContainer">
                <div class="Footer_Left">
                    <img src="../../assets/images/LogoW.png" alt="">
                
                    <ul>
                        <li>
                            <a href="https://www.xigeist.com/util/personalPolicy" style="color:#fff">개인정보 처리방침</a>
                        </li>
                        <li>
                            <a href="https://www.xigeist.com/util/termsUse">이용약관</a>
                        </li>
                        
                        <li>
                            <ul class="selectContainer" v-show="showFooterSelect">
                                <li>
                                    <a href="">FAMILY SITE</a>
                                </li>
                
                                <li>
                                    <a href="https://www.xi.co.kr/" @click="selectedLink = '자이'">자이</a>
                                </li>
                
                                <li>
                                    <a href="https://www.gsenc.com/" @click="selectedLink = 'GS건설'">GS건설</a>
                                </li>
                
                                <li>
                                    <a href="https://xisnd.com/" @click="selectedLink = '자이S&D'">자이S&D</a>
                                </li>
                
                                <li>
                                    <a href="https://www.elysian.co.kr/main.asp" @click="selectedLink = '엘리시안'">엘리시안</a>
                                </li>
                            </ul>

                            <a href="#">FAMILY SITE</a>
                            <img src="../../assets/images/icons/select_footer.png" alt="" @click="clickFooterSelect">
                        </li>
                    </ul>

                    <div class="bottomFooter">
                        <p>자이가이스트(주)</p>
                        <p>서울 종로구 종로33</p>
                        <p>대표 : 남경호</p>
                        <p>사업자 등록번호 : 390-81-01823</p>
                    </div>
                </div>


            
            
            <div class="copyrightFooter">
                <a href="https://www.youtube.com/channel/UCH7HVftSlwVTVBGo-xwi-5A"></a>
                <p>COPYRIGHT 2021 © XIGEIST. ALL RIGHTS RESERVED</p>
            </div>
            </div>
        </div>

    </footer>

</template>

<style scoped>
@import url('../../assets/css/LogIn/joinStep1Footer.css');
</style>

<script>
    export default {
        data() {
            return {
                showFooterSelect:false
            }
        },

        methods: {
            clickFooterSelect() {
                this.showFooterSelect = !this.showFooterSelect
            }
        }

    }
</script>