<template>
    <div id="MyHome_Main">
        <Header />

        <div class="MyHome_Div" v-if="currentPage === 'myHome'">

            <div class="Home_Wrapper">
                <div v-for="(home, index) in fileList" :key="index">
                    <div class="Home_Container" v-if="!home.isEmpty">
                        <router-link @click="saveFileToLocal(home)"
                            :to="{ name: 'ModuleMake', params: { typeName: stringNullOrEmpty(home?.FILE_NAME) ? 'edit' : home?.FILE_NAME } }"
                            class="Home_Thumbs">
                            <input type="radio" id="h1" name="myhome">
                            <label for="h1">
                                <img :src="home.THUMB_IMG" alt="" style="object-fit:cover;">
                            </label>
                        </router-link>

                        <div class="Thumb_Name">
                            <!-- 이름 편집 영역 -->
                            <div v-if="!home.isNameEditing" class="inputWrapper">
                                <h4 v-if="!home.showClone">{{ home.FILE_NAME }}</h4>
                            </div>

                            <div v-else class="inputActive">
                                <input type="text" v-model="home.FILE_NAME">
                                <button @click="saveName(home)" class="inputBtn">확인</button>
                            </div>

                            <p class="ProtoName">{{ home?.TYPE }}</p>

                            <p class="date">{{ home.CREATE_DATE }}</p>

                            <div class="Thumb_info">
                                <img src="../assets//images/icons/size.png" alt="">
                                <p>{{ home.AREA }}㎡</p>

                                <img src="../assets//images/icons/fl.png" alt="">
                                <p>{{ home.FLOOR }}F</p>

                                <!-- <img src="../assets//images/icons/r.png" alt="">
                                <p>3 BedRooms</p>
        
                                <img src="../assets//images/icons/bt.png" alt="">
                                <p>3 BathRooms</p> -->
                            </div>
                        </div>

                        <!-- <div class="MoreBtn">
                            <img src="../assets//images/icons/more.png" alt="">
                        </div> -->
                        <div class="MoreBtn">
                            <input type="button" name="tabOption" :id="`tabOption${index}`" value="Option">
                            <label :for="`tabOption${index}`">
                                <img src="../assets/images/icons/more.png" alt="">
                            </label>

                            <div class="OptionWrapper" :id="`tabOption${index}`">
                                <ul>
                                    <li @click="toggleActive($event)">
                                        <router-link @click="saveFileToLocal(home)"
                                            :to="{ name: 'ModuleMake', params: { typeName: stringNullOrEmpty(home?.FILE_NAME) ? 'edit' : home?.FILE_NAME } }">
                                            <input type="radio" name="tabOpen" id="open" value="open">
                                            <label for="open">열기</label>
                                        </router-link>
                                        <!-- <input type="radio" name="tabOpen" id="open" value="open">
                                        <label for="open">열기</label> -->
                                    </li>
                                    <li @click="startEditingName(home)">
                                        <input type="radio" name="tabOpen" id="changeNM" value="change">
                                        <label for="change">이름 바꾸기</label>
                                    </li>
                                    <li @click="cloneHome(home)">
                                        <input type="radio" name="tabOpen" id="copySlot" value="copy">
                                        <label for="copy">복제</label>
                                    </li>
                                    <li @click="showDeleteModal(home.IDX)">
                                        <input type="radio" name="tabOpen" id="delSlot" value="delete">
                                        <label for="delete">삭제</label>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <!-- 삭제 버튼 클릭시.. (모달 팝업) START -->
                        <div class="modal" v-show="home.isModalVisible">
                            <div class="modal-content">
                                <div class="Deletemodal_Tit">
                                    <img src="../assets//images/icons/alert(big).png" alt="">
                                    <p>정말 삭제하시겠습니까?<br>
                                        삭제한 프로젝트는 복구할 수 없습니다.</p>
                                </div>

                                <div class="Deletemodal_Btn">
                                    <!-- <button @click="confirmDelete(index)">확인</button>
                                    <button @click="cancelDelete(index)">취소</button> -->
                                </div>

                            </div>
                        </div>
                        <!-- 삭제 버튼 클릭시.. (모달 팝업) END -->
                    </div>


                    <!-- 복제된 슬롯 START -->


                    <!-- + 새 프로젝트 만들기 START -->
                </div>

                <!-- + 새 프로젝트 만들기 START -->
                <div v-if="fileList.length < 5">
                    <router-link to="/edit" class="addHouse">
                        <img src="../assets//images/icons/plus.png" alt="" class="add">
                        새 프로젝트
                    </router-link>
                </div>

                <div v-if="isSlotAlertVisible" class="slotAlert">
                    <img src="../assets/images/icons/danger.png" alt="">
                    저장 공간이 부족합니다
                </div>
                <!-- + 새 프로젝트 만들기 END -->
            </div>

        </div>
        <SideMenu />
        <div id="myPage_Root" v-if="currentPage === 'myPage'">
            <div class="myPage_Wrapper">
                <div class="myPage_Content">
                    <div class="userInfo_Wrapper">
                        <h4>사용자 정보</h4>
                        <div class="info_Container">
                            <ul>
                                <li>이메일</li>
                                <li>이름</li>
                                <li>전화번호</li>
                                <li>회원 가입일</li>
                            </ul>

                            <ul>
                                <li><input type="email" readonly v-model="userEmail"></li>
                                <li><input type="text" readonly v-model="userName"></li>
                                <li>
                                    <input type="tel" readonly v-model="userPhone1">
                                    <input type="tel" readonly v-model="userPhone2">
                                    <input type="tel" readonly v-model="userPhone3">
                                </li>
                                <li><input type="text" v-model="userDate" readonly></li>
                            </ul>
                        </div>
                        <!-- <div class="button_Wrap">
                            <button @click="updateInfo" v-if="userCode == 0">저장</button>
                        </div> -->
                    </div>


                    <div class="managePW_Container" v-if="userCode == 0">
                        <div class="userPW_Wrapper">
                            <h4>비밀번호 관리</h4>
                            <div class="managePW_List">
                                <ul>
                                    <li>현재 비밀번호</li>
                                    <li>새 비밀번호</li>
                                    <li>새 비밀번호 확인</li>
                                </ul>

                                <ul>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_old"></li>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_new"></li>
                                    <li><input type="password" placeholder="8~16자 영문 대 소문자, 숫자, 특수문자 조합"
                                            v-model="password_new_verify"></li>
                                </ul>
                            </div>
                            <div class="button_Wrap">
                                <button @click="updatePassword">저장</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <AdminLogin @childEvent="LoginChild" v-if="showAdminLogIn"/>
    </div>
</template>

<style>
@import url("../assets//css//myHome.css");
</style>

<script>
import Cookies from 'js-cookie';
import axios from 'axios';
import CryptoJS from "crypto-js";
import AdminLogin from './Login/AdminLogin.vue';
import SideMenu from '../components/essential/SideMenu.vue'
import Header from '../components/essential/headerTemplate.vue'

export default {
    props: {
        home: Object, // home 객체를 받는 prop
    },
    components: {
        AdminLogin,
        SideMenu,
        Header
    },
    data() {
        return {
            showAdminLogIn: false,
            homes: [],
            activeItem: null,
            curType: {},
            isNameEditing: false, // 이름 바꾸기 클릭시 나오는 input..
            newName: "", // 새로운 이름 저장할 변수
            isModalVisible: false,
            isSlotAlertVisible: false,
            //usedSlotCount:0 //사용중인 슬롯 갯수
            token: {},
            fileList: [],
            currentPage: 'myHome',
            userEmail: "",
            userName: "",
            userPhone: "",
            userDate: "",
            userPhone1: "",
            userPhone2: "",
            userPhone3: "",
            userCode: "",
            password_old: "",
            password_new: "",
            password_new_verify: "",
            userIdx: "",
            fileCount: ""
        }
    },
    async created() {
        await this.getToken();
        //await this.getFileList();
        await this.getUserInfo();
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
        localStorage.setItem('load', '');
        localStorage.setItem('loadIdx', '');
    },
    methods: {
        aesEncrypt128(text) {
        const key = CryptoJS.enc.Utf8.parse('1234567890123456');

        // IV (초기 벡터)
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456');

        // AES-128 암호화
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encrypted;
    },

        AdminLogin() {
            this.showAdminLogIn = true;
        },
        LoginChild() {
            this.showAdminLogIn = false;
        },
        async getToken() {
            const cookie = Cookies.get('token');
            let data = {
                token: cookie == undefined ? '' : cookie
            }
            const res = await axios.post("/server/token_check", JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

            }
            else if (res.data._data["length"] == 0) {
                alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
                this.$router.push('/member/login');
            }
            else if (res.status == 200) {
                this.userIdx = res.data._data[0]["idx"];
                await this.getFileList();
                await this.getUserInfo();
            }
        },
       
        async getFileList() {
            const res = await axios.post("/server/read_file",
                `{ "key":"READ", "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            if (res.status == 200) {
                this.fileList = [...res.data._data];
                this.fileCount = res.data._data["length"];
                console.log(this.fileCount);
            }
        },
        async getUserInfo() {
            const res = await axios.post("/server/get_mypage",
                `{ "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            this.userName = res.data._data[0]["NAME"];
            this.userEmail = res.data._data[0]["USER_EMAIL"];
            this.userPhone = res.data._data[0]["PHONE"];
            this.userPhone1 = this.userPhone.slice(0, 3);
            this.userPhone2 = this.userPhone.slice(3, 7);
            this.userPhone3 = this.userPhone.slice(7);
            this.userDate = res.data._data[0]["REGDATE"].split('T')[0];
            this.userCode = res.data._data[0]["CODE"];
            //})
        },
        saveFileToLocal(home) {
            localStorage.setItem('load', home.FILE_DATA);
            localStorage.setItem('loadIdx', home.IDX);
        },
        //Home_Container (or Home_ContainerClone) 삭제..
        async showDeleteModal(idx) {
            // 해당 홈 컨테이너의 isModalVisible를 true로 초기화
            const res = await axios.post('/server/delete_file',
                `{
            "key":"DELETE",
            "idx":"${idx}"
            }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                });
            if (res.status == 200) {
                await this.getFileList();
            }
        },
        cancelDelete(index) {
            this.homes[index].isModalVisible = false;
        },
        // Home_Container 복제..
        async cloneHome(home) {

            var Enfile_data = this.aesEncrypt128(home.FILE_DATA);
            var Enfile_name = this.aesEncrypt128(home.FILE_NAME+"-복사본");
            var Enthumb_img = this.aesEncrypt128(home.THUMB_IMG);

            const saveData = `{
                "key":"INSERT",
                "user_idx":"${this.userIdx}",
                "idx":"",
                "file_data":"${Enfile_data}",
                "file_name":"${Enfile_name}",
                "type":"${home.TYPE}",
                "create_date":"",
                "update_date":"",
                "thumb_img":"${Enthumb_img}",
                "AREA": ${home.AREA},
                "FLOOR": ${home.FLOOR}
            }`

            var saveResult = saveData.replaceAll("0x","bimpeersyyy").replaceAll("0X","bimpeersYYY").replaceAll("xor","bimpeersxxx").replaceAll("XOR","bimpeersXXX").replaceAll("+","bimpeersplus")
            if (this.fileCount < 5) {
                const res = await axios.post('/server/save_file', saveResult,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                }
                );
                if (res.status == 200) {
                    await this.getFileList();
                }
            }
            else {
                alert("저장 가능한 최대 슬롯은 5개입니다");
            }
        },
        // 이름 바꾸기 클릭시 실행되는 input 편집모드 start
        startEditingName(home) {
            home.isNameEditing = !home.isNameEditing;
        },

        // 변경한 이름 저장..
        async saveName(home) {
            // console.log(`New name for ${home.typeName}: ${home.newName}`);
            home.isNameEditing = false;
            //home.showOption = false;
            console.log(home)

            var Enfile_data = this.aesEncrypt128(home.FILE_DATA);
            var Enfile_name = this.aesEncrypt128(home.FILE_NAME);
            var Enthumb_img = this.aesEncrypt128(home.THUMB_IMG);

            const saveData = `{
                "key":"INSERT",
                "user_idx":"${this.userIdx}",
                "idx":"${home.IDX}",
                "file_data":"${Enfile_data}",
                "file_name":"${Enfile_name}",
                "type":"${home.TYPE}",
                "create_date":"",
                "update_date":"",
                "thumb_img":"${Enthumb_img}",
                "AREA": ${home.AREA},
                "FLOOR": ${home.FLOOR}
            }`
            var saveResult = saveData.replaceAll("0x","bimpeersyyy").replaceAll("0X","bimpeersYYY").replaceAll("xor","bimpeersxxx").replaceAll("XOR","bimpeersXXX").replaceAll("+","bimpeersplus")
            const res = await axios.post('/server/save_file',saveResult,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                });
            if (res.status == 200) {
                await this.getFileList();
            }
        },
        // OptionWrapper ul li
        toggleActive(action, home) {
            // '이름 바꾸기' 클릭 시 startEditingName 메서드 호출
            if (action === 'name') {
                this.startEditingName(home);
            }
        },


        // 새 프로젝트 슬롯 중에서 비어있는 슬롯 제거
        removeUnusedNewProjectSlots() {
            const maxSlots = 5;
            const dataSlotCount = this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;
            const emptyNewProjectSlotCount = this.homes.filter(x => x.isEmpty && x.isNewProjectSlot).length;

            if (dataSlotCount <= maxSlots && emptyNewProjectSlotCount >= 0) {
                const indexToRemove = this.homes.findIndex(x => x.isEmpty && x.isNewProjectSlot);
                this.homes.splice(indexToRemove, 1);
            }
        },
        logout() {
            alert("로그아웃 합니다");
            if(this.userCode == 1) {
                console.log("카카오로그아웃");
                this.kakaoLogout();
            }
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
            
            //document.cookie = "token=" + "";
            //document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/member;";
            //window.location.href = "http://localhost:8080/member/login"
            this.$router.push('/member/login');
        },
        navigateToPage(page) {
            this.currentPage = page;
        },
        updateInfo() { // 마이페이지 회원 정보 업데이트
            var name = this.userName;
            var phonenumber = this.userPhone1 + this.userPhone2 + this.userPhone3;
            axios.post("/server/update_info",
                `{ "user_idx":"${this.userIdx}" , "name":"${name}", "phonenumber":"${phonenumber}" }`).then(res => {
                    if (res.data._result[0]["RESULTCD"] == "1") {
                        alert("회원정보가 수정되었습니다");
                    }
                })
        },
        updatePassword() { // 마이페이지 비밀번호 업데이트
            var hash_old = CryptoJS.SHA256(this.password_old).toString();
            var hash_new = CryptoJS.SHA256(this.password_new).toString();
            if (this.password_new != this.password_new_verify) {
                alert("비밀번호가 서로 일치하지 않습니다.");
            }
            else {
                axios.post("/server/update_password",
                    `{ "user_idx":"${this.userIdx}" , "hashPw_old":"${hash_old}", "hashPw_new":"${hash_new}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                }).then(res => {
                        if (res.data._result[0]["RESULTCD"] == "2") {
                            alert("현재 비밀번호를 확인하세요.");
                        } else if (res.data._result[0]["RESULTCD"] == "1") {
                            alert("비밀번호가 변경되었습니다");
                        }
                    })
            }
        },
        stringNullOrEmpty(value) {
            return value == '' || value == undefined || value == null;
        },
        kakaoLogout() {
            window.Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    console.log(response);
                    document.cookie = "token=" + "";
                    //alert("로그아웃 되었어용");
                },
                fail: function (error) {
                    console.log(error);
                },
            });
        },
    },
    beforeUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    },
    computed: {
        availableSlotCount() {
            const maxSlots = 5;
            return maxSlots - this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;
        },

        // isSlotAlertVisible() {
        //     const maxSlots = 5;
        //     const dataSlotCount = this.homes.filter(x => !x.isEmpty && !x.isNewProjectSlot).length;

        //     // 슬롯이 가득 찼을 때만 true를 반환
        //     return dataSlotCount >= maxSlots;
        // },
    },


}
</script>