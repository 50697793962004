<template>

</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            param1: "",
            birth_temp: "",
            birth_year: "",
            birth_month: "",
            birth_date: "",
            birth: "",
            phone: "",
            key: ""
        }
    },
    mounted() {
        alert("본인인증이 완료되었습니다.");
        window.close();
        

        this.param1 = this.$route.query.param1;
        var str = this.param1.split("-");
        console.log(this.param1);

        this.birth_temp = str[0];
        this.birth_year = this.birth_temp.substring(0, 4);
        this.birth_month = this.birth_temp.substring(4, 6);
        this.birth_date = this.birth_temp.substring(6, 8);
        this.birth = this.birth_year + '-' + this.birth_month + '-' + this.birth_date;

        this.phone = str[1];

        console.log(this.birth);
        console.log(this.phone);

        //this.findEmail();
        opener.location.href = this.$TARGETURL + 'member/changePW2?param1=' + encodeURIComponent(this.birth) + '&param2=' + encodeURIComponent(this.phone);
    },
    methods: {
        
    }
}
</script>