import DoorEntity from "../Entities/DoorEntity"
import UnitEntity from "../Entities/UnitEntity"
import ModuleEntity from "../Entities/ModuleEntity"
import WallEntity from "../Entities/WallEntity"
import WindowEntity from "../Entities/WindowEntity"
import OpeningEntity from "../Entities/OpeningEntity"
import FloorEntity from "../Entities/FloorEntity"
import VoidEntity from "../Entities/VoidEntity"
import CornerEntity from "../Entities/CornerEntity"
import StairsEntity from "../Entities/StairsEntity"
import TempWallEntity from "../Entities/TempWallEntity"
import TempOpningEntity from "../Entities/TempOpningEntity"
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import {SceneLoader} from "@babylonjs/core"
import { Vector3 } from "@babylonjs/core";
import '@babylonjs/loaders'
import * as Material from '@babylonjs/materials'
import CsgHelper from '../Helper/CsgHelper'

export default class EntityLoader{
    constructor(sceneLoader)
    {
        this.modules = [];
        this.roofs = [];
        this.loader = sceneLoader;
    }

    async LoadModule(moduleName,path,h,v,drawScene1f,drawScene2f,viewScene,guid)
    {
        const container = await BABYLON.SceneLoader.LoadAssetContainerAsync(path, `${moduleName}.obj`);
        const meshes = container.meshes;
        const moduleBox = BABYLON.MeshBuilder.CreateBox(null, {size : 1},viewScene.instance);
        const viewer = viewScene.viewer;
        moduleBox.isVisible = false;
        moduleBox.setEnabled(false);

        for(let i = 0; i<meshes.length; i++)
        {
            const mesh = meshes[i];

            const tempBox = mesh.getBoundingInfo().boundingBox;
            const tempPos = Vector3.Center(tempBox.minimum, tempBox.maximum);
            
            const info = this.parsingInfo(mesh.id);
            info.guid = this.loader.CreateGuid();
            viewScene.instance.addMesh(mesh);
            CsgHelper.bakeTransform(mesh);
            mesh.position = tempPos;
            mesh.material.specularColor = new BABYLON.Color3(0, 0, 0); 
            mesh.material.specularPower = 0;
            mesh.info = info;
            mesh.parent = moduleBox;
        }
        try
        {
            this.findMeshParent(meshes);
            const moduleEnt = this.createEntity(meshes,viewScene,drawScene1f,drawScene2f,moduleName,guid);
            this.inputBox(meshes,moduleEnt);
            moduleEnt.viewRootMesh = moduleBox;
            moduleEnt.drawScene1f = drawScene1f;
            moduleEnt.drawScene2f = drawScene2f;
            moduleEnt.viewScene = viewScene;
            moduleEnt.horizontal = h;
            moduleEnt.vertical = v;
            moduleBox.entity = moduleEnt;
            this.findEntityParent(moduleEnt);
            await moduleEnt.moduleDraw();

            // for(let w of moduleEnt.Walls)
            // {
            //     const p = w.viewMeshes[0].parent;
            //     for(let m of w.viewMeshes)
            //     {
            //         CsgHelper.bakeTransform(m);
            //     }
            //     const mergeMesh = BABYLON.Mesh.MergeMeshes(w.viewMeshes, true, true, undefined, false, true);
            //     mergeMesh.parent = p;
            //     CsgHelper.setParentSubtractLocation(mergeMesh,p)
            //     mergeMesh.position.z *= 1;
            //     w.viewMeshes = [mergeMesh];
            // }

            for(let d of moduleEnt.Doors)
            {
                const dInfo = d.info;
                const doorData = viewer.doors.find(x=>dInfo.name.replace('-','').includes(x.name));
                
                const doorEnt = await this.LoadDoor(doorData)
                doorEnt.info = dInfo;
                doorEnt.parent = d.parent;
                doorEnt.moduleEntity = moduleEnt;
                moduleEnt.Doors.push(doorEnt)
                moduleEnt.Entities.push(doorEnt)

                for(let i in doorEnt.viewMeshes)
                {
                    doorEnt.viewMeshes[i].position = d.viewMeshes[i].position.clone();
                    doorEnt.viewMeshes[i].parent = d.viewMeshes[i].parent;
                    doorEnt.viewMeshes[i].setEnabled(true);

                    if(doorEnt.parent.wallType === 'Vertical')
                    {
                        doorEnt.viewMeshes[i].rotation.y = Math.PI/2;
                    }
                }
                doorEnt.setModuleParent();
                doorEnt.setViewFlipType();
                doorEnt.setDrawFlipType();
                doorEnt.viewMeshToDrawMeshTrasfrom();
                doorEnt.createCrushMesh();
                d.dispose();
            }

            for(let w of moduleEnt.Windows)
            {
                const wInfo = w.info;
                const winData = viewer.windows.find(x=>wInfo.name.replace('-','').includes(x.name));
                
                const winEnt = await this.LoadWindow(winData)
                winEnt.info = wInfo;
                winEnt.parent = w.parent;
                winEnt.moduleEntity = moduleEnt;
                moduleEnt.Windows.push(winEnt)
                moduleEnt.Entities.push(winEnt)

                for(let i in winEnt.viewMeshes)
                {
                    winEnt.viewMeshes[i].position = w.viewMeshes[i].position.clone();
                    winEnt.viewMeshes[i].parent =w.viewMeshes[i].parent;
                    winEnt.viewMeshes[i].setEnabled(true);

                    if(winEnt.parent.wallType === 'Vertical')
                    {
                        winEnt.viewMeshes[i].rotation.y = Math.PI/2;
                    }
                }
                winEnt.setModuleParent();
                winEnt.setViewFlipType();
                winEnt.setDrawFlipType();
                winEnt.viewMeshToDrawMeshTrasfrom();
                winEnt.createCrushMesh();
                w.dispose();
            }

            for(let o of moduleEnt.Openings)
            {
                if(o.parent.getWorldWallType().type === 'Vertical')
                {
                    o.viewMeshes.forEach(x=>{
                        const p = x.parent;
                        const pos = x.position.clone();

                        x.parent = null;
                        x.position = Vector3.Zero();
                        x.rotation.y = Math.PI/2
                        CsgHelper.initialMatrix(x);
                        x.parent = p;
                        x.position = pos;
                        x.rotation.y = Math.PI/2
                    })
                }
                o.drawViewMesh();
                o.drawMeshes1f.forEach(x=>{
                    x.parent = moduleEnt.draw1fRootMesh;
                })
                o.drawMeshes2f.forEach(x=>{
                    x.parent = moduleEnt.draw2fRootMesh;
                })
                o.createCrushMesh();
            }

            moduleEnt.Stairs.forEach(s=>{
                s.createCrushMesh();
            });

            moduleEnt.Voids.forEach(s=>{
                s.createCrushMesh();
            });

            moduleEnt.moduleBoxs.forEach(moduleBox => {
                moduleBox.dispose();
            });
            moduleEnt.moduleBoxs = [];

            moduleEnt.makeBox(drawScene1f.instance,'1F');
            moduleEnt.makeBox(drawScene2f.instance,'2F');

            drawScene1f.createEntity = moduleEnt;
            drawScene2f.createEntity = moduleEnt;
            
            this.modules.push(moduleEnt);
        }
        catch(err)
        {
            console.error(err);
        }
    }

    findMeshParent(meshes)
    {
        meshes.forEach(mesh=>{
            const info = mesh.info;
            if(info.parentName.includes('Floor'))
            {
                const parent = meshes.find(x=>x.info.name === info.parentName);
                mesh.parent = parent;
                mesh.position.x -= parent.position.x;
                mesh.position.y -= parent.position.y;
                mesh.position.z -= parent.position.z;
            }
        });
    }

    findEntityParent(moduleEnt)
    {
        moduleEnt.Entities.forEach(ent=>{
            const parent = moduleEnt.Entities.find(x=>x.info.name === ent.info.parentName);
            if(parent)
            {
                ent.parent = parent;
            }
        });
    }

    inputBox(meshes,moduleEnt)
    {
        const boxs = meshes.filter(x=>x.info.ctgrName === 'Box');
        boxs.forEach(box =>{
            try
            {
                const targetEnt = moduleEnt.Entities.find(x=>x.info.name === box.info.parentName);
                box.info.cutEnt = targetEnt;
                targetEnt.viewMeshes.push(box);
                box.isVisible = false;
            }
            catch(err)
            {
                console.error(err);
            }
        })
    }

    createEntity(meshes,viewScene,drawScene1f,drawScene2f,moduleName,guid)
    {
        const moduleEnt = new ModuleEntity();
        moduleEnt.info.name = moduleName;
        moduleEnt.info.ctgrName = "Module";
        moduleEnt.guid = guid;

        let ent = null;
        meshes.forEach(mesh=>{
            const info = mesh.info;
            if(info.ctgrName === 'mm')
            {
                mesh.entity = ent;
                ent.viewMeshes.push(mesh);
            }
            else
            {
                if(info.ctgrName === 'Wall')
                {
                    ent = new WallEntity();
                    moduleEnt.Walls.push(ent);
                    ent.info = info;
                    ent.viewMeshes.push(mesh);
                    ent.viewScene = viewScene;
                    ent.drawScene1f = drawScene1f;
                    ent.drawScene2f = drawScene2f;
                    ent.moduleEntity = moduleEnt;
                    mesh.entity = ent;
                    mesh.material = viewScene.instance.getMaterialByName('WallMat');
                    ent.setWallType();
                    moduleEnt.Entities.push(ent);
                    return;
                }
                else if(info.ctgrName === 'Window')
                {
                    ent = new WindowEntity();
                    moduleEnt.Windows.push(ent);
                }
                else if(info.ctgrName === 'Door')
                {
                    ent = new DoorEntity();
                    moduleEnt.Doors.push(ent);
                }
                else if(info.ctgrName === 'Corner')
                {
                    ent = new CornerEntity();
                    mesh.material = viewScene.instance.getMaterialByName('WallMat');
                    moduleEnt.Corners.push(ent);
                }
                else if(info.ctgrName === 'Floor')
                {
                    ent = new FloorEntity();
                    mesh.material = viewScene.instance.getMaterialByName('WallMat');
                    moduleEnt.Floors.push(ent);
                }
                else if(info.ctgrName === 'Void')
                {
                    ent = new VoidEntity();
                    moduleEnt.Voids.push(ent);
                    mesh.isVisible = false;
                }
                else if(info.ctgrName === 'Opening')
                {
                    ent = new OpeningEntity();
                    moduleEnt.Openings.push(ent);
                }
                else if(info.ctgrName === 'Stair')
                {
                    ent = new StairsEntity();
                    moduleEnt.Stairs.push(ent);
                }
                else if(info.ctgrName === 'Box')
                {
                    return;
                }
                mesh.entity = ent;
                ent.info = info;
                ent.viewMeshes.push(mesh);
                ent.viewScene = viewScene;
                ent.drawScene1f = drawScene1f;
                ent.drawScene2f = drawScene2f;
                ent.moduleEntity = moduleEnt;
                moduleEnt.Entities.push(ent);
            }
        });

        return moduleEnt;
    }

    parsingInfo(id)
    {
        const ids = id.split('_');
        let name = `${ids[1]}-${ids[2]}`;
        let ctgrName = ids[1].replace(/\d+/g, '');
        let typeName = ids[2];
        let flipType = ids[3];
        let parentName = `${ids[3]}-${ids[4]}`;

        const regex = /([V|v]\d[H|h]\d[R|r])/;
        if(regex.test(flipType))
        {
            name = `${ids[1]}-${ids[2]}${ids[3]}`
            parentName = `${ids[4]}-${ids[5]}`;
        }
        else
        {
            flipType = null;
        }

        if(ctgrName === 'Box' || ctgrName === 'box')
        {
            name = ids[1];
            typeName = ctgrName;
            if(ids[2] === 'Opening' || ids[2] === 'Stair' || ids[2] === 'Void')
            {
                parentName = `${ids[2]}-${ids[3]}`;
            }
            else
            {
                parentName = `${ids[2]}-${ids[3]}${ids[4]}`;
            }
        }

        const retObj = {name : name, ctgrName:ctgrName, typeName:typeName,flipType:flipType,parentName,parentName};
        return retObj;
    }

    async LoadWindow(data)
    {
        const name = data.name;
        const container = await BABYLON.SceneLoader.LoadAssetContainerAsync(`./models/windows/${name}/obj/`, `${name}.obj`);
        const meshes = container.meshes;
        const ent = new WindowEntity();
        const viewScene = this.loader.viewScene;
        const drawScene1f = this.loader.drawScene1f;
        const drawScene2f = this.loader.drawScene2f;

        for(let i = 0; i<meshes.length; i++)
        {
            const mesh = meshes[i];
            const tempBox = meshes[i].getBoundingInfo().boundingBox;
            const tempPos = Vector3.Center(tempBox.minimum, tempBox.maximum);
            
            const info = this.parsingInfo(meshes[i].id);
            viewScene.instance.addMesh(mesh);
            CsgHelper.bakeTransform(mesh);
            mesh.position = tempPos;
            mesh.material.specularColor = new BABYLON.Color3(0, 0, 0); 
            mesh.material.specularPower = 0;
            mesh.info = info;
            mesh.info.height = data.height;
            mesh.entity = ent;
            ent.viewMeshes.push(mesh);
            mesh.setEnabled(false);
        }
        ent.info = meshes.find(m=>m.info.ctgrName === 'Window').info;
        ent.viewScene = viewScene;
        ent.drawScene1f = drawScene1f;
        ent.drawScene2f = drawScene2f;

        ent.drawViewMesh();
        ent.createRootMesh();

        ent.viewMeshes.forEach(m=>{
            m.setEnabled(false);
        });

        return ent;
    }

    async LoadDoor(data)
    {
        const name = data.name;
        const container = await BABYLON.SceneLoader.LoadAssetContainerAsync(`./models/doors/${name}/obj/`, `${name}.obj`);
        const meshes = container.meshes;
        const ent = new DoorEntity();
        const viewScene = this.loader.viewScene;
        const drawScene1f = this.loader.drawScene1f;
        const drawScene2f = this.loader.drawScene2f;

        for(let i = 0; i<meshes.length; i++)
        {
            const mesh = meshes[i];
            const tempBox = meshes[i].getBoundingInfo().boundingBox;
            const tempPos = tempBox.centerWorld.clone();
            
            const info = this.parsingInfo(meshes[i].id);
            viewScene.instance.addMesh(mesh);
            CsgHelper.bakeTransform(mesh);
            mesh.position = tempPos.clone();
            mesh.material.specularColor = new BABYLON.Color3(0, 0, 0); 
            mesh.material.specularPower = 0;
            mesh.info = info;
            mesh.info.height = data.height;
            mesh.info.vh = data.vh;
            mesh.info.flipType = 'V0H0R'
            mesh.entity = ent;
            ent.viewMeshes.push(mesh);
            mesh.setEnabled(false);
        }
        ent.info = meshes.find(m=>m.info.ctgrName === 'Door').info;
        ent.viewScene = viewScene;
        ent.drawScene1f = drawScene1f;
        ent.drawScene2f = drawScene2f;

        await ent.drawViewMesh();
        ent.createRootMesh();

        ent.viewMeshes.forEach(m=>{
            m.setEnabled(false);
        });

        return ent;
    }

    async LoadUnit(name,path,drawScene1f,drawScene2f,viewScene,guid)
    {
        const container = await BABYLON.SceneLoader.LoadAssetContainerAsync(path, `${name}.obj`);
        const meshes = container.meshes;
        const ent = new UnitEntity();
        const unitBox = BABYLON.MeshBuilder.CreateBox(null, {size : 1},viewScene.instance);
        unitBox.isVisible = false;
        unitBox.info = {ctgrName : 'Unit',name:name,guid : guid};
        unitBox.entity = ent;
        ent.viewRootMesh = unitBox;
        unitBox.setEnabled(false);

        for(let i = 0; i<meshes.length; i++)
        {
            const mesh = meshes[i];
            const tempBox = meshes[i].getBoundingInfo().boundingBox;
            const tempPos = Vector3.Center(tempBox.minimum, tempBox.maximum);
            
            const info = this.parsingInfo(meshes[i].id);
            viewScene.instance.addMesh(mesh);
            CsgHelper.bakeTransform(mesh);
            mesh.position = tempPos.clone();
            mesh.material.specularColor = new BABYLON.Color3(0, 0, 0); 
            mesh.material.specularPower = 0;
            mesh.info = info;
            mesh.parent = unitBox;
            mesh.entity = ent;
            

            if(mesh.material.name.includes('FrontColor'))
            {
                mesh.material = viewScene.instance.getMaterialByName('WallMat')
            }
            if(mesh.info.ctgrName === 'Wall')
            {
                if(mesh.info.typeName === 'Unit')
                {
                    mesh.material = mesh._scene.getMaterialByName('WallMat');
                }
                else
                {
                    const mat = mesh.material;
                    if(mat.diffuseTexture)
                    {
                        const matFullName = mat.diffuseTexture.name;
                        const matName = matFullName.split('/')[matFullName.split('/').length-1].split('.')[0];
                        const loadMat = mesh._scene.getMaterialByName(matName);
                        const length = mesh.material?.subMaterials?.length;
                        const materialLoader = viewScene.viewer.$SceneLoader.materialLoader;

                        if(loadMat)
                        {
                            mesh.material = loadMat;
                        }
                        else
                        {
                            const newMat = new Material.PBRCustomMaterial(matName,mesh._scene);
                            newMat.metallic = 0.
                            newMat.roughness = 1.
                            newMat.specularColor = new BABYLON.Color3(0, 0, 0)
                            newMat.maxSimultaneousLights = 200;
                            newMat.specularPower = 0;
                            newMat.albedoTexture = mat.diffuseTexture;
                            newMat.Fragment_Before_Fog(materialLoader.getShaderCode(mesh._scene));
                            mesh.material = newMat;
                        }
                    }
                    else
                    {
                        mesh.material = mesh._scene.getMaterialByName('WallMat');
                    }
                }
            }
            ent.viewMeshes.push(mesh);
            mesh.setEnabled(true);
        }
        ent.info = {...unitBox.info};
        ent.viewScene = viewScene;
        ent.drawScene1f = drawScene1f;
        ent.drawScene2f = drawScene2f;
        
        ent.createRoot();
        ent.drawViewMesh();
        await ent.createEntity();

        drawScene1f.createEntity = ent;
        drawScene2f.createEntity = ent;
    }

    async createWall(drawScene1f,drawScene2f,viewScene)
    {
        const ent = new TempWallEntity();
        const diameter = 0.1;
        const d1fPointer = BABYLON.MeshBuilder.CreateSphere(null,{diameter: diameter, segments: 16},drawScene1f.instance);
        d1fPointer.material = drawScene1f.instance.getMaterialByName('WallPointMat');

        const d2fPointer = BABYLON.MeshBuilder.CreateSphere(null,{diameter: diameter, segments: 16},drawScene2f.instance);
        d2fPointer.material = drawScene2f.instance.getMaterialByName('WallPointMat');

        ent.d1fPointer = d1fPointer;
        ent.d2fPointer = d2fPointer;

        d1fPointer.setEnabled(false)
        d2fPointer.setEnabled(false)

        ent.drawScene1f = drawScene1f;
        ent.drawScene2f = drawScene2f;
        ent.viewScene = viewScene;

        drawScene1f.createEntity = ent;
        drawScene2f.createEntity = ent;
    }

    async createOpening(drawScene1f,drawScene2f,viewScene)
    {
        const ent = new TempOpningEntity();
        const diameter = 0.1;

        const d1fPointer = BABYLON.MeshBuilder.CreateSphere(null,{diameter: diameter, segments: 16},drawScene1f.instance);
        d1fPointer.material = drawScene1f.instance.getMaterialByName('WallPointMat');

        const d2fPointer = BABYLON.MeshBuilder.CreateSphere(null,{diameter: diameter, segments: 16},drawScene2f.instance);
        d2fPointer.material = drawScene2f.instance.getMaterialByName('WallPointMat');

        ent.d1fPointer = d1fPointer;
        ent.d2fPointer = d2fPointer;

        d1fPointer.setEnabled(false)
        d2fPointer.setEnabled(false)
        
        ent.drawScene1f = drawScene1f;
        ent.drawScene2f = drawScene2f;
        ent.viewScene = viewScene;

        drawScene1f.createEntity = ent;
        drawScene2f.createEntity = ent;
    }
}