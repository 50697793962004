import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import * as Material from '@babylonjs/materials'
import SceneBase from "./SceneBase"
import { AdvancedDynamicTexture,TextBlock } from '@babylonjs/gui/2D';
import * as GUI from '@babylonjs/gui';

export default class Scene3d extends SceneBase{
    constructor(canvas,viewer)
    {
        super(canvas,viewer);
        this.is2d = false;
        this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.setEvents();
        this.addLight();
        this.setFloorText();
        this.setGuidText();
    }

    setFloorText()
    {
        if(!this.floorText)
        {
            this.floorText = new TextBlock();
        }
        let areaMm = 0;
        let area = 0;

        this.viewer.$SceneLoader.EntityLoader.modules.forEach(m=>{
            const mArea = m.getArea(false);
            areaMm += Number(mArea.areaMm);
            area += Number(mArea.area);
        })

        this.floorText.text = `연면적 (1층+2층) : ${areaMm.toFixed(2)}㎡ (${area.toFixed(1)}평)`;
        this.floorText.color = "rgba(0, 0, 0, 0.75)";
        this.floorText.fontSize = 18;
        this.floorText.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
        this.floorText.top = `-${this.canvasElement.clientHeight/2 - 22}rem`;
        this.floorText.left = `${this.canvasElement.clientWidth/50}rem`;
        this.floorText.isVisible = this.viewer.$SceneLoader.isShowArea;
        this.advancedTexture.addControl(this.floorText);
    }

    setGuidText()
    {
        this.guidText = new TextBlock();
        this.guidText.name = 'guidText';
        this.guidText.text =`※ 현재 표기되는 바닥면적 및 연면적은 단순 모듈 합계 면적이며, 본 설계시 면적 변경이 있을 수 있음\n※ 테라스 및 베란다의 면적은 합계에서 제외 되었음.`;
        this.guidText.top = `${this.canvasElement.clientHeight/2 - 40}rem`;
        this.guidText.left = `-${this.canvasElement.clientWidth/50}rem`;
        this.guidText.color = "rgba(0, 0, 0, 0.5)";
        this.guidText.fontSize = '10';
        this.guidText.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT;
        this.advancedTexture.addControl(this.guidText);
    }

    addLight()
    {
        const intensity = 0.3;
        const light1 = new BABYLON.HemisphericLight("light1", new Vector3(1, 1, 0), this.instance);
        light1.intensity = intensity;
        light1.direction = new BABYLON.Vector3(0.3, -0.5, 1);
        
        const light2 = new BABYLON.HemisphericLight("light2", new Vector3(-1, 1, 0), this.instance);
        light2.intensity = intensity - 0.1;
        light2.direction = new BABYLON.Vector3(-0.3, -0.5, -1);

        const light3 = new BABYLON.HemisphericLight("light3", new Vector3(0, -1, 0), this.instance);
        light3.intensity = intensity - 0.05;
        light3.direction = new BABYLON.Vector3(0, 1, 0);

        // const line = BABYLON.MeshBuilder.CreateLines("lines", { points:[new Vector3(1, 1, 0),new BABYLON.Vector3(0.3, -0.5, 1)] }, this.instance);
        // line.color = new BABYLON.Color3(0,0,1)

        // const line1= BABYLON.MeshBuilder.CreateLines("lines", { points:[new Vector3(-1, 1, 0),new BABYLON.Vector3(-0.3, -0.5, -1)] }, this.instance);
        // line1.color = new BABYLON.Color3(1,0,0)
    }

    setEvents()
    {
        this.instance.onPointerObservable.add(evt => {this.handleMouseWheel(evt)}, BABYLON.PointerEventTypes.POINTERWHEEL);
    }

    handleMouseWheel(evt)
    {
        const scene = this.instance;
        const camera = this.instance.getCameraByName("Camera");
        const ground = scene.getMeshByName("ground");
        const zoomRate = ((camera.radius - camera.minZ) / (camera.maxZ - camera.minZ))*10000;
        
        //camera.panningSensibility = Math.sqrt(zoomRate-zoomRate/2)*0.1;
        camera.panningSensibility = 10000 * 1/zoomRate;

        if(zoomRate<20)
        {
            ground.material = scene.getMaterialByName("smallGridMat");
        }
        else if(zoomRate>80)
        {
            ground.material = scene.getMaterialByName("bigGridMat");
        }
        else
        {
            ground.material = scene.getMaterialByName("nomalGridMat");
        }
    }
}