<template>
    <div id="SideMenu_Root">
        <div class="List_Container">
            <input type="radio" name="selectTab" id="s1" @click="navigateToPage('/mypage')"
                :checked="isCurrentPage('/mypage')">
            <label for="s1">
                <router-link to="/mypage">
                    마이페이지
                </router-link>
            </label>
 
            <input type="radio" name="selectTab" id="s2" @click="navigateToPage('/myhome')"
                :checked="isCurrentPage('/myhome')">
            <label for="s2">
                <router-link to="/myhome">
                    내가 만든 주택
                </router-link>
            </label>
 
            <input type="radio" name="selectTab" id="s3">
            <label for="s3"><a :href="`${this.$TARGETURL2}section/type2`">자이가이스트 모델</a></label>
 
            <input type="radio" name="selectTab" id="s4" @click="handleAdminClick" :checked="isCurrentPage('/manage')" v-if="isAdminUser">
            <label for="s4" v-if="isAdminUser">
                회원 관리
            </label>
        </div>
        <AdminLogin @childEvent="handleLoginChild" v-if="showAdminLogIn" />
    </div>
</template>
 
<style scoped>
@import url('../../assets/css/SideMenu.css');
</style>
 
<script>
import AdminLogin from '../Login/AdminLogin.vue';
import Cookies from 'js-cookie';
import axios from 'axios';
 
export default {
    props: {
        isAdminUser: Boolean,
        isAdminLoggedIn: Boolean
    },
    components: {
        AdminLogin
    },
    data() {
        return {
            showAdminLogIn: false,
            userName: "",
            userIdx: "",
            totalMembers: "",
            isAdminUser: false, // 초기값 설정
            currentPageOnPopupOpen: null // 팝업이 열릴 때의 페이지 기억
        }
    },
    computed: {
        currentRoute() {
            return this.$route.path;
        }
    },
    watch: {
        '$route.path': function(newVal, oldVal) {
            if (!this.showAdminLogIn) {
                this.updateCheckedState();
            }
        },
        showAdminLogIn(newVal) {
            if (newVal) {
                this.currentPageOnPopupOpen = this.$route.path; // 팝업이 열릴 때 현재 페이지를 기억
                console.log(`Popup opened, current page: ${this.currentPageOnPopupOpen}`);
            }
        }
    },
    async created() {
        await this.getToken();
    },
    methods: {
        handleLoginChild(success) {
            this.showAdminLogIn = false;
            if (success) {
                if (this.currentPageOnPopupOpen) {
                    console.log(`Navigating back to: ${this.currentPageOnPopupOpen}`);
                    this.$router.push(this.currentPageOnPopupOpen).then(() => {
                        this.currentPageOnPopupOpen = null; // 페이지 이동 후 기억한 페이지 초기화
                        this.updateCheckedState(); // 페이지 이동 후 List_Container 내 input 버튼 체크 상태 업데이트
                    });
                }
            } else {
                this.updateCheckedState();
            }
        },
        navigateToPage(page) {
            this.$emit('navigate', page);
            this.$router.push(page).then(() => {
                this.updateCheckedState();
            });
        },
        isCurrentPage(page) {
            return this.$route.path === page;
        },
        handleAdminClick() {
            this.isVisible = true;
            this.updateCheckedState();

            if (this.isAdminLoggedIn) {
                this.$router.push('/manage').then(() => {
                    this.updateCheckedState();
                });
            } else {
                this.currentPageOnPopupOpen = this.$route.path; // 현재 페이지 기억
                console.log(`Admin clicked, current page saved as: ${this.currentPageOnPopupOpen}`);
                this.showAdminLogIn = true;
            }
        },
        updateCheckedState() {
            console.log(`Updating checked state for page: ${this.$route.path}`);
            const myPageRadio = document.getElementById('s1');
            const myHomeRadio = document.getElementById('s2');
            const manageRadio = document.getElementById('s4');

            if (myPageRadio) myPageRadio.checked = false;
            if (myHomeRadio) myHomeRadio.checked = false;
            if (manageRadio) manageRadio.checked = false;

            if (this.isCurrentPage('/mypage')) {
                const myPageRadio = document.getElementById('s1');
                if (myPageRadio) myPageRadio.checked = true;
            } else if (this.isCurrentPage('/myhome')) {
                const myHomeRadio = document.getElementById('s2');
                if (myHomeRadio) myHomeRadio.checked = true;
            } else if (this.isCurrentPage('/manage')) {
                const manageRadio = document.getElementById('s4');
                if (manageRadio) manageRadio.checked = true;
            }
        },
        async getToken() {
            const cookie = Cookies.get('token');
            let data = {
                token: cookie === undefined ? '' : cookie
            }
            const res = await axios.post("/server/token_check", JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            if (data.token === '') {
                // 토큰 자체가 없으면 로그인 안된 상태
                this.isAdminUser = false;
            } else if (res.data._data.length === 0) {
                alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
                this.$router.push('/member/login');
            } else if (res.status === 200) {
                this.userIdx = res.data._data[0].idx;
                this.isAdminUser = res.data._data[0].ISADMIN;
            } else {
                this.isAdminUser = false;
            }
            //console.log(`Token check complete, isAdminUser: ${this.isAdminUser}`);
        }
    }
}
</script>
 