<template>
    <div id="findID_Wrapper">
        <div id="findID_Body">
            <div class="findID_Container">
                <hgroup>
                    <h1>비밀번호 변경</h1>
                    <p>비밀번호 변경<br>
                        본인인증을 통해 비밀번호를 변경하실 수 있습니다.</p>
                </hgroup>



                <div class="BtnArea">
                    <button id="cancel"><router-link to="/member/login">취소</router-link></button>
                    <button id="confirm" class="confirmBtn" @click="fnPopup">본인인증
                    </button>
                </div>
            </div>
        </div>
        <bottomInfo />
        <Footer />
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/changePW1.css');
</style>

<script>
import bottomInfo from '../Login/bottomInfo.vue';
import Footer from '../Login/joinStep1Footer.vue';
import childPage from '../Login/childPage.vue'

export default {
    components: {
        bottomInfo: bottomInfo,
        Footer: Footer,
        childPage
    },
    data() {
        return {
            tabValue: 'byPhone',
            receivedData: ''
        }
    },
    methods: {
        toggleClick(event) {
            this.tabValue = event.target.value;
            // 휴대폰 번호로 찾기, 이메일로 찾기 radio Btn value값에 맞춰 form 양식 가져옴...
        },
        fnPopup() {
            window.open('/member/changePWSub', '', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

        },
        handleChildEvent(dataFromChild) {
            console.log("함수접근");
            this.receivedData = dataFromChild;
        }
    }
}
</script>