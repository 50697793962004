<template>
    <div id="Result_Wrapper">
        <div id="Result_Body">
            <div class="Result_Container">
                <hgroup>
                    <h1>아이디/비밀번호 찾기</h1>
                    <p>아이디/비밀번호 찾기<br>
                    간편하게 아이디/비밀번호를 찾으실 수 있습니다.</p>
                </hgroup>

                <div>
                    <p>
                        <span>회원님의 아이디는<br>
                            <strong>입니다.</strong>
                        </span>
                    </p>
                    <span>가입일<br>
                        <strong>2024.02.19</strong>
                    </span>
                </div>

                <div class="FindPWBtnArea">
                    <button>
                        <router-link to="/member/login">로그인</router-link>
                    </button>
                    <router-link to="/member/findPW" class="findPassWord">비밀번호 찾기</router-link>
                </div>
            </div>
        </div>
        <bottomInfo />
        <Footer />
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/findIDResults.css');
</style>

<script>
import bottomInfo from '../Login/bottomInfo.vue'
import Footer from '../Login/joinStep1Footer.vue'

export default {
    components: {
        bottomInfo : bottomInfo,
        Footer : Footer
    }
}
</script>