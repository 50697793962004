<template>
    <div id="findPW_Wrapper">
        <div id="findPW_Body">
            <div class="findPW_Container">
                <hgroup>
                    <h1>아이디/비밀번호 찾기</h1>
                    <p>아이디/비밀번호 찾기<br>
                    간편하게 아이디/비밀번호를 찾으실 수 있습니다.</p>
                </hgroup>

                <div class="tapGroup">
                    <ul>
                        <li @click="clickIDForm">
                            <router-link to="/member/findID">아이디 찾기</router-link>
                        </li>
                        <li class="on"  @click="clickPWForm">
                            <router-link to="/member/findPW">비밀번호 찾기</router-link>
                        </li>
                    </ul>
                </div>

                <form action="" id="findForm">
                    <input type="hidden" name="userID">
                    <div class="checkBoxContainer">
                        <label for="findID01">
                            <!-- <input type="radio" id="findID01" name="findID" checked @click="clickFindbyNum"> -->
                            <input type="radio" id="findID01" name="findID" v-model="tabValue" value="byPhone" checked @change="toggleClick">
                            <div>휴대폰 번호로 찾기</div>
                        </label>

                        <label for="findID02">
                            <input type="radio" id="findID02" name="findID" v-model="tabValue" value="byEmail" @change="toggleClick">
                            <div>이메일로 찾기</div>
                        </label>
                    </div>
                </form>

                <!-- 휴대폰 번호로 찾기 form -->
                <form action="" id="findID01" v-show="tabValue === 'byPhone'" class="findbyNum">
                    <label for="userNm" id="userNm" name="userNm">
                        <span>이름</span>
                        <input type="text" id="userNm" name="userNm" placeholder="이름 입력">
                    </label>

                    <label for="userID" id="userID" name="userID">
                        <span>아이디</span>
                        <input type="text" id="userID" name="userID" placeholder="아이디 입력">
                    </label>

                    <label for="userPn" id="userPn" name="userPn">
                        <span>휴대폰번호</span>
                        <div class="findPnInput">
                            <input type="text" id="userPn" name="userPn" placeholder="휴대폰번호 입력">
                            <button>인증번호 발송</button>
                        </div>
                    </label>

                    <label for="certifiedNum" id="certifiedNum" name="certifiedNum">
                        <span>인증번호</span>
                        <div class="findNumInput">
                            <input type="text" id="certifiedNum" name="certifiedNum" placeholder="인증번호 입력">
                            <button>인증번호 확인</button>
                        </div>
                    </label>
                </form>

                <!-- 이메일로 찾기 form -->
                <form action="" id="findID02" v-show="tabValue === 'byEmail'" class="findbyEmail">
                    <label for="userNm" id="userNm" name="userNm">
                        <span>이름</span>
                        <input type="text" id="userNm" name="userNm" placeholder="이름 입력">
                    </label>

                    <label for="userID" id="userID" name="userID">
                        <span>아이디</span>
                        <input type="text" id="userID" name="userID" placeholder="아이디 입력">
                    </label>


                    <label for="userEmail" id="userEmail" name="userEmail">
                        <span>이메일</span>
                        <div id="emailInput">
                            <div>
                                <div class="EmailInputContainer">
                                    <input type="text" id="emailID" name="emailId" title="이메일주소" placeholder="이메일 입력">
                                    <strong>@</strong>
                                    <input type="text" id="emailDomain" name="emailDomain" title="이메일 서비스 업체" readonly>

                                    <div class="selectRemake">
                                        <select name="emailSelect" id="emailSelect">
                                            <option value="">선택하세요</option>
                                            <option value="naver.com">naver.com</option>
                                            <option value="gmail.com">gmail.com</option>
                                            <option value="daum.net">daum.net</option>
                                            <option value="hanmail.net">hanmail.net</option>
                                            <option value="nate.com">nate.com</option>
                                            <option value="hotmail.com">hotmail.com</option>
                                        </select>
                                        <a href="/" class="selectRemaker" title="이메일서비스업체 선택 옵션선택"></a>
                                       
                                    </div>
                                    <button class="sm sendBtn">인증번호 발송</button>
                                </div>
                            
                            </div>
                        </div>
                    </label>

                    <label for="certifiedNum" id="certifiedNum" name="certifiedNum">
                        <span>인증번호</span>
                        <div class="findNumInput">
                            <input type="text" id="certifiedNum" name="certifiedNum" placeholder="인증번호 입력">
                            <button class="sm">인증번호 확인</button>
                        </div>
                    </label>
                </form>

                <div class="BtnArea">
                    <button id="cancel"><router-link to="/member/login">취소</router-link></button>
                    <button id="confirm" class="confirmBtn">
                        <router-link to="/member/findIDResult">확인</router-link>
                    </button>
                </div>
            </div>
        </div>
        <bottomInfo />
        <Footer />
        <scrollToTop />
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/findPW.css');
</style>

<script>
import bottomInfo from './bottomInfo.vue';
import Footer from './joinStep1Footer.vue'
import scrollToTop from '../Login/scrollToTop.vue'

export default {
    components: {
        bottomInfo,
        Footer,
        scrollToTop
    },
    data() {
        return {
            tabValue:'byPhone'
        }
    },
    methods: {
        toggleClick(event) {
            this.tabVale = event.target.value;
        }
    }
}
</script>