import ContextItemBase from "./ContextItemBase";
import VectorHelper from "../Helper/VectorHelper"
import MeshHelper from "../Helper/MeshHelper"
import Enumerable from 'linq'
import { FluidRenderingObjectCustomParticles, Vector3 } from "@babylonjs/core";
import { mrdlSliderThumbPixelShader } from "@babylonjs/gui/3D/materials/mrdl/shaders/mrdlSliderThumb.fragment";
import ColumnEntity from "./ColumnEntity";
import * as HistoryCommand from "../Entities/historyCommands"
import SaveHelper from "../Helper/SaveHelper";
import CsgHelper from "../Helper/CsgHelper";

const balconyVecticalSizeArray = [2.44,3.05,3.66,4.27,4.88, 5.02, 6.1, 7.32, 8.54, 9.15];
const defaultVerticalSizeArray = [5.02, 6.1, 6.67, 7.32, 7.93, 8.54, 9.15];
const horizontalSizeArray = [1.83, 2.44, 3.34];

export default class ModuleEntity extends ContextItemBase{

    constructor()
    {
        super();
        this.info = {};
        this.guid = null;
        this.Entities = [];
        this.Walls = [];
        this.Windows = [];
        this.Doors = [];
        this.Corners = [];
        this.Openings = [];
        this.Floors = [];
        this.Voids = [];
        this.Stairs = [];
        this.Columns = [];
        this.Units = [];
        //뷰어=============
        this.drawScene1f = null;
        this.drawScene2f = null;
        this.viewScene = null;
        //각 뷰어 모듈 루트 메쉬=============
        this.viewRootMesh = null;
        this.draw1fRootMesh = null;
        this.draw2fRootMesh = null;

        this.moduleBoxs = [];
        this.isModule = true;
        this.isGhost = true;
        this.isFixed = false; // 사용안함
        this.floorPenetrate = "1F";
        this.locatedScene = null;

        this.horizontal = 0;
        this.vertical = 0;
        this.isHideRoof = false;
        this.isHistoryWork = false;
    }
    getAllRoots()
    {
        return [this.viewRootMesh,this.draw1fRootMesh,this.draw2fRootMesh];
    }

    getModuleBox(instance)
    {
        return this.moduleBoxs.find(x=>x._scene === instance)
    }

    getSize(isCombineTrr = true)
    {
        let ret = Vector3.Zero();
        for(let i = 0; i<this.Floors.length; i++)
        {
            if(!isCombineTrr)
            {
                const trr = this.Floors.find(x=>x.info.name.includes('Exterior'))
                if(this.Floors[i] === trr)
                    continue;
            }
            const bb = this.Floors[i].viewMeshes[0].getHierarchyBoundingVectors()
            const size = bb.max.subtract(bb.min);
            if(ret.x.toFixed(2) != size.x.toFixed(2))
            {
                ret.x += size.x;
            }
            if(ret.z.toFixed(2) != size.z.toFixed(2))
            {
                ret.z += size.z;
            }
        }

        if(this.viewRootMesh.rotation.y % Math.PI != 0)
        {
            const temp = ret.x;
            ret.x = ret.z;
            ret.z = temp;
        }

        ret.x = Number(ret.x.toFixed(2));
        ret.z = Number(ret.z.toFixed(2));
        
        return ret;
    }

    setSize(resultDatas)
    {
        resultDatas.forEach(data =>
        {
            var dir = data.dir;
            var size = data.size;
            var isBalconyModule = this.Floors.filter(o => !o.info.typeName.includes("Exterior")).length == 0;

            var moduleBox = this.getBoundingBox2d(null, ["Floor"]);
            var moduleCenterLineStart = moduleBox.center.add(dir.negate().multiply(moduleBox.extendSize));
            var moduleCenterLineEnd = moduleBox.center.add(dir.multiply(moduleBox.extendSize));
    
            var absDir = new BABYLON.Vector3(Math.abs(dir.x), Math.abs(dir.y), Math.abs(dir.z));
            var isShirink = absDir.equalsWithEpsilon(dir, 0.0001) ? false : true;
    
            // 내벽 및 외벽 위치 조절 필요
            this.Walls.forEach(target =>
            {
                var targetLine = target.getWorldWallLine();
                var targetDir = BABYLON.Vector3.Normalize(targetLine.end.subtract(targetLine.start));
    
                if(isBalconyModule)
                {
                    if(!VectorHelper.isParallel(targetDir, dir))
                    {
                        var targetBox = target.getBoundingBox2d(null);
                        var targetDir = BABYLON.Vector3.Normalize(targetBox.center.subtract(moduleBox.center).multiply(absDir));

                        target.translateEntity(isShirink ? targetDir.negate() : targetDir, size);
                    }
                    else
                    {
                        target.scaleEntity(dir, dir, 0, size);
                    }
                }
                else
                {
                    if(target.info.typeName.includes("Interior"))
                    {
                        debugger;
                    }
                    if(VectorHelper.isParallel(targetDir, dir))
                        target.scaleEntity(dir, dir, 0, size);
                }
            });

            // 코너 위치 조절 필요
            this.Corners.forEach(target =>
            {
                var targetBox = target.getBoundingBox2d(null);
                var targetDir = BABYLON.Vector3.Normalize(targetBox.center.subtract(moduleBox.center).multiply(absDir));

                target.translateEntity(isShirink ? targetDir.negate() : targetDir, size);
            });
        });

        resultDatas.forEach(data =>
        {
            var dir = data.dir;
            var size = data.size;

            // 바닥 크기 조절
            this.Floors.forEach(item =>
            {
                item.scaleEntity(dir, dir, 0, size);
            });  
        });
    }

    getArea(isCombineTrr = true)
    {
        const size = this.getSize(isCombineTrr);
        const areaMm = ((size.x-0.14) * (size.z-0.14)).toFixed(2);
        const area = (areaMm * 0.3025).toFixed(2);
        return {areaMm : areaMm, area : area};
    }

    getSceneRootMesh(scene)
    {
        if(scene.is2d)
        {
            if(scene.floor === "1F")
            {
                return this.draw1fRootMesh;
            }
            else
            {
                return this.draw2fRootMesh;
            }
        }
        else
        {
            return this.viewRootMesh;
        }
    }

    getSceneMeshes(scene)
    {
        return Enumerable.from(this.Entities).selectMany(o => o.getSceneMeshes(scene)).toArray();
    }

    setViewModel(value)
    {
        this.viewRootMesh.setEnabled(value);
    }

    getFloorEdges()
    {
        var floors = [];
        var located = this.locatedScene.floor;
        
        this.Floors.flatMap(o => o.viewMeshes.filter(k => k.info.ctgrName == "Floor")).forEach(floor =>
        {
            var floorEdges = MeshHelper.GetWorldBottomEdges(floor).map(o => o.start);
            floors.push({edges:floorEdges.map(o => ({x:Math.round(o.x * 1000), y:Math.round(o.z  * 1000)})), height:floor.parent.position.y, located:located});
        });

        return floors;
    }

    createColumns(floor1fs)
    {
        this.Columns.forEach(o =>
        {
            o.dispose();
        });

        var dirItems = [];

        var box = this.getBoundingBox2d(null, ["Floor"]);
        
        var minXPos = box.minimum.clone();
        var minZPos = box.minimum.clone();
        minXPos.x = box.maximum.x;
        minZPos.z = box.maximum.z;

        var boundingPositions = [
            box.minimum.add(new BABYLON.Vector3(0.707, 0, 0.707).scale(Math.sqrt(2) * 0.065)),
            minXPos.add(new BABYLON.Vector3(-0.707, 0, 0.707).scale(Math.sqrt(2) * 0.065)),
            box.maximum.add(new BABYLON.Vector3(-0.707, 0, -0.707).scale(Math.sqrt(2) * 0.065)),
            minZPos.add(new BABYLON.Vector3(0.707, 0, -0.707).scale(Math.sqrt(2) * 0.065))
        ];

        // Corner처럼 살짝 안으로 이동 필요
        boundingPositions.map(o =>
        {
           o.y = 3.1;
           return o;
        });

        var minPos = boundingPositions[0];
        
        for(var i=1; i<boundingPositions.length; i++)
        {
            var target = boundingPositions[i];
            if(minPos.equalsWithEpsilon(target, 0.0001))
                continue;

            var dir = BABYLON.Vector3.Normalize(target.subtract(minPos));
            
            if(VectorHelper.isParallel(dir, BABYLON.Axis.X) || VectorHelper.isParallel(dir, BABYLON.Axis.Z))
            {
                var distance = BABYLON.Vector3.Distance(minPos, target);
                dirItems.push({dir: dir, position: target, distance: distance});
            }
        }

        if(dirItems.length != 2)
            return;

        var dirItem = dirItems[0];
        var otherItem = dirItems[1];
        if(dirItems[1].distance > dirItems[0].distance)
        {
            dirItem = dirItems[1];
            otherItem = dirItems[0];
        }

        var centerPos = minPos.add(dirItem.position).scale(0.5);
        var otherCenterPos = centerPos.add(otherItem.dir.scale(otherItem.distance));

        boundingPositions.push(centerPos);
        boundingPositions.push(otherCenterPos);

        var moduleEnt = this;

        var floorBoxes = floor1fs.flatMap(o => o.Floors.map(k => k.getBoundingBox2d()));
        boundingPositions.forEach(o =>
        {
            var minX = o.x - 0.065;
            var minZ = o.z - 0.065;
            var maxX = o.x + 0.065;
            var maxZ = o.z + 0.065;

            var min = new BABYLON.Vector3(minX, 0, minZ);
            var max = new BABYLON.Vector3(maxX, 0, maxZ);
            var checkBox = new BABYLON.BoundingBox(min, max);

            var isIntersected = false;
            for(var i=0; i<floorBoxes.length; i++)
            {
                var floorBox = floorBoxes[i];
                if(checkBox.intersectsMinMax(floorBox.minimum, floorBox.maximum))
                {
                    isIntersected = true;
                    break;
                }
            }

            if(!isIntersected)
            {
                var column = new ColumnEntity();
                column.initialize(moduleEnt, o);
    
                moduleEnt.Columns.push(column);
            }
        });
    }

    async moduleDraw()
    {
        try
        {
            this.Walls.forEach(ent => {
                ent.drawViewMesh()
            });
            for(let i =0; i<this.Doors.length; i++)
            {
                await this.Doors[i].drawViewMesh();
            }
            this.Entities.forEach(ent => {
                if(ent.info.ctgrName === 'Wall' || ent.info.ctgrName === 'Door' || ent.info.ctgrName === 'Opening')
                    return;
                ent.drawViewMesh()
            });
        }
        catch(err)
        {
            console.error(err);
        }
        const root1f = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene1f.instance);
        root1f.isVisible = false;
        root1f.position = new Vector3(0,0,0);
        
        const root2f = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene2f.instance);
        root2f.isVisible = false;
        root2f.position = new Vector3(0,0,0);

        root1f.setEnabled(false);
        root2f.setEnabled(false);
        
        this.Entities.forEach(ent=>{
            ent.drawMeshes1f.forEach(mesh=>{
                mesh.entity = ent;
            });
            ent.drawMeshes2f.forEach(mesh=>{
                mesh.entity = ent;
            });
        });

        this.Windows.forEach(w=>{
            w.createRootMesh();
        });
        this.Doors.forEach(d=>{
            d.createRootMesh();
        })
        this.Floors.forEach(f=>{
            f.createRootMesh(f.viewMeshes,this.viewScene.instance,0.82);
            f.createRootMesh(f.drawMeshes1f,this.drawScene1f.instance,1);
            f.createRootMesh(f.drawMeshes2f,this.drawScene2f.instance,1);
        })
        let mM1fs = [];//하위 엔티티의 모든 1층 draw뷰의 메쉬;
        let mM2fs = [];//하위 엔티티의 모든 2층 draw뷰의 메쉬;
        let vMeshes = [];
        this.Entities.forEach(ent=>{
            const m1fs = ent.drawMeshes1f;
            const m2fs = ent.drawMeshes2f;
            const vMesh = ent.viewMeshes;
            mM1fs = mM1fs.concat(m1fs);
            mM2fs = mM2fs.concat(m2fs);
            vMeshes = vMeshes.concat(vMesh);
        });

        mM1fs.forEach(mesh=>{
            const info = mesh.info;
            mesh.parent = root1f;
            if(info.parentName.includes('Floor'))
            {
                const parent = mM1fs.find(x=>x.info.name === info.parentName);
                mesh.parent = parent;
            }
            if(info.ctgrName === 'Box')
            {
                mesh.isVisible = false;
            }
            else
            {
                mesh.isVisible = true;
            }
        });

        mM2fs.forEach(mesh=>{
            const info = mesh.info;
            mesh.parent = root2f;
            if(info.parentName.includes('Floor'))
            {
                const parent = mM2fs.find(x=>x.info.name === info.parentName);
                mesh.parent = parent;
            }
            if(info.ctgrName === 'Box')
            {
                mesh.isVisible = false;
            }
            else
            {
                mesh.isVisible = true;
            }
        });

        root1f.scaling.z = -1;
        root2f.scaling.z = -1;
        this.viewRootMesh.scaling.z = -1;

        root1f.entity = this; 
        root2f.entity = this;

        this.draw1fRootMesh = root1f;
        this.draw2fRootMesh = root2f;
    }

    setPosition(instance,value)
    {
        if(this.locatedScene)
        {
            var pos1f = value.clone();
            pos1f.y = this.locatedScene.floor == "1F" ? 3.1 : 0;
            this.draw1fRootMesh.position = pos1f;

            var pos2f = value.clone();
            pos2f.y = this.locatedScene.floor == "2F" ? 3.1 : 0;
            this.draw2fRootMesh.position = pos2f;
        }
        else
        {
            var pos = value.clone();
            pos.y = 3.1;
            if(instance === this.drawScene1f.instance)
            {
                this.draw1fRootMesh.position = pos;
            }
            else if(instance === this.drawScene2f.instance)
            {
                this.draw2fRootMesh.position = pos;
            }
        }

        var viewPos = value.clone();
        viewPos.y = instance === this.drawScene1f.instance ? 0 : 3.1;
        this.viewRootMesh.position = viewPos.clone();
    }

    setEnable(instance,value)
    {
        if(instance === this.drawScene1f.instance)
        {
            this.draw1fRootMesh.setEnabled(value);
        }
        else if(instance === this.drawScene2f.instance)
        {
            this.draw2fRootMesh.setEnabled(value);
        }
        else
        {
            this.viewRootMesh.setEnabled(value);
        }
        this.setAllEnable(value)
    }

    setAllEnable(value)
    {
        this.Entities.forEach(ent=>{
            ent.drawMeshes1f.forEach(m => {
                m.setEnabled(value);
            });
            ent.drawMeshes2f.forEach(m => {
                m.setEnabled(value);
            });
            ent.viewMeshes.forEach(m => {
                m.setEnabled(value);
            });
            if(ent.info.ctgrName === 'Unit')
            {
                ent.updateDoorEnable()
            }
        })
    }

    setPenetrateMode(floor)
    {
        if(floor === '2F')
        {
            this.draw2fRootMesh.setEnabled(true);
            // this.draw2fRootMesh.position = this.draw1fRootMesh.position.clone();
            // this.draw2fRootMesh.position.y = 0;
        }
        else
        {
            this.draw1fRootMesh.setEnabled(true);
            // this.draw1fRootMesh.position = this.draw2fRootMesh.position.clone();
            // this.draw1fRootMesh.position.y = -3;
        }

        this.Entities.forEach(ent=>{
            let meshes = ent.drawMeshes1f;
            if(floor === '2F')
            {
                meshes = ent.drawMeshes2f;
            }
            if(ent.info.ctgrName === "Wall" || ent.info.ctgrName === "Stair" || ent.info.ctgrName === "Corner" || ent.isSimbol)
            {
                for(let i in meshes)
                {
                    const mesh = meshes[i];
                    if(mesh.material)
                    {
                        if(ent.info.ctgrName === "Wall" || ent.info.ctgrName === "Corner")
                        {
                            mesh.material = mesh._scene.getMaterialByName('WallAlphaMat');
                        }
                        else if(ent.info.ctgrName === "Void")
                        {
                            const p = mesh.parent;
                            mesh.dispose();
                            const dash = ent.createDashLine(mesh._scene);
                            dash.info = ent.info;
                            dash.entity = ent;
                            dash.parent = p;
                            if(floor === '1F')
                            {
                                dash.position.y = 4.1;
                                ent.drawMeshes1f[i] = dash;
                            }
                            else
                            {
                                ent.drawMeshes2f[i] = dash;
                            }
                        }
                        else
                        {
                            const tempmat = mesh.material.clone();
                            tempmat.alpha = 0.4;
                            mesh.material = tempmat;
                        }
                        mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 0.4);
                    }
                }
            }
            else if(ent.info.ctgrName === "Unit")
            {
                ent.setPenetrateMode(floor)
            }
            else
            {
                meshes.forEach(mesh =>{
                    mesh.isVisible =false;
                })
            }
        });
    }

    // minx, minz, maxx, maxz, center로만 판별
    snapEntities(sceneEntity, prevSnapData, x, z, modules)
    {
        var boxes = [];
        var scene = sceneEntity;
        var rootPos = this.getSceneRootMesh(sceneEntity).position;
        var offsetX = x - rootPos.x;
        var offsetZ = z - rootPos.z;

        this.Floors.forEach(o =>
        {
            var box = o.getBoundingBox2d(scene);
            boxes.push(new BABYLON.BoundingBox(new BABYLON.Vector3(box.minimum.x + offsetX, 0, box.minimum.z + offsetZ), new BABYLON.Vector3(box.maximum.x + offsetX, 0, box.maximum.z + offsetZ)));
        })

        return this.getSnapData(boxes, modules.flatMap(o => o.Entities), sceneEntity, prevSnapData, x, z);
    }

    makeBox(instance,floor)
    {
        const box = this.getSize();
        
        let width = box.x;
        let depth = box.z;
        const moduleBox = BABYLON.MeshBuilder.CreateBox('moduleBox',{width: width,height:3.1, depth:depth}, instance);
        moduleBox.scaling = new Vector3(0.99,0.99,-0.99);
        moduleBox.material = instance.getMaterialByName('greenBoxMaterial');
        
        this.moduleBoxs.push(moduleBox);
        moduleBox.isVisible = false;
        moduleBox.ModuleEntity = this;

        if(floor === '1F')
        {
            moduleBox.parent = this.draw1fRootMesh;
        }
        else
        {
            moduleBox.parent = this.draw2fRootMesh;
        }
        moduleBox.position = Vector3.Zero();
        moduleBox.position.y = 1.65;
    }

    getBoundingBox2d(scene, ctgrNames)
    {
        var boundingBox = null;
        for(var i=0; i<this.Entities.length; i++)
        {
            var entity = this.Entities[i];
            if(ctgrNames != null)
            {
                var entityCategory = entity.info.ctgrName;

                if(!ctgrNames.some(o => o == entityCategory))
                    continue;
            }
            var box = entity.getBoundingBox2d(scene);

            if(box != null)
            {
                if(box.extendSize.length() == Infinity)
                    continue;

                if(boundingBox != null)
                {
                    var newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, box.minimum);
                    var newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, box.maximum);
        
                    boundingBox.reConstruct(newMin, newMax);
                }
                else
                    boundingBox = new BABYLON.BoundingBox(box.minimum, box.maximum);
            }
        }
        return boundingBox;
    }

    getOriginBoundingBox2d(scene, ctgrNames)
    {
        let boundingBox = null;
        for(let entity of this.Entities)
        {
            if(ctgrNames != null)
            {
                let entityCategory = entity.info.ctgrName;

                if(!ctgrNames.some(o => o == entityCategory))
                {
                    continue;
                }
            }
            let box = entity.getOriginBoundingBox2d(scene);
            if(box != null)
            {
                if(box.extendSize.length() == Infinity)
                    continue;

                if(boundingBox != null)
                {
                    let newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, box.minimum);
                    let newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, box.maximum);
        
                    boundingBox.reConstruct(newMin, newMax);
                }
                else
                    boundingBox = new BABYLON.BoundingBox(box.minimum, box.maximum);
            }
        }
        return boundingBox;
    }

    getButtonData()
    {
        var datas = [
        {
            type:'rotate',
            press: this.rotate
        },{
            type:'rotate-ccw',
            press:this.rotateccw
        },{
            type:'mirror-h',
            press:this.mirrorh
        },{
            type:'mirror-v',
            press:this.mirrorv
        }];

        var isBalconyModule = this.Floors.filter(o => !o.info.typeName.includes("Exterior")).length == 0;
        if(isBalconyModule)
            datas.push({type:'hide-roof', press:this.hideroof, isToggled: this.isHideRoof});

        datas.push({type:'delete', press:this.delete});

        return datas;
    }

    createContextPanel(scene)
    {
        this.disposeContextPanel();

        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        var box = this.getBoundingBox2d(scene, ["Floor"]);
        var rotateMatrix = BABYLON.Matrix.RotationAxis(BABYLON.Axis.Y, -this.getSceneRootMesh(scene).rotation.y);
        var originSize = BABYLON.Vector3.TransformCoordinates(box.extendSize, rotateMatrix)
        this.horizontal = Math.round(Math.abs(originSize.x) * 200) / 100;
        this.vertical = Math.round(Math.abs(originSize.z) * 200) / 100;

        // 발코니만 존재하는 모듈이거나 여러개의 Floor가 있을 때 발코니가 없을 경우만
        var isBalconyModule = this.Floors.filter(o => !o.info.typeName.includes("Exterior")).length == 0;
        if(!this.Floors.some(o => o.info.typeName.includes("Exterior")) || isBalconyModule)
        {
            var verticalSizeArray = defaultVerticalSizeArray;
            if(isBalconyModule)
                verticalSizeArray = balconyVecticalSizeArray;

            var axisAvailableTypes = [];
            var verticalIndex = verticalSizeArray.indexOf(this.vertical);
            var horizontalIndex = horizontalSizeArray.indexOf(this.horizontal);
            if(verticalIndex >= 0)
            {
                var availableTypes = [];
                if(verticalIndex > 0)
                    availableTypes.push("decrease");
                if(verticalIndex < verticalSizeArray.length - 1)
                    availableTypes.push("increase");

                axisAvailableTypes.push({axis:BABYLON.Vector3.TransformNormal(BABYLON.Axis.Z, rotateMatrix), availableTypes: availableTypes});
            }
            if(horizontalIndex >= 0)
            {
                var availableTypes = [];
                if(horizontalIndex > 0)
                    availableTypes.push("decrease");
                if(horizontalIndex < horizontalSizeArray.length - 1)
                    availableTypes.push("increase");

                axisAvailableTypes.push({axis:BABYLON.Vector3.TransformNormal(BABYLON.Axis.X, rotateMatrix), availableTypes: availableTypes});
            }

            var dirs = [
                new BABYLON.Vector3(-1, 0, 0),
                new BABYLON.Vector3(1, 0, 0),
                new BABYLON.Vector3(0, 0, -1),
                new BABYLON.Vector3(0, 0, 1),
            ];

            this.createEntityBoundingSidePanel(advancedTexture, scene, dirs, axisAvailableTypes);
        }

        this.contextPanels.push(panels.toolbar);

        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    scaleModule(scene, wall, buttonDir, dir, type)
    {   
        if(!this.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MoveCommand(this,SaveHelper.makeSaveData(this)));
        var rootMesh = this.getSceneRootMesh(scene);
        var moduleBox = this.getBoundingBox2d(scene, ["Floor"]);

        var verticalSizeArray = defaultVerticalSizeArray;
        var isBalconyModule = this.Floors.filter(o => !o.info.typeName.includes("Exterior")).length == 0;
        if(isBalconyModule)
            verticalSizeArray = balconyVecticalSizeArray;

        if(type == "increase")
        {
            var sizeVector = new BABYLON.Vector3(Math.abs(dir.x), Math.abs(dir.y), Math.abs(dir.z));
            var offset = 1;
        }
        else
        {
            var sizeVector = new BABYLON.Vector3(-Math.abs(dir.x), -Math.abs(dir.y), -Math.abs(dir.z));
            var offset = -1;
        }

        if(dir.multiply(BABYLON.Axis.X).equalsWithEpsilon(BABYLON.Vector3.Zero(), 0.0001)) // Z축
        {
            var nextSizeOffset = verticalSizeArray.indexOf(this.vertical) + offset;
            var nextSize = verticalSizeArray[nextSizeOffset];

            var sizeOffset = Math.abs(nextSize - this.vertical) / 2;
        }
        else // X축
        {
            var nextSizeOffset = horizontalSizeArray.indexOf(this.horizontal) + offset;
            var nextSize = horizontalSizeArray[nextSizeOffset];

            var sizeOffset = Math.abs(nextSize - this.horizontal) / 2;
        }

        sizeOffset = Math.round(sizeOffset * 1000) / 1000;

        var sizeValue = sizeOffset;
        
        var worldDir = BABYLON.Vector3.TransformNormal(dir, rootMesh.getWorldMatrix());
        var absVector = new BABYLON.Vector3(Math.abs(worldDir.x), Math.abs(worldDir.y), Math.abs(worldDir.z));
        var buttonAbsVector = new BABYLON.Vector3(Math.abs(buttonDir.x), Math.abs(buttonDir.y), Math.abs(buttonDir.z));
        var moduleCenterLineStart = moduleBox.center.add(worldDir.negate().multiply(moduleBox.extendSize));
        var moduleCenterLineEnd = moduleBox.center.add(worldDir.multiply(moduleBox.extendSize));

        // 충돌 체크용 박스
        var thickness = 0;
        if(wall)
            thickness = wall.getWallThickness();

        var crossVector = BABYLON.Vector3.Cross(absVector, BABYLON.Axis.Y.negate());
        var buttonCrossVector = VectorHelper.abs(BABYLON.Vector3.Cross(buttonAbsVector, BABYLON.Axis.Y.negate()));
        var absCrossVector = new BABYLON.Vector3(Math.abs(crossVector.x), Math.abs(crossVector.y), Math.abs(crossVector.z));
        var thicknessSizeVector = buttonDir.scale(offset).multiply(new BABYLON.Vector3(thickness + sizeValue, thickness + sizeValue, thickness + sizeValue));
        var collisionCenter = moduleBox.center.add(buttonDir.multiply(moduleBox.extendSize)).add(thicknessSizeVector.scale(0.5));
        var collisionCenterDir = BABYLON.Vector3.Normalize(collisionCenter.subtract(moduleBox.center));

        var collisionSizeVector = thicknessSizeVector.add(buttonCrossVector.multiply(moduleBox.extendSize)).add(buttonDir.scale(offset).multiply(new BABYLON.Vector3(0.01, 0.01, 0.01)));

        var collisionPoint1 = collisionCenter.add(collisionSizeVector);
        var collisionPoint2 = collisionCenter.add(collisionSizeVector.negate());
        var collisionBox = new BABYLON.BoundingBox(BABYLON.Vector3.Minimize(collisionPoint1, collisionPoint2), BABYLON.Vector3.Maximize(collisionPoint1, collisionPoint2));

        var deletedItems = [];

        // 내벽 및 외벽 위치 조절 필요
        this.Walls.forEach(target =>
        {
            var targetBox = target.getBoundingBox2d(scene);
            var targetLine = target.getWorldWallLine();
            var targetDir = BABYLON.Vector3.Normalize(targetLine.end.subtract(targetLine.start));
            if(target.info.typeName.includes("Interior"))
            {
                // 약간의 공차 필요
                var min = BABYLON.Vector3.Minimize(targetBox.minimum, targetBox.minimum.add(absVector.scale(0.01)));
                var max = BABYLON.Vector3.Maximize(targetBox.maximum, targetBox.maximum.add(absVector.scale(0.01)));

                if(VectorHelper.isParallel(targetDir, worldDir))
                {
                    if(collisionBox.intersectsMinMax(min, max))
                    {
                        var targetSize = Math.abs(BABYLON.Vector3.Dot(targetBox.extendSize, worldDir));
                        if(targetSize + sizeValue * offset <= 0)
                        {
                            deletedItems.push(target);
                        }
                        else
                        {
                            target.scaleEntity(worldDir, sizeVector, 0, sizeValue);
                        }
                    }
                    else
                    {
                        target.translateEntity(worldDir.negate(), sizeValue);
                    }
                }
                else if(type == "decrease")
                {
                    if(collisionBox.intersectsMinMax(min, max))
                        deletedItems.push(target);
                    else
                    {
                        target.translateEntity(worldDir.negate(), sizeValue);
                    }
                }
                else
                {
                    target.translateEntity(worldDir.negate(), sizeValue);
                }
            }
            else if(target.info.typeName.includes("Exterior") || isBalconyModule)
            {
                // 발코니 모듈은 Floor를 옮겨도 같이 따라가지 않아서 수치를 더해줘야함
                var tempSize = isBalconyModule ? sizeValue : sizeValue;
                if(!VectorHelper.isParallel(targetDir, worldDir))
                {
                    var point = VectorHelper.calcNearestPointOnLine(moduleCenterLineStart, moduleCenterLineEnd, targetBox.center);
                    var targetDir = BABYLON.Vector3.Normalize(point.subtract(moduleBox.center));
                    if(targetDir.equalsWithEpsilon(collisionCenterDir, 0.0001))
                    {
                        target.translateEntity(worldDir, tempSize);
                    }
                    else
                    {
                        target.translateEntity(worldDir.negate(), tempSize);
                    }
                }
                else
                {
                    target.scaleEntity(worldDir.negate(), sizeVector, 0, sizeValue);
                }
            }
        });

        // 코너 위치 조절 필요
        this.Corners.forEach(target =>
        {
            var targetBox = target.getBoundingBox2d(scene);
            var point = VectorHelper.calcNearestPointOnLine(moduleCenterLineStart, moduleCenterLineEnd, targetBox.center);
            var targetDir = BABYLON.Vector3.Normalize(point.subtract(moduleBox.center));

            if(targetDir.equalsWithEpsilon(collisionCenterDir, 0.0001))
            {
                target.translateEntity(worldDir, sizeValue);
            }
            else
            {
                target.translateEntity(worldDir.negate(), sizeValue);
            }
        });

        // 바닥 크기 조절
        this.Floors.forEach(item =>
        {
            if(!item.info.typeName.includes("Exterior") || isBalconyModule)
            {
                item.scaleEntity(worldDir, sizeVector, 0, sizeValue);
            }
            else
            {
                item.translateEntity(worldDir, sizeValue);
            }
        });

        this.isFixed = true;

        this.Units.forEach(unit =>
        {
            var unitBox = unit.getBoundingBox2d(scene);
            // 약간의 공차 필요
            var min = unitBox.minimum.add(absCrossVector.negate().scale(0.01));
            var max = unitBox.maximum.add(absCrossVector.scale(0.01));

            if(collisionBox.intersectsMinMax(min, max))
            {
                deletedItems.push(unit);
            }
            else
            {
                unit.translateEntity(worldDir.negate(), sizeValue);
            }
        });

        this.Doors.concat(this.Windows).concat(this.Openings).forEach(item =>
        {
            if(!wall || item.parent != wall)
            {
                var itemBox = item.getBoundingBox2d(scene);
                // 약간의 공차 필요
                var min = itemBox.minimum.add(absCrossVector.negate().scale(0.01));
                var max = itemBox.maximum.add(absCrossVector.scale(0.01));

                if(collisionBox.intersectsMinMax(min, max))
                {
                    deletedItems.push(item);
                }
                else
                {
                    item.translateEntity(worldDir.negate(), sizeValue);
                }
            }
            else{
                item.translateEntity(worldDir, sizeValue);
            }

            item.crushMesh.position = item.viewMeshes.find(x=>x.info.ctgrName === 'Box').position.clone();
            item.crushMesh1f.position = item.drawMeshes1f.find(x=>x.info.ctgrName === 'Box').position.clone();
            item.crushMesh2f.position = item.drawMeshes2f.find(x=>x.info.ctgrName === 'Box').position.clone();
        });

        deletedItems.forEach(item =>
        {
           item.deleteEntity(scene, item); 
        });

        // 중심점으로 이동 필요
        var rootMeshes = [this.viewRootMesh, this.draw1fRootMesh, this.draw2fRootMesh];
        rootMeshes.forEach(rootMesh =>
        {
            rootMesh.position = rootMesh.position.add(new BABYLON.Vector3(sizeValue, sizeValue, sizeValue).multiply(worldDir)); 
        });


        this.isFixed = true;
        
        scene.updateEntireRoof();

        this.moduleBoxs.forEach(mbox => mbox.dispose());
        this.moduleBoxs = [];

        setTimeout(() => {
            this.makeBox(this.drawScene1f.instance, '1F')
            this.makeBox(this.drawScene2f.instance, '2F')
            scene.updateWall();
            if(!this.isHistoryWork)
                this.drawScene1f.viewer.$SceneLoader.history.slice(-1)[0].dataB = SaveHelper.makeSaveData(this);
        }, 200); // Wall이 모두 바뀔때까지 약간의 딜레이 필요
    }

    getAll2DRootMeshes()
    {
        return [this.draw1fRootMesh, this.draw2fRootMesh];
    }
    getAll3DRootMeshes()
    {
        return [this.viewRootMesh];
    }

    dispose()
    {
        const childs = this.draw1fRootMesh.getChildMeshes()
                            .concat(this.draw2fRootMesh.getChildMeshes())
                            .concat(this.viewRootMesh.getChildMeshes())

        childs.forEach(c=>{
            if(c.id === 'crushMesh')
            {
                c.spot.delete();
            }
            c.dispose();
        });

        this.Columns.forEach(o =>
        {
            o.dispose();
        });
        this.moduleBoxs.forEach(mbox => mbox.dispose());
        this.draw1fRootMesh.dispose();
        this.draw2fRootMesh.dispose();
        this.viewRootMesh.dispose();
        this.viewScene.viewer.$SceneLoader.EntityLoader.modules = this.viewScene.viewer.$SceneLoader.EntityLoader.modules.filter(x=>x != this);
    }

    deleteChild(scene, entity)
    {
        entity.viewMeshes.concat(entity.drawMeshes1f).concat(entity.drawMeshes2f).forEach(m=>
        {
            m.getChildMeshes().forEach(child =>
            {
               child.dispose(); 
            });
            m.dispose();
        });
        
        if(entity.crushMesh)
        {
            entity.crushMesh.dispose();
        }
        if(entity.crushMesh1f)
        {
            entity.crushMesh1f.dispose();
        }
        if(entity.crushMesh2f)
        {
            entity.crushMesh2f.dispose();
        }

        var targetArray = [];
        switch(entity.info.ctgrName)
        {
            case "Wall":
                targetArray = this.Walls;
                break;
            case "Door":
                targetArray = this.Doors;
                break;
            case "Window":
                targetArray = this.Windows;
                break;
            case "Opening":
                targetArray = this.Openings;
                break;
            case "Stair":
                targetArray = this.Stairs;
                break;
            case "Corner":
                targetArray = this.Corners;
                break;
            case "Floor":
                targetArray = this.Floors;
                break;
        }

        var targetIndex = targetArray.indexOf(entity);
        if(targetIndex >= 0)
            targetArray.splice(targetIndex, 1);

        var targetEntitiesIndex = this.Entities.indexOf(entity);
        if(targetEntitiesIndex >= 0)
            this.Entities.splice(targetEntitiesIndex, 1);
    }

    delete(scene, entity)
    {
        scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.DelteCommand(entity,SaveHelper.makeSaveData(entity)));

        var index = scene.viewer.$SceneLoader.EntityLoader.modules.indexOf(entity);
        if(index >= 0)
            scene.viewer.$SceneLoader.EntityLoader.modules.splice(index, 1);

        entity.Windows.forEach(x=>{
            x.dispose();
        })

        entity.Doors.forEach(x=>{
            x.dispose();
        })

        entity.Openings.forEach(x=>{
            x.dispose();
        })

        entity.Stairs.forEach(x=>{
            x.dispose();
        })

        entity.Units.forEach(x=>{
            x.dispose();
        })

        scene.removeSelectedMesh();
        scene.updateEntireRoof();
        scene.updateWall();
        entity.dispose();
    }

    hideroof(scene, entity)
    {
        entity.isHideRoof = !entity.isHideRoof;
        scene.updateEntireRoof();
    }
}