import { Vector3 } from "@babylonjs/core";
import WallEntity from "./WallEntity";
import CsgHelper from "../Helper/CsgHelper"

export default class TempWallEntity{
    constructor()
    {
        this.info = {ctgrName : 'Wall'};
        this.d1fWall = null;
        this.d2fWall = null;
        this.d1fPointer = null;
        this.d2fPointer = null;
        this.editPoint = null;
        this.startPoint = null;
        this.endPoint = null;
        this.status = false;
        this.targetModule = null;

        this.drawScene1f = null;
        this.drawScene2f = null;
        this.viewScene = null;
    }

    setStatus(value)
    {
        const sMat1 = this.d1fPointer._scene.getMaterialByName('WallPointMat');
        const fMat1 = this.d1fPointer._scene.getMaterialByName('WallRedPointMat');
        const sMat2 = this.d2fPointer._scene.getMaterialByName('WallPointMat');
        const fMat2 = this.d2fPointer._scene.getMaterialByName('WallRedPointMat');
        
        this.d1fPointer.material = value ? sMat1 : fMat1;
        this.d2fPointer.material = value ? sMat2 : fMat2;
        this.status = value;
    }

    setEnable(instance,value)
    {
        if(instance === this.d1fPointer._scene)
        {
            this.d1fPointer.setEnabled(value);
        }
        else
        {
            this.d2fPointer.setEnabled(value);
        }
    }

    setPosition(instance,pos)
    {
        if(instance === this.d1fPointer._scene)
        {
            this.d1fPointer.position = pos.clone();
        }
        else
        {
            this.d2fPointer.position = pos.clone();
        }
    }

    getInstancePointer(instance)
    {
        if(instance === this.d1fPointer._scene)
        {
            return this.d1fPointer;
        }
        else
        {
            return this.d2fPointer;
        }
    }

    updateTempWall(endPoint,length,axis)
    {
        this.endPoint = endPoint;

        const wallThk = 0.09;
        const d1Scene = this.d1fPointer._scene;
        const d2Scene = this.d2fPointer._scene;
        const center = this.startPoint.add(endPoint).divide(new Vector3(2,2,2))
        
        this.d1fWall?.dispose();
        this.d2fWall?.dispose();

        const axisXValue = axis === 'x' ? 1 : 0;
        const axisZValue = axis === 'z' ? 1 : 0;
        const width = (length + wallThk) * axisXValue + wallThk * axisZValue;
        const depth = (length + wallThk) * axisZValue + wallThk * axisXValue;
        
        const newD1fWall = this.createBox(width,depth,d1Scene,center);
        const newD2fWall = this.createBox(width,depth,d2Scene,center);
        
        this.d1fWall = newD1fWall;
        this.d2fWall = newD2fWall;

        this.d1fWall.setEnabled(true);
        this.d2fWall.setEnabled(true);
    }

    createBox(width,depth,instance,pos)
    {
        const box = BABYLON.MeshBuilder.CreateBox(null,{width : width, height : 2.707, depth : depth},instance);
        box.material = instance.getMaterialByName('WallAlphaMat');
        box.position = pos.clone();
        return box;
    }

    createEntity()
    {
        if(!this.endPoint)
            return;
        
        const ent = new WallEntity();
        ent.drawScene1f = this.drawScene1f
        ent.drawScene2f = this.drawScene2f
        ent.viewScene = this.viewScene

        const d1Ins = this.drawScene1f.instance;
        const d2Ins = this.drawScene2f.instance;
        const viewIns = this.viewScene.instance;
 
        const d1Mesh = this.createWallMesh(d1Ins,'WallMat');
        const d2Mesh = this.createWallMesh(d2Ins,'WallMat');
        const viewMesh = this.createWallMesh(viewIns,'WallMat');

        const mInfos = this.targetModule.Walls.map(x=>x.info).filter(x=>x.typeName.includes('Interior'));
        const lastNum = Math.max(...mInfos.map(x=>x.name.split('Interior')[1]).filter(x=>x).map(x=>Number(x)));
        const itemNum = isFinite(lastNum) ? lastNum +1 : 0;
        const parentName = this.targetModule.info.name + '-' + this.targetModule.info.ctgrName;
        const info = {ctgrName:"Wall", flipType:null, name:"Wall-Interior"+itemNum,typeName:"Interior"+itemNum, parentName:parentName};
        ent.info = info;
        ent.moduleEntity = this.targetModule;
        
        d1Mesh.info = info;
        d2Mesh.info = info;
        viewMesh.info = info;

        d1Mesh.entity = ent;
        d2Mesh.entity = ent;
        viewMesh.entity = ent;

        ent.drawMeshes1f.push(d1Mesh)
        ent.drawMeshes2f.push(d2Mesh)
        ent.viewMeshes.push(viewMesh)

        const rot = this.targetModule.draw1fRootMesh.rotation.y;
        d1Mesh.rotation.y -= rot;
        d2Mesh.rotation.y -= rot;
        viewMesh.rotation.y -= rot;

        d1Mesh.position.y = 0.393+1.3535+this.targetModule.draw1fRootMesh.position.y;
        d2Mesh.position.y = 0.393+1.3535+this.targetModule.draw2fRootMesh.position.y;
        viewMesh.position.y = 0.393+1.3535+this.targetModule.viewRootMesh.position.y;

        CsgHelper.initialMatrix(d1Mesh)
        CsgHelper.initialMatrix(d2Mesh)
        CsgHelper.initialMatrix(viewMesh)

        // CsgHelper.setParentSubtractLocation(d1Mesh,this.targetModule.draw1fRootMesh);
        // CsgHelper.setParentSubtractLocation(d2Mesh,this.targetModule.draw2fRootMesh);
        // CsgHelper.setParentSubtractLocation(viewMesh,this.targetModule.viewRootMesh);

        const d1Pos = d1Mesh.getAbsolutePosition();
        const d2Pos = d2Mesh.getAbsolutePosition();
        const vPos = viewMesh.getAbsolutePosition();

        d1Mesh.parent = this.targetModule.draw1fRootMesh;
        d2Mesh.parent = this.targetModule.draw2fRootMesh;
        viewMesh.parent = this.targetModule.viewRootMesh;

        d1Mesh.setAbsolutePosition(d1Pos);
        d2Mesh.setAbsolutePosition(d2Pos);
        viewMesh.setAbsolutePosition(vPos);

        ent.setWallType();
        ent.moduleEntity = this.targetModule;
        this.targetModule.Entities.push(ent);
        this.targetModule.Walls.push(ent);

        this.startPoint = this.endPoint.clone();
        this.endPoint = null;
        this.setPosition(d1Ins,this.startPoint)
        this.setPosition(d2Ins,this.startPoint)

        this.targetModule.setPenetrateMode(this.targetModule.floorPenetrate)
        
        ent.info.guid = this.viewScene.viewer.$SceneLoader.CreateGuid()
        return ent;
    }

    createWallMesh(instance,matName)
    {
        const csg = BABYLON.CSG.FromMesh(this.d1fWall);
        const mat = instance.getMaterialByName(matName);

        const newWall = csg.toMesh(null,mat,instance,true);
        return newWall;
    }


    dispose()
    {
        this.d1fWall?.dispose();
        this.d2fWall?.dispose();
        this.d1fPointer?.dispose();
        this.d2fPointer?.dispose();

        this.drawScene1f.isAddWallMode = false;
        this.drawScene2f.isAddWallMode = false;

        const d1Ins = this.drawScene1f.instance;
        const d2Ins = this.drawScene2f.instance;

        d1Ins.getMeshesById('wallPoint').forEach(w=>{
            w.dispose();
        })

        d2Ins.getMeshesById('wallPoint').forEach(w=>{
            w.dispose();
        })
    }
}