<template>
  <div>
    <div id="nav">
      <router-link to="/"></router-link>
    </div>
    <router-view />
  </div>
</template>

<style>
@font-face {
  font-family: "Pretendard";
  src: url("../src/assets/fonts/Pretendard-Regular.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-Black.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-Bold.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-ExtraBold.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-ExtraLight.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-Light.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-Medium.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-SemiBold.ttf") format("truetype"),
       url("../src/assets/fonts/Pretendard-Thin.ttf") format("truetype");
}

body {
  margin: 0px;
}
</style>
