export default class VectorHelper
{
    //Returns {.x, .y}, a projected point perpendicular on the (infinite) line.
    static calcNearestPointOnLine(line1, line2, pnt) {
        var L2 = ( ((line2.x - line1.x) * (line2.x - line1.x)) + ((line2.z - line1.z) * (line2.z - line1.z)) );
        if(L2 == 0) return false;
        var r = ( ((pnt.x - line1.x) * (line2.x - line1.x)) + ((pnt.z - line1.z) * (line2.z - line1.z)) ) / L2;

        return new BABYLON.Vector3(line1.x + (r * (line2.x - line1.x)), 0, line1.z + (r * (line2.z - line1.z)));
    }

    //Returns float, the shortest distance to the (infinite) line.
    static calcDistancePointToLine(line1, line2, pnt) {
        var L2 = ( ((line2.x - line1.x) * (line2.x - line1.x)) + ((line2.z - line1.z) * (line2.z - line1.z)) );
        if(L2 == 0) return false;
        var s = (((line1.z - pnt.z) * (line2.x - line1.x)) - ((line1.x - pnt.x) * (line2.z - line1.z))) / L2;
        return Math.abs(s) * Math.sqrt(L2);
    }

    //Returns bool, whether the projected point is actually inside the (finite) line segment.
    static calcIsInsideLineSegment(line1, line2, pnt) {
        var L2 = ( ((line2.x - line1.x) * (line2.x - line1.x)) + ((line2.z - line1.z) * (line2.z - line1.z)) );
        if(L2 == 0) return false;
        var r = ( ((pnt.x - line1.x) * (line2.x - line1.x)) + ((pnt.z - line1.z) * (line2.z - line1.z)) ) / L2;

        return (0 <= r) && (r <= 1);
    }

    //The most useful function. Returns bool true, if the mouse point is actually inside the (finite) line, given a line thickness from the theoretical line away. It also assumes that the line end points are circular, not square.
    static calcIsInsideThickLineSegment(line1, line2, pnt, lineThickness) {
        var L2 = ( ((line2.x - line1.x) * (line2.x - line1.x)) + ((line2.z - line1.z) * (line2.z - line1.z)) );
        if(L2 == 0) return false;
        var r = ( ((pnt.x - line1.x) * (line2.x - line1.x)) + ((pnt.z - line1.z) * (line2.z - line1.z)) ) / L2;

        //Assume line thickness is circular
        if(r < 0) {
            //Outside line1
            return (Math.sqrt(( (line1.x - pnt.x) * (line1.x - pnt.x) ) + ( (line1.z - pnt.z) * (line1.z - pnt.z) )) <= lineThickness);
        } else if((0 <= r) && (r <= 1)) {
            //On the line segment
            var s = (((line1.z - pnt.z) * (line2.x - line1.x)) - ((line1.x - pnt.x) * (line2.z - line1.z))) / L2;
            return (Math.abs(s) * Math.sqrt(L2) <= lineThickness);
        } else {
            //Outside line2
            return (Math.sqrt(( (line2.x - pnt.x) * (line2.x - pnt.x) ) + ( (line2.z - pnt.z) * (line2.z - pnt.z) )) <= lineThickness);
        }
    }

    static reflectToPoint(point, planeOrigin, normal)
    {
        var zeroPoint = point.subtract(planeOrigin);
        var reflectPoint = BABYLON.Vector3.Reflect(zeroPoint, normal);
        return reflectPoint.add(planeOrigin);
    }

    static getPolygonCentroid(points, y) {

        var area = 0;
        for (var i = 0; i < points.length; i++) {
            area += (points[i].x * points[(i + 1) % points.length].z - points[(i + 1) % points.length].x * points[i].z) / 2;
        }

        points.push(new BABYLON.Vector3(points[0].x, y, points[0].z));

        // Get the centroid
        var x = 0;
        var z = 0;
        var m_sf;

        for (var i = 0; i < points.length - 2; i++) {
            m_sf = points[i].x * points[i + 1].z - points[i + 1].x * points[i].z;
            x += (points[i].x + points[i + 1].x) * m_sf;
            z += (points[i].z + points[i + 1].z) * m_sf;
        }

        x /= (6 * area);
        z /= (6 * area);

        return new BABYLON.Vector3(x, y, z);
    }

    static getCentroid2D(points)
    {
        var sumX = 0;
        var sumY = 0;

        points.forEach(o =>
        {
           sumX += o.x;
           sumY += o.y; 
        });

        return {x:sumX/points.length, y:sumY/points.length};
    }

    static isParallel(vector1, vector2, tolerance = 0.0001)
    {
        return vector1.equalsWithEpsilon(vector2, tolerance) || vector1.negate().equalsWithEpsilon(vector2, tolerance);
    }

    static normalize2D(point)
    {
        var length = Math.sqrt(point.x*point.x+point.y*point.y);
        return {x:point.x/length, y:point.y/length};
    }

    static add2D(point1, point2)
    {
        return {x:point1.x + point2.x, y:point1.y + point2.y};
    }

    static subtract2D(point1, point2)
    {
        return {x:point1.x - point2.x, y:point1.y - point2.y};
    }

    static scale2D(point, value)
    {
        return {x:point.x * value, y:point.y * value};
    }

    static abs(vector)
    {
        return new BABYLON.Vector3(Math.abs(vector.x), Math.abs(vector.y), Math.abs(vector.z));
    }
}