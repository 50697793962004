import { Vector3 } from "@babylonjs/core";
import WallEntity from "../Entities/WallEntity";

export default class SaveHelper
{
    static makeSaveData(moduleEnt)
    {
        const walls = moduleEnt.Walls;
        const wallDatas = this.makeWallData(walls);
        const windows = moduleEnt.Windows;
        const windowDatas = this.makeWindowData(windows);
        const openings = moduleEnt.Openings;
        const openingDatas = this.makeOpeningData(openings);
        const units = moduleEnt.Units;
        const unitDatas = this.makeUnitData(units);
        const doors = moduleEnt.Doors;
        const doorDatas = this.makeDoorData(doors);


        const mData = {
            name : moduleEnt.info.name,
            position : moduleEnt.viewRootMesh.position.clone(),
            rotation : moduleEnt.viewRootMesh.rotation.y,
            scaling : moduleEnt.viewRootMesh.scaling.clone(),
            size : moduleEnt.getSize(),
            info : moduleEnt.info,
            wallexPos : moduleEnt.Walls.filter(x=>x.info.name.includes('Exterior')).map(x=>x.viewMeshes[0].position.clone()),
            wallexScailing : moduleEnt.Walls.filter(x=>x.info.name.includes('Exterior')).map(x=>x.viewMeshes[0].scaling.clone()),
            wallRailPos : moduleEnt.Walls.filter(x=>x.info.name.includes('Rail')).map(x=>x.viewMeshes.map(y=>y.position.clone())),
            wallRailDrawPos : moduleEnt.Walls.filter(x=>x.info.name.includes('Rail')).map(x=>x.drawMeshes1f.map(y=>y.position.clone())),
            wallRailNmData : moduleEnt.Walls.filter(x=>x.info.name.includes('Rail')).map(x=>x.info.name),
            walls : wallDatas,
            windows : windowDatas,
            openings : openingDatas,
            units : unitDatas,
            doors : doorDatas,
            guid : moduleEnt.guid
        }
        //moduleEnt.Walls.filter(x=>x.info.typeName.includes('Exterior')).map(x=>x.viewMeshes[0].position),
        return mData;
    }

    static makeWallData(walls)
    {
        const result = [];
        for(let w of walls)
        {
            if(w.info?.typeName.includes("Exterior") || w.info?.typeName.includes("Rail"))
                continue;
            const mesh = w.viewMeshes[0];
            const bb = mesh.getBoundingInfo().boundingBox;
            const width = bb.extendSize.x * 2;
            const depth = bb.extendSize.z * 2;

            const data = {
                position : mesh.position.clone(),
                width : width,
                depth : depth,
                info : w.info,
                type : w.wallType,
                scale : mesh.scaling.clone(),
            }

            result.push(data);
        }
        return result;
    }

    static makeWindowData(windows)
    {
        const result = [];
        for(let w of windows)
        {
            const positions = w.viewMeshes.map(x => x.position.clone());
            const simbol1fPositions = w.drawMeshes1f.map(x => x.position.clone());
            const simbol2fPositions = w.drawMeshes2f.map(x => x.position.clone());
            const rotations = w.viewMeshes.map(x => x.rotation.clone());
            const simbol1fRotations = w.drawMeshes1f.map(x => x.rotation.clone());
            const simbol2fRotations = w.drawMeshes2f.map(x => x.rotation.clone());
            const scalings = w.viewMeshes.map(x => x.scaling.clone());
            const simbol1fScalings = w.drawMeshes1f.map(x => x.scaling.clone());
            const simbol2fScalings = w.drawMeshes2f.map(x => x.scaling.clone());

            const data = {
                positions : positions,
                simbol1fPositions : simbol1fPositions,
                simbol2fPositions : simbol2fPositions,
                rotations : rotations,
                simbol1fRotations : simbol1fRotations,
                simbol2fRotations : simbol2fRotations,
                scalings : scalings,
                simbol1fScalings : simbol1fScalings,
                simbol2fScalings : simbol2fScalings,
                info : w.info
            }

            result.push(data);
        }
        return result;
    }

    static makeOpeningData(openings)
    {
        const result = [];
        for(let o of openings)
        {
            const mesh = o.viewMeshes[0];
            const position = mesh.position.clone();
            const rotation = mesh.rotation.clone();
            const bb = mesh.getBoundingInfo().boundingBox;
            const width = bb.extendSize.x * 2;
            const height = bb.extendSize.y * 2;
            const depth = bb.extendSize.z * 2;

            const data = {
                width : width.toFixed(4),
                height : height.toFixed(4),
                depth : depth.toFixed(4),
                position : position,
                rotation : rotation,
                info : o.info
            }
            result.push(data)
        }
        
        return result;
    }

    static makeUnitData(units)
    {
        const result = [];
        for(let o of units)
        {
            const data ={
                info : o.info,
                position : o.viewRootMesh.position.clone(),
                rotation : o.viewRootMesh.rotation.clone(),
                scaling : o.viewRootMesh.scaling.clone(),
                floorPenetrate : o.floorPenetrate,
                enableDoor : o.enableDoor
            }

            result.push(data)
        }

        return result;
    }

    static makeDoorData(doors)
    {
        const result = [];
        for(let o of doors)
        {
            const positions = o.viewMeshes.map(x => x.position.clone());
            const rotations = o.viewMeshes.map(x => x.rotation.clone());
            const scaling = o.viewMeshes.map(x => x.scaling.clone());

            const simbol1fPositions = o.drawMeshes1f.map(x => x.position.clone());
            const simbol2fPositions = o.drawMeshes2f.map(x => x.position.clone());

            const simbol1fRotations = o.drawMeshes1f.map(x => x.rotation.clone());
            const simbol2fRotations = o.drawMeshes2f.map(x => x.rotation.clone());

            const simbol1Scaling = o.drawMeshes1f.map(x => x.scaling.clone());
            const simbol2Scaling = o.drawMeshes2f.map(x => x.scaling.clone());

            const data = {
                positions : positions,
                rotations : rotations,
                scaling : scaling,
                simbol1fPositions : simbol1fPositions,
                simbol2fPositions : simbol2fPositions,
                simbol1fRotations : simbol1fRotations,
                simbol2fRotations : simbol2fRotations,
                simbol1Scaling : simbol1Scaling,
                simbol2Scaling : simbol2Scaling,
                info : o.info
            }

            result.push(data);
        }

        return result;
    }

    static loadWall(module,walls)
    {
        for(let wData of walls)
        {
            const ent = new WallEntity();
            ent.drawScene1f = module.drawScene1f
            ent.drawScene2f = module.drawScene2f
            ent.viewScene = module.viewScene
    
            const d1Ins = ent.drawScene1f.instance;
            const d2Ins = ent.drawScene2f.instance;
            const viewIns = ent.viewScene.instance;
    
            const d1Mesh = BABYLON.MeshBuilder.CreateBox(null,{width : wData.width, height : 2.707, depth : wData.depth},d1Ins);
            const d2Mesh = BABYLON.MeshBuilder.CreateBox(null,{width : wData.width, height : 2.707, depth : wData.depth},d2Ins);
            const viewMesh = BABYLON.MeshBuilder.CreateBox(null,{width : wData.width, height : 2.707, depth : wData.depth},viewIns);

            d1Mesh.material = d1Ins.getMaterialByName('WallMat');
            d2Mesh.material = d2Ins.getMaterialByName('WallMat');
            viewMesh.material = viewIns.getMaterialByName('WallMat');

            d1Mesh.position = new Vector3(wData.position._x,wData.position._y,wData.position._z)
            d2Mesh.position = new Vector3(wData.position._x,wData.position._y,wData.position._z)
            viewMesh.position = new Vector3(wData.position._x,wData.position._y,wData.position._z)

            d1Mesh.scaling = new Vector3(wData.scale._x,wData.scale._y,wData.scale._z);
            d2Mesh.scaling = new Vector3(wData.scale._x,wData.scale._y,wData.scale._z);
            viewMesh.scaling = new Vector3(wData.scale._x,wData.scale._y,wData.scale._z);

            d1Mesh.parent = module.draw1fRootMesh;
            d2Mesh.parent = module.draw2fRootMesh;
            viewMesh.parent = module.viewRootMesh;

            d1Mesh.entity = ent;
            d2Mesh.entity = ent;
            viewMesh.entity = ent;
            if(wData.info.parentName.includes('Floor'))
            {
                const p = module.Floors.find(x=>x.info.name === wData.info.parentName);
                d1Mesh.parent = p.drawMeshes1f[0];
                d2Mesh.parent = p.drawMeshes2f[0];
                viewMesh.parent = p.viewMeshes[0];
            }

            ent.info = {...wData.info};
            ent.moduleEntity = module;
            ent.drawMeshes1f.push(d1Mesh);
            ent.drawMeshes2f.push(d2Mesh);
            ent.viewMeshes.push(viewMesh);

            d1Mesh.info = ent.info;
            d2Mesh.info = ent.info;
            viewMesh.info = ent.info;

            ent.wallType = wData.type;

            module.Walls.push(ent);
            module.Entities.push(ent);
        }
    }

}