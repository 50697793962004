<template>
    <div id="Header_Root">
        <div class="hw-title">
            <img src="../../assets/images/Logo.png" alt="">
            <p>Home Configurator</p>

            <div class="MyInfo_Wrapper" ref="myInfoWrapper" @mouseleave="hideMyPage">
                <button @click="clickMyPage" type="button">
                    <img src="../../assets/images/icons/mypage_2.png" alt="" class="myInfoIcon">
                </button>

                <div class="MyInfo_List" v-if="showMyPage" :class="{ 'block' : showMyPage}">
                    <p class="arrow"></p>
                    <ul>
                        <li class="name"><strong>{{ this.userName }}</strong> 님</li>
                        <li>
                            <!-- <router-link to="/mypage" :href="`${this.$TARGETURL}myPage`" @click="navigateToPage('/mypage')" :class="{ 'selected': isCurrentPage('/mypage') }" id="myhome">
                                마이페이지
                            </router-link> -->
                            <a @click="navigateToPage('mypage')" :class="{ 'selected': isCurrentPage('/mypage') }" id="myhome">
                                마이페이지
                            </a>
                        </li>
                        <li>
                            <!-- <router-link to="/myHome" @click="navigateToPage('/myhome')" :class="{ 'selected': isCurrentPage('/myhome') }" id="myhome">
                                내가 만든 주택
                            </router-link> -->
                            <a @click="navigateToPage('myhome')" :class="{ 'selected': isCurrentPage('/myhome') }" id="myhome">
                                내가 만든 주택
                            </a>
                        </li>
                        <!-- <li>
                            <router-link to="/account">
                                로그아웃
                            </router-link>
                        </li> -->
                        <li @click="logout">로그아웃</li>
                    </ul>
                </div>
            </div>
        </div>
        <LogInModal @childEvent="getToken" @childEvent2="LoginChild2" v-if="IsModalPopup && !IsLogin" />
    </div>
</template>

<style scoped>
#Header_Root {
    grid-row:1;
    width:100vw;
    height: 100%;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
}
#Header_Root .hw-title {
    display:flex;
    flex-direction: row;
    align-items:center;
    column-gap: 35px;
}
#Header_Root .hw-title img {
    height: 28px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    margin-left: 24px;
}
.iconContainer {
    margin-right:24px;
}
.MyInfo_Wrapper {
    position: absolute;
    top: 20px;
    right: 24px;
    float: right;
    z-index: 9998;
    cursor: pointer;
}
.M_Header .hw-title p {
    font-family: Pretendard,sans-serif;
    font-size: 1.2rem;
    color: #787878;
    font-weight: 300;
    letter-spacing: -.5px;
    cursor: pointer;
    margin-left:0 !important
}

.myInfoIcon {
    cursor: pointer;
    height: 36px !important;
}
.MyInfo_List {
    position:absolute;
    top: 0;
    right: 0;
    height: auto;
    display:none
}
.arrow {
    position:relative;
    content: "";
    top: 35px;
    right: 8px;
    float: right;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #fff;
    box-shadow: -3px -4px 4px -2px #ccc;
    z-index:9990;
}

.MyInfo_Wrapper ul {
    margin-top:60px;
    width: 119px;
    padding: 25px 12px ;
    background: #fff;
    border-radius: 5px;
    box-shadow: 1px 1px 3px 2px #ccc;
}
.MyInfo_Wrapper .name strong {
    font-size:1rem;
}
.MyInfo_Wrapper ul li,
.MyInfo_Wrapper ul li a {
    cursor: pointer;
    font-size:.8rem;
    color:#787878;
    line-height:1.6rem;
}
.MyInfo_Wrapper ul li a:hover,
.MyInfo_Wrapper ul li:hover  {
    font-weight: bold;
}

.MyInfo_Wrapper ul li:first-child:hover  {
    font-weight: initial;
}
.MyInfo_Wrapper ul li:first-child {
    cursor:initial
}
.MyInfo_Wrapper button:active ~ .MyInfo_List {
    display:block;
}
.MyInfo_Wrapper button ~ .MyInfo_List:hover {
    display: block;
}
.MyInfo_Wrapper button {
    background:0;
    border:0;
}
.block {
    display: block;
}
.selected {
    font-weight: bold;
    color:#000
}
</style>


<script>
import Cookies from 'js-cookie';
import LogInModal from '../Login/LogInModal.vue';
import axios from 'axios';

    export default {
        components : {
            LogInModal
        },
        data() {
            return {
                IsModalPopup: false,
                token: {},
                userName: '',
                userEmail: '',
                userIdx: '',
                IsLogin: false,
                showMyPage:false
            }
        },
        async created() {
            await this.getToken();
        },
        methods : {
            async getToken() {
                const cookie = Cookies.get("token");
                let data = {
                    token: cookie == undefined ? "" : cookie,
                };
                //const res = await axios.post("http://localhost:12008/token_check", JSON.stringify(data),{
                const res = await axios.post(
                    "/server/token_check",
                    JSON.stringify(data),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                );
                if (data.token == "") {
                    //토큰 자체가 없으면 걍 로그인 안된거
                    this.IsLogin = false;
                } else if (res.data._data["length"] == 0) {
                    alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
                    Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                    Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
                    //this.$router.push("/member/login");
                    window.location.reload();
                } else if (res.status == 200) {
                    // 로그인 된거
                    this.userEmail = res.data._data[0]["USER_EMAIL"];
                    this.userName = res.data._data[0]["NAME"];
                    this.userIdx = res.data._data[0]["idx"];
                    this.IsLogin = true;
                }
            },
            async getFileList() {
                const res = await axios.post("/server/read_file",
                    `{ "key":"READ", "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
                if (res.status == 200) {
                    this.fileList = [...res.data._data];
                    console.log(this.fileList)
                }
            },
            logout() {
                alert("로그아웃 합니다");
                if (this.userCode == 1) {
                    console.log("카카오로그아웃");
                    this.kakaoLogout();
                }
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
                //window.location.href = "http://localhost:8080/member/login"
                //this.$router.push('/member/login');
                window.location.reload();
                this.IsLogin = false;
            },
            saveFileToLocal(home) {
                localStorage.setItem('load', home.FILE_DATA);
                localStorage.setItem('loadIdx', home.IDX);
            },
            // clickMyPage() {
            //     if (this.userName != '') {
            //         this.showMyPage = !this.showMyPage;
            //         console.log(this.showMyPage);
            //     } else {
            //         this.IsModalPopup = true;
            //         this.showMyPage = true; // 로그인이 된 상태에서 showMyPage도 show되게~..
            //     }
            // },
            clickMyPage() {
                if (this.IsLogin) {
                    this.showMyPage = !this.showMyPage;
                } else {
                    this.IsModalPopup = !this.IsModalPopup;
                }
            },
            // showMyPage가 true일 때 MyPage_Wrapper 영역 밖으로 마우스 놓으면 hide~...
            hideMyPage(event) {
                if (!this.$refs.myInfoWrapper.contains(event.relatedTarget)) {
                    this.showMyPage = false;
                }
            },
            LoginChild2() {
                this.IsModalPopup = false;
            },
            navigateToPage(page) {
                // this.$emit('navigate', page);
                // this.$router.push(page).then(() => {
                //     this.updateCheckedState();
                // });
                window.location.href = this.$TARGETURL + page;
            },
            isCurrentPage(page) {
                return this.$route.path === page;
            },
            updateCheckedState() {
                const mypage = document.getElementById('mypage');
                const myhome = document.getElementById('myhome');

                if (mypage) mypage.checked = false;
                if (myhome) myhome.checked = false;

                if (this.isCurrentPage('/mypage')) {
                    const mypage = document.getElementById('mypage');
                    if (mypage) mypage.checked = true;
                } else if (this.isCurrentPage('/myhome')) {
                    const myhome = document.getElementById('myhome');
                    if (myhome) myhome.checked = true;
                }
            },
            kakaoLogout() {
                alert("카카오로그아웃");
            window.Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    console.log(response);
                    document.cookie = "token=" + "";
                    //alert("로그아웃 되었어용");
                },
                fail: function (error) {
                    console.log(error);
                },
            });
        },
        },
        computed: {
            currentRoute() {
                return this.$route.path;
            }
        }
    }
</script>
