<template>
    <div id="Test_Main" ref="test">
        <div id="LoadingBack" v-if="!isLoaded">
            <img src="../assets/images/icons/indicator.gif" alt="" loop="infinite" />
        </div>
        <div class="Test_Div">
            <!--  nav Start   -->
            <Header />

            <div class="B_Header">
                <div class="btnDiv">
                    <input type="button" name="undo" id="undo" value="undo">
                    <label for="undo"
                        v-show="this.$SceneLoader.historyIndex <= -1 || this.$SceneLoader.history.length < 1"
                        class="bg">
                        <img src="../assets//images/icons/BHeader/Undo(small).png" alt="prev">
                    </label>
                    <label for="undo"
                        v-show="this.$SceneLoader.historyIndex >= 0 && this.$SceneLoader.history.length > 0"
                        @click="undoClickEvent">
                        <img src="../assets//images/icons/BHeader/Undo(small).png" alt="prev">
                    </label>

                    <input type="button" name="redo" id="redo" value="redo">
                    <label for="redo" v-show="this.$SceneLoader.history.length <= this.$SceneLoader.historyIndex + 1"
                        class="bg">
                        <img src="../assets//images/icons/BHeader/Redo(small).png" class="re">
                    </label>
                    <label for="redo" v-show="this.$SceneLoader.history.length > this.$SceneLoader.historyIndex + 1"
                        @click="redoClickEvent">
                        <img src="../assets//images/icons/BHeader/Redo(small).png" class="re">
                    </label>
                    <div class="line"></div>

                    <input type="checkbox" name="2DOption" id="2DOption" value="2DOption" v-model="is2DActive"
                        :checked='is2DActive' :disabled="!is3DActive">
                    <label for="2DOption" class="SecondD bg" @click="toggleOpacity2D" title="2D뷰를 표시하거나 숨깁니다">
                        <img src="../assets/images/icons/BHeader/2DView.png" alt="" class="btnActive">
                    </label>

                    <input type="checkbox" name="3DOption" id="3DOption" value="3DOption" v-model="is3DActive"
                        :checked='is3DActive' :disabled="!is2DActive">
                    <label for="3DOption" @click="toggleOpacity3D" class="ThirdD bg" title="3D뷰를 표시하거나 숨깁니다">
                        <img src="../assets/images/icons/BHeader/3DView.png" alt="" class="btnActive">
                    </label>

                    <input type="checkbox" name="Swap" id="Swap" value="Swap" @click="toggleClick">
                    <label for="Swap" title="2D뷰와 3D뷰의 위치를 전환합니다">
                        <img src="../assets/images//icons/BHeader/Swap.png" alt="">
                    </label>
                    <div class="line"></div>

                    <input type="checkbox" name="SnapFloor" id="SnapFloor" value="SnapFloor"
                        v-model="$SceneLoader.isSnappedFloor" :checked='$SceneLoader.isSnappedFloor'>
                    <label for="SnapFloor" @click="toggleOpacityFloor" class="bg" title="배치 요소를 바닥과 자동으로 부착시킵니다">
                        <img src="../assets/images/icons/BHeader/SnapModule.png" alt="">
                    </label>

                    <input type="checkbox" name="SnapModule" id="SnapModule" value="SnapModule"
                        v-model="$SceneLoader.isSnappedWall" :checked='$SceneLoader.isSnappedWall'>
                    <label for="SnapModule" @click="toggleOpacityModule" class="bg" title="배치 요소를 벽과 자동으로 부착시킵니다">
                        <img src="../assets/images/icons/BHeader/SnapWall.png" alt="">
                    </label>

                    <input type="checkbox" name="SnapDoor" id="SnapDoor" value="SnapDoor"
                        v-model="$SceneLoader.isSnappedDoor" :checked='$SceneLoader.isSnappedDoor'>
                    <label for="SnapDoor" @click="toggleOpacityDoor" class="bg" title="배치 요소를 문과 자동으로 부착시킵니다">
                        <img src="../assets/images/icons/BHeader/SnapDoor.png" alt="">
                    </label>

                    <input type="checkbox" name="SnapWindow" id="SnapWindow" value="SnapWindow"
                        v-model="$SceneLoader.isSnappedWindow" :checked='$SceneLoader.isSnappedWindow'>
                    <label for="SnapWindow" @click="toggleOpacityWindow" class="bg" title="배치 요소를 창문과 자동으로 부착시킵니다">
                        <img src="../assets/images/icons/BHeader/SnapWindow.png" alt="">
                    </label>
                </div>

                <div class="ViewDiv_Tool">

                    <!-- <input type="checkbox" name="create" id="CreateTab" value="create">
                    <label for="CreateTab" class="bg" title="새로만들기" @click="toEditPage">
                        <img src="../assets/images/icons/BHeader/createNew.png" alt="">
                    </label> -->

                    <router-link to="/editTemp" style="margin: 3px;">
                        <img src="../assets/images/icons/BHeader/createNew.png" alt="">
                    </router-link>

                    <!-- <input type="file" ref="fileInput" @change="clickLoad" id="FileTab">
                    <label for="FileTab" title="파일선택">
                        <img src="../assets/images/icons/BHeader/import.png" alt="">
                    </label> -->

                    <div class="SaveSlot">
                        <input type="button" name="Save" id="SaveTab" class="saveSlot">
                        <label for="SaveTab" class="bg" title="Save" @click="clickSaveButton" >
                            <img src="../assets/images/icons/BHeader/save.png" alt="">
                        </label>

                        <div class="SlotWrap" v-show="IsSave">
                            <div class="Slot_Container">
                                <h4>저장할 위치를 선택하세요</h4>
                                <ul class="SlotList">
                                    <li class="SaveHome_Container" v-for="(file, i) in fileList" :key="i">
                                        <div class="saveHome_List">
                                            <input type="button" :id="`saveHomeCheckbox${i}`" class="SaveHome_Btn">
                                            <label :for="`saveHomeCheckbox${i}`">
                                                <div class="SavedHomeSlot_List">
                                                    <div class="imgWrap">
                                                        <img :src="file.THUMB_IMG" alt="">
                                                    </div>

                                                    <div class="SaveHome_info">
                                                        <h4>{{ file.FILE_NAME }}</h4>
                                                        <p>{{ file.CREATE_DATE }}</p>
                                                    </div>
                                                </div>

                                                <div class="overWrite_Slot">
                                                    <img src="../assets/images/icons/alert.png" alt="">
                                                    <h4>기존 프로젝트를 덮어쓰시겠습니까?</h4>

                                                    <ul class="overWriteBtn">
                                                        <li>
                                                            <button @click="fileUpdate(file.IDX)">확인</button>
                                                        </li>
                                                        <li>
                                                            <button @click="close">취소</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </label>
                                        </div>
                                    </li>

                                    <li class="addHome_Container" v-if="fileList.length < 5">
                                        <div class="imgWrap">
                                            <img src="../assets/images/icons/plus.png" alt="">
                                        </div>
                                        <div class="SaveHome_info">
                                            <h4 @click="fileSave">새 프로젝트</h4>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <input type="checkbox" name="Option" id="OptionTab" value="Option" v-model="SettingValue">
                    <label for="OptionTab" class="bg" title="설정">
                        <img src="../assets/images/icons/BHeader/settings.png" alt="">
                        <div id="OptionWapper" v-show="SettingValue">
                            <div id="OptionModal">
                                <p style="grid-row: 2;">면적 표시</p>
                                <p style="grid-row: 3;">스케일바 표시</p>
                                <div class="OptionCheckWapper" style="grid-row: 2;">
                                    <input type="checkbox" id="OptionAreaCheck" v-model="this.$SceneLoader.isShowArea"
                                        @click="updateUi">
                                    <label for="OptionAreaCheck" style="height: 1.3rem;">
                                        <span></span>
                                    </label>
                                </div>
                                <div class="OptionCheckWapper" style="grid-row: 3;">
                                    <input type="checkbox" id="OptionScailCheck"
                                        v-model="this.$SceneLoader.isShowScailBar" @click="updateUi">
                                    <label for="OptionScailCheck" style="height: 1.3rem;">
                                        <span></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <!--  nav End   -->

            <!-- Tool start -->
            <div class="Tool_Div">
                <div class="Tool_Container">
                    <input type="radio" name="tabGroup" id="moduleTab" value="module" v-model="tabValue">
                    <label for="moduleTab" class="module"></label>

                    <input type="radio" name="tabGroup" id="unitTab" value="unit" v-model="tabValue">
                    <label for="unitTab" class="unit"></label>

                    <input type="radio" name="tabGroup" id="wallTab" value="wall" v-model="tabValue">
                    <label for="wallTab" class="wall"></label>

                    <input type="radio" name="tabGroup" id="doorTab" value="door" v-model="tabValue">
                    <label for="doorTab" class="door"></label>

                    <input type="radio" name="tabGroup" id="windowTab" value="window" v-model="tabValue">
                    <label for="windowTab" class="window"></label>

                    <input type="radio" name="tabGroup" id="roofTab" value="roof" v-model="tabValue">
                    <label for="roofTab" class="roof"></label>
                </div>
            </div>

            <!-- 01.모듈 생성 Tab START -->
            <div class="tool_C_Div" v-show="tabValue === 'module'">
                <div class="tool_C_tab">
                    <input type="radio" name="Tab" id="select" value="All" v-model="selectedCategory" checked /><label
                        for="select">All</label>
                    <input type="radio" name="Tab" id="select2" value="LDK" v-model="selectedCategory" /><label
                        for="select2" @mouseover="showLDK" @mouseleave="hide" title="거실, 식사공간, 주방 ">LDK</label>
                    <input type="radio" name="Tab" id="select3" value="Room" v-model="selectedCategory"><label
                        for="select3" @mouseover="showRoom" @mouseleave="hideR" title="침실">Room</label>
                    <input type="radio" name="Tab" id="select4" value="Core" v-model="selectedCategory" /><label
                        for="select4" @mouseover="showCore" @mouseleave="hideC" title="계단 및 수직 공간">Core</label>
                    <input type="radio" name="Tab" id="select5" value="Etc" v-model="selectedCategory" /><label
                        for="select5" @mouseover="showEtc" @mouseleave="hideE" title="기타 용도">Etc</label>
                    <input type="radio" name="Tab" id="select6" value="Basic" v-model="selectedCategory" /><label
                        for="select6" @mouseover="showBasic" @mouseleave="hideB" title="베이직 모듈">Basic</label>
                </div>

                <div class="Module_Wrapper">
                    <div class="module_Thumbs" v-for="(m, index) in paginatedModules" :key="index">
                        <input type="radio" :id="m.id" :checked="m.clicked" @click.stop="handleClick(m)"
                            name="ModuleThumb">
                        <label :for="m.id" @click="C_Module_info(m)">
                            <div class="Module_Name">
                                <p :key="m.code">{{ m.code }}</p>
                            </div>
                            <img :src="m.imgUrl" alt="" @click="handleImageClick(m)">
                        </label>
                    </div>
                </div>

                <div class="module_info" v-show="selectModuleInfo.clicked">
                    <img :src="selectModuleInfo.imgUrl" alt="">
                    <div class="module_spec">
                        <h4 class="tit">{{ selectModuleInfo.displayName }}</h4>
                        <div class="spec_info">
                            <p>{{ selectModuleInfo.areaMm }} ㎡ ({{ selectModuleInfo.area }}py)</p>
                            <p>방 : {{ selectModuleInfo.bedRoomCnt }}&nbsp;&nbsp;|&nbsp;&nbsp;화장실 :
                                {{ selectModuleInfo.bathRoomCnt }}</p>
                            <p>가로 : {{ selectModuleInfo.horizontal }} mm&nbsp;&nbsp;|&nbsp;&nbsp;세로 :
                                {{ selectModuleInfo.vertical }} mm</p>
                        </div>
                    </div>
                </div>

                <div class="module_info_Viewer">
                    <img src="" alt="">
                    <ul>
                        <li></li>
                    </ul>
                </div>

                <div class="paging_Div">
                    <img src="../assets/images/icons/prev.png" class="prev" @click="PreviousPage"
                        :disabled="currentPage === 1">
                    <span v-if="filteredModules.length > itemsPerPage">{{ currentPage }} / {{ totalPages }}</span>
                    <span v-else>1 / 1</span>
                    <img src="../assets/images/icons/prev.png" class="next" @click="NextPage"
                        :disabled="currentPage === totalPages">
                </div>
            </div>
            <!-- 01.모듈 생성 Tab END -->

            <!-- 02.소형 유닛 Tab START -->
            <div class="Unit_List_Div" v-show="tabValue === 'unit'">
                <div class="Unit_Tab">
                    <input type="radio" name="UTab" id="selectU" value="All" v-model="selectedCategoryUnit" /><label
                        for="selectU">All</label>
                    <input type="radio" name="UTab" id="selectU1" value="Entry" v-model="selectedCategoryUnit" /><label
                        for="selectU1" @mouseover="showEntry" @mouseleave="hideEnt" title="현관">Entry</label>
                    <input type="radio" name="UTab" id="selectU2" value="Bath" v-model="selectedCategoryUnit" /><label
                        for="selectU2" @mouseover="showBath" @mouseleave="hideBath" title="화장실">Bath</label>
                    <input type="radio" name="UTab" id="selectU3" value="Stairs" v-model="selectedCategoryUnit" /><label
                        for="selectU3" @mouseover="showStairs" @mouseleave="hideStairs" title="계단실">Stairs</label>
                </div>

                <div class="Unit_Wrapper">
                    <div class="unit_Thumbs" v-for="(m, index) in paginatedUnits" :key="index">
                        <input type="radio" :id="m.id" :checked="m.clicked" @click.stop="handleClick(m)"
                            name="UnitThumb">
                        <label :for="m.id" @click="C_Unit_info(m)">
                            <div class="Unit_Name">
                                <p :key="m.code">{{ m.code }}</p>
                            </div>
                            <img :src="m.imgUrl" alt="" @click="handleImageClick(m)">
                        </label>
                    </div>
                </div>

                <div class="unit_info" v-show="selectUnitInfo.clicked">
                    <img :src="selectUnitInfo.imgUrl" alt="">
                    <div class="module_spec">
                        <h4 class="tit">{{ selectUnitInfo.name }}</h4>
                        <div class="spec_info">
                            <p>{{ selectUnitInfo.areaMm }} ㎡ ({{ selectUnitInfo.area }}py)</p>
                            <!-- <p>{{selectedUnitInfo.}}</p> -->
                            <!-- <p>방 : {{selectUnitInfo.bedRoomCnt}} ｜ 화장실 : {{selectUnitInfo.bathRoomCnt}}</p> -->
                            <p>가로 : {{ selectUnitInfo.horizontal }} mm&nbsp;&nbsp;|&nbsp;&nbsp;세로 :
                                {{ selectUnitInfo.vertical }} mm</p>
                        </div>
                    </div>
                </div>

                <div class="paging_Div">
                    <img src="../assets/images/icons/prev.png" class="prev" @click="UnitPreviousPage"
                        :disabled="UnitCurrentPage === 1">
                    <span v-if="filteredUnits.length > UnitPerPage">{{ UnitCurrentPage }} / {{ totalUnitPages }}</span>
                    <span v-else>1 / 1</span>
                    <img src="../assets/images/icons/prev.png" class="next" @click="UnitNextPage"
                        :disabled="UnitCurrentPage === totalUnitPages">
                </div>
            </div>
            <!-- 02.소형 유닛 Tab END -->

            <!-- 03. 내벽 생성 Tab START -->
            <div class="Wall_List_Div" v-show="tabValue === 'wall'">
                <div class="Wall_Wrapper">
                    <div class="wall_Thumbs">
                        <input type="radio" id="createWall" name="wall" v-model="createWall" value="createInner"
                            @click="clickWInfo">
                        <label for="createWall" class="label">
                            <img src="../assets/images/icons/edit.png">
                            <p>내벽 생성</p>
                        </label>

                        <input type="radio" id="createOpening" name="wall" v-model="createWall" value="createOpening"
                            @click="createOpening">
                        <label for="createOpening" class="label">
                            <img src="../assets/images/icons/wall.png">
                            <p>벽 개구부 생성</p>
                        </label>
                    </div>
                </div>

                <div class="wall_info" v-show="createWall === 'createInner'">
                    <div></div>
                    <ul class="wall_spec">
                        <li class="tit">내벽 생성</li>
                        <li>모듈 안의 두 지점을 클릭하여 벽을 생성하세요</li>
                        <!-- <li><img src="../assets/images/icons/guide.png" alt="GuideIcon" @click="clickCreateWallGuide"></li> -->
                    </ul>
                </div>

                <div class="OpeningWall_info" v-show="createWall === 'createOpening'">
                    <div></div>
                    <ul class="OpeningWall_spec">
                        <li class="tit">벽 개구부 생성</li>
                        <li>벽 안의 두 지점을 클릭하여 개구부를 생성하세요</li>
                        <li><img src="../assets/images/icons/guide.png" alt="GuideIcon" @click="clickCreateWallGuide">
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 03. 내벽 생성 Tab END -->

            <!-- 04. 문 생성 Tab START-->
            <div class="Door_List_Div" v-show="tabValue === 'door'">
                <div class="Door_Tab">
                    <input type="radio" name="DTab" id="selectD" value="All" v-model="selectedCategoryDoor"
                        checked /><label for="selectD">All</label>
                    <input type="radio" name="DTab" id="selectD1" value="Interior"
                        v-model="selectedCategoryDoor" /><label for="selectD1" @mouseover="showInterior"
                        @mouseleave="hideInterior" title="실내문">Interior</label>
                    <input type="radio" name="DTab" id="selectD2" value="Exterior"
                        v-model="selectedCategoryDoor" /><label for="selectD2" @mouseover="showExterior"
                        @mouseleave="hideExterior" title="실외문">Exterior</label>
                    <input type="radio" name="DTab" id="selectD3" value="Etc" title="기타"
                        v-model="selectedCategoryDoor" /><label for="selectD3" @mouseover="showDEtc"
                        @mouseleave="hideDEtc">Etc</label>
                </div>

                <div class="Door_Wrapper">
                    <div class="door_Thumbs" v-for="(m, index) in paginatedDoors" :key="index">
                        <input type="radio" :id="m.id" :checked="m.clicked" @click.stop="handleClick(m)"
                            name="DoorThumb">
                        <label :for="m.id" @click="C_Door_info(m)">
                            <img :src="m.imgUrl" alt="" @click="handleImageClick(m)">
                            <div class="Door_Name">
                                <p :key="m.code">{{ m.code }}</p>
                            </div>

                            <div class="door_size">
                                <p>{{ m.horizontal }} x {{ m.vertical }}</p>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="door_info" v-show="selectDoorInfo.clicked">
                    <img :src="selectDoorInfo.imgUrl" alt="">
                    <div class="module_spec">
                        <h4 class="tit">{{ selectDoorInfo.displayName }}</h4>
                        <div class="spec_info">
                            <p>{{ selectDoorInfo.horizontal }} x {{ selectDoorInfo.vertical }} mm</p>
                        </div>
                    </div>
                </div>

                <div class="module_info_Viewer">
                    <img src="" alt="">
                    <ul>
                        <li></li>
                    </ul>
                </div>

                <div class="paging_Div">
                    <img src="../assets/images/icons/prev.png" class="prev" @click="doorsGoToPreviousPage"
                        :disabled="doorsCurrentPage === 1">
                    <span v-if="filteredDoors.length > itemsPerPage">{{ doorsCurrentPage }} / {{ totalDoorPages
                        }}</span>
                    <span v-else>1 / 1</span>
                    <img src="../assets/images/icons/prev.png" class="next" @click="doorsGoToNextPage"
                        :disabled="doorsCurrentPage === totalDoorPages">
                </div>
            </div>
            <!-- 04. 문 생성 Tab END-->

            <!-- 05. 창문 생성 Tab START-->
            <div class="Window_List_Div" v-show="tabValue === 'window'">
                <div class="Window_Tab">
                    <input type="radio" name="WTab" id="selectW" value="All" v-model="selectedCategoryWindow"
                        checked /><label for="selectW">All</label>
                    <input type="radio" name="WTab" id="selectW1" value="LDK" v-model="selectedCategoryWindow" /><label
                        for="selectW1" @mouseover="showWinLdk" @mouseleave="hideWinLdk"
                        title="거실, 식사공간, 주방 창문">LDK</label>
                    <input type="radio" name="WTab" id="selectW2" value="Room" v-model="selectedCategoryWindow" /><label
                        for="selectW2" @mouseover="showWinRoom" @mouseleave="hideWinRoom" title="방 창문">Room</label>
                    <input type="radio" name="WTab" id="selectW3" value="Bath" v-model="selectedCategoryWindow" /><label
                        for="selectW3" @mouseover="showWinBath" @mouseleave="hideWinBath" title="욕실 창문">Bath</label>
                </div>

                <div class="Window_Wrapper">
                    <div class="Window_Thumbs" v-for="(m, index) in paginatedWindows" :key="index">
                        <input type="radio" :id="m.id" :checked="m.clicked" @click.stop="handleClick(m)"
                            name="WindowThumb">
                        <label :for="m.id" @click="C_Window_info(m)"
                            :style="{ backgroundColor: m.clicked ? '#e5e5e5' : '' }">
                            <img :src="m.imgUrl" alt="" @click="handleImageClick(m)">
                            <div class="Window_Name">
                                <p :key="m.code">{{ m.code }}</p>
                            </div>

                            <div class="window_size">
                                <p>{{ m.horizontal }} x {{ m.vertical }}</p>
                            </div>
                        </label>
                    </div>
                </div>

                <div class="window_info" v-show="selectWindowInfo.clicked">
                    <img :src="selectWindowInfo.imgUrl" alt="">
                    <div class="window_spec">
                        <h4 class="tit">{{ selectWindowInfo.displayName }}</h4>
                        <div class="spec_info">
                            <p>{{ selectWindowInfo.horizontal }} x {{ selectWindowInfo.vertical }} mm</p>
                        </div>
                    </div>
                </div>

                <div class="module_info_Viewer">
                    <img src="" alt="">
                    <ul>
                        <li></li>
                    </ul>
                </div>

                <div class="paging_Div">
                    <img src="../assets/images/icons/prev.png" class="prev" @click="windowsGoToPreviousPage"
                        :disabled="windowsCurrentPage === 1">
                    <span v-if="filteredWindows.length > itemsPerPage">{{ windowsCurrentPage }} / {{ totalWindowPages
                        }}</span>
                    <span v-else>1 / 1</span>
                    <img src="../assets/images/icons/prev.png" class="next" @click="windowsGoToNextPage"
                        :disabled="windowsCurrentPage === totalWindowPages">
                </div>
            </div>
            <!-- 05. 창문 생성 Tab END-->

            <!-- 06. 기둥 생성 Tab START -->
            <!-- <div class="Column_List_Div" v-show="showColumnList">
                <div class="Column_Wrapper">
                    <div class="column_Thumbs" v-show="showColumnList" @click="clickCInfo">
                        <input type="radio" id="createColumn" name="column" value="기둥 배치"  @click="clickCInfo">
                        <label for="createColumn" class="label">
                            <img src="../assets/images/icons/pColumn.png">
                            <p>기둥 배치</p>
                        </label>

                        <input type="radio" id="hideColumn" name="column" value="기둥 삭제">
                        <label for="hideColumn" class="label">
                            <img src="../assets/images/icons/dColumn.png">
                            <p>기둥 삭제</p>
                        </label>
                    </div>

                </div> 

                <div class="column_info" v-show="showCInfo">
                    <div></div>
                    <ul class="column_spec">
                        <li class="tit">기둥 생성하기</li>
                        <li>2층에 위치한 모듈을 선택하여 모듈 아래 필로티 기둥을 배치할 수 있습니다</li>
                    </ul>
                </div>

                <div class="module_info_Viewer">
                    <img src="" alt="">
                    <ul>
                        <li></li>
                    </ul>
                </div>
            </div> -->
            <!-- 06. 기둥 생성 Tab END -->

            <!-- 07. 지붕 생성 Tab START -->
            <div class="Roof_List_Div" v-show="tabValue === 'roof'">
                <div class="Roof_Wrapper">
                    <div class="roof_Thumbs">
                        <input type="checkbox" id="createFlatRoof" name="roof" value="roofTab" v-model="isShowFlatRoof"
                            :checked="isShowFlatRoof" @click="toggleFlatRoof">
                        <label for="createFlatRoof" class="label">
                            <img src="../assets/images/icons/pRoof.png">
                            <p>평지붕</p>
                        </label>
                        <input type="checkbox" name="roof2" value="경사지붕">
                        <label for="createRoof2" class="label">
                            <img src="../assets/images/icons/roof2.png">
                            <p>경사지붕</p>
                        </label>

                        <input type="checkbox">
                        <label for="createRoof2" class="opacity">
                            <img src="../assets//images/icons/stop.png">
                            <p>작업중</p>
                        </label>

                        <input type="checkbox" name="roof2" value="박공지붕">
                        <label for="createRoof3" class="label">
                            <img src="../assets/images/icons/roof3.png">
                            <p>박공지붕</p>
                        </label>

                        <input type="checkbox">
                        <label for="createRoof2" class="opacity2">
                            <img src="../assets//images/icons/stop.png">
                            <p>작업중</p>
                        </label>

                        <!-- <input type="radio" id="hideRoof" name="roof" value="지붕 숨기기">
                        <label for="hideRoof" class="label">
                            <img src="../assets/images/icons/hide.png">
                            <p>지붕 숨기기</p>
                        </label> -->
                    </div>
                </div>

                <!-- <div class="roof_info" v-show="showRInfo">
                    <div></div>

                    <ul class="roof_spec">
                        <li class="tit">지붕 생성</li>
                        <li>필로티 기둥을 넣을 2층에 위치한 모듈울 선택하세요</li>
                    </ul>
                </div> -->

                <div class="module_info_Viewer">
                    <img src="" alt="">
                    <ul>
                        <li></li>
                    </ul>
                </div>
            </div>
            <!-- 07. 지붕 생성 Tab END -->

            <!-- Module_Viewer_Div Start -->
            <div id="viewRootContainer">
                <div id="view3dContainer" class="viewContainer">
                    <BabylonViewer :command="command" v-model:if3dRoof="showRInfo" ref="babylonViewer" />
                </div>
            </div>
            <!-- Module_Viewer_Div End -->

            <!-- 벽 개구부 생성 가이드 (모달팝업) START -->
            <div class="CreateWallGuide_Div" v-show="showCreateWallGuide">
                <span @click="closeCreateWallGuide">X</span>
                <div class="CreateWallGuide_Container">
                    <div class="CreateWallGuide_Tit">
                        <h2>{{ imageTitles[curPos] }}</h2>
                        <p>{{ imageCaptions[curPos] }}</p>
                    </div>

                    <div class="CreateWallGuide_SliderWrapper">
                        <img src="../assets/images/icons/prev.png" alt="Slider_Prev" class="Slider_Prev"
                            @click="prevSlide">

                        <div class="CreateWallGuide_Slider">
                            <img class="image" :src="currentImageUrl" alt="Slider Image" />
                            <div v-if="imageUrls.length > 1" class="image-circle-wrapper">
                                <div class="image-circle" v-for="(imageUrl, index) in imageUrls" :key="imageUrl.index"
                                    :class="{ activeImg: index === curPos }" @click="changeSlide(index)">
                                </div>
                            </div>
                        </div>

                        <img src="../assets/images/icons/prev.png" alt="Slider_Next" class="Slider_Next"
                            @click="nextSlide">
                    </div>
                </div>
            </div>
            <!-- 벽 개구부 생성 가이드 (모달팝업) END -->
        </div>
        <!-- <LogInModal @childEvent="getToken" @childEvent2="LoginChild2" v-if="IsModalPopup" /> -->
    </div>
</template>

<style scoped>
@import url('../assets/css/ModuleMake.css');
</style>

<script>
import BabylonViewer from "../components/BabylonViewer.vue";
//CreateWallGuide 슬라이드 이미지 import
import OpeningwallGif from '@/assets/images/OpeningWall.gif';
import createWall2 from '@/assets/images/createWall2.png';
import createWall3 from '@/assets/images/createWall3.gif'
import createWall4 from '@/assets/images/createWall4.gif'
import LogInModal from '../components/Login/LogInModal.vue';
import Header from '../components/essential/headerTemplate.vue'

import {
    Vector3
} from "@babylonjs/core";
import Cookies from 'js-cookie';

// import moduleData from "../../public/moduleInfos.json"
import axios from 'axios';

export default {

    emits: ['toggle'],
    data() {
        return {
            curPos: 0,
            showCreateWallGuide: false,
            imageUrls: [
                OpeningwallGif,
                createWall2,
                createWall3,
                createWall4,
            ],

            //CreateWallGuide Titles (벽 개구부 생성)
            imageTitles: [
                '1. 벽 개구부 생성',
                '2. 벽 개구부 배치 완료',
                '3. 크기 자동 맞춤',
                '4. 벽 개구부 삭제'
            ],

            //CreateWallGuide Captions (벽 개구부 생성)
            imageCaptions: [
                '벽 안의 두 지점을 선택하여 벽 개구부를 생성할 수 있습니다.',
                '선택한 두 지점 사이가 뚫려 벽 안팎을 지나갈 수 있는 개구부가 만들어집니다.',
                '옆에 다른 개구부가 있으면 자동 맞춤 기능으로 똑같은 크기의 개구부를 만들 수 있습니다.',
                '배치된 벽 개구부를 선택하고 삭제 버튼 혹은 delete를 눌러 삭제할 수 있습니다.'
            ],
            isShowFlatRoof: false,
            intervalId: null,
            tabValue: 'module',
            createWall: '',
            SettingValue: false,
            //ViewTab:'',
            clickedModuleIndex: null,
            clickedUnitIndex: null,
            clickedWindowIndex: null,
            clickedDoorIndex: null,
            show2DOption: false,
            show3DOption: false,
            showSaveOption: false,
            selectedToolIndex: null,
            SecondD: require('../assets/images/icons/BHeader/2DView.png'),
            ThirdD: require('../assets/images/icons/BHeader/3DView.png'),
            typeName: "",
            panel1Width: '100%',
            panel1Height: '100%',
            command: {},
            showSD: true,
            showTD: true,
            //toggle 버튼 제어
            isToggled: false,
            isToggledDoor: false,
            isToggledWall: false,
            is2DActive: true,
            is3DActive: true,
            isToggleActive: true,
            toggleImg: './icons/toggleAct.png',
            currentPage: 1, //현재 페이지 넘버
            itemsPerPage: 6,
            doorsPerPage: 6,
            UnitCurrentPage: 1,
            UnitPerPage: 6,
            doorsCurrentPage: 1,
            windowsPerPage: 6,
            windowsCurrentPage: 1,
            showRInfo: false,
            selectedCategory: 'All',
            selectedCategoryUnit: 'All',
            selectedCategoryDoor: 'All',
            selectedCategoryWindow: 'All',
            if3dRoofValue: false,
            firstView: '2D',
            modules: [],
            windows: [],
            doors: [],
            units: [],
            selectModuleInfo: {},
            selectWindowInfo: {},
            selectDoorInfo: {},
            selectUnitInfo: {},
            activeTab: 'module',
            isLoaded: false,
            token: {},
            userName: '',
            userEmail: '',
            userIdx: '',
            isEmptySlot: true,
            fileList: [],
            IsModalPopup: false,
            showMyPage: false,
            IsSave:false
        };
    },
    async created() {
        //console.log("크리에이티드 들어옴");
        //console.log("idx는",this.userIdx);
        await this.getToken();
        await this.getFileList();
        await this.moduleInit();
        await this.windowInit();
        await this.doorInit();
        await this.setEvent();
        await this.unitInit();
        document.body.addEventListener('click', this.deactivateClickedImage);
        this.isLoaded = true;
        await this.typeInit();
    },
    watch: {
        is2DActive(newValue, oldValue) {
            this.command = {
                oder: "ViewChange",
                is2DActive: this.is2DActive,
                is3DActive: this.is3DActive
            }
        },
        is3DActive(newValue, oldValue) {
            this.command = {
                oder: "ViewChange",
                is2DActive: this.is2DActive,
                is3DActive: this.is3DActive
            }
        },
        tabValue() {
            const d1Select = this.$SceneLoader?.drawScene1f?.selectedEntity?.info.ctgrName;
            const d2Select = this.$SceneLoader?.drawScene2f?.selectedEntity?.info.ctgrName;
            if (d1Select || d2Select) {
                if (this.tabValue != (d1Select + d2Select).replace('undefined', '').toLowerCase()) {
                    this.$SceneLoader?.drawScene1f.removeAllSelectedMesh();
                    this.$SceneLoader?.drawScene2f.removeAllSelectedMesh();
                }
            }
        },
    },
    components: {
        BabylonViewer,
        LogInModal,
        Header
    },
    mounted() {
        this.typeName = this.$route.params.typeName;

        const savedLastClickedId = localStorage.getItem("lastClickedId");
        if (savedLastClickedId !== null) {
            const clickedModule = this.paginatedModules.find(
                (module) => module.id === savedLastClickedId
            );
            if (clickedModule) {
                clickedModule.clicked = true;
            }
        }

        this.$nextTick(async () => {
            this.$SceneLoader.EntityLoader.modules.forEach(o => {
                o.dispose()
            })

            if (this.typeName == 'edit' || this.typeName == undefined) {
                document.title = 'New Model | Home Self-Design System | XiGEIST'
            }
            else {
                document.title = `${this.typeName} | Home Self-Design System | XiGEIST`
            }

            // await this.moduleInit();
            // await this.windowInit();
            // await this.doorInit();
            // await this.setEvent();
            // await this.unitInit();
            // document.body.addEventListener('click', this.deactivateClickedImage);
            // this.isLoaded = true;
            // await this.typeInit();
        })
    },

    methods: {
        async getToken() {
            const cookie = Cookies.get('token');
            let data = {
                token: cookie == undefined ? '' : cookie
            }
            const res = await axios.post( "/server/token_check", JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

            }
            else if (res.data._data["length"] == 0) {
                alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
                Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
                this.$router.push('/member/login');
            }
            else if (res.status == 200) {
                this.userIdx = res.data._data[0]["idx"];
                await this.getFileList();
                await this.getUserInfo();
            }
        },
        async getFileList() {
            const res = await axios.post("/server/read_file",
                `{ "key":"READ", "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            if (res.status == 200) {
                this.fileList = [...res.data._data];
                //console.log(this.fileList)
            }
        },
        // logout() {
        //     alert("로그아웃 합니다");
        //     if (this.userCode == 1) {
        //         console.log("카카오로그아웃");
        //         this.kakaoLogout();
        //     }
        //     Cookies.remove('token');
        //     Cookies.remove('token', { path: '/member' });
        //     //window.location.href = "http://localhost:8080/member/login"
        //     //this.$router.push('/member/login');
        //     window.location.reload();
        // },
        updateCreateWallTitle() {
            this.imageTitles = this.imageTitles[this.curPos] || '';
        },
        updateUi() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.$SceneLoader.drawScene1f.setUI();
                    this.$SceneLoader.drawScene2f.setUI();
                    this.$SceneLoader.viewScene.setFloorText();
                }, 100);
            })
        },
        changeSlide(index) {
            this.curPos = index;
            //this.updateCreateWallTitle();
        },

        clickCreateWallGuide() {
            this.showCreateWallGuide = !this.showCreateWallGuide
        },

        closeCreateWallGuide() {
            this.showCreateWallGuide = false;
        },

        prevSlide() {
            if (this.curPos > 0) {
                this.curPos--;
            }
        },

        nextSlide() {
            if (this.curPos < this.imageUrls.length - 1) {
                this.curPos++;
            }
        },

        toggleFlatRoof() {
            this.$nextTick(() => {
                setTimeout(() => {
                    this.command = {
                        oder: 'isShowFlatRoof',
                        isShowFlatRoof: this.isShowFlatRoof
                    }
                }, 100)
            });

        },

        //모듈 카테고리 선택 업데이트
        changeCategory(category) {
            this.selectedCategory = category;
        },
        //소형유닛 카테고리 선택 업데이트
        changeCategoryUnit(category) {
            this.selectedCategoryUnit = category;
        },
        async fileUpdate(idx) {
            localStorage.setItem('loadIdx', idx);
            await this.$SceneLoader.save(this.userIdx, this.typeName)
            setTimeout(() => {
                this.getFileList();
                alert('저장')
            }, 200);
        },
        async fileSave() {
            localStorage.setItem('loadIdx', '');
            await this.$SceneLoader.save(this.userIdx, this.typeName)
            setTimeout(() => {
                this.getFileList();
                alert('저장')
            }, 200);
        },
        clickLoad() {
            const file = this.$refs.fileInput.files[0];

            const reader = new FileReader();

            reader.onload = async event => {
                if (!event?.target?.result)
                    return;
                const fileContent = event.target.result;
                this.isLoaded = false;
                await this.$SceneLoader.load(fileContent);
                const endInter = setInterval(() => {
                    if (this.$SceneLoader.loadedModules == -100) {
                        this.isLoaded = true;
                        clearInterval(endInter)
                    }
                }, 100);
                //this.isLoaded = true;
            };

            reader.readAsText(file);
        },
        toggleRoof() {
            this.showRInfo = !this.showRInfo;
            this.$refs.babylonViewer.toggleRoof(this.showRInfo);
        },

        // 배치 모드 관련 제어 스크립트 (배치 취소 or 완료시 정보창 hide...)
        async deactivateClickedImage(event) {
            await setTimeout(() => {
                const clickedElement = event.target;

                if (
                    !clickedElement.closest(".module_Thumbs label") &&
                    !clickedElement.closest(".unit_Thumbs label") &&
                    !clickedElement.closest(".door_Thumbs label") &&
                    !clickedElement.closest(".Window_Thumbs label")
                ) {
                    localStorage.setItem("lastClickedId", null);

                    const selectEntity = this.$SceneLoader.drawScene1f.selectedEntity ? this.$SceneLoader.drawScene1f.selectedEntity : this.$SceneLoader.drawScene2f.selectedEntity;
                    if (!this.$SceneLoader?.drawScene1f.createEntity) {
                        this.selectModuleInfo.clicked = false;
                        this.selectUnitInfo.clicked = false;
                        this.selectWindowInfo.clicked = false;
                        this.selectDoorInfo.clicked = false;
                        this.createWall = null;
                    }
                    if (selectEntity) {
                        const info = selectEntity.info;
                        const ctgrName = selectEntity.info?.ctgrName;
                        switch (ctgrName) {
                            case 'Module':
                                this.tabValue = 'module'
                                this.selectModuleInfo = {
                                    ...this.modules.find(x => x.name === info.name)
                                }
                                this.selectModuleInfo.clicked = true;
                                const size = selectEntity.getSize();

                                this.selectModuleInfo.horizontal = (Math.round(size.x * 1000) - 140).toLocaleString();
                                this.selectModuleInfo.vertical = (Math.round(size.z * 1000) - 140).toLocaleString();
                                this.selectModuleInfo.areaMm = ((size.x - 0.14) * (size.z - 0.14)).toFixed(2);
                                this.selectModuleInfo.area = (this.selectModuleInfo.areaMm * 0.3025).toFixed(2);
                                break;
                            case 'Unit':
                                this.tabValue = 'unit'
                                this.selectUnitInfo = {
                                    ...this.units.find(x => x.name === info.name)
                                }
                                this.selectUnitInfo.clicked = true;

                                const uSize = selectEntity.getSize();
                                this.selectUnitInfo.horizontal = (Math.round(uSize.x * 1000) - 90).toLocaleString();
                                this.selectUnitInfo.vertical = (Math.round(uSize.z * 1000) - 90).toLocaleString();
                                this.selectUnitInfo.areaMm = ((uSize.x - 0.09) * (uSize.z - 0.09)).toFixed(2);
                                this.selectUnitInfo.area = (this.selectUnitInfo.areaMm * 0.3025).toFixed(2);
                                break;
                            case 'Door':
                                this.tabValue = 'door'
                                this.selectDoorInfo = {
                                    ...this.doors.find(x => x.name === (info.ctgrName + info.typeName.replace(/[0-9]/g, "")))
                                }
                                this.selectDoorInfo.clicked = true;
                                break;
                            case 'Window':
                                this.tabValue = 'window'
                                const typeExp = /(([0-9]{3,5}mmx([0-9]{3,5}mm)))/g;
                                const typeRes = typeExp.exec(info.typeName);
                                this.selectWindowInfo = {
                                    ...this.windows.find(x => x.name === (info.ctgrName + typeRes[0]))
                                }
                                this.selectWindowInfo.clicked = true;
                                break;
                        }
                    }
                }
            }, 100);
        },

        handleClick(m) {
            if (!m.clicked) {
                this.handleModuleClick(m);
                this.lastClickedRadioId = m.id; // 클릭한 라디오 버튼 ID 업데이트
            }

            if (!m.clicked) {
                this.handleUnitClick(m);
                this.lastClickedRadioId = m.id; // 클릭한 라디오 버튼 ID 업데이트
            }

            if (!m.clicked) {
                this.handleDoorClick(m);
                this.lastClickedRadioId = m.id; // 클릭한 라디오 버튼 ID 업데이트
            }

            if (!m.clicked) {
                this.handleWindowClick(m);
                this.lastClickedRadioId = m.id; // 클릭한 라디오 버튼 ID 업데이트
            }

        },
        handleImageClick(m) {
            if (!m.clicked) {
                this.handleModuleClick(m);
            }
            if (!m.clicked) {
                this.handleUnitClick(m);
            }
            if (!m.clicked) {
                this.handleWindowClick(m);
            }
            if (!m.clicked) {
                this.handleDoorClick(m);
            }
        },
        handleModuleClick(clickedModule, index) {
            // Reset clicked state of all modules
            this.resetClickStates();

            clickedModule.clicked = true;

            // Update the clicked module's index
            this.clickedModuleIndex = index;
            localStorage.setItem("lastClickedId", clickedModule.id);
            // ... handle other items ...
        },

        handleUnitClick(clickedUnit, index) {
            this.resetClickStates();

            clickedUnit.clicked = true;

            this.clickedUnitIndex = index;
            localStorage.setItem("lastClickedId", clickedUnit.id);
        },

        handleWindowClick(clickedWindows, index) {
            // Reset clicked state of all modules
            this.resetClickStates();

            // Set clicked state of the clicked module
            clickedWindows.clicked = true;

            // Update the clicked module's index
            this.clickedWindowIndex = index;
            localStorage.setItem("lastClickedId", clickedWindows.id);
        },

        handleDoorClick(clickedDoor, index) {
            // Reset clicked state of all modules
            this.resetClickStates();

            // Set clicked state of the clicked module
            clickedDoor.clicked = true;

            // Update the clicked module's index
            this.clickedDoorIndex = index;
            localStorage.setItem("lastClickedId", clickedDoor.id);
        },

        resetClickStates() {
            this.filteredModules.forEach(module => {
                module.clicked = false;
            });

            this.filteredUnits.forEach(unit => {
                unit.clicked = false;
            });

            this.filteredWindows.forEach(window => {
                window.clicked = false;
            });

            this.filteredDoors.forEach(door => {
                door.clicked = false;
            });
        },

        setEvent() {
            window.onkeydown = (e) => {
                if(this.userIdx==''){
                    return;
                }
                if (e.key === 'Escape') {
                    this.$SceneLoader.CancelModule();
                    if (!this.$SceneLoader?.drawScene1f.createEntity) {
                        this.selectModuleInfo.clicked = false;
                        this.selectUnitInfo.clicked = false;
                        this.selectWindowInfo.clicked = false;
                        this.selectDoorInfo.clicked = false;
                        this.createWall = null;
                        this.$SceneLoader?.drawScene1f.removeAllSelectedMesh();
                        this.$SceneLoader?.drawScene2f.removeAllSelectedMesh();
                    }
                } else if (e.key === 'Delete') {
                    this.$SceneLoader.SelectedDelete();
                } else if (e.key.includes('Arrow')) {
                    const s1 = this.$SceneLoader?.drawScene1f.selectedEntity;
                    const s2 = this.$SceneLoader?.drawScene2f.selectedEntity;

                    const s = s1 ? s1 : s2;

                    if (s) {
                        const dir = e.key.replace('Arrow', '');
                        let vec = Vector3.Zero();
                        switch (dir) {
                            case 'Right':
                                vec.x += 0.1;
                                break;
                            case 'Left':
                                vec.x -= 0.1;
                                break;
                            case 'Up':
                                vec.z += 0.1;
                                break;
                            case 'Down':
                                vec.z -= 0.1;
                                break;
                        }

                        if (s.info.ctgrName.includes('Module') || s.info.ctgrName.includes('Unit')) {
                            if (s.info.ctgrName.includes('Unit')) {
                                const ms = s.moduleEntity.viewRootMesh.scaling.clone();
                                vec = vec.multiply(ms);
                            }
                            s.draw1fRootMesh.position = s.draw1fRootMesh.position.add(vec);
                            s.draw2fRootMesh.position = s.draw2fRootMesh.position.add(vec);
                            s.viewRootMesh.position = s.viewRootMesh.position.add(vec);
                        }
                    }
                }
            };
            window.onkeyup = (e) => {
                if(this.userIdx==''){
                    return;
                }
                if (e.key.includes('Arrow')) {
                    setTimeout(async () => { //이동후 업데이트
                        this.$SceneLoader?.drawScene1f.updateWall();
                    }, 100);
                }
            }
        },

        //모듈 썸네일 중복 클릭 이벤트 방지..
        handleClick(selectedModule) {
            this.modules.forEach(module => {
                module.clicked = module === selectedModule;
            });
        },

        handleClick(selectedUnit) {
            this.units.forEach(units => {
                units.clicked = unit === selectedUnit;
            });
        },

        handleClick(selectedWindow) {
            this.windows.forEach(window => {
                window.clicked = window === selectedWindow;
            });
        },

        handleClick(selectedDoors) {
            this.doors.forEach(door => {
                door.clicked = door === selectedDoor;
            });
        },

        C_Module_info(selectedInfo) {
            this.selectModuleInfo = selectedInfo;
            this.createModule();
        },
        C_Unit_info(selectedInfo) {
            this.selectUnitInfo = selectedInfo;
            this.createUnit();
        },
        C_Window_info(selectedInfo) {
            this.selectWindowInfo = selectedInfo;
            this.createWindow();
        },
        C_Door_info(selectedInfo) {
            this.selectDoorInfo = selectedInfo;
            this.createDoor();
        },
        toggleClick() {

            let pos2d = 0;
            let pos3d = 0;
            if (this.firstView != '2D') {
                pos2d = 1;
                pos3d = 2;
                this.firstView = '2D'
            } else {
                pos2d = 2;
                pos3d = 1;
                this.firstView = '3D'
            }
            this.command = {
                oder: "ViewPosChange",
                pos2d: pos2d,
                pos3d: pos3d
            }
        },
        createModule() {
            const moduleName = this.selectModuleInfo.name.replace(" ", "");
            this.command = {
                oder: "CreateModule",
                moduleName: moduleName,
                Path: this.$MODULE_PATH + `${moduleName}/obj/`,
                horizontal: this.selectWindowInfo.hval,
                vertical: this.selectWindowInfo.vval
            }
        },
        createWindow() {
            const name = this.selectWindowInfo.name.replace(" ", "");
            this.command = {
                oder: "CreateWindow",
                name: name,
                data: this.selectWindowInfo
            }
        },
        createDoor() {
            const name = this.selectDoorInfo.name.replace(" ", "");
            this.command = {
                oder: "CreateDoor",
                name: name,
                data: this.selectDoorInfo
            }
        },
        createUnit() {
            const name = this.selectUnitInfo.name.replace(" ", "");
            this.command = {
                oder: "CreateUnit",
                name: name,
                Path: this.$UNIT_PATH + `${name}/obj/`
            }
        },
        createOpening() {
            this.command = {
                oder: "CreateOpening",
                name: 'opening'
            };
        },
        selectTool(index) {
            if (this.selectedToolIndex === index) {
                this.selectedToolIndex = null;
            } else {
                // Select the clicked Tool_Content
                this.selectedToolIndex = index;
            }
        },
        toggleSwitch() {
            this.$SceneLoader.isSnappedWindow = this.isToggled = !this.isToggled
        },
        toggleSwitchDoor() {
            this.$SceneLoader.isSnappedDoor = this.isToggledDoor = !this.isToggledDoor
        },
        toggleSwitchWall() {
            this.$SceneLoader.isSnappedWall = this.isToggledWall = !this.isToggledWall
        },
        async undoClickEvent() {
            this.isLoaded = false;
            await this.$SceneLoader.undo();
            this.isLoaded = true;
        },
        async redoClickEvent() {
            this.isLoaded = false;
            await this.$SceneLoader.redo();
            this.isLoaded = true;
        },
        async moduleInit() {
            const loadModuls = await axios.get("/moduleInfos.json");
            this.modules = loadModuls.data;
            this.modules.forEach(m => {
                m.hval = Number(m.horizontal);
                m.vval = Number(m.vertical);
                m.horizontal = (Number(m.horizontal) - 140).toLocaleString();
                m.vertical = (Number(m.vertical) - 140).toLocaleString();
                m.areaMm = (((Number(m.hval) - 140) * (Number(m.vval) - 140)) / 1000000).toFixed(2);
                m.area = (m.areaMm * 0.3025).toFixed(2)
            });
        },
        async windowInit() {
            const loadModuls = await axios.get("/windowInfos.json");
            this.windows = loadModuls.data;

            this.windows.forEach(m => {
                m.height = Number(m.height) + Number(m.vertical) / 2 + 393;
                m.horizontal = Number(m.horizontal).toLocaleString();
                m.vertical = Number(m.vertical).toLocaleString();
            });
            this.$refs.babylonViewer.setWindowData(this.windows);
        },
        async doorInit() {
            const loadModuls = await axios.get("/doorInfos.json");
            this.doors = loadModuls.data;
            this.doors.forEach(m => {
                m.height = Number(m.vertical) / 2 + 393;
                m.vh = Number(m.thickness);
                m.horizontal = Number(m.horizontal).toLocaleString();
                m.vertical = Number(m.vertical).toLocaleString();
            });
            this.$refs.babylonViewer.setDoorData(this.doors);
        },
        async unitInit() {
            const loadModuls = await axios.get("/unitInfos.json");
            this.units = loadModuls.data;
            this.units.forEach(m => {
                m.hval = Number(m.horizontal);
                m.vval = Number(m.vertical);
                m.horizontal = Number(m.horizontal).toLocaleString();
                m.vertical = Number(m.vertical).toLocaleString();
                m.areaMm = (((Number(m.hval) - 140) * (Number(m.vval) - 140)) / 1000000).toFixed(2);
                m.area = (m.areaMm * 0.3025).toFixed(2)
            });
        },
        async typeInit() {
            this.typeName = this.$route.params.typeName;
            try {
                const loadTypes = await axios.get(`/Types/${this.typeName}.xgm`);
                let json = JSON.stringify(loadTypes.data)
                if (json.includes('doctype html')) {
                    throw ('not found file')
                }
                this.isLoaded = false;
                await this.$SceneLoader.load(json)
                const endInter = setInterval(() => {
                    if (this.$SceneLoader.loadedModules == -100) {
                        this.isLoaded = true;
                        clearInterval(endInter)
                    }
                }, 100);
            }
            catch (err) {
                const loadTypes = await axios.get("/typeInfos.json");
                const types = loadTypes.data;
                if (this.typeName) {
                    const targetTpye = types.find(x => x.name === this.typeName);
                    if (targetTpye) {
                        this.$SceneLoader.LoadType(targetTpye, this.$MODULE_PATH);
                        this.isLoaded = false;
                        const endInter = setInterval(() => {
                            if (this.$SceneLoader.loadedModules == -100) {
                                this.isLoaded = true;
                                clearInterval(endInter)
                            }
                        }, 100);
                    }
                    else {
                        console.log('test')
                        const localData = localStorage.getItem('load');
                        if (localData == "" || localData == null) return;
                        this.isLoaded = false;
                        await this.$SceneLoader.load(localData);
                        const endInter = setInterval(() => {
                            if (this.$SceneLoader.loadedModules == -100) {
                                this.isLoaded = true;
                                clearInterval(endInter)
                            }
                        }, 100);
                    }
                }
            }
        },

        async created() {
            const data = await axios.get("/api/hello");
            console.log(data);
        },

        NextPage() {
            const totalPages = Math.ceil(this.filteredModules.length / this.itemsPerPage);
            if (this.currentPage < totalPages) {
                this.currentPage++;
            }
        },
        PreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }

        },

        UnitPreviousPage() {
            if (this.UnitCurrentPage > 1) {
                this.UnitCurrentPage--;
            }
        },

        UnitNextPage() {
            const UnitTotalPages = Math.ceil(this.filteredUnits.length / this.UnitPerPage);
            if (this.UnitCurrentPage < UnitTotalPages) {
                this.UnitCurrentPage++;
            }
        },

        doorsGoToPreviousPage() {
            if (this.windowsCurrentPage > 1) {
                this.windowsCurrentPage--;
            }
        },

        doorsGoToNextPage() {
            const doorsTotalPages = Math.ceil(this.filteredDoors.length / this.doorsPerPage);
            if (this.doorsCurrentPage < doorsTotalPages) {
                this.doorsCurrentPage++;
            }
        },

        windowsGoToPreviousPage() {
            if (this.windowsCurrentPage > 1) {
                this.windowsCurrentPage--;
            }
        },

        windowsGoToNextPage() {
            const windowsTotalPages = Math.ceil(this.filteredWindows.length / this.windowsPerPage);
            if (this.windowsCurrentPage < windowsTotalPages) {
                this.windowsCurrentPage++;
            }
        },

        clickWInfo() {
            this.command = {
                oder: "CreateWall",
                name: "Inner"
            }
        },

        goToPage(page) {
            this.hideModuleInfo();
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        PreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        NextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        async moduleAdd() {
            const response = await fetch('/moduleInfos.json');
            this.modules = await response.json();
        },
        PreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        NextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        clickSaveOption() {
            this.showSaveOption = !this.showSaveOption;
        },
        closeSaveOption() {
            this.showSaveOption = false;
        },
        kakaoLogout() {
            window.Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    console.log(response);
                    document.cookie = "token=" + "";
                    //alert("로그아웃 되었어용");
                },
                fail: function (error) {
                    console.log(error);
                },
            });
        },
        // clickMyPage() {
        //     if (this.userName != '') {
        //         this.showMyPage = !this.showMyPage;
        //         console.log(this.showMyPage);
        //     } else {
        //         this.IsModalPopup = true;
        //     }
        // },
        clickSaveButton() {
            if (this.userIdx != "") {
                //로그인이 돼있으면
                console.log(this.userIdx, this.IsModalPopup);
                this.IsModalPopup = false;
                this.IsSave = !this.IsSave;
                console.log(this.IsSave);
            } else {
                //로그인 안돼있으면
                this.IsModalPopup = true;
                this.IsSave = false;
            }
        },
    },
    computed: {
        //내벽 생성 & 벽 개구부 그리기 가이드 (모달팝업) 관련..
        createWallTitle() {
            // Your existing createWallTitle logic here
        },
        currentImageCaption() {
            return this.imageCaptions[this.curPos] || ''; // Get the current image caption based on curPos
        },
        currentImageUrl() {
            return this.imageUrls[this.curPos];
        },

        filteredModules() {
            // console.log('filteredModules called');
            this.currentPage = 1;
            // 클릭 상태 초기화
            this.modules.forEach(module => {
                module.clicked = false;
            });

            if (this.selectedCategory === 'All') {
                return this.modules.filter(module => module.isVisible); //isVisible이 true인 모듈만 표시
            } else {
                const lowercaseCategory = this.selectedCategory.toLowerCase();
                return this.modules.filter(m => m.code.toLowerCase() === lowercaseCategory && m.isVisible);
            }
        },
        filteredUnits() {
            this.UnitCurrentPage = 1;
            //클릭 상태 초기회
            this.units.forEach(unit => {
                unit.clicked = false;
            });

            if (this.selectedCategoryUnit === 'All') {
                //return this.units
                return this.units.filter(unit => unit.isVisible); // isVisible이 true인 소형유닛만 표시 - 시연회 후 복구 예정
            } else {
                const lowercaseCategory = this.selectedCategoryUnit.toLowerCase();
                return this.units.filter(m => m.code.toLowerCase() === lowercaseCategory && m.isVisible);
            }
        },

        filteredDoors() {
            this.doorsCurrentPage = 1;
            //클릭 상태 초기회
            this.doors.forEach(door => {
                door.clicked = false;
            });

            if (this.selectedCategoryDoor === 'All') {
                return this.doors; // Show all modules
            } else {
                const lowercaseCategory = this.selectedCategoryDoor.toLowerCase();
                return this.doors.filter(door => door.code.toLowerCase() === lowercaseCategory);
            }
        },

        filteredWindows() {
            this.currentPage = 1;
            //클릭 상태 초기회
            this.windows.forEach(window => {
                window.clicked = false;
            });

            if (this.selectedCategoryWindow === 'All') {
                return this.windows; // Show all modules
            } else {
                const lowercaseCategory = this.selectedCategoryWindow.toLowerCase();
                return this.windows.filter(window => window.code.toLowerCase() === lowercaseCategory);
            }
        },

        totalPages() {
            const filteredModulesCount = this.filteredModules.length;
            if (filteredModulesCount === 0) {
                return 1; // Display 1 page when there are no filtered modules
            } else {
                return Math.ceil(filteredModulesCount / this.itemsPerPage);
            }
        },

        totalUnitPages() {
            const filteredUnitsCount = this.filteredUnits.length;
            if (filteredUnitsCount === 0) {
                return 1; // Display 1 page when there are no filtered modules
            } else {
                return Math.ceil(filteredUnitsCount / this.UnitPerPage);
            }
        },

        totalDoorPages() {
            const filteredDoorsCount = this.filteredDoors.length;
            if (filteredDoorsCount === 0) {
                return 1; // Display 1 page when there are no filtered modules
            } else {
                return Math.ceil(filteredDoorsCount / this.doorsPerPage);
            }
        },

        totalWindowPages() {
            const filteredWindowsCount = this.filteredWindows.length;
            if (filteredWindowsCount === 0) {
                return 1; // Display 1 page when there are no filtered modules
            } else {
                return Math.ceil(filteredWindowsCount / this.windowsPerPage);
            }
        },

        paginatedModules() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            return this.filteredModules.slice(startIndex, endIndex).map((module, index) => {
                if (index === this.clickedModuleIndex) {
                    module.clicked = true; // Keep the most recently clicked button active
                }
                return module;
            });
        },

        paginatedUnits() {
            const startIndex = (this.UnitCurrentPage - 1) * this.UnitPerPage;
            const endIndex = startIndex + this.UnitPerPage;

            return this.filteredUnits.slice(startIndex, endIndex).map((unit, index) => {
                if (index === this.clickedUnitIndex) {
                    unit.clicked = true; // Keep the most recently clicked button active
                }
                return unit;
            });
        },

        paginatedWindows() {
            const startIndex = (this.windowsCurrentPage - 1) * this.windowsPerPage;
            const endIndex = startIndex + this.windowsPerPage;

            return this.filteredWindows.slice(startIndex, endIndex).map((window, index) => {
                if (index === this.clickedWindowIndex) {
                    window.clicked = true; // Keep the most recently clicked button active
                }
                return window;
            });
        },

        paginatedDoors() {
            const startIndex = (this.doorsCurrentPage - 1) * this.doorsPerPage;
            const endIndex = startIndex + this.doorsPerPage;

            return this.filteredDoors.slice(startIndex, endIndex).map((doors, index) => {
                if (index === this.clickedDoorIndex) {
                    doors.clicked = true; // Keep the most recently clicked button active
                }
                return doors;
            });
        }
    }
};
</script>
