import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import VectorHelper from "../Helper/VectorHelper"
import MeshHelper from "../Helper/MeshHelper"
import { AdvancedDynamicTexture,TextBlock,Image } from '@babylonjs/gui/2D';
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import SceneBase from "./SceneBase"
import Enumerable from 'linq'
import CsgHelper from "../Helper/CsgHelper"
import * as Material from '@babylonjs/materials'
import * as clipperLib from "js-angusj-clipper/web";
import * as earcut from "earcut";
import SaveHelper from "../Helper/SaveHelper";
import * as HistoryCommand from "../Entities/historyCommands"

export default class Scene2d extends SceneBase{
    constructor(canvas,viewer,floor)
    {
        super(canvas,viewer);
        this.zoomRate = 10;
        this.is2d = true;
        this.floor = floor;
        this.createEntity = null;
        this.mousePosition = Vector3.Zero();
        this.snapLines = [];
        this.prevSnapData = null;
        this.floorText = null;
        this.scaileBar = null;
        this.scaileTests = [];
        this.engine.setHardwareScalingLevel(1);
        this.engine.enableOfflineSupport = false;
        this.doorDir = 1;
        this.isAddWallMode = false;
        this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI");
        this.movingInter = false;
        this.Set2dMode();
    }

    getLocatedModule()
    {
        return this.viewer.$SceneLoader.EntityLoader.modules.filter(x=>x.locatedScene === this);
    }

    Set2dMode()
    {
        const camera = this.instance.getCameraByName("Camera")
        camera.position = new Vector3(0,10,0);
        camera.mode = BABYLON.Camera.ORTHOGRAPHIC_CAMERA;
        camera.lowerAlphaLimit = Math.PI * 3 / 2;
        camera.upperAlphaLimit = Math.PI * 3 / 2;
        camera.lowerBetaLimit = 0;
        camera.upperBetaLimit = 0;
        this.cameraRender(camera);
        camera.update();

        this.setEvents();
        this.setUI();
    }

    setUI()
    {
        this.setFloorText();
        this.setScaileBar();
    }

    setFloorText()
    {
        if(!this.floorText)
        {
            this.floorText = new TextBlock();
        }
        let areaMm = 0;
        let area = 0;

        this.getLocatedModule().forEach(m=>{
            const mArea = m.getArea(false);
            areaMm += Number(mArea.areaMm);
            area += Number(mArea.area);
        })

        this.floorText.text = `${this.floor[0]}층`;
        if(this.viewer.$SceneLoader.isShowArea)
        {
            this.floorText.text += ` 바닥면적 : ${areaMm.toFixed(2)}㎡ (${area.toFixed(1)}평)`;
        }
        this.floorText.color = "rgba(0, 0, 0, 0.75)";
        this.floorText.fontSize = 18;
        this.floorText.top = `-${this.canvasElement.clientHeight/2 - 22}rem`;
        this.floorText.left = `-${this.canvasElement.clientWidth/2 - this.floorText.text.length * 6}rem`;
        if(!this.viewer.$SceneLoader.isShowArea)
        {
            this.floorText.left = `-${this.canvasElement.clientWidth/2 - this.floorText.text.length * 16}rem`;
        }
        this.advancedTexture.addControl(this.floorText);
    }

    setScaileBar()
    {
        if(!this.scaileBar)
        {
            const svg = `<svg width="300" height="8" viewBox="0 0 300 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 8H200V4H100V8Z" fill="#2D2D2D"/>
            <path d="M-2.98023e-06 4H100V-5.96046e-08H-2.98023e-06V4Z" fill="#2D2D2D"/>
            <path d="M200 4H300V-5.96046e-08H200V4Z" fill="#2D2D2D"/>
            </svg>            
            `
            this.scaileBar = new Image('image', 'data:image/svg+xml;base64,' + btoa(svg));
            this.advancedTexture.addControl(this.scaileBar);
            
            const fontSize = `12px`;

            for(let i=0; i<4; i++)
            {
                const t = new TextBlock();
                t.text = i;
                t.fontSize = fontSize;
                t.color = "rgba(0, 0, 0, 0.80)";
                if(i === 3)
                {
                    t.text += 'm'
                }
                this.scaileTests.push(t)
            }

            this.scaileTests.forEach(t=>{
                this.advancedTexture.addControl(t);
            })
        }

        const cWidth = this.canvasElement.clientWidth;
        const cHeight = this.canvasElement.clientHeight;
        const wRate = cWidth/cHeight;
        const wDump = (this.zoomRate * wRate - this.zoomRate)/2;

        const regex = /[^0-9.]/g;
        const tempWidth = Math.abs(this.zoomRate + wDump)*2;
        
        this.scaileBar.width = `${(3/tempWidth)}`;
        this.scaileBar.height = '8px';

        const width = Number(this.scaileBar.width.replace(regex,""));
        this.scaileBar.top = `${this.canvasElement.clientHeight/2 - 30}rem`;
        this.scaileBar.left = `${-48 + width/2}%`;
        this.scaileBar.isVisible = this.viewer.$SceneLoader.isShowScailBar;

        for(let i = 0; i<4; i++)
        {
            const t = this.scaileTests[i];
            t.top = `${this.canvasElement.clientHeight/2 - 46}rem`;
            t.left = `${-48 + (width/3)*i -0.2}%`;
            t.isVisible = this.viewer.$SceneLoader.isShowScailBar;
            if(t.text.length > 1)
            {
                t.left = `${-48 + (width/3)*i + 0.6}%`;
            }
        }
    }

    cameraRender(camera)
    {
        this.engine.resize();

        const width = this.canvasElement.clientWidth;
        const height = this.canvasElement.clientHeight;
        const wRate = width/height;
        const hRate = height/width;
        const wDump = (this.zoomRate * wRate - this.zoomRate)/2;
        const hDump = (this.zoomRate * hRate - this.zoomRate)/2;
        camera.orthoTop = this.zoomRate + hDump;    // 카메라 상단 경계
        camera.orthoBottom = -1*this.zoomRate - hDump;    // 카메라 하단 경계
        camera.orthoLeft = -1*this.zoomRate - wDump;    // 카메라 왼쪽 경계
        camera.orthoRight = this.zoomRate + wDump;    // 카메라 오른쪽 경계
        this.setUI();
    }

    setEvents()
    {
        this.instance.onPointerObservable.add(evt =>
        {
            let event = evt.event;
            let delta = event.wheelDelta || -event.detail;
            this.handleMouseWheel(delta);
        }, BABYLON.PointerEventTypes.POINTERWHEEL);
        this.instance.onPointerDown = (evt, pickResult) =>{ this.onPointerDown(evt, pickResult); };
        this.instance.onPointerUp = (evt, pickResult) =>{ this.onPointerUp(evt, pickResult); };
        this.instance.onPointerMove = (evt) =>{ this.onPointerMove(evt); };
        this.canvasElement.addEventListener('mouseleave', event => {
            if(this.createEntity)
            {
                this.createEntity.setEnable(this.instance,false);
                this.removeSnapLines();
            }
        });

        // this.instance.onBeforeRenderObservable.add(() => {
        //     this.updateViewWall(this.instance);
        // });
    }

    onPointerDown(evt, pickResult)
    {
        if(evt.button == 0) // 마우스 왼쪽 클릭
        {   
            
            this.pointerDownPosition = new Vector3(this.mousePosition.x, 0, this.mousePosition.z);
            if(this.floor === '2F')
            {
                this.pointerDownPosition.y = 3.1;
            }
            if(this.selectedEntity)
            {
                var target = pickResult.pickedMesh.entity;
                
                if(target)
                {
                    if(target.info.ctgrName == "Floor")
                        target = target.moduleEntity;

                    if(this.selectedEntity == target)
                    {
                        var movingMesh = this.selectedEntity.getSceneRootMesh(this);
                        const moduleEnt = this.selectedEntity.isModule ? this.selectedEntity : this.selectedEntity.moduleEntity;
                        this.viewer.$SceneLoader.makeHistory(new HistoryCommand.MoveCommand(moduleEnt,SaveHelper.makeSaveData(moduleEnt)));
                        
                        if(movingMesh != null)
                        {
                            this.selectedEntity.disposeContextPanel();
                            this.movingMesh = movingMesh;
                            this.pointerDownModulePosition = this.selectedEntity.viewRootMesh.absolutePosition.clone();
                        }
                        else
                        {
                            var pickMesh = pickResult.pickedMesh;
                            if(pickMesh.info?.ctgrName)
                            {
                                if(pickMesh.info.ctgrName.includes('Wall') && pickMesh.info.name.includes('Exterior'))
                                    return;

                                this.selectedEntity.disposeContextPanel();
                                this.movingMesh = pickMesh;
                                this.pointerDownModulePosition = pickMesh.absolutePosition.clone();
                            }
                        }
                    }
                }
            }
            else
            {
                var target = pickResult.pickedMesh;
                //console.log(target) test용
            }
        }
    }

    onPointerUp(evt, pickResult)
    {
        if(evt.button == 0) // 마우스 왼쪽 클릭
        {
            this.pointerDownPosition = null;
            this.prevSnapData = null;

            if(this.createEntity)
            {
                const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
                if(this.createEntity.isModule)
                {
                    this.moduleMouseUpEvent();
                }
                else{
                    if(this.createEntity?.info.ctgrName === 'Window')
                    {
                        if(!this.windowMouseUpEvnet())
                        {
                            return;
                        }
                    }
                    else if(this.createEntity?.info.ctgrName === 'Door')
                    {
                        if(!this.doorMouseUpEvent())
                        {
                            return;
                        }
                    }
                    else if(this.createEntity?.info.ctgrName === 'Unit')
                    {
                        const rootMesh = this.floor === '1F' ? this.createEntity.draw1fRootMesh : this.createEntity.draw2fRootMesh;
                        const box = this.createEntity.drawMeshes1f.concat(this.createEntity.drawMeshes2f).filter(x=>x._scene === this.instance).find(x=>x.info.ctgrName === 'Box' && x.info.parentName.replace('-','').includes(this.createEntity.info.name));
                        if(box.material.name === 'greenBoxMaterial')
                        {
                            this.createEntity.setEnable(this.instance,false)
                            const pickedMesh =this.instance.pick(this.instance.pointerX, this.instance.pointerY).pickedMesh;
                            this.createEntity.setEnable(this.instance,true)
                            const moduleEntity = pickedMesh.entity?.moduleEntity;
                            if(moduleEntity)
                            {
                                box.isVisible = false;
                                let pos = rootMesh.position.clone();
                                pos.y = 3.1;
                                let otherPos = pos.clone();
                                otherPos.y = 0;

                                this.createEntity.setPosition(this.instance,pos);

                                const viewWorldMatrix = this.createEntity.viewRootMesh.getWorldMatrix().clone();
                                const d1fWorldMatrix = this.createEntity.draw1fRootMesh.getWorldMatrix().clone();
                                const d2fWorldMatrix = this.createEntity.draw2fRootMesh.getWorldMatrix().clone();
                                ///
                                this.createEntity.viewRootMesh.parent = moduleEntity.viewRootMesh;
                                this.createEntity.draw1fRootMesh.parent = moduleEntity.draw1fRootMesh;
                                this.createEntity.draw2fRootMesh.parent = moduleEntity.draw2fRootMesh;
                                ////
                                this.createEntity.viewRootMesh.computeWorldMatrix(true);
                                this.createEntity.viewRootMesh.setAbsolutePosition(viewWorldMatrix.getTranslation());
                                this.createEntity.viewRootMesh.scaling = moduleEntity.viewRootMesh.scaling.clone();
                                this.createEntity.viewRootMesh.rotation.y = moduleEntity.viewRootMesh.rotation.y;
                                this.createEntity.viewRootMesh.setEnabled(true);

                                this.createEntity.draw1fRootMesh.computeWorldMatrix(true);
                                this.createEntity.draw1fRootMesh.setAbsolutePosition(d1fWorldMatrix.getTranslation());
                                this.createEntity.draw1fRootMesh.scaling = moduleEntity.viewRootMesh.scaling.clone();
                                this.createEntity.draw1fRootMesh.rotation.y = moduleEntity.viewRootMesh.rotation.y;
                                this.createEntity.draw1fRootMesh.position.y = 0.1;

                                this.createEntity.draw2fRootMesh.computeWorldMatrix(true);
                                this.createEntity.draw2fRootMesh.setAbsolutePosition(d2fWorldMatrix.getTranslation());
                                this.createEntity.draw2fRootMesh.scaling = moduleEntity.viewRootMesh.scaling.clone();
                                this.createEntity.draw2fRootMesh.rotation.y = moduleEntity.viewRootMesh.rotation.y;
                                this.createEntity.draw2fRootMesh.position.y = 0.1;

                                this.createEntity.moduleEntity = moduleEntity;
                                this.createEntity.wallEnt.forEach(x=>{
                                    x.moduleEntity = moduleEntity;
                                });
                                this.createEntity.doorEnt.forEach(x=>{
                                    x.moduleEntity = moduleEntity;
                                    x.setCrushModuleEntity();
                                });
                                this.createEntity.stairEnt.forEach(x=>{
                                    x.moduleEntity = moduleEntity;
                                    x.setCrushModuleEntity();
                                });

                                this.createEntity.createCrushMesh();
                                //this.createEntity.createEntity();
                                //this.createEntity.sceneSubtract(this.createEntity.viewScene.instance);
                                
                                otherScene.createEntity.isGhost = false;
                                otherScene.createEntity.floorPenetrate = otherScene.floor;
                                otherScene.createEntity.setPenetrateMode(otherScene.floor)
                                
                                otherScene.createEntity = null;
                                moduleEntity.isFixed = true;
                                moduleEntity.Entities.push(this.createEntity);
                                moduleEntity.Units.push(this.createEntity);

                                this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(this.createEntity,SaveHelper.makeSaveData(moduleEntity)));
                            }
                            else
                            {
                                return;
                            }
                        }
                        else
                        {
                            return;
                        }
                    }
                    else if(this.createEntity?.info.ctgrName === 'Wall')
                    {
                        this.wallMouseUpEvent();
                    }
                    else if(this.createEntity?.info.ctgrName === 'Opening')
                    {
                        this.openingMouseUpEvent();
                    }
                }

                if(!this.isAddWallMode)
                {
                    this.createEntity = null;
                    this.viewer.loadModuleName = null;
                    this.removeSnapLines();
                    
                    setTimeout(() => {
                        this.updateWall();
                    }, 300);
                }
            }
            else
            {
                if(this.movingMesh)
                {
                    if(this.selectedEntity.info?.ctgrName == "Module")
                    {
                        if(this.movingInter)
                        {
                            this.selectedEntity.viewRootMesh.position = this.pointerDownModulePosition.clone();
                            this.selectedEntity.draw1fRootMesh.position.x = this.pointerDownModulePosition.x;
                            this.selectedEntity.draw1fRootMesh.position.z = this.pointerDownModulePosition.z;
                            this.selectedEntity.draw2fRootMesh.position.x = this.pointerDownModulePosition.x;
                            this.selectedEntity.draw2fRootMesh.position.z = this.pointerDownModulePosition.z;
                            this.viewer.$SceneLoader.history.pop();
                            this.viewer.$SceneLoader.historyIndex = this.viewer.$SceneLoader.historyIndexTemp;
                        }
                       
                        this.updateEntireRoof();
                        this.updateColumns();
                    }
                    if(this.movingMesh.info?.ctgrName === 'Wall')
                    {
                        this.movingMesh.entity?.moveNearEntity(this.movingMesh.absolutePosition, this.pointerDownModulePosition);
                    }
                }

                if(pickResult.pickedMesh != null)
                {   
                    if(pickResult.pickedMesh.entity)
                    {
                        setTimeout(() => {
                            this.selectMesh(pickResult.pickedMesh);
                        }, 50); // Wall이 모두 바뀔때까지 약간의 딜레이 필요
                    }
                    else
                        this.removeAllSelectedMesh();
                }
                else
                    this.removeAllSelectedMesh();

                setTimeout(() => {
                    this.updateWall();
                }, 200);
            }
        }
        else if(evt.button === 2)
        {
            if(this.createEntity?.info.ctgrName === 'Door')
            {
                this.doorDir *= -1;
                this.onPointerMove(null);
            }
        }
    }

    onPointerMove(evt)
    {
        this.removeSnapLines();

        let pos = this.mousePosition;
        let pickingInfo = this.instance.pick(this.instance.pointerX, this.instance.pointerY);

        if (pickingInfo.hit)
        {
            pos.x = pickingInfo.pickedPoint.x;
            pos.y = 3.2;
            pos.z = pickingInfo.pickedPoint.z;
        }
        else
            return;

        var createEntity = this.createEntity;
        var movingMesh = this.movingMesh;
        try{
            if(createEntity)
            {
                if(createEntity.isModule)
                {
                    this.moduleMouseMoveEvent(pos)
                }
                else
                {
                    if(this.createEntity?.info.ctgrName === 'Window')
                    {
                        this.windowMouseMoveEvent(pos)
                    }
                    else if(this.createEntity?.info.ctgrName === 'Door')
                    {
                        this.doorMouseMoveEvent(pos)
                    }
                    else if(this.createEntity?.info.ctgrName === 'Unit')
                    {
                        pos.y = 3.1;
                        var snappedPosition = pos.clone();
                        this.createEntity.setEnable(this.instance,true);
                        var currentEntities = this.viewer.$SceneLoader.EntityLoader.modules.filter(o => !o.isGhost);
                        this.createEntitySnap(snappedPosition, this.createEntity, currentEntities);
                        this.snappedPosition = snappedPosition;
                        const box = this.createEntity.drawMeshes1f.concat(this.createEntity.drawMeshes2f).filter(x=>x._scene === this.instance).find(x=>x.info.ctgrName === 'Box' && x.info.parentName.replace('-','').includes(this.createEntity.info.name));
                        box.isVisible = true;
                        box.material = this.instance.getMaterialByName("redBoxMaterial");
                        this.createEntity.setEnable(this.instance,false);
                        let pickingInfo = this.instance.pick(this.instance.pointerX, this.instance.pointerY);
                        this.createEntity.setEnable(this.instance,true);
                        if(pickingInfo.pickedMesh.id === "ground")
                        {
                            box.material = this.instance.getMaterialByName("redBoxMaterial");
                        }
                        else
                        {
                            box.material = this.instance.getMaterialByName("greenBoxMaterial");
                        }
                    }
                    else if(this.createEntity?.info.ctgrName === 'Wall')
                    {
                        this.wallMouseMoveEvent(pos)
                    }
                    else if(this.createEntity?.info.ctgrName === 'Opening')
                    {
                        this.openingMouseMoveEvent(pos)
                    }
                }
            }
            else if(movingMesh)
            {
                var selectedEntity = this.selectedEntity;
                
                // 객체 이동
                if(selectedEntity.info?.ctgrName == "Wall" && this.pointerDownPosition)
                {
                    var diff = pos.subtract(this.pointerDownPosition);
                    var movePos = this.pointerDownModulePosition.add(diff);
        
                    var currentEntities = this.viewer.$SceneLoader.EntityLoader.modules.filter(o => o != selectedEntity);
                    this.createEntitySnap(movePos, selectedEntity, currentEntities);
        
                    this.snappedPosition = movePos.clone();
                }
                else if(selectedEntity.info?.ctgrName == "Window" || selectedEntity.info?.ctgrName == "Door" || selectedEntity.info?.ctgrName == "Opening")
                {
                    const wallEnt = selectedEntity.parent;
                    if(!wallEnt)
                        return;
                    const dashLine = wallEnt.createCenterLine(this.instance);
                    this.snapLines.push(dashLine);

                    var diff = pos.subtract(this.pointerDownPosition);
                    var movePos = this.pointerDownModulePosition.add(diff);
                    selectedEntity.snapMove(movePos)
                }
                else if(selectedEntity.info?.ctgrName == "Module" || selectedEntity.info?.ctgrName == "Unit") // Module
                {
                    if(!this.pointerDownPosition)
                        return;

                    var diff = pos.subtract(this.pointerDownPosition);
                    var movePos = this.pointerDownModulePosition.add(diff);
        
                    var currentEntities = this.viewer.$SceneLoader.EntityLoader.modules.filter(o => o != selectedEntity);
                    this.createEntitySnap(movePos, selectedEntity, currentEntities);
        
                    this.snappedPosition = movePos.clone();

                    if(selectedEntity.info?.ctgrName == "Module")
                    {
                        const myBox = selectedEntity.getModuleBox(this.instance);
                        const otherModules = this.viewer.$SceneLoader.EntityLoader.modules.filter(x=>x.locatedScene == this).map(x=>x.getModuleBox(this.instance)).filter(x => x != myBox);
                        
                        this.movingInter = false;
                        for(let o of otherModules)
                        {
                            const isInter = myBox.intersectsMesh(o,true);
                            if(isInter)
                            {
                                this.movingInter = true;
                                break;
                            }
                        }

                        const highlightLayer = this.highlightLayer;
                        highlightLayer.removeAllMeshes();
                        if(this.movingInter)
                        {
                            const meshes = selectedEntity.Floors.map(x=>x.getDrawMeshes().find(o=>o._scene === this.instance))
                            for(let m of meshes)
                                highlightLayer.addMesh(m, new BABYLON.Color3(0,1,0.8));
                        }
                    }
                }

                const moduleEnt = this.selectedEntity.isModule ? this.selectedEntity : this.selectedEntity.moduleEntity;
                const crushes = moduleEnt.Entities.filter(x=>x.crushMesh).map(x=>x.crushMesh)
                .concat(moduleEnt.Entities.filter(x=>x.crushMesh1f).map(x=>x.crushMesh1f))
                .concat(moduleEnt.Entities.filter(x=>x.crushMesh2f).map(x=>x.crushMesh2f));
                crushes.forEach(async c=>{
                    if(c.spot)
                    {
                        c.spot.update(c);
                    }
                });
                this.viewer.$SceneLoader.history.slice(-1)[0].dataB = SaveHelper.makeSaveData(moduleEnt);
            }
            else
            {
                let pickingInfo = this.instance.pick(this.instance.pointerX, this.instance.pointerY);
                if (pickingInfo.hit)
                {
                    let phlLayer = this.previewHighlightLayer;
                    phlLayer.removeAllMeshes();

                    let mesh = pickingInfo.pickedMesh;
                    if(this.isAddWallMode)
                    {
                        if(mesh.info?.ctgrName === 'Wall')
                        {
                            const wallEntity = mesh.entity;
                            const dashLine = wallEntity.createCenterLine(this.instance);
                            this.snapLines.push(dashLine);
                            wallEntity.showCenterVertex(true,this.instance)
                        }
                        else
                        {
                            this.instance.getMeshesById('wallPoint').forEach(w=>{
                                w.dispose();
                            })
                        }
                    }
                    else if(mesh && mesh.entity && mesh.entity?.moduleEntity?.locatedScene == this)
                    {
                        if(!this.highlightLayer.hasMesh(mesh))
                        {
                            mesh.actionManager = new BABYLON.ActionManager(this.instance);

                            phlLayer.addMesh(mesh, BABYLON.Color3.Purple());
                            
                            mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function(ev){
                                phlLayer.removeMesh(mesh);
                            }));
                            if(mesh.entity.info.ctgrName === 'Floor')
                            {
                                mesh.actionManager.hoverCursor="Move"
                            }
                        }
                    }
                }
            }
        }
        catch(err)
        {
            console.error(err);
        }
    }

    async updateEntireRoof()
    {
        var sceneLoader = this.viewer.$SceneLoader;
        var viewScene = sceneLoader.viewScene;
        var loader = sceneLoader.EntityLoader;

        if(loader.roofs)
        {
            loader.roofs.forEach(o =>
            {
                o.dispose(); 
            });
        }

        if(!this.viewer.$SceneLoader.isShowRoof)
            return;

        const clipper = await clipperLib.loadNativeClipperLibInstanceAsync(
            clipperLib.NativeClipperLibRequestedFormat.WasmWithAsmJsFallback
        );

        var roofFloorHeight = 0.5;
        var roofRailHeight = 1.1;
        var roofRailWidth = 0.16;

        var floorEdges = [];
        var roofEdges = [];
        var mergeRoofs = [];

        loader.modules.forEach(o =>
        {
            var dataEdges = o.getFloorEdges();
            dataEdges.forEach(data =>
            {
                if(!o.isHideRoof)
                {
                    roofEdges.push({
                        edges: JSON.parse(JSON.stringify(data.edges)),
                        height: data.height + 3.1,
                        located: data.located
                    });
                }

                floorEdges.push({
                    edges: JSON.parse(JSON.stringify(data.edges)),
                    height: data.height + 3.1,
                    located: data.located
                });
            });
        });

        while(roofEdges.length > 0)
        {
            var roofData = roofEdges.pop();
            var roof = roofData.edges;
            var sameLocatedRoofs = roofEdges.filter(o => o.located == roofData.located);
            while(true)
            {
                var isUnioned = false;
                for(var i=0; i<sameLocatedRoofs.length; i++)
                {
                    var targetData = sameLocatedRoofs[i];
                    var target = targetData.edges;

                    var unionClip = clipper.clipToPaths({
                        clipType: clipperLib.ClipType.Union,
                        subjectFillType: clipperLib.PolyFillType.EvenOdd,
                        clipInputs: [{data: roof}],
                        subjectInputs: [{data: target}],
                    });

                    if(unionClip.length == 1)
                    {
                        roof = unionClip[0];
                        isUnioned = true;

                        sameLocatedRoofs.splice(i, 1);

                        var roofIndex = roofEdges.indexOf(targetData);
                        if(roofIndex >= 0)
                            roofEdges.splice(roofIndex, 1);
                            
                        break;
                    }
                }

                if(!isUnioned)
                    break;
            }

            roofData.edges = [roof];
            roofData.originEdges = roof;
            mergeRoofs.push(roofData);
        }

        // 1층 지붕은 2층 Floor만큼 제외
        var floor2fs = floorEdges.filter(o => o.located == "2F");
        mergeRoofs.filter(o => o.located == "1F").forEach(o =>
        {
            var roof = o.edges;

            for(var i=0; i<floor2fs.length; i++)
            {
                var floorData = floor2fs[i];
                var floor = floorData.edges;

                var intersectClip = clipper.clipToPaths({
                    clipType: clipperLib.ClipType.Intersection,
                    subjectFillType: clipperLib.PolyFillType.EvenOdd,
                    clipInputs: roof.map(o => ({data: o})),
                    subjectInputs: [{data: floor}],
                });

                if(intersectClip.length <= 0)
                    continue;

                var diffClip = clipper.clipToPaths({
                    clipType: clipperLib.ClipType.Difference,
                    subjectFillType: clipperLib.PolyFillType.EvenOdd,
                    clipInputs: intersectClip.map(o => ({data: o})),
                    subjectInputs: roof.map(o => ({data: o})),
                });

                var checkOffsetClip = clipper.offsetToPaths({
                    delta: -10,
                    offsetInputs: [{
                        data: diffClip,
                        joinType: clipperLib.JoinType.Miter,
                        endType: clipperLib.EndType.ClosedPolygon
                    }]
                });

                roof = diffClip;
                if(checkOffsetClip.length <= 0) // 삭제됨.
                {
                    roof = null;
                    break;
                }
            }

            o.edges = roof;
        });

        // 삭제된 Roof 제거
        mergeRoofs = mergeRoofs.filter(o => o.edges);

        var widthOffset = roofRailWidth * 1000;
        mergeRoofs.forEach(o =>
        {
            var originEdges = o.originEdges;

            var clipperVertices = clipper.offsetToPaths({
                delta: -widthOffset,
                offsetInputs: [{
                    data: originEdges,
                    joinType: clipperLib.JoinType.Miter,
                    endType: clipperLib.EndType.ClosedPolygon
                }]
            });

            if(clipperVertices.length <= 0)
            {
                o.edges = null;
            }
            else
            {
                var railEdges = clipperVertices.flat();
                o.railEdges = railEdges;
            }
        });

        mergeRoofs = mergeRoofs.filter(o => o.edges);

        // 지붕 만들기
        mergeRoofs.forEach(o =>
        {
            var roofEdges = o.edges;
            var railEdges = o.railEdges;
            var height = o.height;

            roofEdges.forEach(roofEdge =>
            {
                var clockwiseRoofEdges = MeshHelper.GetClockwiseVertices(roofEdge.map(v => new BABYLON.Vector3(v.x / 1000, height, v.y / 1000)));

                var roof = BABYLON.MeshBuilder.ExtrudePolygon("Roof", {shape:clockwiseRoofEdges, depth: roofFloorHeight + roofRailHeight}, viewScene.instance, earcut);
                var roofCSG = BABYLON.CSG.FromMesh(roof);
                roof.dispose();
    
                if(railEdges)
                {
                    var clockwiseRailEdges = MeshHelper.GetClockwiseVertices(railEdges.map(v => new BABYLON.Vector3(v.x / 1000, height, v.y / 1000)))
    
                    var extrudeRoofRailing = BABYLON.MeshBuilder.ExtrudePolygon("Railing", {shape:clockwiseRailEdges, depth: roofFloorHeight + roofRailHeight}, viewScene.instance, earcut);
                    extrudeRoofRailing.position = extrudeRoofRailing.position.add(new BABYLON.Vector3(0, roofFloorHeight, 0));
                    var roofRailAreaCSG = BABYLON.CSG.FromMesh(extrudeRoofRailing);
        
                    roofCSG = roofCSG.subtract(roofRailAreaCSG);
                    extrudeRoofRailing.dispose();
                }
    
                roof = roofCSG.toMesh("Roof", null, viewScene.instance, true);
                roof.position = roof.position.add(new BABYLON.Vector3(0, height + roofFloorHeight + roofRailHeight, 0));
                const multimat = new BABYLON.MultiMaterial("multi", roof._scene);
                multimat.subMaterials.push(viewScene.instance.getMaterialByName('WallMat'));
                multimat.subMaterials.push(viewScene.instance.getMaterialByName('paintMat'));
                
                roof.material = multimat;
                loader.roofs.push(roof); 
            });
        });
    }

    async updateViewWall(instance,f)
    {
        const viewIns = this.viewer.$SceneLoader.viewScene.instance;
        const walls = instance.meshes.filter(x=>x.info?.ctgrName==='Wall').filter(x=>x.entity != null);
        const floors = instance.meshes.filter(x=>x.info?.ctgrName==='Floor').filter(x=>x.entity != null).filter(x=>!x.info?.typeName.includes('Unit'));
        const stairs = instance.meshes.filter(x=>x.info?.ctgrName==='Stair').filter(x=>x.entity != null);
        const voids = instance.meshes.filter(x=>x.info?.ctgrName==='Void').filter(x=>x.entity != null);
        const crushes = instance.getMeshesById("crushMesh");
        const crusheTops = instance.getMeshesById("crushMeshTop");
        const backMeshes = instance.getMeshesById("backMesh");
        const CrushPoints = instance.getMeshesById("CrushPoint");

        backMeshes.forEach(b=>b.dispose())
        CrushPoints.forEach(b=>b.dispose())

        crushes.forEach(c=>{
            if(c.spot)
            {
                const tempMeshes = [...c.spot.includedOnlyMeshes];
                const tempMats = [];
                tempMeshes.forEach(o=>{
                    tempMats.push(o._scene.getMaterialByName(o.material?.name));
                    o.material = null;
                })
                c.spot.includedOnlyMeshes = [instance.getMeshByName('spotBox')];
                for(let i = 0; i < tempMeshes.length; i++)
                {
                    tempMeshes[i].material = tempMats[i];
                }
            }
        });

        crusheTops.forEach(c=>{
            if(c.spot)
            {
                const tempMeshes = [...c.spot.includedOnlyMeshes];
                const tempMats = [];
                tempMeshes.forEach(o=>{
                    tempMats.push(o._scene.getMaterialByName(o.material?.name));
                    o.material = null;
                })
                c.spot.includedOnlyMeshes = [instance.getMeshByName('spotBox')];
                for(let i = 0; i < tempMeshes.length; i++)
                {
                    tempMeshes[i].material = tempMats[i];
                }
            }
        });

        crushes.forEach(c=>{
            if(c.spot)
            {
                c.computeWorldMatrix();
                c.spot.update(c);
            }
        });

        crusheTops.forEach(c=>{
            if(c.spot)
            {
                c.computeWorldMatrix();
                c.spot.update(c);
            }
        });

        walls.forEach(w=>{
            if(!w.isEnabled(true))
            {
                return;
            }

            const temp = w.material;
            w.material = null;
            CsgHelper.bakeTransform(w);
            w.computeWorldMatrix();
            crushes.forEach(c=>{
                if(!c.spot)
                {
                    return;
                }
                const spot = c.spot;
                
                if(!w.entity || !spot)
                    return;
                
                if(c.entity?.info?.ctgrName === 'Opening')
                {
                    if(w.entity.info?.parentName.includes('Unit'))
                    {
                        //소형유닛의 벽일 경우 충돌 검사
                    }
                    else if(c.entity.parent != w.entity)
                    {
                        return;
                    }
                }
                
                const isCrush = w.intersectsMesh(c,true);
                if(isCrush)
                {
                    const cbox = c.getBoundingInfo().boundingBox;
                    const width = cbox.extendSize.x * 2;
                    const depth = cbox.extendSize.z * 2;

                    const crushPlane = BABYLON.MeshBuilder.CreateBox(null,{ width: width*0.7,height:2.5, depth: depth*0.7 }, instance);
                    crushPlane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
                    crushPlane.position = c.position.clone();
                    crushPlane.rotation.y = c.rotation.y;
                    crushPlane.parent = c.parent;
                    crushPlane.computeWorldMatrix();

                    if(w.intersectsMesh(crushPlane,true))
                    {
                        spot.includedOnlyMeshes.push(w);
                        if(instance === viewIns)
                        {
                            w.entity.makeTempMesh(w,c);
                        }
                    }
                    crushPlane.dispose();
                }
            });
            w.material = temp;
        })

        if(instance === viewIns)
        {
            for(let c of crushes)
            {
                for(let t of instance.getMeshesById("backMesh"))
                {
                    if(c.entity?.info?.ctgrName === 'Opening')
                    {
                        if(t.entity?.info?.parentName.includes('Unit'))
                        {
                            //소형유닛의 벽일 경우 충돌 검사
                        }
                        else if(t.w.entity != c.entity.parent)
                            continue;
                    }
                    
                    if(c.intersectsMesh(t,true))
                    {
                        c.spot.includedOnlyMeshes.push(t);
                    }
                }
            }
        }

        floors.forEach(f=>{
            if(!f.isEnabled(true))
            {
                return;
            }
            const temp = f._scene.getMaterialByName(f?.material?.name);
            f.material = null;
            
            CsgHelper.bakeTransform(f);
            f.computeWorldMatrix();
            
            crusheTops.forEach(c=>{
                const spot = c.spot;
                
                if(c.entity.info.ctgrName != 'Void')
                {
                    if(!spot.isOtherThrough && f.moduleEntity != c.moduleEntity)
                    {
                        return;
                    }
                    
                    if(spot.isOtherThrough && f.moduleEntity == c.moduleEntity)
                    {
                        return;
                    }
                }
                
                const isCrush = f.intersectsMesh(c,true);
                if(isCrush){
                    spot.includedOnlyMeshes.push(f);
                }
            })

            f.material = temp;
        })

        if(instance === viewIns)
        {
            stairs.forEach(async s=>{
                s.entity.makeTempMesh(instance);
            })
            voids.forEach(async v=>{
                v.entity.makeTempMesh(instance);
            })
        }
    }

    updateWall()
    {
        if(!this.isLoaded)
            return;
        const viewIns = this.viewer.$SceneLoader.viewScene.instance;
        const d1fIns = this.viewer.$SceneLoader.drawScene1f.instance;
        const d2fIns = this.viewer.$SceneLoader.drawScene2f.instance;
        
        this.updateViewWall(d1fIns,'1f');
        this.updateViewWall(d2fIns,'2f');
        this.updateViewWall(viewIns,'vf');

        this.updateColumns();
        this.viewer.$SceneLoader.viewScene.setFloorText();
        this.viewer.$SceneLoader.drawScene1f.setFloorText();
        this.viewer.$SceneLoader.drawScene2f.setFloorText();
    }

    updateColumns()
    {
        var floor1fs = [];
        var floor2fs = [];

        this.viewer.$SceneLoader.EntityLoader.modules.filter(o => !o.isGhost).forEach(o =>
        {
            if(o.locatedScene.floor == "2F")
                floor2fs.push(o);
            else
                floor1fs.push(o);
        });

        floor2fs.forEach(o => o.createColumns(floor1fs));
    }

    createEntitySnap(pos, entity, currentEntities)
    {
        var offsetY = pos.y;
        if(entity.info.ctgrName == "Module")
            offsetY = this.floor == '2F' ? 3.1 : 0;

        var result = entity.snapEntities(this, this.prevSnapData, pos.x, pos.z, currentEntities);
        if(result != null)
        {
            var newSnapData = {};
            var lines = [];
            if(result.minx != null)
            {
                pos.x = pos.x - result.xItems[0].posDist;
                result.xItems.forEach(item =>
                {
                    lines.push([new BABYLON.Vector3(item.minPos, 9, item.linePos[0]), new BABYLON.Vector3(item.minPos, 9, item.linePos[1])])
                });

                newSnapData.X = result.xItems[0].minPos;
            }
            else
                pos.x = pos.x;
            pos.y = offsetY;

            if(result.minz != null)
            {
                pos.z = pos.z - result.zItems[0].posDist;
                result.zItems.forEach(item =>
                {
                    lines.push([new BABYLON.Vector3(item.linePos[0], 9, item.minPos), new BABYLON.Vector3(item.linePos[1], 9, item.minPos)])
                });

                newSnapData.Z = result.zItems[0].minPos;
            }
            else
                pos.z = pos.z;

            for(var i=0; i<lines.length; i++)
            {
                var line = lines[i];
                var dashLine = BABYLON.MeshBuilder.CreateDashedLines("dl", {points: line, dashNb: BABYLON.Vector3.Distance(line[0], line[1]) * this.zoomRate / 5}, this.instance);
                dashLine.color = BABYLON.Color3.Green();

                this.snapLines.push(dashLine);
            }

            this.prevSnapData = newSnapData;
        }
        else
            this.prevSnapData = null;

        if(entity.info.ctgrName != "Module") // 모듈 안에 속해있는 객체는 모듈 밖으로 나가면 안됨
        {
            var module = entity.moduleEntity;
            if(module != null)
            {
                var moduleBox = module.getBoundingBox2d(this, ["Floor"]);

                var tempPos = pos.clone();
                tempPos.y = 0;
                if(!moduleBox.intersectsPoint(tempPos))
                {
                    this.removeSnapLines();
                    return;
                }
    
                var walls = module.Walls.filter(o => o.info.name.includes("Exterior"));
                for(var i=0; i<walls.length; i++)
                {
                    var wall = walls[i];
                    var wallBox = wall.getBoundingBox2d(this);
    
                    if(wallBox.intersectsPoint(tempPos))
                    {
                        this.removeSnapLines();
                        return;
                    }
                }
            }
        }

        entity.setPosition(this.instance, pos);
    }

    removeSnapLines()
    {
        if(this.snapLines.length > 0)
        {
            this.snapLines.forEach(line =>
            {
                line.dispose();
            });
            this.snapLines = [];
        }
    }

    selectMesh(mesh)
    {
        if(mesh.entity.moduleEntity.locatedScene != this)
            return;

        this.removeAllSelectedMesh();

        var scene = this;
        var target = mesh.entity.getContextEntity();

        var meshes = target.getSceneMeshes(scene);

        var panel = target.createContextPanel(this);
        if(panel != null)
        {
            var highlightLayer = this.highlightLayer;
            meshes.forEach(o =>
            {
                //new BABYLON.Color3(0,1,0.8)
                highlightLayer.addMesh(o, BABYLON.Color3.Purple());
            });
            this.previewHighlightLayer.removeAllMeshes();
            this.selectedEntity = target;
            this.highlightMeshes = meshes;
        }
    }

    selectEntity(entity)
    {
        this.removeAllSelectedMesh();

        var scene = this;
        var meshes = entity.getSceneMeshes(scene);

        var panel = entity.createContextPanel(this);
        if(panel != null)
        {
            var highlightLayer = this.highlightLayer;
            meshes.forEach(o =>
            {
                highlightLayer.addMesh(o, BABYLON.Color3.Purple());
            });
            this.selectedEntity = entity;
            this.highlightMeshes = meshes;
        }
    }

    removeAllSelectedMesh()
    {
        this.removeSelectedMesh();

        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        otherScene.removeSelectedMesh();
    }

    removeSelectedMesh()
    {
        if(this.movingMesh)
            this.movingMesh = null;

        if(this.selectedEntity != null)
        {
            this.selectedEntity.disposeContextPanel();
            this.selectedEntity = null;

            var highlightLayer = this.highlightLayer;
            var phlLayer = this.previewHighlightLayer;
            this.highlightMeshes.forEach(o =>
            {
                phlLayer.removeExcludedMesh(o);
                highlightLayer.removeMesh(o);
            });
        }
    }

    handleMouseWheel(delta) {
        const camera = this.instance.getCameraByName("Camera")
        const scene = this.instance;
        const ground = scene.getMeshByName("ground");

        camera.panningSensibility = 5000 * 1/this.zoomRate;

        // 마우스 휠의 방향에 따라 처리
        if (delta > 0) {
            if(this.zoomRate > 4)
            {
                this.zoomRate -= 1;
            }
        } else {
            if(this.zoomRate < 30)
            {
                this.zoomRate += 1;
            }
        }
        
        this.cameraRender(camera)
        if(ground)
        {
            if(this.zoomRate>25)
            {
                ground.material = scene.getMaterialByName("bigGridMat");
            }
            else if(this.zoomRate<10)
            {
                ground.material = scene.getMaterialByName("smallGridMat");
            }
            else
            {
                ground.material = scene.getMaterialByName("nomalGridMat");
            }
        }
        this.setFloorText();
    }

    wallMouseMoveEvent(pos)
    {
        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                                    .filter(x=>x.entity?.moduleEntity)
                                    .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                                    .map(x=>x.entity);
        const pickCtgrs = pickEnts.filter(x=>x.info).map(x=>x.info.ctgrName);

        const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
        const wallMesh = wallEntity?.getDrawMeshes().find(x=>x._scene === this.instance);

        if(!this.createEntity.startPoint)
        {
            this.createEntity.setEnable(this.instance,true)
            pos.y += 3.2;
            this.createEntity.setPosition(this.instance,pos)
    
            if(pickCtgrs.length > 0)
            {
                const terrace = pickEnts.find(x=>x.info.name.includes('Floor-Exterior'));
                if(terrace)
                {
                    this.createEntity.setStatus(false);
                    return;
                }
            }
            else
            {
                this.createEntity.setStatus(false);
                return;
            }
            this.createEntity.setStatus(true);
    
            if(wallEntity && wallMesh)
            {
                const dashLine = wallEntity.createCenterLine(this.instance);
                this.snapLines.push(dashLine);
                wallEntity.showCenterVertex(true,this.instance)
    
                const worldMatrix = wallMesh.getWorldMatrix();
                const worldPosition = BABYLON.Vector3.TransformCoordinates(BABYLON.Vector3.Zero(), worldMatrix);
    
                if(wallEntity.wallType === 'Horizontal')
                {
                    const m = this.createEntity.getInstancePointer(this.instance);
                    m.rotation.y = wallEntity.moduleEntity.viewRootMesh.rotation.y;
                    const rot = CsgHelper.getParentRotation(wallMesh);
                    const sin = Math.abs(Math.sin(rot)) 
                    const cos = Math.abs(Math.cos(rot)) 
    
                    m.position.x = worldPosition.x * sin + m.position.x * cos;
                    m.position.z = worldPosition.z * cos + m.position.z * sin;
                }
                else
                {
                    const m = this.createEntity.getInstancePointer(this.instance);
                    m.rotation.y = wallEntity.moduleEntity.viewRootMesh.rotation.y;
                    const rot = CsgHelper.getParentRotation(wallMesh);
                    const sin = Math.abs(Math.sin(rot)) 
                    const cos = Math.abs(Math.cos(rot)) 
    
                    m.position.x = worldPosition.x * cos + m.position.x * sin;
                    m.position.z = worldPosition.z * sin + m.position.z * cos;
                }
            }
            else
            {
                this.instance.getMeshesById('wallPoint').forEach(w=>{
                    w.dispose();
                })
            }
        }
        else
        {
            const targetEnts = pickEnts.filter(x=>x.moduleEntity === this.createEntity.targetModule);
            
            if(targetEnts.length < 1)
            {
                return;
            }
            
            this.createEntity.setEnable(this.instance,true)
            const endPos = pos.clone();

            if(wallEntity && wallMesh)
            {
                const dashLine = wallEntity.createCenterLine(this.instance);
                this.snapLines.push(dashLine);
                wallEntity.showCenterVertex(true,this.instance)

                const worldMatrix = wallMesh.getWorldMatrix();
                const worldPosition = BABYLON.Vector3.TransformCoordinates(BABYLON.Vector3.Zero(), worldMatrix);
            
                const rot = CsgHelper.getParentRotation(wallMesh);
                const sin = Math.abs(Math.sin(rot)) 
                const cos = Math.abs(Math.cos(rot)) 

                if(wallEntity.wallType === 'Horizontal')
                {
                    endPos.x = worldPosition.x * sin + pos.x * cos;
                    endPos.z = worldPosition.z * cos + pos.z * sin;
                }
                else
                {
                    endPos.x = worldPosition.x * cos + pos.x * sin;
                    endPos.z = worldPosition.z * sin + pos.z * cos;
                }
            }
            else
            {
                this.instance.getMeshesById('wallPoint').forEach(w=>{
                    w.dispose();
                })
            }

            const sPos = this.createEntity.startPoint;
            const xWallPos = new Vector3(endPos.x,sPos.y,sPos.z);
            const zWallPos = new Vector3(sPos.x,sPos.y,endPos.z);

            const xDist = Vector3.Distance(sPos,xWallPos);
            const zDist = Vector3.Distance(sPos,zWallPos);

            if(xDist>zDist)
            {
                this.createEntity.updateTempWall(xWallPos,xDist,'x');
            }
            else
            {
                this.createEntity.updateTempWall(zWallPos,zDist,'z');
            }
        }
    }

    wallMouseUpEvent()
    {
        if(!this.createEntity.status)
        {
            return;
        }

        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                                    .filter(x=>x.entity.moduleEntity)
                                    .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                                    .map(x=>x.entity);

        if(pickEnts.length < 1)
        {
            return;
        }

        if(!this.createEntity.startPoint)
        {
            const targetModule = pickEnts[0].moduleEntity;
            const pointer = this.createEntity.getInstancePointer(this.instance)
            this.createEntity.startPoint = pointer.position.clone();
            this.createEntity.targetModule = targetModule;
        }
        else
        {
            const ent = this.createEntity.createEntity();

            // Snap된 상태에서 벽 배치시 종료
            if(this.snapLines.length > 0)
            {
                // this.createEntity.dispose();
                // this.createEntity = null;
                // this.removeSnapLines();
                this.createEntity.startPoint = null;
            }
            this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(ent,SaveHelper.makeSaveData(ent.moduleEntity)));
        }
    }

    openingMouseMoveEvent(pos)
    {
        var snapOffset = 0.15;

        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                                    .filter(x=>x.entity?.moduleEntity)
                                    .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                                    .map(x=>x.entity);
        
        const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
        const wallMesh = wallEntity?.getDrawMeshes().find(x=>x._scene === this.instance);
        
        this.createEntity.setEnable(this.instance,true)
        pos.y += 3.2;

        if(!wallEntity)
        {
            this.createEntity.setStatus(false);
            this.instance.getMeshesById('wallPoint').forEach(w=>{
                w.dispose();
            })
            return;
        }
        else if(this.createEntity.startPoint)
        {
            if(this.createEntity.targetWall != wallMesh)
            {
                this.createEntity.setStatus(false);
                this.instance.getMeshesById('wallPoint').forEach(w=>{
                    w.dispose();
                })
                return;
            }
        }
        this.createEntity.setStatus(true);

        const dashLine = wallEntity.createCenterLine(this.instance);
        this.snapLines.push(dashLine);
        wallEntity.showCenterVertex(true,this.instance);

        // Snap
        var currentOpenings = this.viewer.$SceneLoader.EntityLoader.modules.filter(o => !o.isGhost && o.locatedScene == this).flatMap(o => o.Openings);
        var nearPos = null;
        var nearDist = null;
        var wallWorldType = wallEntity.getWorldWallType();
        for(var i=0; i<currentOpenings.length; i++)
        {
            var opening = currentOpenings[i];
            var box = opening.getBoundingBox2d(this);
            if(wallWorldType === 'Horizontal')
            {
                if(box.extendSize.z >= box.extendSize.x)
                    continue;
            }
            else
            {
                if(box.extendSize.x >= box.extendSize.z)
                    continue;
            }

            var checkValue = [box.minimum, box.maximum];
            checkValue.forEach(o =>
            {
                var dist;
                if(wallWorldType === 'Horizontal')
                    dist = Math.abs(o.x - pos.x);
                else
                    dist = Math.abs(o.z - pos.z);

                if(dist <= snapOffset)
                {
                    if(nearPos == null || dist < nearDist)
                    {
                        nearPos = o;
                        nearDist = dist;
                    }
                }
            });
        }

        var moduleWalls = wallEntity.moduleEntity.Walls.filter(o => o.wallType != wallEntity.wallType);
        var checkValue = [];
        var wallLine = wallEntity.getWorldWallLine();
        moduleWalls.forEach(o =>
        {
            var targetLine = o.getWorldWallLine();
            var wallStart = wallLine.start;
            var wallEnd = wallLine.end;

            var result = VectorHelper.calcNearestPointOnLine(wallStart, wallEnd, targetLine.start);
            var isChecked = false;

            if(VectorHelper.calcIsInsideLineSegment(wallStart, wallEnd, result))
                isChecked = true;
            else
            {
                var dist = Math.min(BABYLON.Vector3.Distance(wallStart, result), BABYLON.Vector3.Distance(wallEnd, result));
                if(dist <= 0.1)
                    isChecked = true;
            }

            if(isChecked)
            {
                var farPos = targetLine.start;
                if(BABYLON.Vector3.Distance(targetLine.end, result) > BABYLON.Vector3.Distance(targetLine.start, result))
                    farPos = targetLine.end;

                var box = o.getBoundingBox2d(this);
                var min = farPos.clone();
                var max = farPos.clone();
                if(wallWorldType === 'Horizontal')
                {
                    min.x = box.minimumWorld.x;
                    max.x = box.maximumWorld.x;
                }
                else
                {
                    min.z = box.minimumWorld.z;
                    max.z = box.maximumWorld.z;
                }

                checkValue.push(min);
                checkValue.push(max);
            }
        });
        checkValue.forEach(o =>
        {
            var dist;
            if(wallWorldType === 'Horizontal')
                dist = Math.abs(o.x - pos.x);
            else
                dist = Math.abs(o.z - pos.z);

            if(dist <= snapOffset)
            {
                if(nearPos == null || dist < nearDist)
                {
                    nearPos = o;
                    nearDist = dist;
                }
            }
        });

        if(nearPos != null)
        {
            var line = [];
            if(wallWorldType === 'Horizontal')
                pos.x = nearPos.x;
            else
                pos.z = nearPos.z;

            var dir = BABYLON.Vector3.Normalize(nearPos.subtract(pos));
            var startPos = pos.subtract(dir.scale(this.zoomRate / 2));
            var endPos = nearPos.add(dir.scale(this.zoomRate / 2));
            startPos.y = 9;
            endPos.y = 9;
            line = [startPos, endPos];

            var lineDistance = BABYLON.Vector3.Distance(startPos, endPos);

            var snapLine = BABYLON.MeshBuilder.CreateDashedLines("dl", {points: line, dashNb: lineDistance * 10, dashSize: 1, gapSize: 1}, this.instance);
            snapLine.color = BABYLON.Color3.Green();

            this.snapLines.push(snapLine);
        }

        this.createEntity.setPosition(this.instance,pos)

        const worldMatrix = wallMesh.getWorldMatrix();
        const worldPosition = BABYLON.Vector3.TransformCoordinates(BABYLON.Vector3.Zero(), worldMatrix);

        if(wallEntity.wallType === 'Horizontal')
        {
            const m = this.createEntity.getInstancePointer(this.instance);
            m.rotation.y = wallEntity.moduleEntity.viewRootMesh.rotation.y;
            const rot = CsgHelper.getParentRotation(wallMesh);
            const sin = Math.abs(Math.sin(rot)) 
            const cos = Math.abs(Math.cos(rot)) 

            m.position.x = worldPosition.x * sin + m.position.x * cos;
            m.position.z = worldPosition.z * cos + m.position.z * sin;
        }
        else
        {
            const m = this.createEntity.getInstancePointer(this.instance);
            m.rotation.y = wallEntity.moduleEntity.viewRootMesh.rotation.y;
            const rot = CsgHelper.getParentRotation(wallMesh);
            const sin = Math.abs(Math.sin(rot)) 
            const cos = Math.abs(Math.cos(rot)) 

            m.position.x = worldPosition.x * cos + m.position.x * sin;
            m.position.z = worldPosition.z * sin + m.position.z * cos;
        }

        if(this.createEntity.startPoint)
        {
            const sPos = this.createEntity.startPoint;
            const xWallPos = new Vector3(pos.x,sPos.y,sPos.z);
            const zWallPos = new Vector3(sPos.x,sPos.y,pos.z);

            const xDist = Vector3.Distance(sPos,xWallPos);
            const zDist = Vector3.Distance(sPos,zWallPos);

            if(xDist>zDist)
            {
                this.createEntity.updateTempWall(xWallPos,xDist,'x');
            }
            else
            {
                this.createEntity.updateTempWall(zWallPos,zDist,'z');
            }
        }
    }

    openingMouseUpEvent()
    {
        if(!this.createEntity.status)
        {
            return;
        }

        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const infoMesh = pickedMeshs.filter(x=>x.info).filter(x=>x?.entity?.moduleEntity);

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                                    .filter(x=>x.entity?.moduleEntity)
                                    .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                                    .map(x=>x.entity);
        
        const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
        const wallMesh = wallEntity?.getDrawMeshes().find(x=>x._scene === this.instance);

        if(infoMesh.length < 1)
        {
            return;
        }

        if(!this.createEntity.startPoint)
        {
            const targetModule = wallEntity.moduleEntity;
            const pointer = this.createEntity.getInstancePointer(this.instance)
            pointer.isVisible = false;
            this.createEntity.startPoint = pointer.position.clone();
            this.createEntity.targetModule = targetModule;
            this.createEntity.targetWall = wallMesh;
        }
        else
        {
            if(this.createEntity.length < 0.15)
                return;
            const ent = this.createEntity.createEntity();
            this.viewer.$SceneLoader.CancelModule();
            this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(ent,SaveHelper.makeSaveData(ent.moduleEntity)));
        }
    }

    moduleMouseMoveEvent(pos)
    {
        const createEntity = this.createEntity;
        if(createEntity.isGhost)
        {
            var snappedPosition = pos.clone();
            this.createEntity.setEnable(this.instance,true);
            var currentEntities = this.viewer.$SceneLoader.EntityLoader.modules.filter(o => !o.isGhost);
            this.createEntitySnap(snappedPosition, this.createEntity, currentEntities);

            this.snappedPosition = snappedPosition;
            const greenBoxMat = this.instance.getMaterialByName('greenBoxMaterial');
            const redBoxMat = this.instance.getMaterialByName('redBoxMaterial');
            const mbox = this.createEntity.moduleBoxs.find(x=>x._scene === this.instance);
            mbox.isVisible = true;
            const targets = mbox._scene.getMeshesById(mbox.name).filter(x=> x != mbox);
            
            targets.some(t=>{
                if(t.ModuleEntity.floorPenetrate === this.floor)
                {
                    return false;
                }
                if(mbox.intersectsMesh(t))
                {
                    mbox.material = redBoxMat;
                    return true;
                }
                else
                {
                    mbox.material = greenBoxMat;
                }
            })
        }
    }

    moduleMouseUpEvent()
    {
        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        const mbox = this.createEntity.moduleBoxs.find(x=>x._scene === this.instance);
        if(mbox.material.name === 'redBoxMaterial')
        {
            this.viewer.$SceneLoader.CancelModule();
            return;
        }
        let pos = new Vector3(this.snappedPosition.x,3.1,this.snappedPosition.z);
        let otherPos = new Vector3(this.snappedPosition.x,0,this.snappedPosition.z);
        
        this.createEntity.setPosition(otherScene.instance,otherPos);
        this.createEntity.setPosition(this.instance,pos);
        const otherRoot = this.createEntity.getAllRoots().find(x=>x._scene === otherScene.instance);
        otherRoot.position.y = 0;

        this.snappedPosition = null;
        this.createEntity.info.floor = this.floor;
        this.createEntity.isGhost = false;
        this.createEntity.locatedScene = this;
        
        this.createEntity.setViewModel(true);
        this.createEntity.isFixed = true;
        otherScene.createEntity = this.createEntity;
        otherScene.createEntity.isGhost = false;
        otherScene.createEntity.floorPenetrate = otherScene.floor;
        otherScene.createEntity.setPenetrateMode(otherScene.floor)

        this.createEntity.moduleBoxs.forEach(mbox=>mbox.isVisible = false);
        this.createEntity.setAllEnable(true);
        this.updateEntireRoof();
        
        otherScene.createEntity.isFixed = false;

        otherScene.createEntity = null;

        this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(this.createEntity,SaveHelper.makeSaveData(this.createEntity)));
    }

    windowMouseMoveEvent(pos)
    {
        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        this.createEntity.setEnable(this.instance,true)
        pos.y += 3.2;
        this.createEntity.setPosition(this.instance,pos)
        this.createEntity.setPosition(otherScene.instance,pos)

        const greenBoxMat = this.instance.getMaterialByName('greenBoxMaterial');
        const redBoxMat = this.instance.getMaterialByName('redBoxMaterial');
        const mesh1f = this.createEntity.drawMeshes1f.find(x=>x.info.ctgrName!= 'Box')
        const mesh2f = this.createEntity.drawMeshes2f.find(x=>x.info.ctgrName!= 'Box')

        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                        .filter(x=>x.entity?.moduleEntity)
                        .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                        .map(x=>x.entity);

        const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
        const wallMesh = wallEntity?.getDrawMeshes().find(x=>x._scene === this.instance);

        if(wallMesh)
        {
            if(!wallMesh?.entity?.moduleEntity?.floorPenetrate)
            {
                return;
            }
            if(wallMesh.entity.moduleEntity.floorPenetrate === this.floor)
            {
                return;
            }
            const dashLine = wallEntity.createCenterLine(this.instance);
            this.snapLines.push(dashLine);

            mesh2f.material = greenBoxMat;
            mesh1f.material = greenBoxMat;

            const wallType = wallEntity.getWorldWallType();
            const wallPos = wallMesh.getAbsolutePosition();

            if(wallType.type === 'Vertical')
            {
                for(let m of this.createEntity.getDrawMeshes())
                {
                    m.rotation.y = Math.PI /2;
                    m.position.x = wallPos.x;
                }
            }
            else
            {
                for(let m of this.createEntity.getDrawMeshes())
                {
                    m.rotation.y = 0;
                    m.position.z = wallPos.z;
                }
            }
        }
        else
        {
            mesh2f.material = redBoxMat;
            mesh1f.material = redBoxMat;
        }
    }

    windowMouseUpEvnet()
    {
        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        const winEnt = this.createEntity;
        const winMeshes = winEnt.getDrawMeshes().filter(x=>x.info.ctgrName == 'Window');
        if(winMeshes[0].material.name === 'greenBoxMaterial')
        {
            winMeshes.forEach(x=>x.material = x._scene.getMaterialByName('Window2dMat'));

            this.createEntity.setEnable(this.instance,false)
            const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
            this.createEntity.setEnable(this.instance,true)

            const pickEnts = pickedMeshs.filter(x=>x.entity)
                            .filter(x=>x.entity?.moduleEntity)
                            .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                            .map(x=>x.entity);

            const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
            const moduleEnt = wallEntity.moduleEntity;
            const roots = moduleEnt.getAllRoots();
            winEnt.moduleEntity = moduleEnt;
            winEnt.parent = wallEntity;
        
            for(let m of winEnt.getDrawMeshes())
            {
                const oriPos = m.getAbsolutePosition();
                m.parent = roots.find(x=>x._scene === m._scene);
                m.setAbsolutePosition(oriPos)
                m.rotation.y += CsgHelper.getParentRotation(m);
                m.setEnabled(true)
                if(wallEntity.getWorldWallType().type === 'Vertical')
                {
                    m.scaling.x = roots[0].scaling.z;
                    m.scaling.y = roots[0].scaling.x;
                }
                else
                {
                    m.scaling.y = roots[0].scaling.z;
                    m.scaling.x = roots[0].scaling.x;
                }
                if(m.info.ctgrName === 'Box')
                {
                    m.position.y = 2.2;
                    m.setEnabled(true)
                    m.isVisible = true;
                }
            }
            winEnt.info.parentName = wallEntity.info.name;
            winEnt.drawMeshToViewMeshTrasfrom();
            winEnt.createCrushMesh();
            winEnt.viewMeshes.forEach(x=>x.setEnabled(true))
            moduleEnt.Windows.push(winEnt)
            moduleEnt.Entities.push(winEnt)
            moduleEnt.setPenetrateMode(otherScene.floor)
            this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(this.createEntity,SaveHelper.makeSaveData(moduleEnt)));
            
            this.createEntity = null;
            otherScene.createEntity = null;
        }
        else
        {
            return false;
        }
        
        return true;
    }

    doorMouseMoveEvent(pos)
    {
        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        this.createEntity.setEnable(this.instance,true)
        pos.y += 3.2;
        this.createEntity.setPosition(this.instance,pos)
        this.createEntity.setPosition(otherScene.instance,pos)

        const doorName = (this.createEntity.info.ctgrName+this.createEntity.info.typeName.replace(/[0-9]/g,""))
        const greenBoxMat = this.instance.getMaterialByName(doorName)?.clone();
        greenBoxMat.name = 'greenDoorBoxMaterial';
        greenBoxMat.diffuseColor =  new BABYLON.Color3(0.35,0.855,0.35);
        const redBoxMat = this.instance.getMaterialByName(doorName).clone();
        redBoxMat.name = 'redDoorBoxMaterial'
        redBoxMat.diffuseColor =  new BABYLON.Color3(0.855,0.35,0.35);
        const mesh1f = this.createEntity.drawMeshes1f.find(x=>x.info.ctgrName!= 'Box')
        const mesh2f = this.createEntity.drawMeshes2f.find(x=>x.info.ctgrName!= 'Box')

        this.createEntity.setEnable(this.instance,false)
        const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
        this.createEntity.setEnable(this.instance,true)

        const pickEnts = pickedMeshs.filter(x=>x.entity)
                        .filter(x=>x.entity?.moduleEntity)
                        .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                        .map(x=>x.entity);

        const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
        const wallMesh = wallEntity?.getDrawMeshes().find(x=>x._scene === this.instance);

        if(wallMesh)
        {
            if(!wallMesh?.entity?.moduleEntity?.floorPenetrate)
            {
                return;
            }
            if(wallMesh.entity.moduleEntity.floorPenetrate === this.floor)
            {
                return;
            }
            const dashLine = wallEntity.createCenterLine(this.instance);
            this.snapLines.push(dashLine);

            mesh2f.material = greenBoxMat;
            mesh1f.material = greenBoxMat;
            const wallType = wallEntity.getWorldWallType();
            const wallPos = wallMesh.getAbsolutePosition();
            const wallthk = wallEntity.getWallThickness();
            if(wallType.type == 'Vertical')
            {
                for(let m of this.createEntity.getDrawMeshes())
                {
                    const mbbox = m.getBoundingInfo().boundingBox;
                    const msize = mbbox.extendSize.multiply(new Vector3(2,2,2));
   
                    m.rotation.y = Math.PI /2;
                    m.position.x = wallPos.x - (msize.y/2 - wallthk/2)*this.doorDir;
                    if(this.doorDir === -1)
                    {
                        m.rotation.y += Math.PI;
                    }
                }
            }
            else
            {
                for(let m of this.createEntity.getDrawMeshes())
                {
                    const mbbox = m.getBoundingInfo().boundingBox;
                    const msize = mbbox.extendSize.multiply(new Vector3(2,2,2));
   
                    m.rotation.y = 0;
                    m.position.z = wallPos.z - (msize.y/2 - wallthk/2)*this.doorDir;
                    if(this.doorDir === -1)
                    {
                        m.rotation.y += Math.PI;
                    }
                }
            }
        }
        else
        {
            mesh2f.material = redBoxMat;
            mesh1f.material = redBoxMat;
        }
    }

    doorMouseUpEvent()
    {
        const otherScene = this.viewer.$SceneLoader.drawScenes.find(x => x!=this);
        const doorEnt = this.createEntity;
        const doorMeshes = doorEnt.getDrawMeshes().filter(x=>x.info.ctgrName == 'Door');
        
        if(doorMeshes[0].material.name === 'greenDoorBoxMaterial')
        {
            const doorName = (this.createEntity.info.ctgrName+this.createEntity.info.typeName.replace(/[0-9]/g,""))
            doorMeshes.forEach(x=>x.material = x._scene.getMaterialByName(doorName));

            this.createEntity.setEnable(this.instance,false)
            const pickedMeshs =this.instance.multiPick(this.instance.pointerX, this.instance.pointerY).map(x=>x.pickedMesh);
            this.createEntity.setEnable(this.instance,true)

            const pickEnts = pickedMeshs.filter(x=>x.entity)
                            .filter(x=>x.entity?.moduleEntity)
                            .filter(x=>x.entity.moduleEntity.floorPenetrate != this.floor)
                            .map(x=>x.entity);

            const wallEntity = pickEnts.find(x=>x.info.ctgrName === 'Wall');
            const moduleEnt = wallEntity.moduleEntity;
            const roots = moduleEnt.getAllRoots();
            const isRot = Math.abs(Number(Math.sin(moduleEnt.viewRootMesh.rotation.y).toFixed(2)));
            doorEnt.moduleEntity = moduleEnt;
            doorEnt.parent = wallEntity;

            for(let m of doorEnt.getDrawMeshes())
            {
                const oriPos = m.getAbsolutePosition();
                m.parent = roots.find(x=>x._scene === m._scene);
                m.setAbsolutePosition(oriPos);
                m.position.y = 3.3;
                m.rotation.y += CsgHelper.getParentRotation(m);
                m.setEnabled(true)
                
                if(wallEntity.getWorldWallType().type === 'Vertical')
                {
                    m.scaling.x = roots[0].scaling.z;
                    m.scaling.y = roots[0].scaling.x;
                    if(isRot == 1)
                    {
                        m.scaling.x = roots[0].scaling.x*-1;
                        m.scaling.y = roots[0].scaling.z*-1;
                    }
                }
                else
                {
                    m.scaling.y = roots[0].scaling.z;
                    m.scaling.x = roots[0].scaling.x;
                    if(isRot == 1)
                    {
                        m.scaling.y = roots[0].scaling.x*-1;
                        m.scaling.x = roots[0].scaling.z*-1;
                    }
                }
                if(m.info.ctgrName === 'Box')
                {
                    m.position.y = 2.2;
                }
            }
            doorEnt.info.parentName = wallEntity.info.name;
            doorEnt.drawMeshToViewMeshTrasfrom();
            doorEnt.createCrushMesh();
            doorEnt.viewMeshes.forEach(x=>x.setEnabled(true))
            moduleEnt.Doors.push(doorEnt)
            moduleEnt.Entities.push(doorEnt)
            moduleEnt.setPenetrateMode(otherScene.floor)
            this.viewer.$SceneLoader.makeHistory(new HistoryCommand.CreateCommand(this.createEntity,SaveHelper.makeSaveData(moduleEnt)));
            
            this.createEntity = null;
            otherScene.createEntity = null;
        }
        else
        {
            return false;
        }
        return true;
    }
}