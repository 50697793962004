<template>
    <div id="joinStep2_Wrapper">
        <div id="joinStep2_Body">
            <section id="formArea" class="joinStep2_Container">
                <hgroup>
                    <h1>회원가입</h1>
                    <p>
                        자이가이스트의 회원이 되면<br>
                        보다 나은 서비스를 이용하실 수 있습니다.
                    </p>
                </hgroup>
        
                <ol>
                    <li class="on">
                        <img src="../../assets/images/icons/icon_checked.png" alt="">
                        <span>약관동의</span>
                    </li>
        
                    <li class="bar"></li>
                    <li class="on">
                        <span class="num" style=" color:rgba(28,26,27,1);">02</span>
                        <span>정보입력</span>
                    </li>
                    <li class="bar"></li>
        
                    <li class="on bgGray">
                        <span class="num" style=" color:rgba(204,204,204,1);">03</span>
                        <span>가입완료</span>
                    </li>
                </ol>
    
                <form action="" id="joinForm">
                    <label for="" class="mustReg hasBtn">
                        <span>
                            <i>아이디</i>
                        </span>
    
                        <input type="text" id="userLoginId" name="userLoginId" placeholder="아이디 입력(영문 / 숫자 6-12자 이내)">
                        <!-- <button class="sizeM style01" tyle="button">중복확인</button> -->
                    </label>
    
                    <label for="" class="mustReg hasBtn">
                        <span>
                            <i>비밀번호</i>
                        </span>
    
                        <input type="password" id="userPwd" name="userPwd" placeholder="아이디 입력(8자 이상 문자, 숫자, 특수문자 사용)" autocomplete="off">
                    </label>
    
                    <label for="confirnPwd" class="mustReg hasBtn">
                        <span>
                            <i>비밀번호 확인</i>
                        </span>
    
                        <input type="text" id="confirnPwd" name="confirnPwd" placeholder="비밀번호 입력(8자 이상 문자, 숫자, 특수문자 사용)" autocomplete="off">
                    </label>
    
                    <label for="userName" class="mustReg hasBtn">
                        <span>
                            <i>이름</i>
                        </span>
    
                        <div id="nameInput">
                            <input type="text" id="userName" name="userName" placeholder="이름 입력" value="">
                        </div>
                    </label>
    
    
                    <label class="mustReg" for="userPhone">
                        <span>
                            <i>휴대폰번호</i>
                        </span>
                        <input type="tel" id="userPhone" placeholder="휴대폰번호 입력('-'제외하고 입력)">
                    </label>
    
                    <label for="emailId">
                        <span>
                            <i>이메일</i>
                        </span>
                        
                        <div id="emailInput">
                            <div>
                                <input type="text" id="emailID" name="emailId" title="이메일주소" placeholder="이메일 입력">
                                <strong>@</strong>
                                <input type="text" id="emailDomain" name="emailDomain" title="이메일 서비스 업체" readonly>
                            </div>
                            
    
                            <div class="selectRemake">
                                <select name="emailSelect" id="emailSelect">
                                    <option value="">선택하세요</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="gmail.com">gmail.com</option>
                                    <option value="daum.net">daum.net</option>
                                    <option value="hanmail.net">hanmail.net</option>
                                    <option value="nate.com">nate.com</option>
                                    <option value="hotmail.com">hotmail.com</option>
                                </select>
                                <a href="/" class="selectRemaker" title="이메일서비스업체 선택 옵션선택"></a>
                            </div>
                        </div>
                    </label>
    
                    <div class="formBtnArea">
                        <button type="button" class="sizeM style04">취소</button>
                        <button type="button" class="sizeM style03">확인</button>
                    </div>
                    
                </form>
            </section>
        </div>

        <bottomInfo />
        <Footer />
    </div>
</template>

<style scoped>
    @import url('../../assets/css/LogIn/joinStep2Simple.css');
</style>

<script>
    import Footer from '../Login/joinStep1Footer.vue';
    import bottomInfo from '../Login/bottomInfo.vue'
    export default {
        
        components: {
            bottomInfo : bottomInfo,
            Footer : Footer
        }
    }
</script>