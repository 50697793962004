<template>
    <div class="scrollToTop_Container" v-if="showScrollBtn">
        <button class="topBtn" @click="moveToTop">
            <img src="" alt="">
        </button>
    </div>
</template>

<style>
.scrollToTop_Container {
    position:fixed;
    bottom: 30px;
    right: 30px;
}
.scrollToTop_Container .topBtn {
    overflow: hidden;
    border-radius: 50%;
    width: 54px;
    height: 54px;
    background: url('../../assets/images/icons/button_top02.png');
    background-position: center top;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: rgba(28,26,27,1);
    border: 0;
}
.scrollToTop_Container .topBtn:hover {
    background: url('../../assets/images/icons/button_top02.png');
    background-position:center bottom;
    background-repeat: no-repeat;
    z-index: 999;
    background-color:#fff; 
    box-shadow: 0 0 6px rgba(0,0,0,.5);
}
</style>

<script>
export default {
    data() {
        return {
            showScrollBtn:false
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        moveToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
        handleScroll() {
            this.showScrollBtn = window.scrollY > 80;
        }
    }
}
</script>