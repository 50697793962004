export default class MeshHelper
{
    static GetBottomEdges(mesh)
    {
        var isCreatedEdgesRenderer = true;
        var renderer = mesh.edgesRenderer;
        if(!renderer)
        {
            mesh.enableEdgesRendering();
            mesh.edgesWidth = 0;
            isCreatedEdgesRenderer = false;
            renderer = mesh.edgesRenderer;
        }

        var edges = [];
        for (let i = 0; i < renderer.linesPositions.length; i+=3*4) {
            const e = new BABYLON.Vector3(
                renderer.linesPositions[i],
                renderer.linesPositions[i+1],
                renderer.linesPositions[i+2]
            )
            const e2 = new BABYLON.Vector3(
                renderer.linesPositions[i+6],
                renderer.linesPositions[i+6+1],
                renderer.linesPositions[i+6+2]
            )

            edges.push({start:e, end:e2});
        }

        edges = edges.filter(o => Math.abs(o.start.y - o.end.y) < 0.0001); // 수평 Edge 제거

        if(edges.length <= 0)
            return null;

        var minZ = edges.reduce((prev, curr) => prev.start.y < curr.start.y ? prev : curr).start.y;
        edges = edges.filter(o => Math.abs(o.start.y - minZ) < 0.0001);

        if(isCreatedEdgesRenderer)
            mesh.disableEdgesRendering();

        var sortedEdges = [];
        var checkEdge = edges.pop();
        while(edges.length > 0)
        {
            var nextEdge = edges.find(o => o.start.equalsWithEpsilon(checkEdge.end, 0.001) || o.end.equalsWithEpsilon(checkEdge.end, 0.001));
            if(!nextEdge)
                break;
            
            sortedEdges.push(checkEdge);
            var nextEdgeIndex = edges.indexOf(nextEdge);

            if(nextEdge.end.equalsWithEpsilon(checkEdge.end, 0.001))
                checkEdge = {start:nextEdge.end, end:nextEdge.start};
            else
                checkEdge = nextEdge;

            edges.splice(nextEdgeIndex, 1);
        }
        sortedEdges.push(checkEdge);

        return sortedEdges;
    }

    static GetWorldBottomEdges(mesh)
    {
        var isCreatedEdgesRenderer = true;
        var renderer = mesh.edgesRenderer;
        if(!renderer)
        {
            mesh.enableEdgesRendering();
            mesh.edgesWidth = 0;
            isCreatedEdgesRenderer = false;
            renderer = mesh.edgesRenderer;
        }

        var edges = [];
        for (let i = 0; i < renderer.linesPositions.length; i+=3*4) {
            const e = new BABYLON.Vector3(
                renderer.linesPositions[i],
                renderer.linesPositions[i+1],
                renderer.linesPositions[i+2]
            )
            const e2 = new BABYLON.Vector3(
                renderer.linesPositions[i+6],
                renderer.linesPositions[i+6+1],
                renderer.linesPositions[i+6+2]
            )

            edges.push({start:e, end:e2});
        }

        edges = edges.filter(o => Math.abs(o.start.y - o.end.y) < 0.0001); // 수평 Edge 제거

        if(edges.length <= 0)
            return null;

        var minZ = edges.reduce((prev, curr) => prev.start.y < curr.start.y ? prev : curr).start.y;
        edges = edges.filter(o => Math.abs(o.start.y - minZ) < 0.0001);

        var parentMatrix = mesh.parent.getWorldMatrix();
        var matrix = BABYLON.Matrix.Scaling(mesh.parent.scaling.x, mesh.parent.scaling.y, mesh.parent.scaling.z).multiply(BABYLON.Matrix.RotationAxis(BABYLON.Axis.Y, mesh.parent.rotation.y));
        var meshPos = BABYLON.Vector3.TransformCoordinates(mesh.position, matrix);
        edges = edges.map(o => ({start:BABYLON.Vector3.TransformCoordinates(o.start, parentMatrix).add(meshPos), end:BABYLON.Vector3.TransformCoordinates(o.end, parentMatrix).add(meshPos)}));

        if(!isCreatedEdgesRenderer)
        {
            mesh.disableEdgesRendering();   
        }

        var sortedEdges = [];
        var checkEdge = edges.pop();
        while(edges.length > 0)
        {
            var nextEdge = edges.find(o => o.start.equalsWithEpsilon(checkEdge.end, 0.001) || o.end.equalsWithEpsilon(checkEdge.end, 0.001));
            if(!nextEdge)
                break;
            
            sortedEdges.push(checkEdge);
            var nextEdgeIndex = edges.indexOf(nextEdge);

            if(nextEdge.end.equalsWithEpsilon(checkEdge.end, 0.001))
                checkEdge = {start:nextEdge.end, end:nextEdge.start};
            else
                checkEdge = nextEdge;

            edges.splice(nextEdgeIndex, 1);
        }
        sortedEdges.push(checkEdge);

        return sortedEdges;
    }

    static GetClockwiseVertices(vertices)
    {
        var maxX = Math.max(...vertices.map(o => o.x));
        var maxXVertices = vertices.filter(o => Math.abs(o.x - maxX) < 0.001);
        var maxXminZ = Math.min(...maxXVertices.map(o => o.z));
        var checkVertex = maxXVertices.find(o => Math.abs(o.z - maxXminZ) < 0.001);

        var index = vertices.indexOf(checkVertex);
        var nextIndex = index + 1 >= vertices.length ? 0 : index + 1;
        var nextVertex = vertices[nextIndex];

        var dir = BABYLON.Vector3.Normalize(nextVertex.subtract(checkVertex));

        if(dir.x < 0 || dir.z < 0)
            return vertices.reverse();
        else
            return vertices;
    }
}