import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            selectedLink:''
        };
    },
    mutations: {
        setSelectedLink(state, link) {
            state.SelectedLink = link;
        }
    },
    actions: {
        setSelectedLink({ commit }, link) {
            commit('setSelectedLink', link);
        }
    },
    getters: {
        SelectedLink:state => state.SelectedLink
    }
});

export default store;