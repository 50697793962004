import ContextItemBase from "./ContextItemBase";
import { Vector3 } from "@babylonjs/core";
import FloorEntity from "./FloorEntity";
import CsgHelper from "../Helper/CsgHelper"
import WallEntity from "../Entities/WallEntity"
import DoorEntity from "../Entities/DoorEntity"
import StairsEntity from "../Entities/StairsEntity"
import Enumerable from 'linq'
import TopSpotItem from './../Items/TopSpotItem';
import * as HistoryCommand from "../Entities/historyCommands"
import SaveHelper from "../Helper/SaveHelper";

export default class UnitEntity extends ContextItemBase {
    constructor()
    {
        super();
        this.drawMeshes1f = [];
        this.drawMeshes2f = [];
        this.viewMeshes = [];

        this.drawScene1f = null;
        this.drawScene2f = null;
        this.viewScene = null;

        this.viewRootMesh = null;
        this.draw1fRootMesh = null;
        this.draw2fRootMesh = null;

        this.isGhost = true;
        this.floorPenetrate = '1F'
        this.moduleEntity = null;
        this.enableDoor = 0;
        this.isHistoryWork = false;

        this.wallEnt = [];
        this.doorEnt = [];
        this.stairEnt = [];
    }

    getSize()
    {
        const bb = this.viewMeshes.find(x=>x.info?.ctgrName === 'Floor').getHierarchyBoundingVectors()
        const size = bb.max.subtract(bb.min)
        return size;
    }

    updateDoorEnable()
    {
        const targetEnt = this.doorEnt.filter(x=>x.info.typeName.includes('Swap'));
        if(targetEnt.length < 1)
        {
            return;
        }

        targetEnt.forEach(dEnt=>{
            dEnt.setEnable(this.drawScene1f.instance,false);
            dEnt.setEnable(this.drawScene2f.instance,false);
            dEnt.setEnable(this.viewScene.instance,false);
        })

        if(this.doorEnt.length > 0)
        {
            targetEnt[this.enableDoor].setEnable(this.drawScene1f.instance,true);
            targetEnt[this.enableDoor].setEnable(this.drawScene2f.instance,true);
            targetEnt[this.enableDoor].setEnable(this.viewScene.instance,true);
        }
    }

    createRoot()
    {
        const unitBox1f = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene1f.instance);
        unitBox1f.isVisible = false;
        unitBox1f.entity = this;
        unitBox1f.setEnabled(false)
        const unitBox2f = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene2f.instance);
        unitBox2f.isVisible = false;
        unitBox2f.entity = this;
        unitBox2f.setEnabled(false)

        this.draw1fRootMesh = unitBox1f;
        this.draw2fRootMesh = unitBox2f;
    }

    async createEntity()
    {
        const walls = this.viewMeshes.filter(x=>x.info?.ctgrName === 'Wall');
        const doors = this.viewMeshes.filter(x=>x.info?.ctgrName === 'Door');
        const stairs = this.viewMeshes.filter(x=>x.info?.ctgrName === 'Stair');
        
        walls.forEach(w=>{
            const ent = new WallEntity();
            ent.info = w.info;
            ent.viewMeshes.push(w);
            ent.viewScene = this.viewScene;
            ent.drawScene1f = this.drawScene1f;
            ent.drawScene2f = this.drawScene2f;
            ent.moduleEntity = this.moduleEntity;
            ent.setWallType();
            ent.drawViewMesh();

            ent.drawMeshes1f.forEach(x=>{
                x.parent = this.draw1fRootMesh;
                x.entity = ent;
                x.info = w.info;
                x.isVisible = true;
            });
            ent.drawMeshes2f.forEach(x=>{
                x.parent = this.draw2fRootMesh;
                x.entity = ent;
                x.info = w.info;
                x.isVisible = true;
            });

            this.drawMeshes1f = this.drawMeshes1f.concat(ent.drawMeshes1f); 
            this.drawMeshes2f = this.drawMeshes2f.concat(ent.drawMeshes2f); 
            w.entity = ent;

            this.wallEnt.push(ent);
        });
        for(let i = 0; i<doors.length; i++)
        {
            const ent = new DoorEntity();
            const box = this.viewMeshes.find(x=>x.info.parentName === doors[i].info.name);
            ent.info = doors[i].info;
            ent.viewMeshes.push(doors[i]);
            ent.viewMeshes.push(box);
            ent.viewScene = this.viewScene;
            ent.drawScene1f = this.drawScene1f;
            ent.drawScene2f = this.drawScene2f;
            ent.moduleEntity = this.moduleEntity;
            doors[i].entity = ent;
            const parent = this.wallEnt.find(x=>x.info.name === ent.info.parentName);
            ent.parent = parent;
            
            await ent.drawViewMesh();
            ent.drawMeshes1f.forEach(x=>{
                x.parent = this.draw1fRootMesh
                x.entity = this;
            });
            ent.drawMeshes2f.forEach(x=>{
                x.parent = this.draw2fRootMesh
                x.entity = this;
            });

            ent.createCrushMesh(this);
            this.drawMeshes1f = this.drawMeshes1f.concat(ent.drawMeshes1f); 
            this.drawMeshes2f = this.drawMeshes2f.concat(ent.drawMeshes2f); 
            
            this.doorEnt.push(ent);
        }
        stairs.forEach(s=>{
            const ent = new StairsEntity();
            const boxs = this.viewMeshes.filter(x=>x.info.parentName === s.info.name);
            ent.info = s.info;
            ent.viewMeshes.push(s);
            ent.viewMeshes = ent.viewMeshes.concat(boxs);
            ent.viewScene = this.viewScene;
            ent.drawScene1f = this.drawScene1f;
            ent.drawScene2f = this.drawScene2f;
            ent.moduleEntity = this.moduleEntity;
            s.entity = ent;

            ent.drawViewMesh();
            ent.drawMeshes1f.forEach(x=>x.parent = this.draw1fRootMesh);
            ent.drawMeshes2f.forEach(x=>x.parent = this.draw2fRootMesh);
            ent.drawMeshes1f.forEach(x=>x.entity = this);
            ent.drawMeshes2f.forEach(x=>x.entity = this);

            ent.createCrushMesh();
            this.drawMeshes1f = this.drawMeshes1f.concat(ent.drawMeshes1f); 
            this.drawMeshes2f = this.drawMeshes2f.concat(ent.drawMeshes2f); 

            this.stairEnt.push(ent);
        })
        this.updateDoorEnable()

    }

    // minx, minz, maxx, maxz, center로만 판별
    snapEntities(sceneEntity, prevPos, x, z, modules)
    {
        var filteredModules = modules.filter(o => o.locatedScene == sceneEntity);
        var scene = sceneEntity;
        
        var boxes = [];
        const floors = this.viewMeshes.filter(x=>x.info?.ctgrName === 'Box' && x.info?.parentName.includes('Unit-'));
        floors.forEach(o =>
        {
            var box = o.getBoundingInfo().boundingBox;
            boxes.push(new BABYLON.BoundingBox(new BABYLON.Vector3(x - box.extendSizeWorld.x, 0, z - box.extendSizeWorld.z), new BABYLON.Vector3(x + box.extendSizeWorld.x, 0, z + box.extendSizeWorld.z)));
        })

        return this.getSnapData(boxes, filteredModules.flatMap(o => o.Entities), sceneEntity, prevPos, x, z);
    }

    getContextEntity()
    {
        return this;
    }

    getSceneMeshes(scene)
    {
        if(scene.is2d)
        {
            if(scene.floor === "1F")
            {
                return this.drawMeshes1f;
            }
            else
            {
                return this.drawMeshes2f;
            }
        }
        else
        {
            return this.viewMeshes;
        }
    }

    getButtonData()
    {
        if(this.doorEnt.filter(x=>x.info.typeName.includes('Swap')).length > 1)
        {
            return [
                {
                    type:'module',
                    press:this.selectModule
                },{
                    type:'rotate',
                    press: this.rotate
                },{
                    type:'rotate-ccw',
                    press:this.rotateccw
                },{
                    type:'mirror-h',
                    press:this.mirrorh
                },{
                    type:'mirror-v',
                    press:this.mirrorv
                },{
                    type:'door-swap',
                    press:this.doorSwap
                },{
                    type:'delete',
                    press:this.delete
                },
            ];
        }
        else
        {
            return [
                {
                    type:'module',
                    press:this.selectModule
                },{
                    type:'rotate',
                    press: this.rotate
                },{
                    type:'rotate-ccw',
                    press:this.rotateccw
                },{
                    type:'mirror-h',
                    press:this.mirrorh
                },{
                    type:'mirror-v',
                    press:this.mirrorv
                },{
                    type:'delete',
                    press:this.delete
                },
            ];
        }
    }

    createContextPanel(scene)
    {
        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        this.contextPanels.push(panels.toolbar);

        var box = this.getBoundingBox2d(scene);
        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    delete(scene, entity)
    {
        scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.DelteCommand(entity,SaveHelper.makeSaveData(entity.moduleEntity)));
        entity.dispose();
    }

    rotate(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.RotateCommand(entity));
        let rotation = entity.draw1fRootMesh.rotation.y;
        entity.draw1fRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(-90);
        entity.draw2fRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(-90);
        entity.viewRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(-90);
        
        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    rotateccw(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.RotateccwCommand(entity));
        let rotation = entity.draw1fRootMesh.rotation.y;
        entity.draw1fRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(90);
        entity.draw2fRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(90);
        entity.viewRootMesh.rotation.y = rotation + BABYLON.Tools.ToRadians(90);
        
        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    mirrorv(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorvCommand(entity));
        entity.draw1fRootMesh.scaling.z *= -1;
        entity.draw2fRootMesh.scaling.z *= -1;
        entity.viewRootMesh.scaling.z *= -1;
        
        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    mirrorh(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorhCommand(entity));
        entity.draw1fRootMesh.scaling.x *= -1;
        entity.draw2fRootMesh.scaling.x *= -1;
        entity.viewRootMesh.scaling.x *= -1;
        
        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    doorSwap(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.DoorSwapCommand(entity));
        const targetEnt = entity.doorEnt.filter(x=>x.info.typeName.includes('Swap'));
        if(targetEnt.length > 1)
        {
            entity.enableDoor = (entity.enableDoor+1)%(targetEnt.length)
        }
        entity.updateDoorEnable();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    drawViewMesh(){
        this.viewMeshes.forEach(mesh =>{
            if(mesh.info.ctgrName === 'Wall' || mesh.info.ctgrName === 'Door' || mesh.info.parentName.includes('Door') || mesh.info.ctgrName === 'Stair')
            {
                return;
            }
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            
            m1f.info = mesh.info;
            m2f.info = mesh.info;

            m1f.entity = this;
            m2f.entity = this;

            m1f.parent = this.draw1fRootMesh;
            m2f.parent = this.draw2fRootMesh;

            if(mesh.material.diffuseTexture)
            {
                const matName = mesh.material.diffuseTexture.name.split('/').slice(-1)[0];

                const mat1f = this.drawScene1f.instance.getMaterialByName(matName);
                if(!mat1f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene1f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene1f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m1f.material = oriMat;
                }
                else
                {
                    m1f.material = mat1f;
                }

                const mat2f = this.drawScene2f.instance.getMaterialByName(matName);
                if(!mat2f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene2f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene2f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m2f.material = oriMat;
                }
                else
                {
                    m2f.material = mat2f;
                }
            }

            if(mesh.info.ctgrName === 'Corner')
            {
                m1f.material = this.drawScene1f.instance.getMaterialByName('WallMat');
                m2f.material = this.drawScene2f.instance.getMaterialByName('WallMat');
            }

            if(mesh.info.ctgrName === 'Box')
            {
                m1f.isVisible = false;
                m2f.isVisible = false;
            }

            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);
        });
    }

    setPosition(instance,value)
    {
        if(this.isGhost)
        {
            if(instance === this.drawScene1f.instance)
            {
                var pos1f = value.clone();
                pos1f.y = 3.1;
                this.draw1fRootMesh.setAbsolutePosition(pos1f);
            }
            else if(instance === this.drawScene2f.instance)
            {
                var pos2f = value.clone();
                pos2f.y = 3.1;
                this.draw2fRootMesh.setAbsolutePosition(pos2f);
            }
        }
        else
        {
            var pos1f = value.clone();
            pos1f.y = this.draw1fRootMesh.absolutePosition.y;
            this.draw1fRootMesh.setAbsolutePosition(pos1f);
            var pos2f = value.clone();
            pos2f.y = this.draw2fRootMesh.absolutePosition.y;
            this.draw2fRootMesh.setAbsolutePosition(pos2f);
        }
        value.y = instance === this.drawScene1f.instance ? 0 : 3.1;
        this.viewRootMesh.setAbsolutePosition(value.clone());
    }

    setEnable(instance,value)
    {
        if(instance === this.drawScene1f.instance)
        {
            this.draw1fRootMesh.setEnabled(value);
        }
        else if(instance === this.drawScene2f.instance)
        {
            this.draw2fRootMesh.setEnabled(value);
        }
        else
        {
            this.viewRootMesh.setEnabled(value);
        }
    }

    createCrushMesh()
    {
        if(this.crushMesh)
        {
            return;
        }
        const crushMesh = this.viewMeshes.find(x=>x.info.ctgrName === "Box" && x.info.parentName.replace('-','').includes(this.info.name))?.clone();
        if(!crushMesh)
            return;
        crushMesh.material = this.viewScene.instance.getMaterialByName("redBoxMaterial");
        this.crushMeshSetting(crushMesh,this.viewRootMesh);
        this.crushMesh = crushMesh;

        if(this.crushMesh1f)
        {
            return;
        }
        const crushMesh1f = this.drawMeshes1f.find(x=>x.info.ctgrName === "Box" && x.info.parentName.replace('-','').includes(this.info.name))?.clone();
        if(!crushMesh1f)
            return;
        crushMesh1f.material = this.drawScene1f.instance.getMaterialByName("redBoxMaterial");
        this.crushMeshSetting(crushMesh1f,this.draw1fRootMesh);
        this.crushMesh1f = crushMesh1f;

        if(this.crushMesh2f)
        {
            return;
        }
        const crushMesh2f = this.drawMeshes2f.find(x=>x.info.ctgrName === "Box" && x.info.parentName.replace('-','').includes(this.info.name))?.clone();
        if(!crushMesh2f)
            return;
        crushMesh2f.material = this.drawScene2f.instance.getMaterialByName("redBoxMaterial");
        this.crushMeshSetting(crushMesh2f,this.draw2fRootMesh);
        this.crushMesh2f = crushMesh2f;
    }

    crushMeshSetting(crushMesh,rootMesh)
    {
        crushMesh.parent = null;
        crushMesh.id = "crushMeshTop";
        crushMesh.originPos = crushMesh.position.clone();
        crushMesh.moduleEntity = this.moduleEntity;
        crushMesh.rootMesh = rootMesh;
        crushMesh.parent = rootMesh;
        crushMesh.entity = this;
        crushMesh.isVisible = false;
        crushMesh.setEnabled(true);
        crushMesh.spot = new TopSpotItem(crushMesh, crushMesh._scene,null, rootMesh != this.moduleEntity.viewRootMesh)
        crushMesh.spot.isOtherThrough = false;
    }


    setPenetrateMode(floor)
    {
        if(floor === '2F')
        {
            this.draw2fRootMesh.setEnabled(true);
            this.draw2fRootMesh.position = this.draw1fRootMesh.position.clone();
            this.draw2fRootMesh.position.y = 0;
        }
        else
        {
            this.draw1fRootMesh.setEnabled(true);
            this.draw1fRootMesh.position = this.draw2fRootMesh.position.clone();
            this.draw1fRootMesh.position.y = 0;
        }

        let meshes = this.drawMeshes1f;
        if(floor === '2F')
        {
            meshes = this.drawMeshes2f;
        }
        meshes.forEach(mesh =>{
            if(mesh.info.ctgrName != 'Wall' && mesh.info.ctgrName != 'Door' && mesh.info.ctgrName != 'Stair')
            {
                mesh.isVisible = false;
            }
            if(mesh.material)
            {
                const tempmat = mesh.material.clone();
                tempmat.alpha = 0.4;
                mesh.material = tempmat;
                mesh.edgesColor = new BABYLON.Color4(0, 0, 0, 0.4);
            }
        })
    }

    dispose()
    {
        if(this.crushMesh)
        {
            this.crushMesh.spot.delete();
            this.crushMesh.dispose();
        }
        if(this.crushMesh1f)
        {
            this.crushMesh1f.spot.delete();
            this.crushMesh1f.dispose();
        }
        if(this.crushMesh2f)
        {
            this.crushMesh2f.spot.delete();
            this.crushMesh2f.dispose();
        }

        this.doorEnt.forEach(o=>o.dispose());
        this.stairEnt.forEach(o=>o.dispose());
        
        this.viewRootMesh?.dispose();
        this.draw1fRootMesh?.dispose();
        this.draw2fRootMesh?.dispose();

        if(this.moduleEntity)
        {
            this.moduleEntity.Entities = this.moduleEntity.Entities.filter(x => x != this);
            this.moduleEntity.Units = this.moduleEntity.Units.filter(x => x != this);
        }
    }

    getBoundingBox2d(scene)
    {
        var boundingBox = null;
        if(scene == null)
        {
            var meshes = this.viewMeshes;
        }
        else
        {
            if(scene.floor == "1F")
                var meshes = this.drawMeshes1f;
            else
                var meshes = this.drawMeshes2f;
        }

        meshes.forEach(mesh => {
            const box = mesh.getBoundingInfo().boundingBox;

            var minimumWorld = box.minimumWorld;
            var maximumWorld = box.maximumWorld;
            minimumWorld.y = 0;
            maximumWorld.y = 0;

            if(boundingBox != null)
            {
                var newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, minimumWorld);
                var newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, maximumWorld);
    
                boundingBox.reConstruct(newMin, newMax);
            }
            else
                boundingBox = new BABYLON.BoundingBox(minimumWorld, maximumWorld);
        });
        return boundingBox;
    }

    getOriginBoundingBox2d(scene)
    {
        var boundingBox = null;
        if(scene.floor == "1F")
            var meshes = this.drawMeshes1f;
        else
            var meshes = this.drawMeshes2f;

        meshes.forEach(mesh => {
            const box = mesh.getBoundingInfo().boundingBox;

            var minimum = box.minimum.add(mesh.position);
            var maximum = box.maximum.add(mesh.position);
            minimum.y = 0;
            maximum.y = 0;

            if(boundingBox != null)
            {
                var newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, minimum);
                var newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, maximum);
    
                boundingBox.reConstruct(newMin, newMax);
            }
            else
                boundingBox = new BABYLON.BoundingBox(minimum, maximum);
        });
        return boundingBox;
    }

    getSceneRootMesh(scene)
    {
        if(scene.is2d)
        {
            if(scene.floor === "1F")
            {
                return this.draw1fRootMesh;
            }
            else
            {
                return this.draw2fRootMesh;
            }
        }
        else
        {
            return this.viewRootMesh;
        }
    }

    getAll2DRootMeshes()
    {
        return this.getDrawMeshes();
    }
    getAll3DRootMeshes()
    {
        return [...this.viewMeshes];
    }

    getDrawMeshes()
    {
        return this.drawMeshes1f.concat(this.drawMeshes2f);
    }

    deleteEntity(scene, entity)
    {
        entity.moduleEntity.deleteChild(scene, entity);
        entity.moduleEntity.isFixed = true;
        entity.dispose();
    }

    translateEntity(vector, value)
    {
        this.viewMeshes.concat(this.drawMeshes1f).concat(this.drawMeshes2f).forEach(mesh =>
        {
            mesh.setAbsolutePosition(mesh.absolutePosition.add(new BABYLON.Vector3(value, value, value).multiply(vector)));
        });

        this.crushMesh.position = this.viewMeshes.find(x=>x.info.ctgrName === 'Box' && x.info.parentName.replace('-','').includes(this.info.name)).position.clone();
        this.crushMesh1f.position = this.drawMeshes1f.find(x=>x.info.ctgrName === 'Box' && x.info.parentName.replace('-','').includes(this.info.name)).position.clone();
        this.crushMesh2f.position = this.drawMeshes2f.find(x=>x.info.ctgrName === 'Box' && x.info.parentName.replace('-','').includes(this.info.name)).position.clone();
    
        for(let s of this.stairEnt)
        {
            for(let i = 0; i<s.crushMeshs.length; i++)
            {
                const pos = s.crushMeshs[i].absolutePosition.add(new BABYLON.Vector3(value, value, value).multiply(vector));
                s.crushMeshs[i].setAbsolutePosition(pos.clone());
                s.crushMesh1fs[i].setAbsolutePosition(pos.clone());
                s.crushMesh2fs[i].setAbsolutePosition(pos.clone());
            }
        }
    
    }
}