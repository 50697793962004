import EntityBase from "./EntityBase";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import * as Material from '@babylonjs/materials'
import CsgHelper from "../Helper/CsgHelper"

export default class FloorEntity extends EntityBase{
    constructor()
    {
        super();
    }

    getContextEntity()
    {
        return this.moduleEntity;
    }

    createRootMesh(targetMeshes,instance,color)
    {
        try
        {
            const meshes = [];
            let childs = [];

            let min = targetMeshes[0].getBoundingInfo().boundingBox.minimumWorld;
            let max = targetMeshes[0].getBoundingInfo().boundingBox.maximumWorld;
            
            targetMeshes.filter(x=>x.info.ctgrName != 'Box').forEach(o=>{
                const ch = o.getChildMeshes();
                ch.forEach(c=>c.setParent(null));

                meshes.push(o);
                childs = childs.concat(ch);

                let meshMin = o.getBoundingInfo().boundingBox.minimumWorld;
                let meshMax = o.getBoundingInfo().boundingBox.maximumWorld;

                min = BABYLON.Vector3.Minimize(min, meshMin);
                max = BABYLON.Vector3.Maximize(max, meshMax);
            });
    
            const mergeMesh = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);
            
            mergeMesh.info = this.info;
            mergeMesh.moduleEntity = this.moduleEntity;
            mergeMesh.parent = this.moduleEntity.viewRootMesh;

            const newInfo = new BABYLON.BoundingInfo(min, max);
            const newPos = newInfo.boundingBox.center.clone();
            const retPos = newPos.subtract(mergeMesh.parent.position);
            
            const bbox = mergeMesh.getBoundingInfo().boundingBox;
            const translateMatrix = BABYLON.Matrix.Translation(-bbox.center.x, -bbox.center.y, -bbox.center.z)
            mergeMesh.bakeTransformIntoVertices(translateMatrix);
            mergeMesh.position = retPos.clone();
            mergeMesh.alwaysSelectAsActiveMesh = true
            mergeMesh.entity = this;

            childs.forEach(c=>{
                c.setParent(mergeMesh);
            })

            if(instance === this.viewScene.instance)
            {
                this.viewMeshes = [];
                this.viewMeshes.push(mergeMesh)
            }
            else if(instance === this.drawScene1f.instance)
            {
                this.drawMeshes1f = [];
                this.drawMeshes1f.push(mergeMesh)
            }
            else
            {
                this.drawMeshes2f = [];
                this.drawMeshes2f.push(mergeMesh)
            }

            this.updateMaterial(mergeMesh,color);
            CsgHelper.bakeTransform(mergeMesh)
        }
        catch(err)
        {
            console.error(err);
        }
    }

    updateMaterial(mergeMesh,color)
    {
        const length = mergeMesh.material?.subMaterials?.length;
        if(isFinite(length)<1)
        {
            return;
        }
        for(let i=0; i<length; i++)
        {
            const mat = mergeMesh.material?.subMaterials[i]
            if(mat.diffuseTexture)
            {
                const name = mat.diffuseTexture.name.split('/').slice(-1)[0].split('.')[0];
                const madMat = mergeMesh._scene.getMaterialByName(name);
                if(madMat)
                {
                    mergeMesh.material.subMaterials[i] = madMat;
                }
                else
                {
                    const newMat = new Material.PBRCustomMaterial(`${name}`,mergeMesh._scene);
                    newMat.albedoColor =  new BABYLON.Color3(color,color,color);
                    newMat.metallic = 0.
                    newMat.roughness = 1.
                    newMat.specularColor = new BABYLON.Color3(0, 0, 0)
                    newMat.maxSimultaneousLights = 200;
                    newMat.specularPower = 0;
                    newMat.albedoTexture = mat.diffuseTexture;
                    mergeMesh.material.subMaterials[i] = newMat;
                    newMat.Fragment_Before_Fog(this.viewScene.viewer.$SceneLoader.materialLoader.getShaderCode(mergeMesh._scene));
                }
            }
        }
        mergeMesh.material.name = `floorMat${this.viewMeshes[0].uniqueId}`;

        if(!mergeMesh._scene.getMaterialByName(mergeMesh.material.name))
        {
            mergeMesh._scene.addMaterial(mergeMesh.material);
        }
    }

    dispose()
    {
        for(let m of this.drawMeshes1f)
        {
            m.dispose();
        }
        for(let m of this.drawMeshes2f)
        {
            m.dispose();
        }
        for(let m of this.viewMeshes)
        {
            m.dispose();
        }

        if(this.moduleEntity)
        {
            this.moduleEntity.Entities = this.moduleEntity.Entities.filter(x => x != this);
            this.moduleEntity.Floors = this.moduleEntity.Floors.filter(x => x != this);
        }
    }
}