<template>
    <p>자식컴포넌트</p>
    <button @click="sendDataToParent">데이터 전송</button>
</template>

<script>
export default {

    data() {
        return {
            param1: "",
            birth_temp: "",
            birth_year: "",
            birth_month: "",
            birth_date: "",
            birth: "",
            phone: "",
            key: ""
        }
    },
    mounted() {
        //this.testwindow(window.opener);
        this.param1 = this.$route.query.param1;
        var str = this.param1.split("-");
        console.log(this.param1);

        this.birth_temp = str[0];
        this.birth_year = this.birth_temp.substring(0, 4);
        this.birth_month = this.birth_temp.substring(4, 6);
        this.birth_date = this.birth_temp.substring(6, 8);
        this.birth = this.birth_year + '-' + this.birth_month + '-' + this.birth_date;

        this.phone = str[1];

        console.log(this.birth);
        console.log(this.phone);

        //name = decodeURIComponent(name_en);

        //console.log(this.key);

        // if (this.key == "join") { //회원가입시 본인인증이면
        //     alert("본인인증이 완료되었습니다");
        //     window.close();
        //     opener.location.href = 'http://localhost:8080/member/joinStep2';
        // } else if(this.key == "findEmail"){ //이메일 찾기일 때 본인인증이면7399
        //     //findEmailResult 페이지에 파라미터 보냄

        //     opener.location.href = 'http://localhost:8080/member/findEmailResult'
        //     this.$emit("setInput", this.phone);
        //     //window.close();
        // }

        //opener.location.href = 'http://localhost:8080/member/findEmailResult'
        //this.$emit("setInput", this.phone);

    },
    methods: {
        // testwindow(parentWindow) {
        //     if (parentWindow.location.href.includes("findEmail")) {
        //         this.key = "findEmail";
        //     } else if (parentWindow.location.href.includes("joinStep1")) {
        //         this.key = "join";
        //     }
        // }
        sendDataToParent() {
            // 이벤트를 발생시켜 부모 컴포넌트로 데이터 전달
            this.$emit('childEvent', '안녕하세요');
        }
    }

}
</script>