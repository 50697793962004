import { Vector3 } from "@babylonjs/core";
import OpeningEntity from "./OpeningEntity";
import CsgHelper from "../Helper/CsgHelper"

export default class TempOpningEntity{
    constructor()
    {
        this.info = {ctgrName : 'Opening'};
        this.d1fWall = null;
        this.d2fWall = null;
        this.viewWall = null;
        this.d1fPointer = null;
        this.d2fPointer = null;
        this.editPoint = null;
        this.startPoint = null;
        this.endPoint = null;
        this.status = false;
        this.targetModule = null;
        this.targetWall = null;
        this.length = 0;

        this.drawScene1f = null;
        this.drawScene2f = null;
        this.viewScene = null;
    }

    setStatus(value)
    {
        const sMat1 = this.d1fPointer._scene.getMaterialByName('WallPointMat');
        const fMat1 = this.d1fPointer._scene.getMaterialByName('WallRedPointMat');
        const sMat2 = this.d2fPointer._scene.getMaterialByName('WallPointMat');
        const fMat2 = this.d2fPointer._scene.getMaterialByName('WallRedPointMat');
        
        this.d1fPointer.material = value ? sMat1 : fMat1;
        this.d2fPointer.material = value ? sMat2 : fMat2;
        this.status = value;
    }
    
    setEnable(instance,value)
    {
        if(instance === this.d1fPointer._scene)
        {
            this.d1fPointer.setEnabled(value);
        }
        else
        {
            this.d2fPointer.setEnabled(value);
        }
    }

    setPosition(instance,pos)
    {
        if(instance === this.d1fPointer._scene)
        {
            this.d1fPointer.position = pos.clone();
        }
        else
        {
            this.d2fPointer.position = pos.clone();
        }
    }

    getInstancePointer(instance)
    {
        if(instance === this.d1fPointer._scene)
        {
            return this.d1fPointer;
        }
        else
        {
            return this.d2fPointer;
        }
    }

    createEntity()
    {
        const ent = new OpeningEntity();
        ent.drawScene1f = this.drawScene1f
        ent.drawScene2f = this.drawScene2f
        ent.viewScene = this.viewScene

        const viewIns = this.viewScene.instance;

        const viewMesh = this.createWallMesh(viewIns,'greenBoxMaterial');
        const viewBox = this.createWallMesh(viewIns,'blueBoxMaterial');

        const mInfos = this.targetModule.Openings.map(x=>x.info).filter(x=>x.typeName.includes('OpeningNew'));
        const lastNum = Math.max(...mInfos.map(x=>x.name.split('OpeningNew')[1]).filter(x=>x).map(x=>Number(x)));
        const itemNum = isFinite(lastNum) ? lastNum +1 : 0;
        const parentName = this.targetWall.info.ctgrName + '-' + this.targetWall.info.typeName;
        const info = {ctgrName:"Opening", flipType:null, name:"OpeningNew"+itemNum,typeName:"New"+itemNum, parentName:parentName};
        ent.info = info;
        ent.parent = this.targetWall.entity;
        ent.moduleEntity = this.targetModule;

        const boxInfo = {ctgrName:"Box", flipType:null, name:"Box",typeName:"Box", parentName:info.name};
        viewBox.info = boxInfo;
        viewMesh.info = info;
        viewMesh.entity = ent;

        const rot = this.targetModule.draw1fRootMesh.rotation.y;
        viewMesh.rotation.y -= rot;
        viewBox.rotation.y -= rot;

        viewMesh.position.y = 0.393+1.2835+this.targetModule.viewRootMesh.position.y;
        viewBox.position.y = 0.393+1.2835+this.targetModule.viewRootMesh.position.y;

        ent.viewMeshes.push(viewMesh)
        ent.viewMeshes.push(viewBox)

        // CsgHelper.initialMatrix(viewMesh)
        // CsgHelper.initialMatrix(viewBox)

        // CsgHelper.setParentSubtractLocation(viewMesh,this.targetModule.viewRootMesh);
        // CsgHelper.setParentSubtractLocation(viewBox,this.targetModule.viewRootMesh);

        const tempPos = viewMesh.getAbsolutePosition();

        viewMesh.parent = this.targetModule.viewRootMesh;
        viewBox.parent = this.targetModule.viewRootMesh;

        viewMesh.setAbsolutePosition(tempPos)
        viewBox.setAbsolutePosition(tempPos)

        viewMesh.position.y = 3.1/2;
        viewBox.position.y = 3.1/2;

        ent.moduleEntity = this.targetModule;
        this.targetModule.Entities.push(ent);
        this.targetModule.Openings.push(ent);

        

        ent.drawViewMesh();
        ent.drawMeshes1f.forEach(x=>{
            x.parent = this.targetModule.draw1fRootMesh;
            x.setAbsolutePosition(tempPos)
            x.position.y = tempPos.y <= 3.2 ? 6.4 : 3.2;
            x.rotation.y = viewMesh.rotation.y;
            // CsgHelper.initialMatrix(x)
            //CsgHelper.setParentSubtractLocation(x,this.targetModule.draw1fRootMesh);
            x.entity = ent;
        })
        ent.drawMeshes2f.forEach(x=>{
            x.parent = this.targetModule.draw2fRootMesh;
            x.setAbsolutePosition(tempPos)
            x.position.y = tempPos.y > 3.2 ? 6.4 : 3.2;
            x.rotation.y = viewMesh.rotation.y;
            // CsgHelper.initialMatrix(x)
            //CsgHelper.setParentSubtractLocation(x,this.targetModule.draw2fRootMesh);
            x.entity = ent;
        })

        ent.createCrushMesh();
        const boxPos = ent.viewMeshes.find(x=>x.info?.ctgrName == 'Box').position.clone();
        ent.crushMesh1f.position = boxPos.clone();
        ent.crushMesh2f.position = boxPos.clone();

        this.targetModule.setPenetrateMode(this.targetModule.floorPenetrate)
        
        ent.info.guid = this.viewScene.viewer.$SceneLoader.CreateGuid()
        return ent;
    }

    createWallMesh(instance,matName)
    {
        const tempWalls = [this.d1fWall,this.d2fWall,this.viewWall];
        const temp = tempWalls.find(x=>x._scene === instance);
        const csg = BABYLON.CSG.FromMesh(temp);
        const mat = instance.getMaterialByName(matName);

        const newWall = csg.toMesh(null,mat,instance,true);
        newWall.isVisible = false;
        newWall.setEnabled(true);

        const bbox = newWall.getBoundingInfo().boundingBox;
        const size = bbox.extendSize.multiply(new Vector3(2,2,2));
        
        const rot = this.targetModule.draw1fRootMesh.rotation.y;

        if(size.x < size.z)
        {
            newWall.rotation.y = Math.PI/2;
            CsgHelper.initialMatrix(newWall);
            newWall.rotation.y = Math.PI/2;
        }
        

        return newWall;
    }

    updateTempWall(endPoint,length,axis)
    {
        this.endPoint = endPoint;
        this.length = length;
        
        const wallThk = this.targetWall.entity.info.typeName.includes('Exterior') ? 0.14 : 0.09;
        const d1Scene = this.d1fPointer._scene;
        const d2Scene = this.d2fPointer._scene;
        const center = this.startPoint.add(endPoint).divide(new Vector3(2,2,2))
        
        this.d1fWall?.dispose();
        this.d2fWall?.dispose();
        this.viewWall?.dispose();

        const axisXValue = axis === 'x' ? 1 : 0;
        const axisZValue = axis === 'z' ? 1 : 0;
        const width = (length) * axisXValue + wallThk * axisZValue;
        const depth = (length) * axisZValue + wallThk * axisXValue;
        
        const newD1fWall = this.createBox(width,depth,d1Scene,center);
        const newD2fWall = this.createBox(width,depth,d2Scene,center);
        const newViewfWall = this.createBox(width,depth,this.viewScene.instance,center);
        
        this.d1fWall = newD1fWall;
        this.d2fWall = newD2fWall;
        this.viewWall = newViewfWall;

        this.d1fWall.setEnabled(true);
        this.d2fWall.setEnabled(true);
        this.viewWall.setEnabled(false);
        this.viewWall.isVisible = false;
    }

    createBox(width,depth,instance,pos)
    {
        let height = 2.707;
        if(instance === this.viewScene.instance)
        {
            height = 2.567;
        }
        const box = BABYLON.MeshBuilder.CreateBox(null,{width : width, height : height, depth : depth},instance);
        box.material = instance.getMaterialByName('greenBoxMaterial');
        box.position = pos.clone();
        return box;
    }

    dispose()
    {
        this.d1fWall?.dispose();
        this.d2fWall?.dispose();
        this.viewWall?.dispose();
        this.d1fPointer?.dispose();
        this.d2fPointer?.dispose();

        this.drawScene1f.isAddWallMode = false;
        this.drawScene2f.isAddWallMode = false;

        const d1Ins = this.drawScene1f.instance;
        const d2Ins = this.drawScene2f.instance;

        d1Ins.getMeshesById('wallPoint').forEach(w=>{
            w.dispose();
        })

        d2Ins.getMeshesById('wallPoint').forEach(w=>{
            w.dispose();
        })
    }
}