<template>
    <div id="joinSection3_Wrapper">
        <div id="joinScetion3_Body">
            <section class="joinStep3_Container">
            <hgroup>
                <h1>회원가입</h1>
                <p>
                    자이가이스트의 회원이 되면<br>
                    보다 나은 서비스를 이용하실 수 있습니다.
                </p>
            </hgroup>
    
            <ol>
                <li class="on">
                    <img src="../../assets/images/icons/icon_checked.png" alt="">
                    <span class="changeColor">약관동의</span>
                </li>
    
                <li class="bar"></li>
                <li class="on">
                    <img src="../../assets/images/icons/icon_checked.png" alt="">
                    <span class="changeColor">정보입력</span>
                </li>
                <li class="bar"></li>
    
                <li class="on">
                    <img src="../../assets/images/icons/icon_checked.png" alt="">
                    <span class="changeColor">가입완료</span>
                </li>
            </ol>

            <div class="titleContainer">
                <h2>환영합니다!</h2>
                <p>자이가이스트의 회원이 되신 것을 진심으로 축하드립니다.</p>

                <ul>
                    <li>
                        <a href="https://www.xigeist.com/brand/brandStory">자이가이스트 이해하기 ></a>
                    </li>
    
                    <li>
                        <a href="/">모델 경험하기 ></a>
                    </li>
    
                    <li>
                        <a href="https://www.xigeist.com/contact/conceptHouseList">샘플하우스 방문예약하기 ></a>
                    </li>
                </ul>

                <button type="button">
                    <a href="/member/login">로그인</a>
                </button>
            </div>
        </section>
        </div>
        
        <bottomInfo />
        <Footer />
        <scrollToTop />
    </div>
</template>

<style scoped>
    @import url('../../assets/css/LogIn/joinStep3.css');
</style>

<script>
    import bottomInfo from '../Login/bottomInfo.vue';
    import Footer from '../Login/joinStep1Footer.vue'
    import scrollToTop from '../Login/scrollToTop.vue'
    export default {
        components: {
            bottomInfo,
            Footer,
            scrollToTop
        }
    }
</script>