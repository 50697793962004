import { CurrentScreenBlock } from "@babylonjs/core";
import DockingEntity from "./DockingEntity";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import CsgHelper from '../Helper/CsgHelper'
import * as HistoryCommand from "../Entities/historyCommands"

export default class WindowEntity extends DockingEntity{
    constructor()
    {
        super();
        this.isSimbol = true;
        this.crushCollider = null;
        this.viewRootMesh = null;
        this.crushMesh = null;
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const info = mesh.info;
            if(info.ctgrName === 'Box')
            {
                const oriCsg = BABYLON.CSG.FromMesh(mesh);
                const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
                const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
                m1f.info = info;
                m2f.info = info;
                this.drawMeshes1f.push(m1f);
                this.drawMeshes2f.push(m2f);
                m1f.material = null;
                m2f.material = null;
            }
        });

        const w1f = this.createSimbol(this.drawScene1f.instance,this.drawMeshes1f);
        w1f.info = this.info;
        w1f.entity = this;
        w1f.setEnabled(false);
        const w2f = this.createSimbol(this.drawScene2f.instance,this.drawMeshes2f);
        w2f.info = this.info;
        w2f.entity = this;
        w2f.setEnabled(false);
        this.drawMeshes1f.push(w1f);
        this.drawMeshes2f.push(w2f);
    }

    createSimbol(sceneIns,meshes)
    {
        const box = meshes.find(x=>x.info.ctgrName === 'Box');
        box.isVisible = false;
        const boxInfo = box.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;
        
        if(this.parent)
        {
            if(this.parent.wallType == "Horizontal")
            {
                const windowPlane = BABYLON.MeshBuilder.CreatePlane(null,{ width: width, height: 0.14 }, sceneIns);
                windowPlane.material = sceneIns.getMaterialByName('Window2dMat');
                windowPlane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
                windowPlane.position = box.position.clone();
                windowPlane.position.y = 3.2;
                windowPlane.position.z = this.parent.getOriginPos().z;
                windowPlane.enableEdgesRendering();
                windowPlane.edgesWidth = 4.0;
                windowPlane.edgesColor = new BABYLON.Color4(0.439, 0.447, 0.455, 1);
                return windowPlane;
            }
            else
            {
                const windowPlane = BABYLON.MeshBuilder.CreatePlane(null,{ width: depth, height: 0.14 }, sceneIns);
                windowPlane.material = sceneIns.getMaterialByName('Window2dMat');
                windowPlane.rotation = new BABYLON.Vector3(Math.PI / 2, Math.PI / 2, 0);
                windowPlane.position = box.position.clone();
                windowPlane.position.y = 3.2;
                windowPlane.position.x = this.parent.getOriginPos().x;
                windowPlane.enableEdgesRendering();
                windowPlane.edgesWidth = 4.0;
                windowPlane.edgesColor = new BABYLON.Color4(0.439, 0.447, 0.455, 1);
                return windowPlane;
            }
        }
        else
        {
            const windowPlane = BABYLON.MeshBuilder.CreatePlane(null,{ width: width, height: 0.14 }, sceneIns);
            windowPlane.material = sceneIns.getMaterialByName('Window2dMat');
            windowPlane.position = box.position.clone();
            windowPlane.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
            windowPlane.position.y = 3.2;
            windowPlane.enableEdgesRendering();
            windowPlane.edgesWidth = 4.0;
            windowPlane.edgesColor = new BABYLON.Color4(0.439, 0.447, 0.455, 1);
            return windowPlane;
        }
    }

    createRootMesh()
    {
        try
        {
            const meshes = [];
            this.viewMeshes.filter(x=>x.info.ctgrName != 'Box').forEach(o=>{
                CsgHelper.bakeTransform(o);
                meshes.push(o);
            });
    
            const mergeMesh = BABYLON.Mesh.MergeMeshes(meshes, true, true, undefined, false, true);

            mergeMesh.info = this.info;
            mergeMesh.moduleEntity = this.moduleEntity;
            mergeMesh.parent = this.moduleEntity?.viewRootMesh;

            CsgHelper.bakeTransform(mergeMesh);

            const box = this.viewMeshes.find(x=>x.info.ctgrName === 'Box');
            mergeMesh.position = box.position.clone();
            mergeMesh.alwaysSelectAsActiveMesh = true

            this.viewMeshes = [];
            this.viewMeshes.push(mergeMesh)
            this.viewMeshes.push(box)
        }
        catch(err)
        {
            console.error(err);
        }
    }

    setPosition(instance,value)
    {
        const meshes = this.getDrawMeshes();
        const targets = meshes.filter(x=>x._scene === instance);
        targets.forEach(mesh=>{
            mesh.position = value.clone();
        })

        this.viewMeshes.forEach(x=>{
            x.position = value.clone();
        })
    }

    getButtonData()
    {
        return[
            {
                type:'module',
                press:this.selectModule
            },{
                type:'door-mirror-h',
                press:this.mirrorh
            },{
                type:'door-mirror-v',
                press:this.mirrorv
            },
            // {
            //     type:'copy',
            //     press:this.copy
            // },
            {
                type:'delete',
                press:this.delete
            },
        ];
    }

    createContextPanel(scene)
    {
        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        this.contextPanels.push(panels.toolbar);

        var box = this.getBoundingBox2d(scene);
        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    mirrorv(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorvCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        var wallType = entity.parent.wallType;
        if(wallType == "Vertical")
        {
            meshes2d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;
            });
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.y = -o.scaling.y;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;
            });
        }
    }

    mirrorh(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorhCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        var wallType = entity.parent.wallType;
        if(wallType == "Vertical")
        {
            meshes2d.forEach(o => 
            {
                o.scaling.y = -o.scaling.y;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
        }
    }

    getAll2DRootMeshes()
    {
        return this.getDrawMeshes();
    }
    getAll3DRootMeshes()
    {
        return this.viewMeshes;
    }

    setDrawFlipType()
    {
        const v = Number(this.info.flipType[1]);
        const h = Number(this.info.flipType[3]);

        for(let vm of this.getDrawMeshes())
        {
            if(v === 1)
            {
                vm.scaling.y *= -1;
            }
            if(h === 1)
            {
                vm.scaling.x *= -1;
            }
        }
    }

    setViewFlipType()
    {
        const v = Number(this.info.flipType[1]);
        const h = Number(this.info.flipType[3]);

        for(let vm of this.viewMeshes)
        {
            if(v === 1)
            {
                vm.scaling.z *= -1;
            }
            if(h === 1)
            {
                vm.scaling.x *= -1;
            }
        }
    }

    setModuleParent()
    {
        if(!this.moduleEntity)
            return;

        this.drawMeshes1f.forEach(x=>x.parent = this.moduleEntity.draw1fRootMesh);
        this.drawMeshes2f.forEach(x=>x.parent = this.moduleEntity.draw2fRootMesh);
        this.viewMeshes.forEach(x=>x.parent = this.moduleEntity.viewRootMesh);
        
    }

    viewMeshToDrawMeshTrasfrom()
    {
        const drawWindowMeshes = this.getDrawMeshes().filter(o=>o.info.ctgrName === 'Window');
        const drawBoxMeshes = this.getDrawMeshes().filter(o=>o.info.ctgrName === 'Box');
        const viewMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Window')
        const viewBoxMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Box')

        const vbox = viewMesh.getBoundingInfo().boundingBox;
        const vz = vbox.extendSize.z * 2;

        for(let drawWindowMesh of drawWindowMeshes)
        {
            drawWindowMesh.position.x = viewMesh.position.x;
            drawWindowMesh.position.z = viewMesh.position.z;
            drawWindowMesh.rotation.y = viewMesh.rotation.y;

            const dbox = drawWindowMesh.getBoundingInfo().boundingBox;
            const dy = dbox.extendSize.y *2;
            
            const gap = vz - dy;
            if(drawWindowMesh.rotation.y === 0)
            {
                drawWindowMesh.position.z -= gap/2 * drawWindowMesh.scaling.y * this.moduleEntity.viewRootMesh.scaling.z;
            }
            else
            {
                drawWindowMesh.position.x += gap/2 * drawWindowMesh.scaling.y * this.moduleEntity.viewRootMesh.scaling.x;
            }
        }

        for(let drawBoxMesh of drawBoxMeshes)
        {
            drawBoxMesh.position.x = viewBoxMesh.position.x;
            drawBoxMesh.position.z = viewBoxMesh.position.z;
            drawBoxMesh.rotation.y = viewBoxMesh.rotation.y;
        }
    }

    drawMeshToViewMeshTrasfrom()
    {
        const drawWindowMesh = this.getDrawMeshes().find(o=>o.info.ctgrName === 'Window');
        const drawBoxMesh = this.getDrawMeshes().find(o=>o.info.ctgrName === 'Box');
        const viewMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Window')
        const viewBoxMesh = this.viewMeshes.find(x=>x.info.ctgrName === 'Box')

        viewMesh.scaling.x = drawWindowMesh.scaling.x;
        viewMesh.scaling.z = drawWindowMesh.scaling.y;
        viewBoxMesh.scaling = drawBoxMesh.scaling;
        viewMesh.rotation.y = drawWindowMesh.rotation.y;
        viewBoxMesh.rotation.y = drawBoxMesh.rotation.y;
        viewMesh.position = drawWindowMesh.position.clone();
        viewMesh.position.y = this.info.height/1000;
        viewBoxMesh.position = drawBoxMesh.position.clone();
        viewBoxMesh.position.y = this.info.height/1000;
        
        this.viewMeshes.forEach(x=>x.parent = this.moduleEntity.viewRootMesh)
    }
}