import EntityBase from "./EntityBase";

export default class ColumnEntity extends EntityBase{
    constructor()
    {
        super();
    }

    dispose()
    {
        this.drawMeshes1f.concat(this.viewMeshes).forEach(o =>
        {
           o.dispose(); 
        });
    }

    initialize(moduleEnt, position)
    {
        this.info = {ctgrName : "Column"};
        this.viewScene = moduleEnt.viewScene;
        this.drawScene1f = moduleEnt.drawScene1f;
        this.drawScene2f = moduleEnt.drawScene2f;
        this.moduleEntity = moduleEnt;

        var scenes = [{scene:this.viewScene, y:1.55}, {scene:this.drawScene1f, y:3.1}];
        var meshes = [];
        var column = this;
        scenes.forEach(o =>
        {
            var scene = o.scene.instance;
            var mesh = BABYLON.MeshBuilder.CreateBox(null, {width : 0.14, height : 3.1, depth : 0.14}, scene); 
            var pos = position.clone();;
            pos.y = o.y;

            mesh.position = pos;
            mesh.info = column.info;
            mesh.entity = column;
            mesh.material = scene.getMaterialByName('WallMat');

            meshes.push(mesh);
        });

        this.viewMeshes.push(meshes[0]);
        this.drawMeshes1f.push(meshes[1]);
    }

    setPosition(instance, value)
    {
        var pos1f = value.clone();
        pos1f.y = this.locatedScene.floor == "1F" ? 3.1 : 0;
        this.draw1fRootMesh.position = pos1f;

        var pos2f = value.clone();
        pos2f.y = this.locatedScene.floor == "2F" ? 3.1 : 0;
        this.draw2fRootMesh.position = pos2f;

        var viewPos = value.clone();
        viewPos.y = instance === this.drawScene1f.instance ? 0 : 3.1;
        this.viewRootMesh.position = viewPos.clone();
    }
}