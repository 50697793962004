import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import * as Material from '@babylonjs/materials'

export default class MaterialLoader{
    constructor()
    {

    }

    LoadCommonMaterials(scene)
    {
        const smallGridMat = new BABYLON.StandardMaterial("smallGridMat",scene);
        const smallGridTexture = new BABYLON.Texture("./materials/Grid/smallGrid.png",scene);
        smallGridTexture.uScale = 2000;
        smallGridTexture.vScale = 2000;
        smallGridMat.diffuseTexture = smallGridTexture;
        smallGridMat.specularColor = new BABYLON.Color3(0, 0, 0);
        smallGridMat.specularPower = 0;

        const nomalGridMat = new BABYLON.StandardMaterial("nomalGridMat",scene);
        const nomalGridTexture = new BABYLON.Texture("./materials/Grid/nomalGrid.png",scene);
        nomalGridTexture.uScale = 2000;
        nomalGridTexture.vScale = 2000;
        nomalGridMat.diffuseTexture = nomalGridTexture;
        nomalGridMat.specularColor = new BABYLON.Color3(0, 0, 0);
        nomalGridMat.specularPower = 0;

        const bigGridMat = new BABYLON.StandardMaterial("bigGridMat",scene);
        const bigGridTexture = new BABYLON.Texture("./materials/Grid/bigGrid.png",scene);
        bigGridTexture.uScale = 2000;
        bigGridTexture.vScale = 2000;
        bigGridMat.diffuseTexture = bigGridTexture;
        bigGridMat.specularColor = new BABYLON.Color3(0, 0, 0);
        bigGridMat.specularPower = 0;

        const greenBoxMaterial = new BABYLON.StandardMaterial("greenBoxMaterial", scene);
        greenBoxMaterial.diffuseColor =  new BABYLON.Color3(0,5,0);
        greenBoxMaterial.alpha = 0.3;
        greenBoxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        greenBoxMaterial.specularPower = 0;
        greenBoxMaterial.backFaceCulling = false;

        const redBoxMaterial = new BABYLON.StandardMaterial("redBoxMaterial", scene);
        redBoxMaterial.diffuseColor =  new BABYLON.Color3(5,0,0);
        redBoxMaterial.alpha = 0.1;
        redBoxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        redBoxMaterial.specularPower = 0;
        redBoxMaterial.backFaceCulling = false;

        const blueBoxMaterial = new BABYLON.StandardMaterial("blueBoxMaterial", scene);
        blueBoxMaterial.diffuseColor =  new BABYLON.Color3(0,0,5);
        blueBoxMaterial.alpha = 0.1;
        blueBoxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
        blueBoxMaterial.specularPower = 0;
        blueBoxMaterial.backFaceCulling = false;

        const openningMat = new BABYLON.StandardMaterial("OpenningMat",scene);
        openningMat.diffuseColor =  new BABYLON.Color3(1, 1, 1);
        openningMat.specularColor = new BABYLON.Color3(0, 0, 0);
        openningMat.specularPower = 0;
        openningMat.alpha = 0;

        const wallTileMat = new Material.PBRCustomMaterial("WallTileMat",scene);
        const wallTileTexture = new BABYLON.Texture("./materials/textures/Demountable_Ceiling_Tile.jpg",scene);
        // wallTileTexture.uScale = 0.5;
        // wallTileTexture.vScale = 0.5;
        wallTileMat.albedoTexture = wallTileTexture;
        wallTileMat.bumpTexture = wallTileTexture;
        wallTileMat.metallic = 0.
        wallTileMat.roughness = 1.
        wallTileMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallTileMat.maxSimultaneousLights = 200;
        wallTileMat.Fragment_Before_Fog(this.getShaderCode(scene));
    }

    LoadDrawMaterials(scene)
    {
        const Door2dMat = new BABYLON.StandardMaterial("Door2dMat",scene);
        const Door2dTexture = new BABYLON.Texture("./materials/2D/Doors/Door_900x2100.png",scene);
        Door2dMat.diffuseTexture = Door2dTexture;
        Door2dMat.specularColor = new BABYLON.Color3(0, 0, 0); 
        Door2dMat.specularPower = 0;
        Door2dMat.backFaceCulling = false;
        Door2dMat.diffuseTexture.hasAlpha = true;

        const winSvg = `<svg width="101" height="16" viewBox="0 0 101 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_620_24130)">
        <rect width="101" height="16" fill="white"/>
        <path d="M0 9.81787H101" stroke="#4A4C4E"/>
        <path d="M0 6.18164H101" stroke="#4A4C4E"/>
        <path d="M0 0.5H101" stroke="#4A4C4E"/>
        <path d="M0 15.5H101" stroke="#4A4C4E"/>
        </g>
        <defs>
        <clipPath id="clip0_620_24130">
        <rect width="101" height="16" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        `
        const Window2dMat = new BABYLON.StandardMaterial("Window2dMat",scene);
        const Window2dTexture = new BABYLON.Texture('data:image/svg+xml;base64,' + btoa(winSvg),scene);
        Window2dMat.diffuseTexture = Window2dTexture;
        Window2dMat.specularColor = new BABYLON.Color3(0, 0, 0);
        Window2dMat.specularPower = 0;
        Window2dMat.backFaceCulling = false;

        const wallPointMat = new BABYLON.StandardMaterial("WallPointMat", scene);
        wallPointMat.diffuseColor =  new BABYLON.Color3(0.47058823529411764,0.7450980392156863,1);
        wallPointMat.alpha = 0.8;
        wallPointMat.specularColor = new BABYLON.Color3(0, 0, 0);
        wallPointMat.specularPower = 0;

        const wallRedPointMat = new BABYLON.StandardMaterial("WallRedPointMat", scene);
        wallRedPointMat.diffuseColor =  new BABYLON.Color3(1,0,0);
        wallRedPointMat.alpha = 0.8;
        wallRedPointMat.specularColor = new BABYLON.Color3(0, 0, 0);
        wallRedPointMat.specularPower = 0;

        const wallOtherPointMat = new BABYLON.StandardMaterial("WallOtherPointMat", scene);
        wallOtherPointMat.diffuseColor =  new BABYLON.Color3(1,1,1);
        wallOtherPointMat.alpha = 1;
        wallOtherPointMat.specularColor = new BABYLON.Color3(0, 0, 0);
        wallOtherPointMat.specularPower = 0;

        const wallMat = new Material.PBRCustomMaterial("WallMat",scene);
        wallMat.albedoColor =  new BABYLON.Color3(0.1137, 0.1529, 0.1922);
        wallMat.metallic = 0.
        wallMat.roughness = 1.
        wallMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallMat.maxSimultaneousLights = 200;
        wallMat.Fragment_Before_Fog(this.getShaderCode(scene));
        wallMat.backFaceCulling = true;

        const wallAlphaMat = new Material.PBRCustomMaterial("WallAlphaMat",scene);
        wallAlphaMat.albedoColor =  new BABYLON.Color3(0.1137, 0.1529, 0.1922);
        wallAlphaMat.metallic = 0.
        wallAlphaMat.roughness = 1.
        wallAlphaMat.alpha = 0.4
        wallAlphaMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallAlphaMat.maxSimultaneousLights = 200;
        wallAlphaMat.Fragment_Before_Fog(this.getShaderCode(scene));
        wallAlphaMat.backFaceCulling = true;

        const wallBackMat = new Material.PBRCustomMaterial("WallBackMat",scene);
        wallBackMat.albedoColor =  new BABYLON.Color3(0.1137, 0.1529, 0.1922);
        wallBackMat.metallic = 0.
        wallBackMat.roughness = 1.
        wallBackMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallBackMat.maxSimultaneousLights = 200;
        wallBackMat.Fragment_Before_Fog(this.getShaderCode(scene));
        wallBackMat.backFaceCulling = false;
    }

    LoadViewMaterials(scene)
    {
        const wallMat = new Material.PBRCustomMaterial("WallMat",scene);
        wallMat.metallic = 0.
        wallMat.roughness = 1.
        wallMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallMat.maxSimultaneousLights = 200;
        wallMat.backFaceCulling = true;
        wallMat.Fragment_Before_Fog(this.getShaderCode(scene));
        wallMat.twoSidedLighting = false;

        const wallBackMat = new Material.PBRCustomMaterial("WallBackMat",scene);
        wallBackMat.metallic = 0.
        wallBackMat.roughness = 1.
        wallBackMat.specularColor = new BABYLON.Color3(0, 0, 0)
        wallBackMat.maxSimultaneousLights = 200;
        wallBackMat.Fragment_Before_Fog(this.getShaderCode(scene));
        wallBackMat.backFaceCulling = true;
        wallBackMat.doubleSided = true;

        const paintMat = new Material.PBRCustomMaterial("paintMat",scene);
        // const paintTexture = new BABYLON.Texture("./materials/textures/painted_concrete_diff.jpg",scene);
        // const paintNomalTexture = new BABYLON.Texture("./materials/textures/painted_concrete_nor_gl.jpg",scene);
        // paintMat.albedoTexture = paintTexture
        // paintMat.bumpTexture = paintNomalTexture
        paintMat.metallic = 0.
        paintMat.roughness = 1.
        paintMat.albedoColor =  new BABYLON.Color3(0.333, 0.333, 0.333);
    }

    getShaderCode(scene)
    {
        const engine = scene.getEngine();
        const maxHoleCount = 100;
        let shaderCode = '';
        for(let i = 0; i< maxHoleCount; i++)
        {
            shaderCode +=`
                \n
                #if defined(PROJECTEDLIGHTTEXTURE${i})
                    strq = textureProjectionMatrix${i} * vec4(vPositionW, 1.0);
                    strq /= strq.w;
                    proj = strq.x < 0. || strq.x > 1. || strq.y < 0. || strq.y > 1. ? vec3(0.) : texture${engine.version === 1 && engine.name === "WebGL" ? "2D" : ""}(projectionLightSampler${i}, strq.xy).rgb;
                    if (proj.r == 1. && proj.g == 0. && proj.b == 0.)
                    {
                        discard;
                    }
                #endif
                \n
            `
        }

        return `vec4 strq;
                vec3 proj;
                //finalColor = vec4(1., 0., 0., 1.);
                ${shaderCode}
               `;
    }
}