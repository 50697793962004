<template>
    <div id="findPW_Wrapper">
        <div id="findPW_Body">

            <div class="findPW_Container">
                <hgroup>
                    <h1>비밀번호 변경</h1>
                    <p>
                        비밀번호를 변경하세요.</p>
                </hgroup>

                <div v-if="email == ''">
                    <p>
                        가입된 회원 정보가 없습니다.
                    </p>
                </div>


                <form action="" id="findID01" class="findbyNum" v-if="email != ''">
                    <label for="userNm" id="userNm" name="userNm">
                        <span>이메일</span>
                        <input type="text" id="userNm" name="userNm" v-model="email" readonly>
                    </label>
                    <label for="userNm" id="userNm" name="userNm">
                        <span>새 비밀번호</span>
                        <input type="password" id="userNm" name="userNm" v-model="password">
                    </label>

                    <label for="userID" id="userID" name="userID">
                        <span>새 비밀번호 확인</span>
                        <input type="password" id="userID" name="userID" v-model="password_verify">
                    </label>


                </form>

                <div class="BtnArea">
                    <button id="cancel"><router-link to="/member/login">취소</router-link></button>
                    <button id="confirm" class="confirmBtn" @click="chagePassword" v-if="email != ''">
                        비밀번호 변경
                    </button>
                </div>
            </div>
        </div>
        <bottomInfo />
        <Footer />
        <scrollToTop />
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/changePW2.css');
</style>

<script>
import bottomInfo from './bottomInfo.vue';
import Footer from './joinStep1Footer.vue'
import scrollToTop from '../Login/scrollToTop.vue'
import CryptoJS from "crypto-js";
import axios from 'axios';

export default {
    components: {
        bottomInfo,
        Footer,
        scrollToTop
    },
    data() {
        return {
            email: "",
            password: "",
            password_verify: "",
            param1: "",
            param2: ""
        }
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            this.param1 = this.$route.query.param1;
            this.param2 = this.$route.query.param2;

            //axios.post("http://localhost:12008/find_email",
            axios.post("/server/find_email",
                `{ "birthdate": "${this.param1}", "phonenumber": "${this.param2}" , "code":"0"}`).then(res => {
                    if (res.data._data["length"] != 0) {
                        let tempEmail1 = res.data._data[0]["USER_EMAIL"].split('@')[0];
                        let tempEmail2 = res.data._data[0]["USER_EMAIL"].split('@')[1];
                        this.email = tempEmail1.slice(0, 2) + "****** @ " + tempEmail2.slice(0, 2) + "******" + tempEmail2.slice(-4);
                    }
                    else {
                        this.email = "";
                    }
                })
        },
        chagePassword() {
            if (this.password != this.password_verify) {
                alert("비밀번호가 일치하지 않습니다");
            }
            else {
                let data = {
                    hashPw: CryptoJS.SHA256(this.password).toString(),
                    birthdate: this.param1,
                    phonenumber: this.param2
                }
                axios.post("/server/find_password", JSON.stringify(data), {
                //axios.post("http://localhost:12008/find_password", JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    console.log(res.data);
                    if (res.data._data[0]["CODE"] == '0') {
                        alert("비밀번호가 변경되었습니다");
                        window.location.href = '/member/login'
                    } else if (res.data._data[0]["CODE"] == '1') {
                        alert("카카오 계정으로 가입된 회원은 비밀번호를 변경할 수 없습니다");
                    } else if (res.data._data[0]["CODE"] == '2') {
                        alert("네이버 계정으로 가입된 회원은 비밀번호를 변경할 수 없습니다");
                    }
                })

            }

        }
    }
}
</script>