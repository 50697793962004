import DockingEntity from "./DockingEntity";
import TopSpotItem from './../Items/TopSpotItem';
import { Vector3 } from "@babylonjs/core";
import CsgHelper from "../Helper/CsgHelper";

export default class StairsEntity extends DockingEntity{
    constructor()
    {
        super();
        this.crushMeshs = [];
        this.crushMesh1fs = [];
        this.crushMesh2fs = [];
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            
            m1f.info = mesh.info;
            m2f.info = mesh.info;

            if(mesh.material.diffuseTexture)
            {
                const matName = mesh.material.diffuseTexture.name.split('/').slice(-1)[0];

                const mat1f = this.drawScene1f.instance.getMaterialByName(matName);
                if(!mat1f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene1f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene1f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m1f.material = oriMat;
                }
                else
                {
                    m1f.material = mat1f;
                }

                const mat2f = this.drawScene2f.instance.getMaterialByName(matName);
                if(!mat2f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene2f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene2f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m2f.material = oriMat;
                }
                else
                {
                    m2f.material = mat2f;
                }
            }

            m1f.enableEdgesRendering();
            m1f.edgesWidth = 4.0;
            m1f.edgesColor = new BABYLON.Color4(0.439, 0.447, 0.455, 1);

            m2f.enableEdgesRendering();
            m2f.edgesWidth = 4.0;
            m2f.edgesColor = new BABYLON.Color4(0.439, 0.447, 0.455, 1);

            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);

            if(mesh.info.ctgrName === 'Box')
            {
                m1f.isVisible = false;
                m2f.isVisible = false;
            }
        });
    }

    createCrushMesh()
    {
        ////////////////////////
        if(this.crushMeshs.length > 0)
        {
            this.crushMesh.forEach(c=>c.dispose())
        }
        const crushMeshs = [];
        this.viewMeshes.filter(x=>x.info.ctgrName === "Box").forEach(x=>{
            crushMeshs.push(x.clone());
        })
        if(crushMeshs.length < 1)
            return;
        crushMeshs.forEach(x=>x.material = this.viewScene.instance.getMaterialByName("redBoxMaterial"));
        this.crushMeshSetting(crushMeshs,crushMeshs[0].parent);
        this.crushMeshs = crushMeshs;
        ///////////////////////////

        ///////////////////////////
        if(this.crushMesh1fs.length > 0)
        {
            return;
        }
        const crushMesh1fs = [];
        this.drawMeshes1f.filter(x=>x.info.ctgrName === "Box").forEach(x=>{
            crushMesh1fs.push(x.clone());
        })
        if(crushMesh1fs.length < 1)
            return;
        crushMesh1fs.forEach(x=>x.material = this.drawScene1f.instance.getMaterialByName("redBoxMaterial"));
        this.crushMeshSetting(crushMesh1fs,crushMesh1fs[0].parent);
        this.crushMesh1fs = crushMesh1fs;
        ////////////////////////////

        ///////////////////////////
        if(this.crushMesh2fs.length > 0)
        {
            return;
        }
        const crushMesh2fs = [];
        this.drawMeshes2f.filter(x=>x.info.ctgrName === "Box").forEach(x=>{
            crushMesh2fs.push(x.clone());
        })
        if(crushMesh2fs.length < 1)
            return;
        crushMesh2fs.forEach(x=>x.material = this.drawScene2f.instance.getMaterialByName("redBoxMaterial"));
        this.crushMeshSetting(crushMesh2fs,crushMesh2fs[0].parent);
        this.crushMesh2fs = crushMesh2fs;
        ////////////////////////////////////
    }

    crushMeshSetting(crushMeshs,rootMesh)
    {
        if(crushMeshs.length < 1)
            return;

        crushMeshs.forEach(crushMesh=>{
            CsgHelper.initialMatrix(crushMesh)
            CsgHelper.setParentSubtractLocation(crushMesh,rootMesh)
            crushMesh.parent = null;
            crushMesh.id = "crushMeshTop";
            crushMesh.originPos = crushMesh.position.clone();
            crushMesh.moduleEntity = this.moduleEntity;
            crushMesh.rootMesh = rootMesh;
            crushMesh.parent = rootMesh;
            crushMesh.entity = this;
            crushMesh.isVisible = false;
            crushMesh.setEnabled(true);
            crushMesh.spot = new TopSpotItem(crushMesh, crushMesh._scene,null, rootMesh._scene != this.viewScene.instance)
            crushMesh.computeWorldMatrix();
        })

        // if(crushMeshs[0]._scene === this.viewScene.instance)
        // {
        //     this.mergeCrushMesh(crushMeshs);   
        // }
    }

    setCrushModuleEntity()
    {
        this.crushMeshs.forEach(c=>{
            c.moduleEntity = this.moduleEntity;
        })
        this.crushMesh1fs.forEach(c=>{
            c.moduleEntity = this.moduleEntity;
        })
        this.crushMesh2fs.forEach(c=>{
            c.moduleEntity = this.moduleEntity;
        })
    }

    async makeTempMesh(instance)
    {
        const currentScene = this.getCurrentScene(instance);
        const currentMeshes = this.getCurrentMeshes(instance);
        const crushMeshTops = this.crushMeshs;
        const csgList = []
        let isCrush = false;
        const tempScaling = crushMeshTops[0]?.scaling.clone();
        if(!tempScaling)
            return;
        for(let c of crushMeshTops)
        {
            c.scaling.x *= 1.0001;
            c.scaling.z *= 1.0001;
            const csg = BABYLON.CSG.FromMesh(c);
            csgList.push(csg)
            if(c.spot.includedOnlyMeshes.length > 1)
                isCrush = true;
        }

        if(!isCrush)
            return;

        const mList = [];
        for(let csg of csgList)
        {
            mList.push(csg.toMesh('backMesh',null,instance,true))
        }
        const mat = instance.getMaterialByName('WallBackMat');
        const result = BABYLON.Mesh.MergeMeshes(mList, true, true, undefined, false, true);
        result.parent = currentMeshes[0].parent;
        result.id = 'backMesh'
        result.material = mat;

        const bb = result.getBoundingInfo().boundingBox;
        const fHeight = 0.393;
        let height = bb.extendSize.y * 2;
        
        const sY = fHeight/height+0.01;
        result.scaling.y = sY;
        result.position.y = 3.1 - fHeight/2 + 0.09// - fHeight/2 -0.01;
        result.flipFaces(true)
        result.showBoundingBox = false;

        for(let c of crushMeshTops)
        {
            c.scaling = tempScaling.clone();
            const spot = c.spot;
            spot.includedOnlyMeshes.push(result);
        }
    }

    dispose()
    {
        if(this.crushMeshs){
            this.crushMeshs.forEach(o=>{
                o.spot?.delete();
                o.dispose();
            })
        }
        
        if(this.crushMesh1fs){
            this.crushMesh1fs.forEach(o=>{
                o.spot?.delete();
                o.dispose();
            })
        }

        if(this.crushMesh2fs){
            this.crushMesh2fs.forEach(o=>{
                o.spot?.delete();
                o.dispose();
            })
        }

        this.drawMeshes1f.forEach(m=>m.dispose());
        this.drawMeshes2f.forEach(m=>m.dispose());
        this.viewMeshes.forEach(m=>m.dispose());
    }
}