<template>
    <div id="LogIn_Wrapper2" v-if="isVisible">
        <div class="LogIn_Body2">
            <button class="closeEditPop" @click="closeEditPop">X</button>
            <div class="LogIn_Wrapper2">
                <div>
                    <h1>로그인</h1>
                    <p>자이가이스트에 오신 것을 환영합니다.<br>
                        로그인을 하시면 보다 나은 서비스를 만나보실 수 있습니다.</p>
                </div>

                <div class="LogIn_Form2">
                    <form v-on:submit.prevent>
                        <input type="text" name="userid" class="text-field" placeholder="이메일 입력" v-model="email">
                        <input type="password" name="password" class="text-field" placeholder="비밀번호 입력" v-model="password">
                        <label for="keepLogin" class="keepLoginBtn2">
                            <input type="checkbox" id="keepLogin" name="keepLogin" class="checkmarkLogin"
                                @change="keepLogin">
                            <div>로그인 상태 유지</div>
                        </label>
                        <button value="로그인" @click="postData" class="submit-btn">로그인</button>
                    </form>

                    <div class="Register_Div2">
                        <a :href= "this.$TARGETURL + 'member/joinStep1'" class="register">회원가입</a>
                        <div class="IDPW_Div2">
                            <a :href="this.$TARGETURL + 'member/findemail'" class="findID">아이디 찾기</a>
                            <a :href="this.$TARGETURL + 'member/changePW1'" class="findPW">비밀번호 변경</a>
                        </div>
                    </div>

                    <div class="SNS_Div2">
                        <h4>SNS계정 간편 로그인</h4>
                        <span class="KakaoBtn" @click="kakaoJoin">
                            <img src="../../assets/images/icons/button_kakao.png" alt="">
                            카카오로 로그인하기
                        </span>

                        <div id="naver_id_login" style="display: none;"></div>
                        <span id="naverLogin">
                            <img src="../../assets/images/icons/naver.png" alt="">
                            네이버로 로그인하기
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url("../../assets/css/LogIn/LogInModal.css");
</style>

<script>
import axios from 'axios';
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';

const client_id = "TDQ3VSyu9CPGHbOkbGUe"; //자이가이스트 HSDS
//const client_id = "4kjfJYnHSV14faBjq5R8"; //자이viewer 테스트
export default {
    data() {
        return {
            users: [],
            email: "",
            password: "",
            image: "",
            selectedFile: "",
            naverLogin2: null,
            account_email: "",
            name: "",
            birth: "",
            phone: "",
            gender: "",
            nickname: "",
            updatedate: "",
            key: "",
            m: "",
            EncodeData: "",
            code: "",
            isSocial: "",
            address: "",
            path: "",
            interest: "",
            hashPw: "",
            isKeepLoin: false,
            isVisible : true
        }
    },
    mounted() {
        this.naverLogin2 = new window.naver_id_login(
            client_id,
            this.$TARGETURL + "callback",  // 개발자센터에서 등록한 Callback URL

        );
        var state = this.naverLogin2.getUniqState();
        this.naverLogin2.setButton("white", 2, 62); // 버튼설정
        this.naverLogin2.setDomain(this.$TARGETURL);
        this.naverLogin2.setState(state);
        this.naverLogin2.setPopup(); // 팝업 여부
        this.naverLogin2.init_naver_id_login();
        this.accessToken = this.naverLogin2.oauthParams.access_token;
        console.log(this.accessToken); 

        $(document).on("click", "#naverLogin", function () {
            var btnNaverLogin = document.getElementById("naver_id_login").firstChild;
            btnNaverLogin.click();
        })
    },
    methods: {
        keepLogin() {
            var aaa = document.getElementById("keepLogin");
            if (aaa.checked) {
                //alert("체크");
                this.isKeepLoin = true;
            }
            else {
                //alert("안체크");
                this.isKeepLoin = false;
            }
        },
        //카카오 회원가입
        kakaoJoin() {
            //window.Kakao.init("50721e897a5869296e050475ab6dd3ea");

            window.Kakao.Auth.login({
                scope: "profile_image, account_email, profile_nickname, name, gender, age_range, birthday, birthyear, phone_number",
                success: this.getKakaoAccount,
            });
        },
        async getKakaoAccount() {
            console.log("1");
            window.Kakao.API.request({
                url: "/v2/user/me",
                success: async (res) => {
                    const kakao_account = res.kakao_account;
                    const nickname = kakao_account.profile.nickname;
                    const email = kakao_account.email;
                    var birth_temp = kakao_account.birthday;
                    const token = window.Kakao.Auth.getAccessToken();
                    let today = new Date();
                    let data = {
                        key: 1,
                        name: kakao_account.name,
                        birthdate: kakao_account.birthyear + "-" + birth_temp.slice(0, 2) + "-" + birth_temp.slice(2),
                        phonenumber: ("0" + kakao_account.phone_number.split(" ")[1]).replace(/-/g, ''), //국가코드, 하이픈 삭제
                        email: kakao_account.email,
                        gender: kakao_account.gender,
                        nickname: kakao_account.profile.nickname,
                        code: 1, // 카카오 가입 코드 1,
                        address: "",
                        path: "",
                        interest: "",
                        password: "",
                        token: token,
                        updatetime: today.getFullYear() + '-' + today.getMonth() + '-' + today.getDay(),
                        hashPw: "",
                        receive_s: "",
                        receive_e: ""
                    }

                    //document.cookie = "token=" + token;
                    let expireDate = new Date();
                    if(this.isKeepLoin){
                        expireDate.setTime(expireDate.getTime() + (24 * 60 * 60 * 1000 * 365));
                    }
                    else {
                        expireDate.setTime(expireDate.getTime() + (3 * 60 * 60 * 1000)); //3시간 후
                    }
                    Cookies.set('token', token, { expires: expireDate, domain: this.$DOMAIN, path: '/' });
                    await axios.post("/server/social_check", JSON.stringify(data), {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(res => {
                        if (res.data._data["length"] == 0) { //다 가입 안돼있으면
                            this.isSocial = 3;
                            alert("환영합니다.");
                        }
                        else if (res.data._data[0]["CODE"] == 1) { //소셜가입돼있으면
                            this.isSocial = 1;
                            alert("환영합니다.");
                        }
                        else if (res.data._data[0]["CODE"] == 0) { //일반회원 가입돼있으면
                            this.isSocial = 2;
                            alert("일반회원으로 가입된 이메일입니다");
                            this.kakaoLogout();
                        }
                    })
                    console.log(this.isSocial);
                    if (this.isSocial == 3) { //소셜 가입한 적 없으면 회원가입 테이블에도 넣음
                        await axios.post("/server/join_test", JSON.stringify(data), {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                    }


                    console.log("nickname", nickname);
                    console.log("email", email);
                    console.log("token", token); 

                    if (this.isSocial != 2) {
                        await axios.post("/server/login_test", JSON.stringify(data), {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).then(res => {
                            this.isVisible = false;
                            this.$emit('childEvent');
                        })
                    }
                    console.log(data);

                },
                fail: (error) => {
                    console.log(error);
                },
            });
        },
        kakaoLogout() {
            window.Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    console.log(response);
                    document.cookie = "token=" + "";
                    //alert("로그아웃 되었어용");
                },
                fail: function (error) {
                    console.log(error);
                },
            });
        },
        async test() {
            // alert("입력한 값은 11 입니다.");
            const data = await axios.get("/server/login").then((response) => {
                this.users = response.data;
                console.log(this.users);

            })
                .catch(error => {
                    console.error(error);
                })
        },
        postData() {
            let data = {
                email: this.email,
                password: this.password,
                token: "",
                code: "0",
                hashPw: CryptoJS.SHA256(this.password).toString(),
            }
            axios.post("/server/login_test", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                let token = response.data;
                if (token != false) {
                    //document.cookie = "token=" + token;
                    let expireDate = new Date();
                    if(this.isKeepLoin){
                        expireDate.setTime(expireDate.getTime() + (24 * 60 * 60 * 1000 * 365));
                    }
                    else {
                        expireDate.setTime(expireDate.getTime() + (3 * 60 * 60 * 1000)); //3시간 후
                    }
                    Cookies.set('token', token, { expires: expireDate, domain: this.$DOMAIN, path: '/' });
                    console.log(token);
                    alert("환영합니다.");
                    this.isVisible = false;
                    //location.href = this.$TARGETURL + "myhome"
                    //location.href = "https://selfdesign.xigeist.com/"
                    //location.href = this.$CLIENTURL;
                    //location.href = this.$TARGETURL2;
                    this.$emit('childEvent');
                }
                else {
                    alert("아이디, 비밀번호를 확인하세요.");
                }
            })
                .catch((error) => {
                    console.error(error);
                })
        },
        closeEditPop() {
            this.isVisible = false;
            this.$emit('childEvent2');
        }

    },
}

</script>