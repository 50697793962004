import EntityBase from "./EntityBase";
import { Vector3, runCoroutine } from "@babylonjs/core";
import VectorHelper from "../Helper/VectorHelper"
import * as GUI from '@babylonjs/gui/2D';
import CsgHelper from "../Helper/CsgHelper";

export default class WallEntity extends EntityBase{
    constructor()
    {
        super();
        this.wallType = null;
        this.startPointMesh = null;
        this.endPointMesh = null; 
    }

    getWallThickness()
    {
        var wall = this.drawMeshes1f[0];
        var box = wall.getBoundingInfo().boundingBox;
        if(this.wallType === "Vertical")
        {
            return box.extendSize.x * 2;
        }
        else
        {
            return box.extendSize.z * 2;
        }
    }
    
    getWorldWallType()
    {
        let rot = 0;
        if(this.moduleEntity?.viewRootMesh.rotation.y)
        {
            rot = this.moduleEntity.viewRootMesh.rotation.y;
        }

        const mesh = this.viewMeshes[0];
        const sin = Math.abs(Math.sin(rot).toFixed(6));
        const cos = Math.abs(Math.cos(rot).toFixed(6));
        const bbox = mesh.getBoundingInfo().boundingBox;
        const size = bbox.extendSize.multiply(new Vector3(2,2,2));
        const width = size.x * cos + size.z * sin;
        const depth = size.z * cos + size.x * sin;
        
        if(width<depth)
        {
            return {type : "Vertical", width : width, depth : depth}
        }
        else
        {
            return {type : "Horizontal", width : width, depth : depth}
        }
    }

    getWallLine()
    {
        var wall = this.drawMeshes1f[0];
        var wallBox = wall.getBoundingInfo().boundingBox;
        if(this.wallType == "Vertical")
        {
            var wallMinPoint = new BABYLON.Vector3(wallBox.center.x + wall.position.x, 0, wallBox.minimum.z + wall.position.z);
            var wallMaxPoint = new BABYLON.Vector3(wallBox.center.x + wall.position.x, 0, wallBox.maximum.z + wall.position.z);
        }
        else
        {
            var wallMinPoint = new BABYLON.Vector3(wallBox.minimum.x + wall.position.x, 0, wallBox.center.z + wall.position.z);
            var wallMaxPoint = new BABYLON.Vector3(wallBox.maximum.x + wall.position.x, 0, wallBox.center.z + wall.position.z);
        }

        return {
            start: wallMinPoint,
            end: wallMaxPoint
        }
    }

    getWorldWallLine()
    {
        var wall = this.drawMeshes1f[0];
        var wallBox = wall.getBoundingInfo().boundingBox;
        if(this.getWorldWallType().type == "Vertical")
        {
            var wallMinPoint = new BABYLON.Vector3(wallBox.centerWorld.x, 0, wallBox.minimumWorld.z);
            var wallMaxPoint = new BABYLON.Vector3(wallBox.centerWorld.x, 0, wallBox.maximumWorld.z);
        }
        else
        {
            var wallMinPoint = new BABYLON.Vector3(wallBox.minimumWorld.x, 0, wallBox.centerWorld.z);
            var wallMaxPoint = new BABYLON.Vector3(wallBox.maximumWorld.x, 0, wallBox.centerWorld.z);
        }

        return {
            start: wallMinPoint,
            end: wallMaxPoint
        }
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            m1f.isVisible = false;
            m2f.isVisible = false;
            
            m1f.info = mesh.info;
            m2f.info = mesh.info;

            m1f.material = this.drawScene1f.instance.getMaterialByName('WallMat');
            m2f.material = this.drawScene2f.instance.getMaterialByName('WallMat');

            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);
        });
    }

    createDrawWallMesh()
    {
        const boxInfo = this.meshes2d[0].getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWallMesh = BABYLON.MeshBuilder.CreateBox(null,{width:width,height:height,depth:depth},this.drawScene.instance);
        newWallMesh.position = this.selectBox2d.originPos.clone();
        newWallMesh.originPos = this.selectBox2d.originPos.clone();

        this.meshes2d[0].dispose();
        this.meshes2d[0] = newWallMesh;
    }

    createViewWallMesh()
    {
        const boxInfo = this.meshes[0].getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWallMesh = BABYLON.MeshBuilder.CreateBox(null,{width:width,height:height,depth:depth},this.viewScene.instance);
        newWallMesh.position = this.selectBox3d.originPos.clone();
        newWallMesh.originPos = this.selectBox3d.originPos.clone();
        newWallMesh.material = this.meshes[0].material;

        this.meshes[0].dispose();
        this.meshes[0] = newWallMesh;
    }


    setWallType()
    {
        const mesh = this.viewMeshes[0];
        const boundingInfo = mesh.getBoundingInfo();
        const width = boundingInfo.boundingBox.extendSize.x * 2;
        const depth = boundingInfo.boundingBox.extendSize.z * 2;

        if(width<depth)
        {
            this.wallType = "Vertical"
        }
        else
        {
            this.wallType = "Horizontal"
        }
    }

    getOriginPos()
    {
        const mesh = this.viewMeshes[0];
        const pos = mesh.position.clone();
        const floorPos = mesh.parent.position.clone();
        if(!floorPos)
            return pos;
        return pos.add(floorPos);
    }

    getButtonData()
    {
        var buttonItems = [{
            type:'module',
            press:this.selectModule
        }];
        
        if(!this.info.name.includes('Exterior'))
        {
            buttonItems.push({
                type:'delete',
                press:this.delete
            });
        }

        return buttonItems;
    }

    createContextPanel(scene)
    {
        var advancedTexture = this.getAdvancedTexture(scene.instance);
        var panels = this.getContextDefaultPanel();

        var pnButtons = this.createButtonPanel(advancedTexture, scene, this.info.ctgrName, this.getButtonData());
        panels.toolbar.addControl(pnButtons);

        this.contextPanels.push(panels.toolbar);

        var box = this.getBoundingBox2d(scene);
        var posMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        posMesh.position = box.center;
        this.boundingSideMeshes.push(posMesh);

        this.contextPanels.forEach(panel =>
        {
            advancedTexture.addControl(panel);
        })

        this.createToolTip(advancedTexture);

        return this.contextPanels;
    }

    initMesh(instance)
    {
        const d1fIns = this.drawScene1f.instance;
        const d2fIns = this.drawScene2f.instance;

        let oldWall = this.viewMeshes[0];
        if(instance === d1fIns)
        {
            oldWall = this.drawMeshes1f[0];
        }
        else if(instance === d2fIns)
        {
            oldWall = this.drawMeshes2f[0];
        }

        let bbinfo = oldWall.getBoundingInfo();
        if(oldWall.originBoundingInfo)
        {
            bbinfo = oldWall.originBoundingInfo;
        }

        const boxInfo = bbinfo;
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWall = BABYLON.MeshBuilder.CreateBox(null,{ width: width, height: height,depth:depth }, instance);
        newWall.position = oldWall.position.clone();
        newWall.rotation = oldWall.rotation.clone();
        newWall.scaling = oldWall.scaling.clone();
        newWall.parent = oldWall.parent;
        newWall.info = oldWall.info;
        newWall.entity = oldWall.entity;
        newWall.material = oldWall.material;
        newWall.originBoundingInfo = oldWall.originBoundingInfo;

        oldWall.dispose();
        if(instance === d1fIns)
        {
            this.drawMeshes1f[0] = newWall;
        }
        else if(instance === d2fIns)
        {
            this.drawMeshes2f[0] = newWall;
        }
        else
        {
            this.viewMeshes[0] = newWall;
        }
    }

    initViewMesh()
    {
        const oldWall = this.viewMeshes[0];
        const boxInfo = oldWall.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWall = BABYLON.MeshBuilder.CreateBox(null,{ width: width, height: height,depth:depth }, this.viewScene.instance);
        newWall.position = oldWall.position.clone();
        newWall.rotation = oldWall.rotation.clone();
        newWall.scaling = oldWall.scaling.clone();
        newWall.parent = oldWall.parent;
        newWall.info = oldWall.info;
        newWall.entity = oldWall.entity;
        newWall.material = oldWall.material;

        oldWall.dispose();
        this.viewMeshes[0] = newWall;
    }

    initDraw1fMesh()
    {
        const oldWall = this.drawMeshes1f[0];
        const boxInfo = oldWall.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWall = BABYLON.MeshBuilder.CreateBox(null,{ width: width, height: height,depth:depth }, this.drawScene1f.instance);
        newWall.position = oldWall.position.clone();
        newWall.rotation = oldWall.rotation.clone();
        newWall.scaling = oldWall.scaling.clone();
        newWall.parent = oldWall.parent;
        newWall.entity = oldWall.entity;
        newWall.info = oldWall.info;
        newWall.material = oldWall.material;

        oldWall.dispose();
        this.drawMeshes1f[0] = newWall;
    }

    initDraw2fMesh()
    {
        const oldWall = this.drawMeshes2f[0];
        const boxInfo = oldWall.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        const newWall = BABYLON.MeshBuilder.CreateBox(null,{ width: width, height: height,depth:depth }, this.drawScene2f.instance);
        newWall.position = oldWall.position.clone();
        newWall.rotation = oldWall.rotation.clone();
        newWall.scaling = oldWall.scaling.clone();
        newWall.parent = oldWall.parent;
        newWall.entity = oldWall.entity;
        newWall.info = oldWall.info;
        newWall.material = oldWall.material;

        oldWall.dispose();
        this.drawMeshes2f[0] = newWall;
    }

    // minx, minz, maxx, maxz, center로만 판별
    snapEntities(sceneEntity, prevPos, x, z, modules)
    {
        var filteredModules = modules.filter(o => o.locatedScene == sceneEntity);

        var mesh = this.viewMeshes[0];
        var box = mesh.getBoundingInfo().boundingBox;
        var boxes = [new BABYLON.BoundingBox(new BABYLON.Vector3(x - box.extendSizeWorld.x, 0, z - box.extendSizeWorld.z), new BABYLON.Vector3(x + box.extendSizeWorld.x, 0, z + box.extendSizeWorld.z))];

        var availableAxis = [];
        if(this.getWorldWallType().type == 'Vertical')
            availableAxis.push('z');
        else
            availableAxis.push('x');

        return this.getSnapData(boxes, filteredModules.flatMap(o => o.Entities).filter(o => o != this), sceneEntity, prevPos, x, z, availableAxis);
    }

    moveNearEntity(resultPos, originPos)
    {
        var viewMesh = this.viewMeshes[0];

        var diff = resultPos.subtract(originPos);
        diff.y = 0;

        var offset = diff.length();
        var dir = BABYLON.Vector3.Normalize(diff);
        var internalDir = BABYLON.Vector3.TransformNormal(dir, viewMesh.parent.getWorldMatrix());

        var wallBox = viewMesh.getBoundingInfo().boundingBox;
        var boxMin = BABYLON.Vector3.Minimize(originPos.add(wallBox.extendSizeWorld), originPos.subtract(wallBox.extendSizeWorld));
        var boxMax = BABYLON.Vector3.Maximize(originPos.add(wallBox.extendSizeWorld), originPos.subtract(wallBox.extendSizeWorld));

        boxMin.y = 0;
        boxMax.y = 0;

        var box = new BABYLON.BoundingBox(boxMin, boxMax);
        var moduleEntity = this.moduleEntity;
        var resultLine = this.getWorldWallLine();
        resultLine.start = resultLine.start.add(dir.scale(offset));
        resultLine.end = resultLine.end.add(dir.scale(offset));

        var deletedItems = [];

        // 간섭되는 내벽 위치 조절 필요
        moduleEntity.Walls.forEach(target =>
        {
            var targetBox = target.getBoundingBox2d(moduleEntity.locatedScene);
            var targetLine = target.getWorldWallLine();
            var targetDir = BABYLON.Vector3.Normalize(targetLine.end.subtract(targetLine.start));
            if(target.info.typeName.includes("Interior"))
            {
                if(VectorHelper.isParallel(targetDir, dir))
                {
                    var projectPoint = VectorHelper.calcNearestPointOnLine(resultLine.start, resultLine.end, targetLine.start);
                    if(BABYLON.Vector3.Distance(projectPoint, resultLine.start) > 0.01 && BABYLON.Vector3.Distance(projectPoint, resultLine.end) > 0.01)
                    {
                        // 약간의 공차 필요
                        var min = BABYLON.Vector3.Minimize(targetBox.minimum, targetBox.minimum.add(dir.scale(0.01)));
                        var max = BABYLON.Vector3.Maximize(targetBox.maximum, targetBox.maximum.add(dir.scale(0.01)));

                        if(box.intersectsMinMax(min, max))
                        {
                            var minValue = BABYLON.Vector3.Dot(min, targetDir);
                            var maxValue = BABYLON.Vector3.Dot(max, targetDir);
                            var posValue = BABYLON.Vector3.Dot(resultPos, targetDir);
                            if(minValue <= posValue && posValue <= maxValue)
                            {
                                if(Math.abs(maxValue - minValue - offset - BABYLON.Vector3.Dot(wallBox.extendSizeWorld, targetDir)) <= 0.02)
                                {
                                    deletedItems.push(target);
                                }
                                else
                                {
                                    target.scaleEntity(dir, internalDir, offset / 2, offset / 2);
                                }
                            }
                            else
                            {
                                target.scaleEntity(dir, internalDir, offset / 2, offset / 2);   
                            }
                        }
                    }
                }
            }
        });

        var wall = this;

        moduleEntity.Doors.concat(moduleEntity.Windows).concat(moduleEntity.Openings).forEach(item =>
        {
            if(item.parent == wall)
            {
                item.translateEntity(dir, offset);

                item.crushMesh.position = item.viewMeshes.find(x=>x.info.ctgrName === 'Box').position.clone();
                item.crushMesh1f.position = item.drawMeshes1f.find(x=>x.info.ctgrName === 'Box').position.clone();
                item.crushMesh2f.position = item.drawMeshes2f.find(x=>x.info.ctgrName === 'Box').position.clone();
                
                const wPos = this.viewMeshes[0].position;
                if(this.wallType === 'Horizontal')
                {
                    item.crushMesh.position.z = wPos.z;
                    item.crushMesh1f.position.z = wPos.z;
                    item.crushMesh2f.position.z = wPos.z;
                }
                else
                {
                    item.crushMesh.position.x = wPos.x;
                    item.crushMesh1f.position.x = wPos.x;
                    item.crushMesh2f.position.x = wPos.x;
                }
            }
        });

        deletedItems.forEach(item =>
        {
            item.deleteEntity(moduleEntity.locatedScene, item); 
        });
    }

    setPosition(instance, value)
    {
        var viewMesh = this.viewMeshes[0];

        var pos = value.clone();
        if(this.getWorldWallType().type == 'Vertical')
        {
            pos.z = viewMesh.absolutePosition.z;
        }
        else
        {
            pos.x = viewMesh.absolutePosition.x;
        }

        this.drawMeshes1f.forEach(mesh =>
        {
            var pos1f = pos.clone();
            pos1f.y = mesh.absolutePosition.y;
            mesh.setAbsolutePosition(pos1f); 
        });

        this.drawMeshes2f.forEach(mesh =>
        {
            var pos2f = pos.clone();
            pos2f.y = mesh.absolutePosition.y;
            mesh.setAbsolutePosition(pos2f); 
        });

        var viewpos = pos.clone();
        viewpos.y = viewMesh.absolutePosition.y;
        viewMesh.setAbsolutePosition(viewpos);
    }

    createCenterLine(instance)
    {
        const mesh = this.viewMeshes[0];
        const points = this.getWorldWallLine();
        points.start.y = 9;
        points.end.y = 9;
        const line = [points.start, points.end];

        const width = mesh.getBoundingInfo().boundingBox.extendSize.x * 2;
        const height = mesh.getBoundingInfo().boundingBox.extendSize.y * 2;
    
        let dashNb = 20;
        const dashRate = 20;
        if(width > height)
        {
            dashNb = width*dashRate;
        }
        else
        {
            dashNb = height*dashRate;
        }

        const dashLine = BABYLON.MeshBuilder.CreateDashedLines("dl", {points: line, dashNb: dashNb,dashSize: 1, gapSize: 1}, instance);
        dashLine.color = BABYLON.Color3.Green();

        return dashLine;
    }

    showCenterVertex(isShow,instance)
    {
        if(this.startPoint)
        {
            this.startPoint.dispose();
        }

        if(this.endPointMesh)
        {
            this.endPointMesh.dispose();
        }

        if(isShow)
        {
            const wallPointMat = instance.getMaterialByName('WallOtherPointMat');
            const diameter = 0.05;
            const points = this.getWorldWallLine();
            points.start.y = 9;
            points.end.y = 9;
            this.startPointMesh = BABYLON.MeshBuilder.CreateSphere('wallPoint',{diameter: diameter, segments: 16},instance);
            this.startPointMesh.position = points.start.clone();
            this.startPointMesh.material = wallPointMat;

            this.endPointMesh = BABYLON.MeshBuilder.CreateSphere('wallPoint',{diameter: diameter, segments: 16},instance);
            this.endPointMesh.position = points.end.clone();
            this.endPointMesh.material = wallPointMat;
        }
    }

    getAll2DRootMeshes()
    {
        return [this.draw1fRootMesh, this.draw2fRootMesh];
    }
    getAll3DRootMeshes()
    {
        return [this.viewRootMesh];
    }

    deleteEntity(scene, entity)
    {
        var module = entity.moduleEntity;
        var deleteItems = module.Doors.concat(module.Windows).concat(module.Openings).filter(o => o.parent == entity);
        deleteItems.forEach(item =>
        {
            item.deleteEntity(scene, item); 
        });

        module.deleteChild(scene, entity);
        module.isFixed = true;
        entity.dispose();
    }

    makeTempMesh(w,c)
    {
        if(w.info.name?.includes('Unit') || w.info.name?.includes('Tile'))
        {
            return;
        }

        const wBox = w.getBoundingInfo().boundingBox;
        
        const wWidth = wBox.extendSizeWorld.x * 2;
        const wDepth = wBox.extendSizeWorld.z * 2;

        let rot = w.entity.moduleEntity.viewRootMesh.rotation.y;
        
        const cBox = c.getBoundingInfo().boundingBox;
        const cWidth = cBox.extendSize.x * 2;
        const cHeight = cBox.extendSize.y * 2;
        const cDepth = cBox.extendSize.z * 2;

        const lengh = 0.01;
        
        if(c.entity.info.parentName.includes('Unit'))
        {
            return;
        }
        
        if(this.getWorldWallType().type == 'Vertical')
        {
            const temp = BABYLON.MeshBuilder.CreateBox('backMesh',
                { width: wWidth, height: cHeight,depth: cWidth}, w._scene);
            temp.parent = w;
            temp.material = null;
            temp.material = w._scene.getMaterialByName('WallBackMat'); // WallBackMat
            temp.scaling.z = (cWidth+lengh)/cWidth
            temp.scaling.y = 1.01
            temp.rotation.y = rot;
            const pos = c.getAbsolutePosition().clone();
            temp.parent = w;
            temp.setAbsolutePosition(pos);
            temp.position = temp.position.multiply(new Vector3(Math.abs(Math.sin(rot).toFixed(2)),1,Math.abs(Math.cos(rot).toFixed(2))))
            CsgHelper.initialMatrix(temp)
            temp.flipFaces(true);
            temp.showBoundingBox = false;
            temp.entity = this;
            temp.w = w;
        } 
        else
        {
            const temp = BABYLON.MeshBuilder.CreateBox('backMesh',
                { width: cWidth, height: cHeight,depth: wDepth}, w._scene);
            temp.parent = w;
            temp.material = null;
            temp.material = w._scene.getMaterialByName('WallBackMat');
            temp.scaling.x = (cWidth+lengh)/cWidth
            temp.scaling.y = 1.01
            temp.rotation.y = rot;
            const pos = c.getAbsolutePosition().clone();
            temp.parent = w;
            temp.setAbsolutePosition(pos);
            temp.position = temp.position.multiply(new Vector3(Math.abs(Math.cos(rot).toFixed(2)),1,Math.abs(Math.sin(rot).toFixed(2))))
            CsgHelper.initialMatrix(temp)
            temp.flipFaces(true);
            temp.showBoundingBox = false;
            temp.entity = this;
            temp.w = w;
        }
    }

    dispose()
    {
        for(let m of this.drawMeshes1f)
        {
            m.dispose();
        }
        for(let m of this.drawMeshes2f)
        {
            m.dispose();
        }
        for(let m of this.viewMeshes)
        {
            m.dispose();
        }

        if(this.moduleEntity)
        {
            this.moduleEntity.Entities = this.moduleEntity.Entities.filter(x => x != this);
            this.moduleEntity.Walls = this.moduleEntity.Walls.filter(x => x != this);
        }
    }
}