import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import { Vector3 } from '@babylonjs/core/Legacy/legacy';
import CsgHelper from '../Helper/CsgHelper'

export default class SpotItem extends BABYLON.SpotLight
{
    constructor(target,instance,wallType,is2d = false)
    {
        const spotPos = new BABYLON.Vector3(0, 0, 0);
        let spotDir = new Vector3(wallType === 'Horizontal' ? 0 : 1 ,0 ,wallType === 'Horizontal' ? -1 : 0);
        if(target.parent.rotation.y % Math.PI != 0)
        {
            spotDir = new Vector3(wallType === 'Horizontal' ? 1 : 0 ,0 ,wallType === 'Horizontal' ? 0 : -1);
        }
        super(null, spotPos, spotDir, 1, 1, instance);
        this.target = target;
        this.wallType = wallType;
        this.instance = instance;
        this.guideVisible = false;
        this.is2d = is2d;
        this.setSpotLightProjectionMatrix();

        this.projectionTexture = this.createTexture()
        this.includedOnlyMeshes = [instance.getMeshByName('spotBox')]
        this.intensity = 0
        
    }

    setSpotLightProjectionMatrix(){
        const child = [...this.target.getChildMeshes()];
        child.forEach(c=>c.parent = null)
        const bb = this.target.getHierarchyBoundingVectors()
        child.forEach(c=>c.parent = this.target)
        const size = bb.max.subtract(bb.min)
        if(this.is2d)
        {
            size.y = 6
        }
        const center = this.target.getBoundingInfo().boundingBox.centerWorld.clone();

        if(CsgHelper.getParentRotation(this.target) % Math.PI === 0)
        {
            if(this.wallType === 'Horizontal')
            {
                this.makeH(size,center)
            }
            else
            {
                this.makeV(size,center)
            }
        }
        else
        {
            if(this.wallType === 'Horizontal')
            {
                this.makeV(size,center)
            }
            else
            {
                this.makeH(size,center)
            }
        }
        
        
        this._projectionTextureProjectionLightDirty = false;
        this._projectionTextureDirty = true;
    }

    makeH(size,center)
    {
        this._projectionTextureProjectionLightMatrix = BABYLON.Matrix.OrthoOffCenterLH(
            -size.x/2 - center.x,
            size.x/2 - center.x,
            -size.y/2 + center.y,
            size.y/2 + center.y, 0, 1
        );

        //가이드 라인
        const p1 = new BABYLON.Vector3(-size.x/2 - center.x, -size.y/2 + center.y, center.z);
        const p2 = new BABYLON.Vector3(size.x/2 - center.x, -size.y/2 + center.y, center.z);
        const p3 = new BABYLON.Vector3(size.x/2 - center.x, size.y/2 + center.y, center.z);
        const p4 = new BABYLON.Vector3(-size.x/2 - center.x, size.y/2 + center.y, center.z);
        const points = [p1,p2,p3,p4,p1];
        const line = BABYLON.MeshBuilder.CreateLines("lines", { points }, this.instance);
        line.color = new BABYLON.Color3(0,0,1)
        line.scaling.x = -1;
        line.position.z -= 0.2
        line.isVisible = this.guideVisible;

        if(this.guideLine)
        {
            this.guideLine.dispose();
        }
        this.guideLine = line;
    }

    makeV(size,center)
    {
        this._projectionTextureProjectionLightMatrix = BABYLON.Matrix.OrthoOffCenterLH(
            -size.z/2 - center.z ,
            size.z/2 - center.z ,
            -size.y/2 + center.y ,
            size.y/2 + center.y , 0,1
        );

        //가이드 라인
        const p1 = new BABYLON.Vector3(center.x, -size.y/2 + center.y, -size.z/2 - center.z);
        const p2 = new BABYLON.Vector3(center.x, -size.y/2 + center.y, size.z/2 - center.z);
        const p3 = new BABYLON.Vector3(center.x, size.y/2 + center.y, size.z/2 - center.z);
        const p4 = new BABYLON.Vector3(center.x, size.y/2 + center.y, -size.z/2 - center.z);
        const points = [p1,p2,p3,p4,p1];
        const line = BABYLON.MeshBuilder.CreateLines("lines", { points }, this.instance);
        line.color = new BABYLON.Color3(0,0,1)
        line.scaling.z = -1;
        line.position.x += 0.2;
        line.isVisible = this.guideVisible;

        if(this.guideLine)
        {
            this.guideLine.dispose();
        }
        this.guideLine = line;
    }

    createTexture(){
        const textureSize = 1024

        const dynamicTexture = new BABYLON.DynamicTexture("discardTexture", textureSize, this.instance, false, BABYLON.Constants.TEXTURE_NEAREST_SAMPLINGMODE)
        const context = dynamicTexture.getContext()

        context.fillStyle = 'rgb(255, 0, 0)'
        context.fillRect(0, 0, textureSize, textureSize)

        dynamicTexture.update()

        return dynamicTexture
    }

    update(target)
    {
        this.target = target;
        let spotDir = new Vector3(this.wallType === 'Horizontal' ? 0 : 1 ,0 ,this.wallType === 'Horizontal' ? -1 : 0);
        if(CsgHelper.getParentRotation(target) % Math.PI != 0)
        {
            spotDir = new Vector3(this.wallType === 'Horizontal' ? 1 : 0 ,0 ,this.wallType === 'Horizontal' ? 0 : -1);
        }
        this.direction = spotDir;
        this.guideLine?.dispose();
        this.setSpotLightProjectionMatrix();
    }

    delete()
    {
        const tempMeshes = [...this.includedOnlyMeshes];
        const tempMats = [];
        this.guideLine?.dispose();

        tempMeshes.forEach(o=>{
            tempMats.push(o._scene.getMaterialByName(o.material?.name));
            o.material = null;
        })

        this.includedOnlyMeshes = [this.instance.getMeshByName('spotBox')];
        this?.dispose();
        
        for(let i = 0; i < tempMeshes.length; i++)
        {
            tempMeshes[i].material = tempMats[i];
        }
    }
}