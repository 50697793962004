//import { createApp } from 'vue'
//import App from './App.vue'

createApp(App).mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './assets/store' //Vuex 스토어 import
import SceneLoader from "./models/Loaders/SceneLoader"
//import LogInModal from "./components/Login/LogInModal"

const app = createApp(App)
app.use(store)
app.use(router).mount('#app')
//app.component('LogInModal', LogInModal);

app.config.globalProperties.$CLIENTURL = "http://test-service.bimpeers.com:5010/"

app.config.globalProperties.$MODULE_PATH = "./models/modules/";
app.config.globalProperties.$WINDOW_PATH = "./models/windows/";
app.config.globalProperties.$DOOR_PATH = "./models/doors/";
app.config.globalProperties.$UNIT_PATH = "./models/units/";
app.config.globalProperties.$SceneLoader = new SceneLoader();

//자이 서버 용
app.config.globalProperties.$TARGETURL = "https://self-design.xigeist.com/"; //2차
app.config.globalProperties.$TARGETURL2 = "https://selfdesign.xigeist.com/"; //1차
app.config.globalProperties.$AUTHURL = "https://xiapi.xigeist.com/" //본인인증서버
app.config.globalProperties.$DOMAIN = ".xigeist.com" //쿠키 유지 최상위 도메인

//app.config.globalProperties.$SERVERURL = "https://xiapi.xigeist.com/" //서버
//app.config.globalProperties.$SERVERURL = "http://localhost/" //서버
  //app.config.globalProperties.$SERVERURL = "http://10.130.2.41/" //서버


//빔피어스 서버 테스트 용
  //app.config.globalProperties.$TARGETURL = "http://xiviewer.bimpeers.com/"; //2차
  //app.config.globalProperties.$TARGETURL2 = "http://xiclient.bimpeers.com/"; //1차
//   app.config.globalProperties.$SERVERURL = "http://xiapiserver.bimpeers.com:4486/" //서버
   //app.config.globalProperties.$AUTHURL = "http://test-service.bimpeers.com:4487/" //본인인증서버
   //app.config.globalProperties.$DOMAIN = ".bimpeers.com" //쿠키 유지 최상위 도메인


//로컬 테스트 용
//app.config.globalProperties.$TARGETURL = "http://localhost:8080/";
//app.config.globalProperties.$TARGETURL2 = "http://localhost:8081/";
// //app.config.globalProperties.$SERVERURL = "http://xiapiserver.bimpeers.com:4486/" //서버
 //app.config.globalProperties.$SERVERURL = "http://localhost:12008/"
// app.config.globalProperties.$AUTHURL = "http://localhost:55239/"

//app.config.globalProperties.$TARGETURL = "http://test-service.bimpeers.com:5006/";
//app.config.globalProperties.$TARGETURL = "http://1.214.117.116:5006/";
//app.config.globalProperties.$TARGETURL2 = "http://1.214.117.116/";
//app.config.globalProperties.$TARGETURL2 = "http://test-service.bimpeers.com/";



//window.Kakao.init("50721e897a5869296e050475ab6dd3ea");
