<template>

</template>

<script>
import axios from 'axios';
import { routeLocationKey } from 'vue-router';

export default {
    data() {
        return {
            param1: "",
            param2: "",
            birth_temp: "",
            birth_year: "",
            birth_month: "",
            birth_date: "",
            birth: "",
            phone: "",
            key: ""
        }
    },
    mounted() {
        this.param1 = this.$route.query.param1;
        this.param2 = this.$route.query.param2;
        
        var str = this.param1.split("-");
        console.log("param1 : "+this.param1);
        console.log("param2 : "+this.param2);
        this.birth_temp = str[0];
        this.birth_year = this.birth_temp.substring(0, 4);
        this.birth_month = this.birth_temp.substring(4, 6);
        this.birth_date = this.birth_temp.substring(6, 8);
        this.birth = this.birth_year + '-' + this.birth_month + '-' + this.birth_date;

        this.phone = str[1];

        let data = {
            birthdate: this.birth,
            phonenumber: this.phone,
            key: 2
        }
        console.log(data);
        axios.post("/server/join_test", JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            console.log(res.data._data["length"]);
            if (res.data._data["length"] == 0) {
                alert("본인인증이 완료되었습니다.");
                window.close();
                //opener.location.href = 'http://localhost:8080/member/joinStep2?param2=' + this.param2;
                opener.location.href = this.$TARGETURL + 'member/joinStep2?param2=' + this.param2;
            } else {
                alert("이미 가입된 회원정보입니다.");
                window.close();
                //opener.location.href = 'http://localhost:8080/member/Login';
                opener.location.href = this.$TARGETURL + 'member/Login'
            }
        })


    }
}
</script>