import Scene2d from "../Scenes/Scene2d";
import Scene3d from "../Scenes/Scene3d";
import MaterialLoader from "./MaterialLoader"
import EntityLoader from "./EntityLoader"
import WallEntity from "../Entities/WallEntity";
import OpeningEntity from "../Entities/OpeningEntity";
import { Vector3, runCoroutine } from "@babylonjs/core";
import CsgHelper from "../Helper/CsgHelper";
import SaveHelper from "../Helper/SaveHelper";
import axios from "axios";
import CryptoJS from "crypto-js";

export default class SceneLoader{
    constructor()
    {
        this.viewScene = null;
        this.drawScene1f = null;
        this.drawScene2f = null;
        this.drawScenes =[];
        this.materialLoader = new MaterialLoader();
        this.EntityLoader = new EntityLoader(this);
        this.isSnappedFloor = false;
        this.isSnappedWindow = false;
        this.isSnappedDoor = false;
        this.isSnappedWall = true;
        this.isShowRoof = false;
        this.saveJson = null;
        this.isShowArea = true;
        this.isShowScailBar = true;
        this.history = [];
        this.historyIndex = 0;
        this.historyIndexTemp = -1;
        this.loadedModules = 0;
    }

    aesEncrypt128(text) {
        const key = CryptoJS.enc.Utf8.parse('1234567890123456');

        // IV (초기 벡터)
        const iv = CryptoJS.enc.Utf8.parse('1234567890123456');

        // AES-128 암호화
        const encrypted = CryptoJS.AES.encrypt(text, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // while(Enfile_data.includes("0x")) {
        //     Enfile_data = Enfile_data.replace("0x","bimpeersyyy");
        // }
        // Enfile_data = Enfile_data.replaceAll("0X","bimpeersYYY");
        // Enfile_data = Enfile_data.replaceAll("xor","bimpeersxxx");
        // Enfile_data = Enfile_data.replaceAll("XOR","bimpeersXXX");

        // Enthumb_img = Enthumb_img.replaceAll("0x","bimpeersyyy");
        // Enthumb_img = Enthumb_img.replaceAll("0X","bimpeersYYY");
        // Enthumb_img = Enthumb_img.replaceAll("xor","bimpeersxxx");
        // Enthumb_img = Enthumb_img.replaceAll("XOR","bimpeersXXX");

        //var encrypted_r = "";

        //encrypted_r = encrypted.replace("0x","bimpeersyyy")
        //encrypted_r = encrypted.replaceAll("0X","bimpeersYYY")
        //encrypted_r = encrypted.replaceAll("xor","bimpeersxxx")
        //encrypted_r = encrypted.replaceAll("XOR","bimpeersXXX")
        console.log("encr : ", encrypted);
        return encrypted;
    }
    async save(idx,name)
    {
        const saveModules = [];
        const ground = this.viewScene.instance.getMeshByName("ground");
        ground.isVisible = false;
        var stringtest = "안녕하세요요요요요요요";
            var ddd = stringtest.replaceAll("요", "하");
            console.log("ddd : ", ddd);

        this.viewScene.floorText.isVisible = false;
        this.viewScene.guidText.isVisible = false;

        let floor = 1;
        for(let module of this.EntityLoader.modules)
        {
            if(module.floorPenetrate === "1F")
            {
                floor = 2;
            }
            saveModules.push(SaveHelper.makeSaveData(module));console.log(module)
        }

        let areaMm = 0;
        let area = 0;

        this.EntityLoader.modules.forEach(m=>{
            const mArea = m.getArea(false);
            areaMm += Number(mArea.areaMm);
            area += Number(mArea.area);
        })
        console.log(localStorage.getItem('loadIdx'))
        this.saveJson = JSON.stringify(saveModules) 

        //AES128로 암호화
        
        await BABYLON.Tools.CreateScreenshot(this.viewScene.engine,this.viewScene.instance.getCameraByName("Camera"), {precision: 1.0},
        async (img)=>{
            console.log("이름", name);
            // const saveData = `{
            //     "key":"INSERT",
            //     "user_idx":"${idx}",
            //     "idx":"${localStorage.getItem('loadIdx')??''}",
            //     "file_data":'${this.saveJson}',
            //     "file_name":"${name}",
            //     "type":"${name?.includes('Proto')?name : ''}",
            //     "create_date":"",
            //     "update_date":"",
            //     "thumb_img":"${img}",
            //     "AREA": ${areaMm},
            //     "FLOOR": ${floor}
            // }`
            var Enfile_data = this.aesEncrypt128(this.saveJson);
            var Enfile_name = this.aesEncrypt128(name);
            var Enthumb_img = this.aesEncrypt128(img);

            const saveData = `{
                "key":"INSERT",
                "user_idx":"${idx}",
                "idx":"${localStorage.getItem('loadIdx')??''}",
                "file_data":"${Enfile_data}",
                "file_name":"${Enfile_name}",
                "type":"${name?.includes('Proto')?name : ''}",
                "create_date":"",
                "update_date":"",
                "thumb_img":"${Enthumb_img}",
                "AREA": ${areaMm},
                "FLOOR": ${floor}
            }`
            
            var saveResult = saveData.replaceAll("0x","bimpeersyyy").replaceAll("0X","bimpeersYYY").replaceAll("xor","bimpeersxxx").replaceAll("XOR","bimpeersXXX").replaceAll("+","bimpeersplus")

            const res = await axios.post('/server/save_file',saveResult,{
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-origin' : '*',
                'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                'Access-Control-Allow-Credentials' : 'true'
            }
            })
            ground.isVisible = true;
            this.viewScene.floorText.isVisible = true;
            this.viewScene.guidText.isVisible = true;
            console.log(res)
        });
    }

    makeHistory(command)
    {
        this.history = this.history.slice(0,this.historyIndex+1)
        this.history.push(command);

        this.historyIndexTemp = this.historyIndex;
        this.historyIndex = this.history.length - 1;
    }

    async load(fileContent)
    {
        this.history = [];
        this.historyIndex = -1;
        this.loadedModules = 0;
        
        this.EntityLoader.modules.forEach(o=>{
            o.dispose()
        })
        const saveJson = JSON.parse(fileContent);

        const sLength = saveJson.length;
        for(let mData of saveJson)
        {
            this.makeLoadModule(mData)
        }
        
        const checkInterval = setInterval(()=>{
            if(sLength === this.loadedModules)
            {
                this.drawScene1f.updateWall();
                this.drawScene1f.viewer.isLoaded = true;
                this.loadedModules = -100;
                clearInterval(checkInterval)
            }
        },100);
    }

    updateSceneRoof()
    {
        this.drawScene1f.updateEntireRoof();
    }

    drawScenesInit()
    {
        this.drawScenes.push(this.drawScene1f);
        this.drawScenes.push(this.drawScene2f);
    }

    LoadScene(is2d,canvas,viewer,floor = null)
    {
        let scene = null;
        if(is2d)
        {
            scene = new Scene2d(canvas,viewer,floor);
            this.materialLoader.LoadCommonMaterials(scene.instance);
            this.materialLoader.LoadDrawMaterials(scene.instance);
            scene.createGround();
        }
        else
        {
            scene = new Scene3d(canvas,viewer);
            this.materialLoader.LoadCommonMaterials(scene.instance);
            this.materialLoader.LoadViewMaterials(scene.instance);
            scene.createGround();
        }

        return scene;
    }

    async makeLoadModule(mData)
    {
        // console.log('makeLoadModule')
        const viewer = this.viewScene.viewer;
        const viewIns = this.viewScene.instance;
        await this.CreateModule(mData.name,`./models/modules/${mData.name}/obj/`);
        const floor = mData.info.floor;
        const is1F = floor === '1F';
        const cModule = this.drawScene1f.createEntity;
        
        cModule.info = {...mData.info}
        cModule.isGhost = false;
        cModule.locatedScene = is1F ? this.drawScene1f : this.drawScene2f;
        
        //외벽 코너 바닥 제외 모두 삭제
        for(let ent of cModule.Entities)
        {
            if(ent.info?.ctgrName === 'Floor' || ent.info?.ctgrName === 'Corner' || ent.info?.ctgrName === 'Stair' || ent.info?.ctgrName === 'Void')
                continue;
            if(ent.info?.ctgrName === 'Wall' && (ent.info?.typeName.includes("Exterior") || ent.info?.typeName.includes("Rail")))
                continue;
            ent.dispose();
        }

        //단일 바닥 모듈만 크기 조절
        if(cModule.Floors.length === 1)
        {
            const curSize = cModule.getSize().clone();
            const loadSize = new Vector3(mData.size._x,mData.size._y,mData.size._z);
            
            var subVector = loadSize.subtract(curSize);
            var checkDirs = [BABYLON.Axis.X, BABYLON.Axis.Z];
            var resultDatas = [];
            checkDirs.forEach(o =>
            {
                var dirVector = subVector.multiply(o);
                if(dirVector.length() > 0)
                {
                    var dir = BABYLON.Vector3.Normalize(dirVector);
                    var size = dirVector.length() / 2;
    
                    resultDatas.push({dir:dir, size: size});
                }
            });

            cModule.setSize(resultDatas);

            const rails = cModule.Walls.filter(x=>x.info?.typeName.includes("Rail"))
            if(rails.length > 0)
            {
                let k = 0;
                for(const i in rails)
                {
                    if(mData.wallRailNmData[i-k] != rails[i].info.name)
                    {
                        rails[i].dispose()
                        k++;
                        continue;
                    }
                    for(const j in rails[i].viewMeshes)
                    {
                        rails[i].viewMeshes[j].position.x = mData.wallRailPos[i-k][j]._x;
                        rails[i].viewMeshes[j].position.z = mData.wallRailPos[i-k][j]._z;
                    }

                    for(const j in rails[i].drawMeshes1f)
                    {
                        rails[i].drawMeshes1f[j].position.x = mData.wallRailDrawPos[i-k][j]._x;
                        rails[i].drawMeshes1f[j].position.z = mData.wallRailDrawPos[i-k][j]._z;
                        rails[i].drawMeshes2f[j].position.x = mData.wallRailDrawPos[i-k][j]._x;
                        rails[i].drawMeshes2f[j].position.z = mData.wallRailDrawPos[i-k][j]._z;
                    }
                }
            }
        }

        const corners = cModule.Corners;
        for(const i in corners)
        {
            const c = corners[i];
            c.drawMeshes1f[0].position.x = c.viewMeshes[0].position.x;
            c.drawMeshes1f[0].position.z = c.viewMeshes[0].position.z;

            c.drawMeshes2f[0].position.x = c.viewMeshes[0].position.x;
            c.drawMeshes2f[0].position.z = c.viewMeshes[0].position.z;
        }
        
        const x = mData.position._x;
        const z = mData.position._z;
        const otherScene = is1F ? this.drawScene2f : this.drawScene1f;
        cModule.setPosition(otherScene.instance,new Vector3(Number(x), is1F ? 3.1 : 0, Number(z)));
        cModule.setPosition(cModule.locatedScene.instance,new Vector3(Number(x), is1F ? 0 : 3.1, Number(z)));
        
        cModule.setViewModel(true);
        cModule.isGhost = false;
        cModule.floorPenetrate = otherScene.floor;

        const rotation = Number(mData.rotation);
        if(rotation != 0)
        {
            cModule.viewRootMesh.rotation = new Vector3(0,rotation,0);
            cModule.draw1fRootMesh.rotation = new Vector3(0,rotation,0);
            cModule.draw2fRootMesh.rotation = new Vector3(0,rotation,0);
        }

        const flipX = mData.scaling._x;
        const flipZ = mData.scaling._z;
        
        if(flipX === -1)
        {
            cModule.viewRootMesh.scaling.x *= -1;
            cModule.draw1fRootMesh.scaling.x *= -1;
            cModule.draw2fRootMesh.scaling.x *= -1;
        }
        if(flipZ === 1)
        {
            cModule.viewRootMesh.scaling.z *= -1;
            cModule.draw1fRootMesh.scaling.z *= -1;
            cModule.draw2fRootMesh.scaling.z *= -1;
        }

        cModule.moduleBoxs.forEach(mbox=>mbox.isVisible = false);

        this.drawScene1f.createEntity = null;
        this.drawScene2f.createEntity = null;

        SaveHelper.loadWall(cModule,mData.walls);

        const wallex = cModule.Walls.filter(x=>x.info.name.includes('Exterior'));
        for(let i in wallex)
        {
            let exex = mData.wallexPos[i]._x;
            let exez = mData.wallexPos[i]._z;
            let exScaling = new Vector3(mData.wallexScailing[i]._x,mData.wallexScailing[i]._y,mData.wallexScailing[i]._z);
            
            wallex[i].viewMeshes[0].position.x = exex;
            wallex[i].viewMeshes[0].position.z = exez;
            wallex[i].viewMeshes[0].scaling = exScaling.clone();
            wallex[i].viewMeshes[0].entity = wallex[i];

            wallex[i].drawMeshes1f[0].position.x = exex;
            wallex[i].drawMeshes1f[0].position.z = exez;
            wallex[i].drawMeshes1f[0].scaling = exScaling.clone();
            wallex[i].drawMeshes1f[0].entity = wallex[i];

            wallex[i].drawMeshes2f[0].position.x = exex;
            wallex[i].drawMeshes2f[0].position.z = exez;
            wallex[i].drawMeshes2f[0].scaling = exScaling.clone();
            wallex[i].drawMeshes2f[0].entity = wallex[i];
        }
        
        for(let w of mData.windows)
        {
            const wInfo = w.info;
            const winData = viewer.windows.find(x=>wInfo.name.replace('-','').includes(x.name));
            const ent = await this.EntityLoader.LoadWindow(winData);
            ent.info = {...w.info}
            ent.parent = cModule.Walls.find(x=>x.info.name === ent.info.parentName)
            ent.moduleEntity = cModule;

            for(let i in ent.drawMeshes1f)
            {
                const mesh = ent.drawMeshes1f[i];
                const pData = w.simbol1fPositions[i];
                const rData = w.simbol1fRotations[i];
                const sData = w.simbol1fScalings[i];
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
                mesh.parent = cModule.draw1fRootMesh;
                mesh.setEnabled(true)
            }
            for(let i in ent.drawMeshes2f)
            {
                const mesh = ent.drawMeshes2f[i];
                const pData = w.simbol2fPositions[i];
                const rData = w.simbol2fRotations[i];
                const sData = w.simbol2fScalings[i];
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
                mesh.parent = cModule.draw2fRootMesh;
                mesh.setEnabled(true)
            }
            for(let i in ent.viewMeshes)
            {
                const mesh = ent.viewMeshes[i];
                const pData = w.positions[i];
                const rData = w.rotations[i];
                const sData = w.scalings[i];
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
                mesh.parent = cModule.viewRootMesh;
                mesh.setEnabled(true)
            }
            cModule.Entities.push(ent)
            cModule.Windows.push(ent)
            ent.createCrushMesh();
        }

        for(let o of mData.openings)
        {
            const viewMesh = BABYLON.MeshBuilder.CreateBox(null,{width : o.width, height : o.height, depth : o.depth},viewIns);
            const boxMesh = BABYLON.MeshBuilder.CreateBox(null,{width : o.width, height : o.height, depth : o.depth},viewIns);
            
            viewMesh.position = new Vector3(o.position._x,o.position._y,o.position._z)
            boxMesh.position = new Vector3(o.position._x,o.position._y,o.position._z)
            boxMesh.material = viewIns.getMaterialByName('redBoxMaterial');

            viewMesh.isVisible = false;
            boxMesh.isVisible = false;

            viewMesh.rotation = new Vector3(o.rotation._x,o.rotation._y,o.rotation._z)
            boxMesh.rotation = new Vector3(o.rotation._x,o.rotation._y,o.rotation._z)

            viewMesh.info = {...o.info};
            boxMesh.info = {name : 'Box',ctgrName : 'Box',typeName : 'Box',parentName : viewMesh.info.ctgrName + viewMesh.info.typeName};

            viewMesh.parent = cModule.viewRootMesh;
            boxMesh.parent = cModule.viewRootMesh;

            const ent = new OpeningEntity();
            ent.drawScene1f = this.drawScene1f;
            ent.drawScene2f = this.drawScene2f;
            ent.viewScene = this.viewScene;
            ent.info = {...o.info};
            ent.viewMeshes.push(viewMesh);
            ent.viewMeshes.push(boxMesh);
            ent.parent = cModule.Walls.find(x=>x.info.name === ent.info.parentName)
            viewMesh.entity = ent;
            boxMesh.entity = ent;
            ent.drawViewMesh();
            
            ent.drawMeshes1f.forEach(x=>{
                x.position = viewMesh.position.clone();
                x.parent = cModule.draw1fRootMesh
                x.entity = ent;
                x.position.y = 3.2;
            })
            ent.drawMeshes2f.forEach(x=>{
                x.position = viewMesh.position.clone();
                x.parent = cModule.draw2fRootMesh
                x.entity = ent;
                x.position.y = 3.2;
            })
            ent.moduleEntity = cModule;
            ent.createCrushMesh();
            ent.crushMesh.position = ent.viewMeshes[0].position.clone();
            ent.crushMesh1f.position = ent.drawMeshes1f[0].position.clone();
            ent.crushMesh2f.position = ent.drawMeshes2f[0].position.clone();

            cModule.Entities.push(ent)
            cModule.Openings.push(ent)
        }

        for(let o of mData.doors)
        {
            const dInfo = o.info;
            const doorData = viewer.doors.find(x=>dInfo.name.replace('-','').includes(x.name));
            const doorEnt = await this.EntityLoader.LoadDoor(doorData)
            doorEnt.info = {...o.info};
            doorEnt.parent = cModule.Walls.find(x=>x.info.name === doorEnt.info.parentName);
            doorEnt.moduleEntity = cModule;
            cModule.Doors.push(doorEnt)
            cModule.Entities.push(doorEnt)

            for(let i in doorEnt.drawMeshes1f)
            {
                const mesh = doorEnt.drawMeshes1f[i];
                const pData = o.simbol1fPositions[i];
                const rData = o.simbol1fRotations[i];
                const sData = o.simbol1Scaling[i];
                
                mesh.parent = cModule.draw1fRootMesh;
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
            }

            for(let i in doorEnt.drawMeshes2f)
            {
                const mesh = doorEnt.drawMeshes2f[i];
                const pData = o.simbol2fPositions[i];
                const rData = o.simbol2fRotations[i];
                const sData = o.simbol2Scaling[i];

                mesh.parent = cModule.draw2fRootMesh;
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
            }

            for(let i in doorEnt.viewMeshes)
            {
                const mesh = doorEnt.viewMeshes[i];
                const pData = o.positions[i];
                const rData = o.rotations[i];
                const sData = o.scaling[i];
                
                mesh.position = new Vector3(pData._x,pData._y,pData._z)
                mesh.rotation = new Vector3(rData._x,rData._y,rData._z)
                mesh.scaling = new Vector3(sData._x,sData._y,sData._z)
                mesh.parent = cModule.viewRootMesh;
                mesh.setEnabled(true)
            }

            doorEnt.createCrushMesh(cModule);
        }

        cModule.setPenetrateMode(otherScene.floor)

        for(let o of cModule.Voids)
        {
            const y1 = o.drawMeshes1f[1].position.y;
            const y2 = o.drawMeshes2f[1].position.y;
            o.drawMeshes1f[1].position = o.drawMeshes1f[0].position.clone();
            o.drawMeshes2f[1].position = o.drawMeshes2f[0].position.clone();

            o.drawMeshes1f[1].position.y =y1;
            o.drawMeshes2f[1].position.y =y2;
        }

        for(let o of mData.units)
        {
            await this.CreateUnit(o.info.name,`./models/units/${o.info.name}/obj/`); 
            this.drawScene1f.createEntity.moduleEntity = cModule;
            this.drawScene1f.createEntity.viewRootMesh.position = new Vector3(o.position._x,o.position._y,o.position._z);
            this.drawScene1f.createEntity.viewRootMesh.rotation = new Vector3(o.rotation._x,o.rotation._y,o.rotation._z);
            this.drawScene1f.createEntity.viewRootMesh.scaling = new Vector3(o.scaling._x,o.scaling._y,o.scaling._z);
            this.drawScene1f.createEntity.viewRootMesh.parent = cModule.viewRootMesh;

            this.drawScene1f.createEntity.draw1fRootMesh.position = new Vector3(o.position._x,0,o.position._z);
            this.drawScene1f.createEntity.draw1fRootMesh.rotation = new Vector3(o.rotation._x,o.rotation._y,o.rotation._z);
            this.drawScene1f.createEntity.draw1fRootMesh.scaling = new Vector3(o.scaling._x,o.scaling._y,o.scaling._z);
            this.drawScene1f.createEntity.draw1fRootMesh.parent = cModule.draw1fRootMesh;

            this.drawScene1f.createEntity.draw2fRootMesh.position = new Vector3(o.position._x,0,o.position._z);
            this.drawScene1f.createEntity.draw2fRootMesh.rotation = new Vector3(o.rotation._x,o.rotation._y,o.rotation._z);
            this.drawScene1f.createEntity.draw2fRootMesh.scaling = new Vector3(o.scaling._x,o.scaling._y,o.scaling._z);
            this.drawScene1f.createEntity.draw2fRootMesh.parent = cModule.draw2fRootMesh;
            
            this.drawScene1f.createEntity.wallEnt.forEach(x=>{
                x.moduleEntity = cModule;
            });
            this.drawScene1f.createEntity.doorEnt.forEach(x=>{
                x.moduleEntity = cModule;
                x.setCrushModuleEntity();
            });
            this.drawScene1f.createEntity.stairEnt.forEach(x=>{
                x.moduleEntity = cModule;
                x.setCrushModuleEntity();
            });

            this.drawScene1f.createEntity.enableDoor = o.enableDoor;
            this.drawScene1f.createEntity.updateDoorEnable();

            this.drawScene1f.createEntity.createCrushMesh();
            this.drawScene1f.createEntity.floorPenetrate = o.floorPenetrate;
            this.drawScene2f.createEntity.setPenetrateMode(o.floorPenetrate)
            
            this.drawScene1f.createEntity.setEnable(this.drawScene1f.instance,true)
            this.drawScene2f.createEntity.setEnable(this.drawScene2f.instance,true)
            this.drawScene2f.createEntity.setEnable(null,true)

            this.drawScene1f.createEntity.info = o.info;

            cModule.Entities.push(this.drawScene1f.createEntity);
            cModule.Units.push(this.drawScene1f.createEntity);

            this.drawScene1f.createEntity = null;
            this.drawScene2f.createEntity = null;
        }

        if(mData?.guid != "" && mData?.guid != undefined && mData?.guid != null)
        {
            cModule.guid = mData.guid;
        }

        cModule.moduleBoxs.forEach(moduleBox => {
            moduleBox.dispose();
        });
        cModule.moduleBoxs = [];
        
        cModule.makeBox(cModule.drawScene1f.instance, '1F')
        cModule.makeBox(cModule.drawScene2f.instance, '2F')

        cModule.setEnable(cModule.locatedScene.instance,true);
        this.loadedModules++;
        return cModule;
    }

    CreateGuid()
    {
        const trNum = ()=>{
            return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return trNum() + trNum() + '-' + trNum() + '-' + trNum() + '-' +
        trNum() + '-' + trNum() + trNum() + trNum();
    }

    async CreateModule(moduleName,path,h,v)
    {
        this.CancelModule();
        
        const guid = this.CreateGuid();
        await this.EntityLoader.LoadModule(moduleName,path,h,v,this.drawScene1f,this.drawScene2f,this.viewScene,guid);
    }

    async CreateWindow(data)
    {
        this.CancelModule();
        const windowEnt = await this.EntityLoader.LoadWindow(data);
        windowEnt.info.guid = this.CreateGuid();
        this.drawScene1f.createEntity = windowEnt;
        this.drawScene2f.createEntity = windowEnt;
    }

    async CreateDoor(data)
    {
        this.CancelModule();
        const doorEnt = await this.EntityLoader.LoadDoor(data);
        doorEnt.info.guid = this.CreateGuid();
        this.drawScene1f.createEntity = doorEnt;
        this.drawScene2f.createEntity = doorEnt;
    }

    async CreateUnit(name,path)
    {
        this.CancelModule();
        const guid = this.CreateGuid();
        await this.EntityLoader.LoadUnit(name,path,this.drawScene1f,this.drawScene2f,this.viewScene,guid);
    }

    async CreateOpening()
    {
        this.CancelModule();
        this.drawScenes.forEach(d=>{
            d.isAddWallMode = true;
        });

        await this.EntityLoader.createOpening(this.drawScene1f,this.drawScene2f,this.viewScene);
    }

    CancelModule()
    {
        if(this.drawScene1f.createEntity?.info.ctgrName == "Module")
        {
            const index = this.EntityLoader.modules.indexOf(this.drawScene1f.createEntity);
            this.EntityLoader.modules.splice(index,1);
            this.drawScene1f.createEntity.dispose();
            this.drawScene1f.createEntity = null;
            this.drawScene2f.createEntity = null;
            this.drawScene1f.viewer.loadModuleName = '';
        }
        else if(this.drawScene1f.createEntity)
        {
            this.drawScene1f.createEntity.dispose();
            this.drawScene1f.createEntity = null;
            this.drawScene2f.createEntity = null;
            this.drawScene1f.viewer.loadModuleName = '';
        }
    }

    SelectedDelete()
    {
        var scene = this.drawScene1f;
        var selectedEntity = this.drawScene1f.selectedEntity;
        if(!selectedEntity)
        {
            scene = this.drawScene2f;
            selectedEntity = this.drawScene2f.selectedEntity;
        }

        if(!selectedEntity)
            return;

        if(selectedEntity.getButtonData().some(o => o.type == "delete"))
        {
            selectedEntity.delete(scene, selectedEntity);
            selectedEntity.disposeContextPanel();
        }
    }

    async CreateWall()
    {
        this.CancelModule();
        this.drawScenes.forEach(d=>{
            d.isAddWallMode = true;
        });

        await this.EntityLoader.createWall(this.drawScene1f,this.drawScene2f,this.viewScene);
    }

    Resize()
    {
        this.drawScenes.forEach(drawScene=>{
            const cam = drawScene.instance.getCameraByName("Camera");
            drawScene.cameraRender(cam);
        });
        this.viewScene.engine.resize();
        this.viewScene.setFloorText();
    }

    flipH()
    {
        const d1wordRoot = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene1f.instance);
        d1wordRoot.isVisible = false;
        d1wordRoot.position = Vector3.Zero();

        const d2wordRoot = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene1f.instance);
        d2wordRoot.isVisible = false;
        d2wordRoot.position = Vector3.Zero();

        const vWordRoot = BABYLON.MeshBuilder.CreateBox(null, {size : 1},this.drawScene1f.instance);
        vWordRoot.isVisible = false;
        vWordRoot.position = Vector3.Zero();

        for(let m of this.EntityLoader.modules)
        {
            m.draw1fRootMesh.parent = d1wordRoot;
            m.draw2fRootMesh.parent = d2wordRoot;
            m.viewRootMesh.parent = vWordRoot;
        }

        d1wordRoot.scaling.x *= -1;
        d2wordRoot.scaling.x *= -1;
        vWordRoot.scaling.x *= -1;

        for(let m of this.EntityLoader.modules)
        {
            CsgHelper.initialMatrix(m.draw1fRootMesh)
            CsgHelper.initialMatrix(m.draw2fRootMesh)
            CsgHelper.initialMatrix(m.viewRootMesh)
        }

        d1wordRoot.dispose();
        d2wordRoot.dispose();
        vWordRoot.dispose();
    }

    async undo()
    {
        if(this.historyIndex < 0)
            return;
        const hIndex = this.historyIndex--;
        
        const currentHistory = this.history[hIndex];
        await currentHistory.undo();
    }

    async redo()
    {
        if(this.historyIndex+1 >= this.history.length)
            return;

        const hIndex = ++this.historyIndex;
        
        const currentHistory = this.history[hIndex];
        await currentHistory.redo();
    }

    LoadType(targetTpye,path)
    {
        this.drawScene1f.isLoaded = false;
        this.drawScene2f.isLoaded = false;
        this.loadedModules = 0;

        targetTpye.typeModules.forEach(async m=>{
            await this.CreateModule(m.name,path+`${m.name}/obj/`);
            if(m.floor === '1F')
            {
                this.drawScene1f.createEntity.info.floor = m.floor;
                this.drawScene1f.createEntity.isGhost = false;
                this.drawScene1f.createEntity.setEnable(this.drawScene1f.instance,true);

                this.drawScene1f.createEntity.locatedScene = this.drawScene1f;

                this.drawScene1f.createEntity.setPosition(this.drawScene2f.instance,new Vector3(Number(m.positionX),0,Number(m.positionY)));
                this.drawScene1f.createEntity.setPosition(this.drawScene1f.instance,new Vector3(Number(m.positionX),3.1,Number(m.positionY)));
                this.drawScene1f.createEntity.draw2fRootMesh.position.y = 0;

                this.drawScene1f.createEntity.setViewModel(true);
                
                this.drawScene2f.createEntity.isGhost = false;
                this.drawScene2f.createEntity.setPenetrateMode(this.drawScene2f.floor)
                this.drawScene2f.createEntity.floorPenetrate = this.drawScene2f.floor;

                if(m.rotation != 0)
                {
                    const rotation = Number(m.rotation) * Math.PI / 180;
                    this.drawScene1f.createEntity.viewRootMesh.rotation = new Vector3(0,rotation,0);
                    this.drawScene1f.createEntity.draw1fRootMesh.rotation = new Vector3(0,rotation,0);
                    this.drawScene1f.createEntity.draw2fRootMesh.rotation = new Vector3(0,rotation,0);
                }

                if(m.flipX)
                {
                    this.drawScene1f.createEntity.viewRootMesh.scaling.x *= -1;
                    this.drawScene1f.createEntity.draw1fRootMesh.scaling.x *= -1;
                    this.drawScene1f.createEntity.draw2fRootMesh.scaling.x *= -1;
                }
                if(m.flipY)
                {
                    this.drawScene1f.createEntity.viewRootMesh.scaling.z *= -1;
                    this.drawScene1f.createEntity.draw1fRootMesh.scaling.z *= -1;
                    this.drawScene1f.createEntity.draw2fRootMesh.scaling.z *= -1;
                }

                this.drawScene1f.createEntity.moduleBoxs.forEach(mbox=>mbox.isVisible = false);
                this.drawScene1f.createEntity = null;
                this.drawScene2f.createEntity = null;
            }
            else
            {
                this.drawScene2f.createEntity.info.floor = m.floor;
                this.drawScene2f.createEntity.isGhost = false;
                this.drawScene2f.createEntity.setEnable(this.drawScene2f.instance,true);
                
                this.drawScene2f.createEntity.locatedScene = this.drawScene2f;
                this.drawScene2f.createEntity.setPosition(this.drawScene1f.instance,new Vector3(Number(m.positionX),0,Number(m.positionY)));
                this.drawScene2f.createEntity.setPosition(this.drawScene2f.instance,new Vector3(Number(m.positionX),3.1,Number(m.positionY)));
                this.drawScene1f.createEntity.draw1fRootMesh.position.y = 0;

                this.drawScene2f.createEntity.setViewModel(true);
                
                this.drawScene1f.createEntity.isGhost = false;
                this.drawScene1f.createEntity.setPenetrateMode(this.drawScene1f.floor)
                this.drawScene1f.createEntity.floorPenetrate = this.drawScene1f.floor;

                if(m.rotation != 0)
                {
                    const rotation = Number(m.rotation) * Math.PI / 180;
                    this.drawScene1f.createEntity.viewRootMesh.rotation = new Vector3(0,rotation,0);
                    this.drawScene1f.createEntity.draw1fRootMesh.rotation = new Vector3(0,rotation,0);
                    this.drawScene1f.createEntity.draw2fRootMesh.rotation = new Vector3(0,rotation,0);
                }

                if(m.flipX)
                {
                    this.drawScene1f.createEntity.viewRootMesh.scaling.x *= -1;
                    this.drawScene1f.createEntity.draw1fRootMesh.scaling.x *= -1;
                    this.drawScene1f.createEntity.draw2fRootMesh.scaling.x *= -1;
                }
                if(m.flipY)
                {
                    this.drawScene1f.createEntity.viewRootMesh.scaling.z *= -1;
                    this.drawScene1f.createEntity.draw1fRootMesh.scaling.z *= -1;
                    this.drawScene1f.createEntity.draw2fRootMesh.scaling.z *= -1;
                }

                this.drawScene1f.createEntity.moduleBoxs.forEach(mbox=>mbox.isVisible = false);
                this.drawScene1f.createEntity = null;
                this.drawScene2f.createEntity = null;
            }
            this.loadedModules++;
        });

        this.drawScene1f.isLoaded = true;
        this.drawScene2f.isLoaded = true;

        const checkInterval = setInterval(()=>{
            if(targetTpye.typeModules.length === this.loadedModules)
            {
                this.drawScene1f.updateWall();
                this.loadedModules = -100;
                clearInterval(checkInterval)
            }
        },100);
    }
}