import EntityBase from "./EntityBase";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import SpotItem from '../Items/SpotItem'
import CsgHelper from '../Helper/CsgHelper'

export default class DockingEntity extends EntityBase{
    constructor()
    {
        super();
        this.crushMesh = null;
        this.crushMesh1f = null;
        this.crushMesh2f = null;
        this.isDock = true;
    }

    getCrushMeshes()
    {
        return [this.crushMesh,this.crushMesh1f,this.crushMesh2f];
    }

    setModule(moduleEnt)
    {
        this.moduleEntity = moduleEnt;
        this.drawMeshes1f.forEach(o=>o.parent = moduleEnt.draw1fRootMesh);
        this.drawMeshes2f.forEach(o=>o.parent = moduleEnt.draw2fRootMesh);
        this.viewMeshes.forEach(o=>o.parent = moduleEnt.viewRootMesh);
    }

    setCrushModuleEntity()
    {
        this.crushMesh.moduleEntity = this.moduleEntity;
        this.crushMesh1f.moduleEntity = this.moduleEntity;
        this.crushMesh2f.moduleEntity = this.moduleEntity;
    }

    createCrushMesh(root = null)
    {
        let rootEnt = root ? root : this.moduleEntity;
        if(this.crushMesh)
        {
            this.crushMesh.dispose();
        }
        const crushMesh = this.viewMeshes.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh)
            return;
        crushMesh.material = this.viewScene.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh = this.crushMeshSetting(crushMesh,rootEnt.viewRootMesh);

        if(this.crushMesh1f)
        {
            this.crushMesh1f.dispose();
        }
        const crushMesh1f = this.drawMeshes1f.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh1f)
            return;
        crushMesh1f.material = this.drawScene1f.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh1f = this.crushMeshSetting(crushMesh1f,rootEnt.draw1fRootMesh);

        if(this.crushMesh2f)
        {
            this.crushMesh2f.dispose();
        }
        const crushMesh2f = this.drawMeshes2f.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh2f)
            return;
        crushMesh2f.material = this.drawScene2f.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh2f = this.crushMeshSetting(crushMesh2f,rootEnt.draw2fRootMesh);
    }

    crushMeshSetting(crushMesh,rootMesh)
    {
        const pRot = CsgHelper.getParentRotation(crushMesh);
        const size = 0.45;
        const boxInfo = crushMesh.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;
        const length = width > depth ? width : depth;
        const newBox = BABYLON.MeshBuilder.CreateBox(null,{width : length, height : height, depth : size},crushMesh._scene);

        newBox.position = crushMesh.position.clone();
        newBox.rotation = crushMesh.rotation.clone();
        newBox.scaling = crushMesh.scaling.clone();
        newBox.material = crushMesh.material;

        crushMesh.dispose();
        crushMesh = newBox;
        crushMesh.parent = null;
        crushMesh.id = "crushMesh";
        
        const allWallMesh = this.parent.viewMeshes.concat(this.parent.getDrawMeshes())
        const wallMesh = allWallMesh.find(x=>x._scene === crushMesh._scene);
        let wallPos =  wallMesh.getAbsolutePosition().clone();
        const tempCrush = crushMesh.clone();
        tempCrush.parent = rootMesh;
        tempCrush.setAbsolutePosition(wallPos);
        wallPos = tempCrush.position.clone();
        tempCrush.dispose();

        const wallType = this.parent.getWorldWallType().type;
        const sin = Math.abs(Number(Math.sin(rootMesh.rotation.y).toFixed(2)));
        const cos = Math.abs(Number(Math.cos(rootMesh.rotation.y).toFixed(2)));
        
        if(wallType === "Horizontal")
        {
            crushMesh.position.x = wallPos.x * sin + crushMesh.position.x * cos;
            crushMesh.position.z = wallPos.z * cos + crushMesh.position.z * sin;
            crushMesh.rotation.y = 0 - pRot
        }
        else
        {
            crushMesh.position.x = wallPos.x * cos + crushMesh.position.x * sin;
            crushMesh.position.z = wallPos.z * sin + crushMesh.position.z * cos;
            crushMesh.rotation.y = Math.PI/2 - pRot
        }
        // CsgHelper.initialMatrix(crushMesh)

        crushMesh.showBoundingBox = true;
        crushMesh.originPos = crushMesh.position.clone();
        crushMesh.moduleEntity = this.moduleEntity;
        crushMesh.rootMesh = rootMesh;
        crushMesh.parent = rootMesh;
        crushMesh.isVisible = false;
        crushMesh.entity = this;
        crushMesh.setEnabled(true); 
        crushMesh.spot = new SpotItem(crushMesh,crushMesh._scene,this.parent.wallType, rootMesh._scene != this.viewScene.instance)
        crushMesh.spot.includedOnlyMeshes.push(wallMesh);
        //crushMesh.spot.parent = rootMesh;

        return crushMesh;
    }

    dispose()
    {
        if(this.crushMesh)
        {
            this.crushMesh.spot.delete();
            this.crushMesh.dispose();
        }
        if(this.crushMesh1f)
        {
            this.crushMesh1f.spot.delete();
            this.crushMesh1f.dispose();
        }
        if(this.crushMesh2f)
        {
            this.crushMesh2f.spot.delete();
            this.crushMesh2f.dispose();
        }
        this.drawMeshes1f.forEach(m=>m.dispose());
        this.drawMeshes2f.forEach(m=>m.dispose());
        this.viewMeshes.forEach(m=>m.dispose());

        if(this.moduleEntity)
        {
            this.moduleEntity.Entities = this.moduleEntity.Entities.filter(x => x != this);
            this.moduleEntity.Doors = this.moduleEntity.Doors.filter(x => x != this);
            this.moduleEntity.Openings = this.moduleEntity.Openings.filter(x => x != this);
            this.moduleEntity.Windows = this.moduleEntity.Windows.filter(x => x != this);
        }
    }

    snapMove(pos)
    {
        const meshes = this.viewMeshes.concat(this.getDrawMeshes()).concat(this.getCrushMeshes());
        
        for(let m of meshes)
        {
            const mPos = m.getAbsolutePosition();

            if(this.parent.getWorldWallType().type == "Vertical")
            {
                mPos.z = pos.z;
            }
            else
            {
                mPos.x = pos.x;
            }
            m.setAbsolutePosition(mPos);
        }
    }

    deleteEntity(scene, entity)
    {
        entity.moduleEntity.deleteChild(scene, entity);
        entity.moduleEntity.isFixed = true;
        entity.dispose();
    }

    // updateBox()
    // {
    //     this.crushMesh.spot.guidLine.dispose();
    //     this.crushMesh.spot.dispose();
    //     this.crushMesh.dispose();
        
    //     const m = this.viewMeshes.find(x=>x.info.ctgrName === this.info.ctgrName);
    //     var pos = m.position.clone();
    //     this.viewMeshes.concat(this.drawMeshes1f).concat(this.drawMeshes2f).forEach(mesh =>
    //     {
    //         if(mesh.info.ctgrName === 'Box')
    //             mesh.position = pos;
    //     });
        
    //     this.createCrushMesh();
    // }
}