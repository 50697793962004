<template>

</template>

<script>
export default {

    data() {
        return {
            param1: "",
        }
    },
    mounted() {

        this.param2 = this.$route.query.param2;
        console.log(this.param2);
        //window.location.href = 'http://localhost:64596/checkplus_main_join.asp?param2=' + this.param2;
        window.location.href = this.$AUTHURL + 'checkplus_main_join.asp?param2=' + this.param2;
       // window.location.href = this.$SERVERURL + 'checkplus_main_join.asp?param2=' + this.param2;

       //window.location.href = this.$SERVERURL + 'authtest';
        //this.testAuth();
    },
    methods: {
        async testAuth() {
            //await axios.get(this.$SERVERURL + "authtest");
        }
    }

}
</script>