export const infoData = [
    {
        info:'개인정보 처리방침',
        data:'2022-07-30',
        txt:`자이가이스트(이하 '회사'라 한다)은 개인정보보호법 제30조에 따라 정보주체의 개인정보를 보호하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 다음과 같이 개인정보처리방침을 수립ㆍ공개합니다.`,
        title:'제1조 개인정보의 처리목적',
        subtitle:'회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.',
        th1:'수집방법',
        th2:'수집항목',
        th3:'수집 및 이용목적',
        th4:'보유 및 이용기간',
        td1_1:'회원가입',
        td1_2:`필수정보 : 로그인ID,비밀번호,성명,성별,생년월일,휴대전화번호<br>
                선택정보 : 이메일, 주소, 가입경로, 관심분야`,
        td1_3:'서비스 이용 및 상담, 고객문의 회신, 분쟁 조정을 위한 기록 보존, 민원처리 등',

        td2_1:'간편가입',
        td2_2:'이메일 주소, 이름, 휴대전화 번호',
        td3_1:'휴대전화 인증',
        td3_2:'휴대전화 번호',
        td3_3:'서비스 제공 시 절차 안내 및 마케팅 정보 수신',
        
        td4_1:'고객상담(간편)',
        td4_2:`필수정보 : 이름, 연락처, 비밀번호, 상담내용<br>
                선택정보 : 첨부파일`,

        td5_1:'고객상담(견적)',
        td5_2:`필수정보 : 성명, 휴대전화번호, 비밀번호, 상담내용<br>
                선택 정보: 건축예상평수, 건축예정시기, 건축예정금액, 토지소유여부`,
        td5_3:`회원관리, 본인여부확인, 서비스 상담, 견적서 제공, 서비스 개선을 위한 Data 분석, 문의자 확인, 문의에 대한 회신 등의 처리`,
        td5_4:'회원탈퇴 및 목적달성 후 지체없이 삭제합니다',

        td6_1:'전화 상담신청',
        td6_2:'이름, 연락처, 주소',
        
        td7_1:'방문예약',
        td7_2:`필수정보 : 성명, 연락처, 신청날짜, 신청시간<br>
                선택정보 : 동행자 수`,

        td8_1:`사전점검(입주 전)<br>
                A/S(입주 후)`,
        td8_2:`필수정보 : 성명, 연락처, 단지<br>
            선택정보 : 동/호수, 첨부파일`,
        td8_3:'하자 문의 내용 처리',

        td9_1:'계약서 열람, 조회',
        td9_2:'이름, 연락처, 주소, 계약 시 견적서 및 계약자 정보',
        td9_3:'계약서 열람 처리',

        td10_1:'자동 수집 항목',
        td10_2:'서비스 안내, 서비스 개선을 위한 Data분석 등',

        title2:'제2조 개인정보의 처리 및 보유기간',
        content2:`
        1. 회사는 법령에 따른 개인정보의 보유ㆍ이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유ㆍ이용 기간 내에서 개인정보를 처리ㆍ보유합니다.<br>	 
        2. 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다.<br>	 	 
        3. 고객센터를 통한 상담 과정에서 웹 페이지, 메일, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.<br>	 	 
        4. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 
            1) 회원정보 : 회원 탈퇴 후 1개월 일까지<br>	 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
            2) 서비스 이용에 따른 채권∙채무관계 정산시 까지<br>	 	 
        5. 1년 간 회원의 서비스 이용 기록이 없는 경우,  『정보통신망 이용촉진 및 정보보호등에 관한 법률』 제 39 조 6에 근거하여 회원에게 사전 통지하고, 별도 보관 5년 후 삭제합니다.<br>	 	 
        6. 회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 이벤트 혜택 등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과 불법행위를 하는 회원을 차단 하고자 회원 탈퇴 후 1개월 동안 보관합니다.<br>	 	 
        7. 단, 전자상거래 등에서의 소비자보호 법률 등 관련 법령의 규정에 따라 거래 관계 확인을 위해 개인정보를 일정기간 보유 할 수 있습니다.`,
        th5:'법령',
        th6:'항목',
        th7:'기간',
        td5_1_1:'전자상거래 등에서의 소비자보호에 관한 법률',
        td5_1_2:`계약 또는 청약철회 등에 관한 기록<br>
                대금결제 및 재화 등의 공급에 관한 기록<br>
                소비자의 불만 또는 분쟁 처리에 관한 기록`,
        td5_1_3:`5년<br>
                5년<br>
                3년`,

        td6_1_1:'위치정보의 보호 및 이용 등에 관한 법률',
        td6_1_2:'개인위치정보에 관한 기록',
        td6_1_3:'6개월',

        td7_1_1:'전자금융거래법',
        td7_1_2:'전자금융 거래에 관한 기록',
        td7_1_3:'5년',

        td8_1_1:'통신비밀보호법',
        td8_1_2:'서비스 이용 관련 개인정보(로그기록)',
        td8_1_3:'3년',
        
        title3:'제3조 개인정보의 제3자 제공',
        content3:`회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 단, 아래의 경우에는 예외로 합니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        - 이용자들이 사전에 동의한 경우<br> 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 
        - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`,

        title4:'제4조 개인정보의 처리 위탁',
        content4A:'	1. 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.',
        td9_1_1:'위탁받는자(수탁자)',
        td9_1_2:'위탁 업무 내용',
        td9_1_3:'개인정보의 보유 및 이용기간',
        td10_1_1:'디엔아이(주)',
        td10_1_2:'정보 전산 처리 및 유지관리, 웹사이트 운영',
        td10_1_3:'회원탈퇴시 혹은 위탁계약 종료 시 까지',
        td11_1_1:'LG CNS, 톡드림',
        td11_1_2:'SMS/LMS/MMS/카카오비즈톡 등 메시지 전송',
        td12_1_1:'나이스평가정보(주)',
        td12_1_2:'본인인증',
        

        content4B:'2. 회사는 위탁계약 체결 시 개인정보보호법 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적ㆍ관리적 보호조치, 재위탁제한, 수탁자에 대한 관리ㆍ감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.',
        content4C:'	3. 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보처리방침을 통하여 공개하도록 하겠습니다.',
        title5:`제5조 정보주체의 권리·의무 및 행사방법`,
        content5:`
        1. 정보주체는 회사에 대해 언제든지 다음 각 호의 개인정보보호 관련 권리를 행사할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
            1) 개인정보 열람 요구<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 	 
            2) 오류 등이 있을 경우 정정 요구<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 	 
            3) 삭제 요구<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 	 
            4) 처리정지 요구<br>	 	 	 	 	 	 	 	 	 	 
        2. 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.<br>	 
        3. 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.<br>	 
        4. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.<br>	 
        5. 정보주체는 개인정보보호법 등 관계 법령을 위반하여 회사가 처리하고 있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 안됩니다.`,
        title6:'제6조 자동으로 수집되는 개인정보 및 거부에 관한 사항',
        subtitle6:'회사는 이용자의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등을 운용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저에 보내는 아주 작은 텍스트 파일로 이용자의 컴퓨터 하드디스크에 저장됩니다.',
        content6:`
        1. 쿠키의 사용 목적<br>	 
	 
        - 회원과 비회원의 접속 빈도나 방문 시간 등의 분석, 이용 형태 및 관심분야 파악, 자취 추적, 각종 이벤트 참여 정도, 방문 횟수 파악 등을 통한 타겟 마케팅 등 개인별 맞춤 서비스를 제공하기 위해 쿠키를 사용 합니다.<br><br> 	 
 	
        2. 쿠키의 설치, 운영과 거부<br>	
        - 이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.<br>	<br>
        - 쿠키의 설정은 웹브라우저별 옵션 선택을 통해<br>①모든 쿠키를 허용, ②쿠키가 저장될 때 마다 확인, ③모든 쿠키의 저장을 거부 할 수 있습니다.<br><br>	 
        - 이러한 쿠키의 설정 방법은 웹브라우저에 따라 차이가 있으므로, 자세한 사항은 각 브라우저별 도움말을 참고해 주시기 바랍니다.<br>	  	 
        - Chrome : 설정 메뉴 선택 > 고급 설정 표시 선택 > 개인정보-콘텐츠 설정 선택 > 쿠키 수준 설정<br>	
        - firefox : 옵션 메뉴 선택 > 개인정보 선택 > 방문기록-사용자 정의 설정 > 쿠키 수준 설정<br>	  	 
 	- safari : 환경설정 메뉴 선택 > 개인정보 탭 선택 > 쿠키 및 웹 사이트 데이터 수준 설정<br>	   	 
 	- 단, 쿠키의 저장을 거부하였을 경우에는 로그인이 필요한 서비스 등 일부 서비스 이용에 어려움이 있을 수 있습니다.<br><br>		  	 
 	3. 인터넷 서비스 이용 과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.<br>	 
 	- IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등`,
        title7:'제7조 개인정보의 파기',
        subtitle7:`회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다.<br> 다만, 다른 법률에 따라 보존하여야 하는 경우에는 그러하지 않습니다.	 
        회사의 개인정보 파기 절차 및 방법은 다음과 같습니다.`,
        content7:`
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        - 파기절차: 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 재생할 수 없는 방법에 의하여 완전히 삭제되며, 추후 열람이나 이용이 불가능한 상태로 파기됩니다.	<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	  
 	 	- 파기방법: 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`,
        title8:'제8조 개인정보의 안전성 확보조치',
        subtitle8:`회사는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적, 관리적, 물리적	 
        조치를 하고 있습니다. 단, 고객 개인의 부주의나 인터넷 상의 문제로 개인정보가 유출되어 발생한 문제에 대해서는 회사는 일체의 책임을 지지 않습니다.`,
        content8:`
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        1. 개인정보의 안전한 관리를 위해 개인정보보호위원회의 개인정보보호법과 동법 시행령 및 표준 개인정보보호지침을 준수하여 시행하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        2. 개인정보취급담당자의 최소화 및 교육: 개인정보를 취급하는 담당자를 지정하고 최소화하며 담당자에 대한 수시 교육을 통해 개인정보를 안전하게 관리하고 있습니다.	<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
        3. 개인정보에 대한 접근: 개인정보를 처리하는 데이터 베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통하여 개인정보에 대한 접근통제에 필요한 조치를 하고 있으며 침입차단시스템을<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        이용하여 외부로부터의 무단 접근을 통제하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        4. 접속기록의 보관 및 위∙변조: 개인정보처리시스템에 접속한 기록(웹 로그, 요약정보 등)을 최소 1년 이상 보관, 관리하고 있으며, 접속 기록이 위∙변조 및 도난, 분실되지 않도록 보안기능을<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;사용하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        5. 개인정보의 암호화: 고객님의 중요 개인정보는 암호화 되어 저장 및 관리되고 있습니다. 또한 중요한 데이터는 저장 및 전송 시 암호화하여 사용하는 등의 별도 보안기능을 사용하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        6. 해킹 등에 대비한 기술적 대책: 회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신 • 점검을 하며 외부로부터 접근이 통제된<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다. 또한 네트워크 트래픽의 통제(Monitoring)는 물론 불법적으로 정보를 변경하는 등의 시도를 탐지하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        7. 개인정보의 안전한 보관을 위한 물리적 조치: 개인정보를 보관하고 있는 개인정보시스템의 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다. 또한 개인정보가<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;기록된 문서의 안전한 보관을 위하여 잠금 장치의 설치 등 물리적 조치를 취하고 있습니다.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        8. 개인정보보호 전담조직의 운영: 사내 개인정보보호 전담조직 등을 통해 개인정보보호조치의 이행사항 및 담당자의 준수여부를 확인하여 문제 발견 시 즉시 시정 조치하도록 하고 있습니다.`,
        title9:'제9조 개인정보 자동 수집 장치의 설치ㆍ운영 및 거부에 관한 사항',
        content9:`&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        1. 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.<br>	 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
 	    2. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.<br>
         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 	 	    - 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.<br>	 
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 	 	    - 쿠키의 설치∙운영 및 거부: 웹 브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.<br>	 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
 	 	    - 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.`,
        title10:'제10조 개인정보보호 책임자',
        content10A:'1. 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다',
        th8:'개인정보 책임자',
        th9:'개인정보 관리자',
        th10:'개인정보 민원 처리 담당부서',
        td13_1_1:'성명 : 남경호',
        td13_1_2:'성명 : 이준영 책임',
        td13_1_3:'부서명 : 고객센터',
        td14_1_1:'대표',
        td14_1_2:'소속 : PM팀',
        td14_1_3:'웹사이트 내 고객 문의',
        content10B:'	2. 정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.',
        title11:'제11조 개인정보 열람청구',
        subtitle11:'정보주체는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.',
        content11:`
        1. 개인정보 열람청구 접수∙처리 부서<br>	 	 	 	 	 	 	 	 	 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        부서명: 자이가이스트 고객센터<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 	 
        연락처: 02-2154-4311 / xigeist.official@gmail.com<br>	 	 	 	 	 	 	 	 	 
        2. 웹 사이트 고객 문의<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	        	 	 	 
        URL: https://www.xigeist.com >고객지원>1:1문의
        `,
        title12:'제12조 권익침해 구제방법',
        content12:`
        정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.<br>	 
        아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다.<br>
        1. 개인정보 침해신고센터(한국인터넷진흥원 운영)<br>	 
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 
            - 소관업무: 개인정보 침해사실 신고, 상담 신청<br> 	 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 
            - 홈페이지: privacy.kisa.or.kr<br>	
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 	 	 	 	 	 	 	 
            - 전화: (국번없이) 118<br>	
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 	 	 	 	 	 	 	 
            - 주소: (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터<br>	 
   2. 개인정보 분쟁조정위원회<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 
        - 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)<br>	
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
        - 홈페이지: www.kopico.go.kr<br>	
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 	 	 	 	 	 
        - 전화: (국번없이) 1833-6972<br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;		 	 	 	 	 	 	 	 	 	 	 	 
        - 주소: (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층<br>	 	 
   3. 대검찰청 사이버범죄수사단: 02-3480-3573 (www.spo.go.kr)<br>	 	 
   4. 사이버범죄 신고시스템: 182 (http://ecrm.cyber.go.kr)<br>	 
        `,

    title13:'제13조 개인정보처리방침 변경',
    subtitle13:'이 개인정보처리방침은 2022.07.30부터 적용됩니다.'

    }
]