<template>
    <div id="joinStep2_Wrapper">
        <div id="joinStep2_Body">
            <section id="formArea" class="joinStep2_Container">
                <hgroup>
                    <h1>회원가입</h1>
                    <p>
                        자이가이스트의 회원이 되면<br>
                        보다 나은 서비스를 이용하실 수 있습니다.
                    </p>
                </hgroup>

                <ol>
                    <li class="on">
                        <img src="../../assets/images/icons/icon_checked.png" alt="">
                        <span>약관동의</span>
                    </li>

                    <li class="bar"></li>
                    <li class="on">
                        <span class="num" style=" color:rgba(28,26,27,1);">02</span>
                        <span>정보입력</span>
                    </li>
                    <li class="bar"></li>

                    <li class="on bgGray">
                        <span class="num" style=" color:rgba(204,204,204,1);">03</span>
                        <span>가입완료</span>
                    </li>
                </ol>

                <form action="" id="joinForm">
                    <label for="" class="mustReg hasBtn">
                        <!-- <span>
                            <i>아이디</i>
                        </span>

                        <div id="idInput">
                             <input type="text" id="userLoginId" name="userLoginId" placeholder="아이디 입력(영문 / 숫자 6-12자 이내)" v-model="userid" @blur="validateUserID">
                            
                        <button class="style02" type="button" @click="checkId">중복확인</button>
                        </div> -->
                        <span>
                            <i>이메일</i>
                        </span>

                        <div id="emailInput">
                            <div>
                                <input type="text" id="emailID" name="emailId" title="이메일주소" placeholder="이메일 입력"
                                    v-model="email_first" @blur="validateEmailFirst" @change="updateEmailDomain">
                                <strong>@</strong>
                                <input type="text" id="emailDomain" name="emailDomain" title="이메일 서비스 업체" readonly
                                    v-model="emailDomain">
                            </div>

                            <div class="selectRemake">
                                <select name="emailSelect" id="emailSelect" v-model="email_last"
                                    @change="updateEmailDomain">
                                    <option value="">선택하세요</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="gmail.com">gmail.com</option>
                                    <option value="daum.net">daum.net</option>
                                    <option value="hanmail.net">hanmail.net</option>
                                    <option value="nate.com">nate.com</option>
                                    <option value="hotmail.com">hotmail.com</option>
                                </select>
                                <a href="/" class="selectRemaker" title="이메일서비스업체 선택 옵션선택"></a>

                                <button type="button" @click="checkEmail" class="btn">중복확인</button>
                            </div>
                        </div>

                    </label>

                    <label for="" class="mustReg hasBtn">
                        <span>
                            <i>비밀번호</i>
                        </span>

                        <input type="password" id="userPwd" name="userPwd" placeholder="비밀번호 입력(8자 이상 문자, 숫자, 특수문자 사용)"
                            autocomplete="off" v-model="password" @blur="validatePassword">
                    </label>

                    <label for="confirnPwd" class="mustReg hasBtn">
                        <span>
                            <i>비밀번호 확인</i>
                        </span>

                        <input type="password" id="confirnPwd" name="confirnPwd"
                            placeholder="비밀번호 입력(8자 이상 문자, 숫자, 특수문자 사용)" autocomplete="off" v-model="password_verify"
                            @blur="validatePasswordMatch">
                    </label>

                    <label for="userName" class="mustReg hasBtn">
                        <span>
                            <i>이름</i>
                        </span>

                        <div id="nameInput">
                            <input type="text" id="userName" name="userName" placeholder="이름 입력" v-model="name"
                                @blur="validateName">

                            <div>
                                <span>
                                    <i>성별</i>
                                </span>
                                <label for="userMale">
                                    <input type="radio" id="userMale" name="userGndr" value="남" v-model="genderPick">
                                    <p>남</p>
                                </label>

                                <label for="userFemale">
                                    <input type="radio" id="userFemale" name="userGndr" value="여" v-model="genderPick">
                                    <p>여</p>
                                </label>
                            </div>

                        </div>




                        <div class="inlineStyle">



                        </div>
                    </label>

                    <label class="mustReg" for="userBday">
                        <span>
                            <i>생년월일</i>
                        </span>
                        <input type="text" id="userBday" placeholder="생년월일 8자리 입력(예, 19990101)" v-model="birthdate"
                            @blur="validateBirthdate">
                    </label>

                    <label class="mustReg" for="userPhone">
                        <span>
                            <i>휴대폰번호</i>
                        </span>
                        <input type="tel" id="userPhone" placeholder="휴대폰번호 입력('-'제외하고 입력)" v-model="phonenumber"
                            @blur="validatePhoneNumber">
                    </label>

                    <!-- <label for="emailId">
                        <span>
                            <i>이메일</i>
                        </span>

                        <div id="emailInput">
                            <div>
                                <input type="text" id="emailID" name="emailId" title="이메일주소" placeholder="이메일 입력"
                                    v-model="email_first" @blur="validateEmailFirst">
                                <strong>@</strong>
                                <input type="text" id="emailDomain" name="emailDomain" title="이메일 서비스 업체" readonly v-model="emailDomain"> 
                            </div>


                            <div class="selectRemake">
                                <select name="emailSelect" id="emailSelect" v-model="email_last" @change="updateEmailDomain">
                                    <option value="">선택하세요</option>
                                    <option value="naver.com">naver.com</option>
                                    <option value="gmail.com">gmail.com</option>
                                    <option value="daum.net">daum.net</option>
                                    <option value="hanmail.net">hanmail.net</option>
                                    <option value="nate.com">nate.com</option>
                                    <option value="hotmail.com">hotmail.com</option>
                                </select>
                                <a href="/" class="selectRemaker" title="이메일서비스업체 선택 옵션선택"></a>
                            </div>
                        </div>

                    </label> -->

                    <label for="userPcd" class="hasBtn">
                        <span>주소</span>
                        <div id="addressInput">
                            <input type="text" id="userPcd" readonly v-model="zonecode">
                            <button type="button" class="btn" @click="openPostcode">우편번호 검색</button>
                        </div>
                    </label>

                    <label for="usrBaseAddr">
                        <span></span>
                        <input type="text" id="userBaseAddr" name="userBaseAddr" readonly v-model="roadAddress">
                    </label>

                    <label for="usrDetAddr">
                        <span></span>
                        <input type="text" id="usrDetAddr" name="usrDetAddr" v-model="detailAddress">
                    </label>
                    <hr>
                    <div class="checkboxContainer">
                        <h1>가입경로(선택)</h1>

                        <div>
                            <label for="rootbyweb">
                                <input type="checkbox" id="rootbyweb" value="rootbyweb" v-model="checkboxes1.rootbyweb"
                                    @change="() => onClickChangeCheckbox('rootbyweb')">
                                <img :src="checkboxes1.rootbyweb ? checkboxIcons.checked : checkboxIcons.unchecked">
                                <p>웹검색</p>
                            </label>


                            <label for="rootbymedia">
                                <input type="checkbox" id="rootbymedia" value="rootbymedia"
                                    v-model="checkboxes1.rootbymedia"
                                    @change="() => onClickChangeCheckbox('rootbymedia')">
                                <img :src="checkboxes1.rootbymedia ? checkboxIcons.checked : checkboxIcons.unchecked">
                                <p>영상광고</p>
                            </label>
                        </div>
                    </div>

                    <div class="checkboxContainer">
                        <h1>관심분야(선택)</h1>

                        <div>
                            <label for="interestArc">
                                <input type="checkbox" id="interestArc" value="interestArc"
                                    v-model="checkboxes2.interestArc"
                                    @change="() => onClickChangeCheckbox('interestArc')">
                                <img :src="checkboxes2.interestArc ? checkboxIcons.checked : checkboxIcons.unchecked">
                                <p>건축계약</p>
                            </label>

                            <label for="interestView">
                                <input type="checkbox" id="interestView" value="interestView"
                                    v-model="checkboxes2.interestView"
                                    @change="() => onClickChangeCheckbox('interestView')">
                                <img :src="checkboxes2.interestView ? checkboxIcons.checked : checkboxIcons.unchecked">
                                <p>홍보관 관람</p>
                            </label>

                            <label for="meet">
                                <input type="checkbox" id="meet" value="meet" v-model="checkboxes2.meet"
                                    @change="() => onClickChangeCheckbox('meet')">
                                <img :src="checkboxes2.meet ? checkboxIcons.checked : checkboxIcons.unchecked">
                                <p>1:1 문의</p>
                            </label>
                        </div>
                    </div>
                </form>
                <div class="formBtnArea">
                    <button type="button" class="sizeM style04">취소</button>
                    <button type="button" class="sizeM style03" @click="PostMemberInfo">확인</button>
                </div>
            </section>

        </div>

        <bottomInfo />
        <Footer />
        <scrollToTop />
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/joinStep2.css');
</style>

<script>
import Footer from '../Login/joinStep1Footer.vue';
import bottomInfo from '../Login/bottomInfo.vue';
import scrollToTop from '../Login/scrollToTop.vue'
import axios from 'axios';
import CryptoJS from "crypto-js";
export default {
    components: {
        Footer,
        bottomInfo,
        scrollToTop
    },
    data() {
        return {
            userid: "",
            password: "",
            password_verify: "",
            name: "",
            birthdate: "",
            phonenumber: "",
            email_first: "",
            email_last: "",
            emailDomain: '',
            email: '',
            zonecode: "",
            roadAddress: "",
            detailAddress: "",
            genderPick: "",
            isEmailValid: "false",
            address: "",
            path: "",
            interest: "",
            key: "",
            nickname: "",
            code: "",
            hashPw: "",
            param2: "",
            receiveSns: "",
            receiveEmail: "",

            // 가입경로, 관심분야 체크박스 관련
            checkboxes1: {
                rootbyweb: false,
                rootbymedia: false,
            },
            checkboxes2: {
                interestArc: false,
                interestView: false,
                meet: false
            },
            checkboxIcons: {
                checked: require('../../assets/images/icons/checkbox_checked.png'),
                unchecked: require('../../assets/images/icons/checkbox.png')
            }
        }
    },

    computed: {
        currentIcons() {
            return this.checkboxIcons[this.isChecked ? 'checked' : 'unchecked'];
        }
    },

    mounted() {
        this.param2 = this.$route.query.param2;
        if(this.param2 == undefined) {
            alert("올바른 접근이 아닙니다");
            //window.location.href = "http://localhost:8080/member/joinstep1";
            this.$router.push('/member/joinstep1');
        } else {
            if(this.param2 == "0") { //둘다 안 동의
                this.receiveSns= "N";
                this.receiveEmail = "N";
            } else if(this.param2 == "1") {  //SNS동의
                this.receiveSns= "Y";
                this.receiveEmail = "N";
            } else if(this.param2 == "2") { //이메일동의
                this.receiveSns= "N";
                this.receiveEmail = "Y";
            } else if(this.param2 == "3") { //둘다동의
                this.receiveSns= "Y";
                this.receiveEmail = "Y";
            }
        }
    },
    methods: {
        PostMemberInfo() {
            if (this.validation()) {
                let today = new Date();
                if(this.checkboxes1.rootbyweb) {
                    this.path += "웹검색,";
                }
                if(this.checkboxes1.rootbymedia) {
                    this.path += "영상광고,";
                }
                if(this.checkboxes2.interestArc) {
                    this.interest += "건축계약,";
                }
                if(this.checkboxes2.interestView) {
                    this.interest += "홍보관 관람,";
                }
                if(this.checkboxes2.meet) {
                    this.interest += "1:1 문의,";
                }

                if(this.path != ""){
                    this.path = this.path.slice(0, -1);
                }
                if(this.interest != ""){
                    this.interest = this.interest.slice(0, -1);
                }
                let data = {
                    email: this.email,
                    password: this.password,
                    hashPw: CryptoJS.SHA256(this.password).toString(),
                    name: this.name,
                    birthdate: this.birthdate.slice(0,4) + '-' + this.birthdate.slice(4,6) + '-' + this.birthdate.slice(6),
                    phonenumber: this.phonenumber,
                    gender: this.genderPick == '남' ? 'male' : 'female',
                    address: this.roadAddress + " " + this.detailAddress,
                    path: this.path,
                    interest: this.interest,
                    key: 1,
                    nickname: this.nickname,
                    code: 0, //일반가입 코드 0
                    updatetime: today.getFullYear() + '-' + today.getMonth() + '-' + today.getDay(),
                    receive_s: this.receiveSns,
                    receive_e: this.receiveEmail
                };
                console.log(this.receive_s);
                console.log(this.receive_e);
                axios.post("/server/join_test", JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((response) => {
                    //console.log(response.data)
                    alert("회원가입이 완료되었습니다");
                    location.href = "/member/login"
                }).catch((error) => {
                    console.error('Error:', error);
                });
            }

        },
        openPostcode() {
            new window.daum.Postcode({
                oncomplete: (data) => {
                    this.zonecode = data.zonecode;
                    this.roadAddress = data.roadAddress;
                },
            }).open();
        },
        checkEmail() {
            this.updateEmailDomain();
            console.log(this.email);
            if(this.email_first == "" || this.emailDomain == "" ){
                alert("이메일을 입력하세요");
                return;
            }
            let data = {
                key: 0,
                email: this.email,
                code: 0
            }
            axios.post("/server/join_test", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                console.log(res);
                if(res.data._data["length"] == 0){
                    this.isEmailValid = true;
                    alert("사용 가능한 이메일입니다");
                } else if(res.data._data[0]["CODE"] == "1") {
                    alert("카카오 계정으로 가입된 이메일입니다");
                } else if(res.data._data[0]["CODE"] == "2") {
                    alert("네이버 계정으로 가입된 이메일입니다");
                } else if(res.data._data["length"] != 0){
                    alert("이미 가입된 이메일입니다");
                }
            })
        },
        validation() {
            if (this.isEmailValid != true) {
                alert("아이디 중복을 확인하세요");
                return false;
            }
            if (this.email == "" || this.password == "" || this.password_verify == "" || this.name == "" || this.birthdate == "" || this.phonenumber == "" || this.genderPick == "") {
                alert("필수 정보를 입력하세요");
                return false;
            }
            return true;
        },
        fronttest() {
            let data = {
                id: "aa"
                // password: this.password,
                // name: this.name,
                // birthdate: this.birthdate,
                // phonenumber: this.phonenumber,
                // gender: this.genderPick
            }
            axios.post("/server/fronttest", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                alert(response.data);
                console.log(response.data);
            })
        },

        // 아이디 유효성 검사
        validateUserID() {
            const check = /^[a-z0-9]{6,12}$/;
            if (!check.test(this.userid)) {
                if (this.userid !== '') {
                    alert("아이디 올바른 형식으로 입력")
                }
                this.userid = '';
            }
        },

        // 비밀번호 유효성 검사
        validatePassword() {
            const check = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;'?/>.<,])([a-zA-Z0-9!@#$%^&*()_+}{":;'?/>.<,]){8,}$/;
            console.log(this.password)
            if (!check.test(this.password)) {
                if (this.password !== '') {
                    alert("비밀번호를 올바른 형식으로 입력하세요.")
                }
                this.password = '';
                this.password_verify = '';
            }
        },

        // 패스워드 확인 유효성검사 (패스워드 값과 동일하지 않으면 X)
        validatePasswordMatch() {
            if (this.password !== this.password_verify) {
                alert("비밀번호가 일치 하지 않습니다.");
                this.password = '';
                this.password_verify = '';
            }
        },
        // 이름 유효성 검사
        validateName() {
            const check = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]{2,}$/;
            if (!check.test(this.name)) {
                if (this.name !== '') {
                    alert("이름을 올바르게 입력하세요.");
                }
                this.name = '';
            }
        },

        // 생년월일 유효성 검사
        validateBirthdate() {
            const check = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;

            const year = parseInt(this.birthdate.substring(0, 4));
            const month = parseInt(this.birthdate.substring(4, 6));
            const day = parseInt(this.birthdate.substring(6, 8));

            // 윤년인지 확인
            const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);

            const daysInMonth = [31, isLeapYear ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

            const isValidDay = day > 0 && day <= daysInMonth[month - 1]; // 해당 월의 유효한 일자인지 확인
            if (!check.test(this.birthdate) || !isValidDay) {
                if (this.birthdate !== '') {
                    alert("올바른 형식의 생년월일을 입력하세요.");
                }
                this.birthdate = '';
            }
        },

        //휴대폰번호 유효성검사
        validatePhoneNumber() {
            const check = /^010\d{8}$/;
            if (!check.test(this.phonenumber)) {
                if (this.phonenumber !== '') {
                    alert("올바른 형식의 휴대폰번호를 입력하세요.")
                }
                this.phonenumber = '';
            }
        },

        // 이메일 유효성 검사
        validateEmailFirst() {
            const check = /^[a-zA-Z0-9._%+-]+$/;
            if (!check.test(this.email_first)) {
                if (this.email_first !== '') {
                    alert("올바른 형식의 이메일을 입력하세요.");
                }
                this.email_first = '';
            }
        },

        // 이메일 도메인..~ 변경될 때마다 중복확인 초기화
        updateEmailDomain() {
            this.isEmailValid = false;
            this.emailDomain = this.email_last;
            this.email = this.email_first + "@" + this.emailDomain;
        },

        onClickChangeCheckbox() {
            this.isChecked = !this.isChecked;
        }
    },

}
</script>