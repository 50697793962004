<template>
    <div id="Admin_LogIn_Wrapper" v-if="isVisible">
        <div class="Admin_LogIn_Body">
            <button class="closeEditPop" @click="closeEditPop">X</button>
            <div class="Admin_LogIn_Wrapper">
                <p>비밀번호를 입력하세요</p>
                <div class="Admin_LogIn_Form">
                    <input type="password" name="password" class="text-field" placeholder="비밀번호 입력" v-model="password">
                    <button value="확인" @click="postData">확인</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('../../assets/css/LogIn/AdminLogin.css');
</style>

<script>
import axios from 'axios';
import CryptoJS from "crypto-js";
import Cookies from 'js-cookie';

export default {
    data() {
        return {
            isVisible: true,
            password: "",
            token: ""
        }
    },
    mounted() {
        this.token = Cookies.get('token');
    },
    methods: {
        closeEditPop() {
            this.isVisible = false;
            this.$emit('childEvent', false); // Emit false if the pop-up is closed without logging in
        },
        async postData() {
            let data = {
                i_opjob: "GET_ISADMIN",
                password: this.password,
                hashPw: CryptoJS.SHA256(this.password).toString(),
                token: this.token,
                keyword: "",
                startDate: "",
                endDate: "",
                idx: ""
                //키워드 수정
            }
            const res = await axios.post("/server/MANAGE_MEMBER", JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (res.data._data["length"] == 0) {
                alert("비밀번호를 확인하세요.");
            } 
            else {
                alert("회원 관리 페이지로 이동합니다.");
                this.isVisible = false;
                Cookies.set('manage_token', this.token);
                window.location.href = this.$TARGETURL + 'manage';
            }
        }
    }
}
</script>
