<template>
    <div></div>
</template>

<style scoped>
</style>

<script>
export default {
    mounted() {
        this.$nextTick(()=>{
            setTimeout(()=>{
                this.$router.push("/edit")
            },100)
        })
    },
};
</script>