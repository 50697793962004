<template>
    <div style="display: flex; align-items: center; padding-top:8px; column-gap:5px" class="paging_Wrapper">
        <span>
            <a @click="previousPage">&lt;</a>
        </span>
        <span class="page" v-for="page in getPaginationArray(leftmostPage)" :key="page">
            <a :class="{ on: page === curPage }" @click="onChangeCurPage(page)">
                {{ page }}
            </a>
        </span>
        <span>
            <a @click="nextPage">&gt;</a>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        list: {
            type: Array,
            required: true,
        },
        ITEM_PER_PAGE: {
            type: Number,
            required: true,
        },
        PAGE_PER_SECTION: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            curPage: 1,
            leftmostPage: 1,
        };
    },
    computed: {
        totalPage() {
            return Math.ceil(this.list.length / this.ITEM_PER_PAGE);
        },
    },
    methods: {
        getPaginationArray(left) {
            const res = [];
            for (let i = left; i < Math.min(this.totalPage + 1, left + this.PAGE_PER_SECTION); i++) {
                res.push(i);
            }
            return res;
        },
        onChangeCurPage(page) {
            this.curPage = page;
            this.$emit('change-page', page);
            if (page < this.leftmostPage) {
                this.leftmostPage = page;
            } else if (page >= this.leftmostPage + this.PAGE_PER_SECTION) {
                this.leftmostPage = page - this.PAGE_PER_SECTION + 1;
            }
        },
        previousPage() {
            if (this.curPage > 1) {
                this.onChangeCurPage(this.curPage - 1);
            }
        },
        nextPage() {
            if (this.curPage < this.totalPage) {
                this.onChangeCurPage(this.curPage + 1);
            }
        },
    },
};
</script>

<style scoped>
.page,
.paging_Wrapper span {
	cursor: pointer;
	height: 40px;
	width: 40px;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	border: 1px solid #787878;
}
.page a.on {
	width: 40px;
	height: 40px;
	background: #787878;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	padding: 20px 20px
}
.paging_Wrapper span a {
	padding:20px 20px
}
</style>
