<template>
    <div id="joinStep1_Wrapper">
        <div id="joinStep1_Body">
            <section id="formArea" class="joinStep1_Container">
                <hgroup>
                    <h1>회원가입</h1>
                    <p>
                        자이가이스트의 회원이 되면<br>
                        보다 나은 서비스를 이용하실 수 있습니다.
                    </p>
                </hgroup>

                <ol>
                    <li class="on">
                        <span class="num">01</span>
                        <span>약관동의</span>
                    </li>

                    <li class="bar"></li>
                    <li class="on bgGray">
                        <span class="num" style=" color:rgba(204,204,204,1);">02</span>
                        <span>정보입력</span>
                    </li>
                    <li class="bar"></li>

                    <li class="on bgGray">
                        <span class="num" style=" color:rgba(204,204,204,1);">03</span>
                        <span>가입완료</span>
                    </li>
                </ol>

                <form action="" id="memberAgree">
                    <label for="allAgree">
                        <input type="checkbox" id="allAgree" name="allAgree" v-model="allAgree"
                            @change="toggleAllCheckbox">
                        <div class="checkmark">자이가이스트의 모든 약관을 확인하고 전체동의 합니다.</div>
                    </label>

                    <div>
                        <div class="formTitContainer">
                            <span>이용약관(필수)</span>
                            <label for="mustTermsEssencial" class="mustReg oneAgree">
                                <input type="checkbox" id="mustTermsEssencial" name="mustTerms" @change="checkAllAgree">
                                <div class="checkmark">동의</div>
                            </label>
                        </div>

                        <div class="scrollArea" v-for="(term, index) in termsData" :key="index">
                            <div class="scrollAreaContainer">
                                <p>{{ term.agree }}</p>
                                <p>{{ term.date }}</p>
                                <h4 class="formTit">{{ term.title }}</h4>
                                <p>{{ term.subtitle }}</p>
                                <p class="formContent"> {{ term.content }}</p>
                                <!-- 이용약관 - 제1조 (목적)-->

                                <p>{{ term.subtitle2 }}</p>
                                <p class="formContent" v-html="term.content2"></p>
                                <!-- 이용약관 - 제2조 (용어 정의)-->

                                <p>{{ term.subtitle3 }}</p>
                                <p v-html="term.content3" class="formContent"></p>
                                <!--이용약관 - 제3조 (약관의 효력 및 변경)-->

                                <p>{{ term.subtitle4 }}</p>
                                <p class="formContent">{{ term.content4 }}</p>
                                <!--이용약관 - 제4조 (약관 외 준칙)-->

                                <h4 class="formTit">{{ term.title2 }}</h4>
                                <p>{{ term.subtitle5 }}</p>
                                <p v-html="term.content5" class="formContent"></p>
                                <!-- 이용약관 - 제5조 (회원가입 계약의 성립) -->

                                <p>{{ term.subtitle6 }}</p>
                                <p v-html="term.content6" class="formContent"></p>
                                <!--이용약관 - 제6조 (회원신청)-->

                                <p>{{ term.subtitle7 }}</p>
                                <p v-html="term.content7" class="formContent"></p>

                                <!--이용약관 - 제7조 (회원 개인정보)-->

                                <p>{{ term.subtitle8 }}</p>
                                <p v-html="term.content8" class="formContent">

                                </p>
                                <!--이용약관 - 제8조 (회원정보 변경)-->

                                <p>{{ term.subtitle9 }}</p>
                                <p v-html="term.content9" class="formContent"></p>
                                <!--이용약관 - 제9조 (회원정보 사용에 대한 동의)-->

                                <p>{{ term.subtitle10 }}</p>
                                <p v-html="term.content10" class="formContent"></p>
                                <!--이용약관 - 제10조 (회원신청의 승낙)-->

                                <p>{{ term.subtitle11 }}</p>
                                <p class="formContent">{{ term.content11 }}</p>
                                <!--이용약관 - 제11조 (계약사항의 변경)-->

                                <h4 class="formTit">{{ term.title3 }}</h4>
                                <p>{{ term.subtitle12 }}</p>
                                <p v-html="term.content12" class="formContent"></p>
                                <!--이용약관 - 제12조 (회사의 의무)-->

                                <p>{{ term.subtitle13 }}</p>
                                <p v-html="term.content13" class="formContent"></p>
                                <!--이용약관 - 제13조 (회원의 의무)-->

                                <h4 class="formTit">{{ term.title4 }}</h4>
                                <p>{{ term.subtitle14 }}</p>
                                <p v-html="term.content14" class="formContent"></p>
                                <!--이용약관 - 제14조 (회원 아이디 (ID)와 비밀번호 관리에 대한 회원의 의무)-->

                                <p>{{ term.subtitle15 }}</p>
                                <p v-html="term.content15" class="formContent"></p>
                                <!--이용약관 - 제15조 (게시물의 저작권)-->

                                <p>{{ term.subtitle16 }}</p>
                                <p v-html="term.content16" class="formContent"></p>
                                <!--이용약관 - 제16조 (회원의 게시물 관리)-->

                                <p>{{ term.subtitle17 }}</p>
                                <p v-html="term.content17" class="formContent"></p>
                                <!--이용약관 - 제17조 (서비스의 제공)-->

                                <p>{{ term.subtitle18 }}</p>
                                <p class="formContent">{{ term.content18 }}</p>
                                <!--이용약관 - 제18조 (서비스 이용시간)-->

                                <p>{{ term.subtitle19 }}</p>
                                <p class="formContent">{{ term.content19 }}</p>
                                <!--이용약관 - 제19조 (서비스 이용 책임)-->

                                <p>{{ term.subtitle20 }}</p>
                                <p v-html="term.content20" class="formContent"></p>
                                <!--이용약관 - 제20조 (서비스 제공의 중지)-->

                                <p>{{ term.subtitle21 }}</p>
                                <p class="formContent">{{ term.content21 }}</p>
                                <!--이용약관 - 제21조 (링크)-->

                                <h4 class="formTit">{{ term.title5 }}</h4>
                                <p>{{ term.subtitle22 }}</p>
                                <p class="formContent" v-html="term.content22"></p>
                                <!--이용약관 - 제22조 (계약해지 및 이용제한)-->

                                <h4 class="formTit">{{ term.title6 }}</h4>
                                <p>{{ term.subtitle23 }}</p>
                                <p class="formContent" v-html="term.content23"></p>
                                <!--이용약관 - 제23조(손해배상)-->

                                <p>{{ term.subtitle24 }}</p>
                                <p class="formContent" v-html="term.content24"></p>
                                <!--이용약관 - 제24조(면책조항)-->

                                <p>{{ term.subtitle25 }}</p>
                                <p class="formContent">{{ term.content25 }}</p>
                                <!--이용약관 - 제25조(관할법원)-->

                                <h4 class="formTit">{{ term.title7 }}</h4>
                                <p class="formContent">{{ term.content27 }}</p>

                            </div>


                            <!-- <div class="userScrollbar">
                                <button title="userScrollHandle"></button>
                            </div> -->
                        </div>
                    </div>

                    <div>
                        <div class="formTitContainer">
                            <span>개인정보 수집 이용 동의 (필수)</span>
                            <label for="mustTermsPersonalInfo" class="mustReg oneAgree">
                                <input type="checkbox" id="mustTermsPersonalInfo" name="mustTerms"
                                    @change="checkAllAgree">
                                <div class="checkmark">동의</div>
                            </label>
                        </div>

                        <div class="scrollArea" v-for="(info, index) in infoData" :key="index">
                            <div class="scrollAreaContainer">
                                <p>{{ info.info }}</p>
                                <p>{{ info.date }}</p>
                                <h4 class="formTit">{{ info.title }}</h4>
                                <p>{{ info.subtitle }}</p>

                                <div id="wideTableWrapper">
                                    <table class="TableWrapper">
                                        <colgroup>
                                            <col width="150px">
                                            <col width="40%">
                                            <col width="45%">
                                            <col width="35%">
                                        </colgroup>

                                        <thead>
                                            <th>{{ info.th1 }}</th>
                                            <th>{{ info.th2 }}</th>
                                            <th>{{ info.th3 }}</th>
                                            <th>{{ info.th4 }}</th>
                                        </thead>


                                        <tbody>
                                            <tr>
                                                <td>{{ info.td1_1 }}</td>
                                                <td v-html="info.td1_2"></td>
                                                <td rowspan="2">{{ info.td1_3 }}</td>
                                                <td v-html="info.td5_4" rowspan="10"></td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td2_1 }}</td>
                                                <td>{{ info.td2_2 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td3_1 }}</td>
                                                <td>{{ info.td3_2 }}</td>
                                                <td>{{ info.td3_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td4_1 }}</td>
                                                <td v-html="info.td4_2"></td>
                                                <td v-html="info.td5_3" rowspan="3"></td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td5_1 }}</td>
                                                <td v-html="info.td5_2"></td>
                                                <!-- <td v-html="info.td5_3" rowspan="2"></td> -->

                                            </tr>

                                            <tr>
                                                <td>{{ info.td6_1 }}</td>
                                                <td>{{ info.td6_2 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td7_1 }}</td>
                                                <td v-html="info.td7_2"></td>
                                                <td>{{ info.td7_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td v-html="info.td8_1"></td>
                                                <td v-html="info.td8_2"></td>
                                                <td>{{ info.td8_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td9_1 }}</td>
                                                <td>{{ info.td9_2 }}</td>
                                                <td>{{ info.td9_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td10_1 }}</td>
                                                <td>{{ info.td10_2 }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- 제1조 개인정보의 처리목적 -->

                                <h4 class="formTit">{{ info.title2 }}</h4>
                                <p class="formContent" v-html="info.content2"></p>

                                <div id="wideTableWrapper">
                                    <table class="TableWrapper">
                                        <colgroup>
                                            <col width="150px">
                                            <col width="40%">
                                            <col width="120px">
                                        </colgroup>

                                        <thead>
                                            <th>{{ info.th5 }}</th>
                                            <th>{{ info.th6 }}</th>
                                            <th>{{ info.th7 }}</th>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>{{ info.td5_1_1 }}</td>
                                                <td v-html="info.td5_1_2"></td>
                                                <td v-html="info.td5_1_3"></td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td6_1_1 }}</td>
                                                <td>{{ info.td6_1_2 }}</td>
                                                <td>{{ info.td6_1_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td7_1_1 }}</td>
                                                <td>{{ info.td7_1_2 }}</td>
                                                <td>{{ info.td7_1_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td8_1_1 }}</td>
                                                <td>{{ info.td8_1_2 }}</td>
                                                <td>{{ info.td8_1_3 }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!-- 제2조 개인정보의 처리 및 보유기간 -->

                                <div>
                                    <h4 class="formTit">{{ info.title3 }}</h4>
                                    <p class="formContent" v-html="info.content3"></p>
                                </div>
                                <!--제3조 개인정보의 제3자 제공-->


                                <h4 class="formTit">{{ info.title4 }}</h4>
                                <p v-html="info.content4A"></p>

                                <div id="wideTableWrapper">
                                    <table class="TableWrapper">
                                        <colgroup>
                                            <col width="33%">
                                            <col width="33%">
                                            <col width="33%">
                                        </colgroup>
                                        <thead>
                                            <th>{{ info.td9_1_1 }}</th>
                                            <th>{{ info.td9_1_2 }}</th>
                                            <th>{{ info.td9_1_3 }}</th>
                                        </thead>

                                        <tbody>
                                            <tr>
                                                <td>{{ info.td10_1_1 }}</td>
                                                <td>{{ info.td10_1_2 }}</td>
                                                <td class="wide" rowspan="3" colspan="3">{{ info.td10_1_3 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td11_1_1 }}</td>
                                                <td>{{ info.td11_1_2 }}</td>
                                            </tr>

                                            <tr>
                                                <td>{{ info.td12_1_1 }}</td>
                                                <td>{{ info.td12_1_2 }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p v-html="info.content4B"></p>
                                <p v-html="info.content4C"></p>
                                <!--제4조 개인정보의 처리 위탁-->

                                <div>
                                    <h4 class="formTit" v-html="info.title5"></h4>
                                    <p v-html="info.content4A"></p>
                                    <p class="formContent" v-html="info.content5"></p>
                                </div>
                                <!--제5조 정보주체의 권리·의무 및 행사방법-->

                                <div>
                                    <h4 class="formTit" v-html="info.title6"></h4>
                                    <p>{{ info.subtitle6 }}</p>
                                    <p v-html="info.content6"></p>
                                </div>
                                <!--제6조 자동으로 수집되는 개인정보 및 거부에 관한 사항-->

                                <div>
                                    <h4 class="formTit" v-html="info.title7"></h4>
                                    <p v-html="info.subtitle7"></p>
                                    <p v-html="info.content7"></p>
                                </div>
                                <!--제7조 개인정보의 파기-->

                                <div>
                                    <h4 class="formTit" v-html="info.title8"></h4>
                                    <p v-html="info.subtitle8"></p>
                                    <p v-html="info.content8"></p>
                                </div>
                                <!--제8조 개인정보의 안전성 확보조치-->

                                <div>
                                    <h4 class="formTit" v-html="info.title9"></h4>
                                    <p v-html="info.content9"></p>
                                </div>
                                <!--제9조 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항-->

                                <div>
                                    <h4 class="formTit" v-html="info.title10"></h4>
                                    <p v-html="info.content10A"></p>

                                    <div id="wideTableWrapper">
                                        <table class="TableWrapper">
                                            <colgroup>
                                                <col width="33%">
                                                <col width="33%">
                                                <col width="33%">
                                            </colgroup>
                                            <thead>
                                                <th>{{ info.th8 }}</th>
                                                <th>{{ info.th9 }}</th>
                                                <th>{{ info.th10 }}</th>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>{{ info.td13_1_1 }}</td>
                                                    <td>{{ info.td13_1_2 }}</td>
                                                    <td>{{ info.td13_1_3 }}</td>
                                                </tr>

                                                <tr>
                                                    <td>{{ info.td14_1_1 }}</td>
                                                    <td>{{ info.td14_1_2 }}</td>
                                                    <td>{{ info.td14_1_3 }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <p v-html="info.content10B"></p>

                                </div>
                                <!--제10조 개인정보보호 책임자-->

                                <div>
                                    <h4 class="formTit" v-html="info.title11"></h4>
                                    <p v-html="info.subtitle11"></p>
                                    <p v-html="info.content11" class="formContent"></p>
                                </div>
                                <!--제11조 개인정보 열람청구-->

                                <div>
                                    <h4 class="formTit" v-html="info.title12"></h4>
                                    <p v-html="info.content12"></p>
                                </div>

                                <!--제12조 권익침해 구제방법-->
                                <h4 class="formTit" v-html="info.title11"></h4>
                                <p v-html="info.subtitle13"></p>
                                <!--제13조 개인정보처리방침 변경-->
                            </div>
                        </div>
                    </div>

                    <div class="moreInfo">
                        <h1>마케팅 활용 동의 및 광고 수집 동의(선택)</h1>
                        <div class="labelWrapper">
                            <label for="receiveSns" id="oneAgree" @change="checkAllAgree">
                                <input type="checkbox" id="receiveSns" name="receiveSns" value="Y">
                                <div class="checkmark">SMS / 전화 수신동의</div>
                            </label>

                            <label for="receiveEmail" id="oneAgree" @change="checkAllAgree">
                                <input type="checkbox" id="receiveEmail" name="receiveEmail" value="Y">
                                <div class="checkmark">이메일 수신동의</div>
                            </label>
                        </div>

                    </div>

                </form>

                <!-- <div class="buttonWrapper">
                    <button type="button" class="style03 sizeM">
                        <a href="/member/joinStep2">본인인증</a>
                    </button>
                </div> -->
                <form name="passForm" method="post">
                    <!-- 요청모드 (필수 데이터) -->
                    <input type="hidden" name="m" value="checkplusService">
                    <!-- 업체정보 암호화 데이터 -->
                    <input type="hidden" name="EncodeData" value="<%= sEncData %>">
                    <!-- 팝업 호출 링크 -->
                    <!-- <button type="button" @click="fnPopup"> CheckPlus 안심본인인증 Click</button> -->
                    <div class="buttonWrapper">
                        <button type="button" class="style03 sizeM" @click="fnPopup">
                            본인인증
                        </button>
                    </div>
                </form>
            </section>
        </div>

        <bottomInfo />
        <Footer />
        <scrollToTop />
    </div>



</template>

<style scoped>
@import url('../../assets/css/LogIn/joinStep1.css');
</style>


<script>
import Footer from '../Login/joinStep1Footer.vue'
import { termsData } from '../../assets/js/LogIn/EssencialUse.js';
import { infoData } from '../../assets/js/LogIn/PersonalInfo.js';
import bottomInfo from '../Login/bottomInfo.vue'
import scrollToTop from '../Login/scrollToTop.vue'
import axios from 'axios';
import { VueElement } from 'vue';

export default {
    data() {
        return {
            termsData: termsData,
            infoData: infoData,
            allAgree: false,
            dataFromSession: '',
        }
    },
    components: {
        Footer,
        bottomInfo,
        scrollToTop
    },
    methods: {
        //#allAgree 체크박스 checked시 나머지 체크박스 (#agree) 모두 checked 활성...
        toggleAllCheckbox() {
            const otherCheckboxes = document.querySelectorAll('input[type="checkbox"]:not(#allAgree)');

            if (this.allAgree) {
                otherCheckboxes.forEach(checkbox => {
                    checkbox.checked = true;
                });
            } else {
                otherCheckboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });
            }
        },

        //#agree 체크박스 모두 체크시 #allAgree 체크박스 checked... & checked 된 #agree 체크박스 중 하나라도 checked 해제시 #allAgree 체크박스 checked 해제...
        checkAllAgree() {
            const mustTermsEssencial = document.getElementById('mustTermsEssencial');
            const mustTermsPersonalInfo = document.getElementById('mustTermsPersonalInfo');
            const receiveSns = document.getElementById('receiveSns');
            const receiveEmail = document.getElementById('receiveEmail');

            if (mustTermsEssencial.checked && mustTermsPersonalInfo.checked && receiveSns.checked && receiveEmail.checked) {
                this.allAgree = true;
            } else {
                this.allAgree = false;
            }

        },
        fnPopup() {
            var str = "";
            if(document.getElementById('mustTermsEssencial').checked && document.getElementById('mustTermsPersonalInfo').checked){
                if(document.getElementById('receiveSns').checked && !document.getElementById('receiveEmail').checked){ //sns동의
                    str += "?param2=1";
                }
                else if(!document.getElementById('receiveSns').checked && document.getElementById('receiveEmail').checked){ //이메일동의
                    str += "?param2=2";
                }
                else if(document.getElementById('receiveSns').checked && document.getElementById('receiveEmail').checked) { //둘다동의
                    str += "?param2=3";
                } else { //둘다 안동의
                    str += "?param2=0";
                }  

                window.open('/member/joinStepSub'+ str, '', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
            } else {
                alert("필수 항목에 동의하세요.");
            }
        
        },
        
        
    }
}
</script>