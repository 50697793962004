import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import MeshHelper from "./MeshHelper";

class CsgHelper
{
    bakeTransform(mesh)
    {
        const pos = mesh.position.clone();
        const parent = mesh.parent;
        const bbox = mesh.getBoundingInfo().boundingBox;
        const translateMatrix = BABYLON.Matrix.Translation(-bbox.center.x, -bbox.center.y, -bbox.center.z)
        mesh.position = Vector3.Zero()
        mesh.parent = null;
        mesh.bakeTransformIntoVertices(translateMatrix);
        mesh.position = pos;
        mesh.parent = parent;
    }

    extendsTransFrom(mesh)
    {
        const parent = mesh.parent;
        if(parent)
        {
            mesh.setParent(null)
            this.bakeTransform(mesh);
        }
    }

    initialMatrix(mesh)
    {
        this.extendsTransFrom(mesh);

        const pos = mesh.position.clone();
        mesh.position = Vector3.Zero();
        mesh.bakeCurrentTransformIntoVertices();
        mesh.position = pos;

        mesh.rotation = BABYLON.Vector3.Zero();
        mesh.scaling = new BABYLON.Vector3(1,1,1);
        mesh.computeWorldMatrix();
    }

    subtract(m1,m2,instance)
    {
        const p1 = m1.parent;
        const p2 = m2.parent;

        const pRot1 = this.getParentRotation(m1);
        const pRot2 = this.getParentRotation(m2);

        const child1 = m1.getChildMeshes();
        const child2 = m2.getChildMeshes();

        child1.forEach(c=>c.setParent(null));
        child2.forEach(c=>c.setParent(null));

        this.initialMatrix(m1);
        this.initialMatrix(m2);

        const csg1 = BABYLON.CSG.FromMesh(m1);
        const csg2 = BABYLON.CSG.FromMesh(m2);

        const retcsg = csg1.subtract(csg2);
        const mesh = retcsg.toMesh(null,m1.material,instance,true);
        m1.dispose();
        
        child1.forEach(c=>{
            this.bakeTransform(c)
            c.setParent(mesh);
        })

        child2.forEach(c=>{
            this.bakeTransform(c)
            c.setParent(m2);
        })

        if(p1)
        {
            mesh.parent = p1;
            mesh.rotation.y = pRot1;
            this.setParentSubtractLocation(mesh,p1);
        } 
        
        if(p2)
        {
            m2.parent = p2;
            m2.rotation.y = pRot2;
            this.setParentSubtractLocation(m2,p2);
        }
        
        return mesh;
    }

    setParentSubtractLocation(mesh,parent)
    {
        if(parent)
        {
            mesh.position = mesh.position.subtract(parent.position);
            mesh.scaling = mesh.scaling.multiply(parent.scaling);
            mesh.position = mesh.position.multiply(mesh.scaling);

            const cos = Math.cos(-parent.rotation.y);
            const sin = Math.sin(-parent.rotation.y);
            const p = mesh.position.clone();
            mesh.position.x = p.x*cos - p.z*sin;
            mesh.position.z = p.x*sin + p.z*cos;

            if(parent.parent)
            {
                this.setParentSubtractLocation(mesh,parent.parent);
            }
        }
    }

    getParentSubtractLocation(mesh)
    {
        const clone = mesh.clone();
        this.setParentSubtractLocation(clone,clone.parent);
        let pos = clone.position.clone();
        clone.dispose();
        return pos;
    }

    getParentScale(mesh)
    {
        let scale = mesh.scaling.clone();
        if(mesh.parent)
        {
            scale = mesh.scaling.multiply(mesh.parent.scaling);
        }
        if(mesh.parent?.parent)
        {
            scale = mesh.scaling.multiply(this.getParentRotation(mesh.parent.parent));
        }

        return scale;
    }

    getParentRotation(mesh)
    {
        let rot = 0;
        if(mesh.parent)
        {
            rot += mesh.parent.rotation.y;
        }
        if(mesh.parent?.parent)
        {
            rot += this.getParentRotation(mesh.parent);
        }

        return rot;
    }

    getMeshSize(mesh)
    {
        const boxInfo = mesh.getBoundingInfo();
        let width = boxInfo.boundingBox.extendSize.x * 2;
        let height = boxInfo.boundingBox.extendSize.y * 2;
        let depth = boxInfo.boundingBox.extendSize.z * 2;

        return {width : width, height : height, depth : depth};
    }
}

export default new CsgHelper();