export const termsData = [
    {
        agree: '이용약관 동의',
        date: '2022-07-30',
        title: '제1장 총칙',
        subtitle: '제1조 (목적)',
        content: `본약관은 자이가이스트(이하 \'회사\')에서 운영하는 자이 인터넷 서비스(자이가이스트 브랜드 홈페이지 및 자이가이스트 관련 사이트들의 총칭으로, 이하 \'서비스\'라 한다)의 이용조건 및 절차에 관한 기본적인 사항을 정함을 목적으로 합니다.`,
        subtitle2: '제2조 (용어 정의)',
        content2: `
            ① '자이가이스트'란 회사가 이용자들에게 서비스를 제공하기 위하여 운영하는 인터넷 Web / Mobile 사이트를 의미하며, '회사'가 재화 또는 용역 (이하 '재화 등'이라함)을 이용자들에게 제공하기 위하여 컴퓨터 등 정보통신서립를 이용하여 '재화 등'을 거래할 수 있도록 설정한 가상의 영업장 및 사이버 몰로, 아룰러 이를 운영하는 사업자의 의미로도 사용합니다.<br>
            ② '이용자'란 '서비스'에 접속하여 이약관에 따라 '자이가이스트'이 제공하는 서비스을 받는 회원 및 비회원을 의미합니다.<br>
            ③ '회원'이라 함은 '사이트'에 접속하여 본 약관에 따라 '사이트'에 '회원'으로 가입하여 '사이트'가 제공하는 '서비스'를 받는 자를 말합니다.<br>
            ④ '비회원'이란 '회원'에 가입하지 않고 서비스를 이용하는 자를 말합니다.<br>
            ⑤ 이메일(E-Mail) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하기 위한 전자메일주소<br>
            ⑥ 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합<br>
            ⑦ '서비스'란 다양한 정보의 제공 및 상품(재화와 용역 포함)의 판매 또는 상품과 관련한 다양한 판촉행위 및, 기타 부가 '서비스'를 말합니다.<br>
            ⑧ 운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람<br>
            ⑨ 해지 : 회사 또는 회원이 서비스 개통 후 회원계약을 해약 하는 것<br>
            ⑩ '할인 쿠폰'이란 자이가이스트를 통하여 물품을 구매할 때 표시된 금액 또는 비율만큼 물품 대금에서 할인 받을 수 있는 회사 전용의 사이버 또는 오프라인 쿠폰을 말합니다.
        `,
        subtitle3:'제3조 (약관의 효력 및 변경)',
        content3:`
            ① 본 서비스를 이용하고자 하는 모든 이용자에 대하여 그 효력을 발생합니다.<br>	 
            ② 이 약관의 내용은 서비스의 일부 화면 또는 기타 방법 등에 의하여 그 내용을 게재함으로써 그 효력을 발생합니다.<br>
            ③ 회사는 운영 또는 영업상 중요한 사유가 있을 경우 본 약관을 임의로 변경할 수 있으며, 변경된 약관은 본 조 제2항과 같은 방법 기타 법령에 규정된 방법으로 공지함으로써 그 효력을 발생합니다.
            `,
        subtitle4:'제4조 (약관 외 준칙)',
        content4:
        `
        이 약관에 명시되지 않은 사항은 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률, 전기통신기본법, 전기통신사업법, 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률 및 기타 관련법령의 규정에 의합니다.`,
        title2:'제 2장 서비스 회원가입 계약',
        subtitle5:'제5조 (회원가입 계약의 성립)',
        content5:
        `
            ① "이용 약관 동의" 에 체크 후 회원이 "회원가입" 단추를 누르면 이 약관에 동의하는 것으로 간주됩니다.<br>	 
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            가. 단, 이경우 회원은 타인의 정보를 이용하는 등 행위를 통하여 회원가입을 해선 안됩니다.<br>	 
            ② 회원계약은 회원의 회원신청에 대하여 회사가 심사 후 승낙함으로써 성립합니다.`,
        subtitle6:'제6조 (회원신청)',
        content6:
        `
            ① 회원신청은 온라인으로 회사 소정의 가입신청 양식에서 요구하는 사항을 기록하여 신청합니다.<br>	 
            ② 온라인 가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로 간주하며 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를 받을 수 없으며, 서비스 사용의 제한을 받을 수 있습니다.`,
        subtitle7:'제7조 (회원 개인정보)',
        content7:
        `
            ① '사이트'는 이용자의 정보 수집시 '사이트'에 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며, 그 외 사항은 선택사항으로 합니다.<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;가. ID<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;나. 비밀번호<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;다. 성명<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;라. 휴대폰번호<br>	 
            ② 제공된 개인정보는 당해 이용자의 동의 없이 목적 외 이용이나 제3자에게 제공할 수 없으며, 이에대한 모든 책임은 '사이트'가 집니다.<br>	 
            ③ '사이트' 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.`,
        subtitle8:'제8조 (회원정보 변경)',
        content8:
        `
            ① 회원은 언제든지 '사이트'에 탈퇴를 요청할 수 있으며, '사이트'는 즉시 탈퇴처리를 하여야 합니다. 탈퇴는 '회원' 본인이 직접 '사이트'에서 '회사'가 정한 방법에 의거하여 탈퇴요청을 하여야 합니다.<br>	 
            ② '회원' 스스로 탈퇴를 한 후 재가입, 재이용을 희망할 경우에는 회원 재가입, 임의해지 등을 반복적으로 행함으로써 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상의 이익을 불·편법으로 수취하거나 이 과정에서 명의도용 등의 행위 우려가 있으므로 이러한 불·편법 행위 차단 등의 목적으로 회원탈퇴한 날로부터 30일이 경과해야 회원 재가입이 가능하며 본 약관이 정하는 회원가입절차와 관련 조항에 따라 재이용 의사가 회사에 통지되어 회사가 이용을 승낙한 이후에 서비스의 재이용이 가능합니다.<br>	 
            ③ '회원'이 탈퇴하는 경우 '회원'이 '사이트'에 작성하였던 게시물 중 본인계정에 등록된 게시물은 일체 삭제되나, 타인에 의해 퍼가기, 스크랩 등이 되어 재 게시되거나, 공용게시판에 등록된 게시물 등은 삭제되지 않으니 사전에 삭제 후 탈퇴하시기 바랍니다.<br>	 
            ④ 회사가 회원자격을 상실시킬 경우 회원에게 이를 사전에 통지한 후 탈퇴시킵니다. '회원' 본인의 의사로 재가입은 30일 이후에 가능합니다.`,
        subtitle9:'제9조 (회원정보 사용에 대한 동의)',
        content9:`
            ① 회원의 개인 정보에 대해서는 사이트의 개인정보 처리방침이 적용됩니다.<br>	 
            ② 회사가 제6조에 따라 신청서에 기재를 요구하는 회원의 개인정보는 본 회원계약의 이행과 본 회원계약상의 서비스제공을 위한 목적으로 이용합니다.<br>	 	 
            ③ 회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 회원 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 회원 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 회원 정보의 내용 등을 사전에 공지하고 회원의 동의를 얻어야 합니다.<br>	  
            ④ 회원은 회원정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.<br>	  
            ⑤ 회원이 회원신청서에 회원정보를 기재하고, 회사에 본 약관에 따라 회원신청을 하는 것은 회사가 본 약관에 따라 회원신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.<br>	 	 
            ⑥ 회원이 회사의 제품을 문의하기 위해 상담을 원하는 경우, 이를 위한 회원의 현장주소, 전화번호, 휴대폰 번호, 구입한 제품에 대한 정보(공사명, 계약일, 계약번호, 연락처, 계약서유무, 하자내용)를 필요로 할 수 있으며, 이는 회원에 대한 상담 및 A/S를 위한 자료로 쓰여집니다.`,
        subtitle10:'제10조 (회원신청의 승낙)',
        content10:`
            ① 회사는 회원이 제6조에서 정한 모든 사항을 정확히 기재하여 회원신청을 하였을 때 제2항, 제3항의 경우를 예외로 하여 승낙할 수 있습니다.<br> 
            ② 회사는 다음 각 호의 1에 해당하는 회원신청에 대하여는 승낙을 유보할 수 있습니다.<br>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                가. 서비스 이용설비에 여유가 없는 경우<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                나. 기술상 지장이 있는 경우<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                다. 기타 회사의 사정상 이용승낙이 곤란한 경우<br> 	 
            ③ 회사는 다음 각 호의 1에 해당하는 회원신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.<br>  
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                가. 이름이 실명이 아닌 경우<br> 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                나. 다른 사람의 명의를 사용하여 신청한 경우<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                다. 회원신청 시 필요내용을 허위로 기재하여 신청한 경우<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                라. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                마. 법정 대리인의 동의가 필요한 만 14세 미만 아동<br> 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                바. 기타 회사가 정한 회원신청요건이 미비 되었을 때`,
        subtitle11:'제11조 (계약사항의 변경)',
        content11:'회원은 회원신청 시 기재한 사항이 변경되었을 경우에는 온라인으로 수정을 해야 하고 미변경으로 인하여 발생되는 문제의 책임은 회원에게 있습니다.',
        title3: '제3장 계약당사자의 의무',
        subtitle12:'제12조 (계약당사자의 의무)',
        content12:`
            ① 회사는 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.<br>	 
            ② 1항의 범위 내에서, 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우저의 설정을 변경할 수 있습니다.`,
        subtitle13:'제13조 (회원의 의무)',
        content13:`
            ① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
                가. 다른 회원의 ID를 부정하게 사용하는 행위<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                나. 서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용 이외의 목적으로 복제하거나 이를 출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                다. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                라. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위<br>	 	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                마. 범죄와 결부된다고 객관적으로 판단되는 행위<br>	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                바. 기타 관계법령에 위배되는 행위<br>	 	 

            ② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.<br>	 	 
            ③ 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.<br>	 	 
            ④ 회원은 회사의 사전 승낙 없이는 서비스를 이용하여 영업 활동을 할 수 없으며, 영업 활동의 결과와 회원이 약관에 위반한 영업 활동을 이용하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동에 대하여 회사에 대하여 손해배상의무를 집니다.<br>	 	 
            ⑤ 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도,증여할 수 없으며, 이를 담보로 제공할 수 없습니다.`,
        title4:'제4장 서비스 이용',
        subtitle14:'제14조 (회원 아이디 (ID)와 비밀번호 관리에 대한 회원의 의무)',
        content14:`
            ① 아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.
        `,
        subtitle15:'제15조 (게시물의 저작권)',
        content15:`
        서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.<br>	 	 	 	 	 	 	 	 
            ① 게시물에 대한 권리와 책임은 게시자에게 있으며 회사는 게시자의 동의 없이는 이를 서비스 내 게재 이외에 영리적 목적으로 사용하지 않습니다. 단, 비영리적인 경우에는 그러하지 아니하며 또한 회사는 서비스내의 게재권을 갖습니다.<br> 	 
            ② 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.	<br> 
            ③ 회원이 작성한 게시물은 사이트서치를 통해 브랜드사이트 및 포털사이트의 검색결과창에 공개될 수 있습니다.
        `,
        subtitle16:'제16조 (회원의 게시물 관리)',
        content16:`
            ① '회원'의 게시물이 정보통신망 이용촉진 및 정보보호 등에 관한 법률' 및 '저작권법' 등 관련법에 위반도는 내용을 포함하는 경우, 관리자는 관련법에 정한 절차에 따라 해당 게시물의 게시 중단 및 삭제 등을 요청할 수 있으며, '사이트'는 관련법에 따른 조치를 취해야 합니다.<br>	 
            ② '회원'의 공개 게시물의 내용이 다음의 경우에 해당 될 경우 해당 게세물에 대한 접근 제한 조치를 취할 수 있고, 작성 회원에게 삭제요청을 하거나 소명기회를 부여한 후 삭제할 수 있으며, 해당 이용자의 '회원' 자격을 제한하거나, 정지, 상실시킬 수 있습니다. 단, 긴급한 경우 우선 삭제 후 소명을 요청 할 수 있습니다.<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                가. 회사에서 규정한 게시 기간을 초과한 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
                나. 회사의 저작권, 제 3 자의 저작권 등 기타 권리를 침해하는 내용인 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                다. 범죄적 행위에 결부된다고 인정되는 내용일 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                라. 폭언, 협박, 음란한 언행 등 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우	<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                마. 회원이 자신의 홈페이지 및 SNS 등에 음란물을 게재하거나 음란사이트를 링크하는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
                바. 다른 사람의 '사이트'이용을 방해하거나 그정보를 도용하는 등 전자상거래 질서를 위반되는 내용인 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                사. 기타 관계법령, 회사에서 정한 규정에 위반된다고 판단되는 경우`,
        subtitle17:'제17조 (서비스의 제공)',
        content17:`
            ① '회사'는 다음과 같은 서비스를 제공합니다.<br>	 	 	 	 	 	 	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                가. 재화 또는 용역에 대한 정보 제공 및 구매 계약의 체결<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 	 
                나. 구매 계약이 체결된 재화 또는 용역의 배송(시행)<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 	 	 	 	 
                다. 시행 된 서비스에 대한 결과물 제공<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 	 	 	 	 	 
                라. 시행한 서비스에 대한 하자보수<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 	 	 	 	 
                마. 기타 “회사”가 정하는 업무<br>	 	 	 	 	 	 
            ② '회사'가 재공하기로 이용자와 계약을 체결한 서비스의 내용을 기술적 사양의 변경 등의 사유로 변경 할 경우에 그 사유를 이용자에게 통보가능한 방법을 이용하여 즉시 통보하여야 합니다.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 
                가. 통보가 늦거나 이뤄지지 않아 발생되는 이용자의 손해를 배상합니다. 다만, '회사'의 고의 도는 과실이 없음읗 입증 할 경우 아무런 책임을 부담하지 않습니다.`,
        subtitle18:'제18조 (서비스 이용시간)',
        content18:`
        ① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.
        `,
        subtitle19:'제19조 (서비스 이용 책임)',
        content19:`
        회원은 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를 이용하여 불법 상품을 판매하는 영업활동을 할 수 없으며 특히 해킹, 돈벌기 광고,음란사이트를 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치등에 관해서는 회사가 책임을 지지 않습니다.`,
        subtitle20:'제20조 (서비스 제공의 중지)',
        content20:`
            ① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.<br>	 	 	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                가. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우<br>	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                나. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우<br>	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                다. 기타 불가항력적 사유가 있는 경우<br>	 	 
            ② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 있습니다.
        `,
        subtitle21:'제21조 (링크)',
        content21:`회사 혹은 서비스를 이용하는 제3자는 하이퍼링크(웹사이트 및 텍스트, 이미지 및 영상등)를 제공할 수 있습니다. 회사는 그러한 사이트 또는 자료에 대한 통제권을 가지지 못하므로 회원이 해당 하이퍼링크를 이용함에 따라 발생할 수 있는 상황에 대하여 회사의 고의, 과실이 없는 한 어떠한 책임도 갖지 않습니다.<br>	 
        또한 회원이 하이퍼링크에서 제공하는 재화(콘텐츠, 상품 또는 서비스 등)를 이용함에 따라 야기되거나 야기되었다고 주장하는 어떠한 손해나 손실에 대해서도 회사는 회사의 고의, 과실이 없는 한 직접적 또는 간접적인 책임을 갖지 않습니다.`,
        title5:'제5장 계약해지 및 이용제한',
        subtitle22:'제22조 (계약해지 및 이용제한)',
        content22:`
            ① 회원이 회원계약을 해지하고자 하는 때에는 회원 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.<br>	 
            ② 회사는 회원이 다음 각 호의 1에 해당하는 행위를 하였을 경우 사전통지 없이 회원계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                가. 타인의 서비스 ID 및 비밀번호를 도용한 경우<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                나. 서비스 운영을 고의로 방해한 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 
                다. 가입한 이름이 실명이 아닌 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  	 
                라. 같은 사용자가 다른 ID로 이중등록을 한 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  	 	 
                마. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 
                바. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 
                사. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 
                아. 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성 정보를 전송하는 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   	 
                자. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 
                차. 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 	 
                카. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 	 
                타. 타인의 개인정보, 이용자ID 및 비빌 번호를 부정하게 사용하는 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   	 
                파. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우<br>	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
                하. 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트 링크하는 경우<br>	 	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                거. 본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                너. 기타 관련 법령이나 회사가 정한 규정에 위배되는 경우<br>	 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	 
                더. 범죄적 행위에 관련되는 경우`,
        title6:'제6장 손해배상 등',
        subtitle23:'제23조 (손해배상)',
        content23:`회사는 서비스 이용요금이 무료인 동안의 서비스 이용과 관련하여 회사의 고의ㆍ과실에 의한 것이 아닌 한 회원에게 발생한 어떠한 손해에 대하여도 책임을 지지 않습니다.	<br> 
        회원이 서비스를 이용함에 있어 행한 불법행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상청구, 소송을 비롯한 각종의 이의제기를 받는 경우 당해 회원은 회사의 면책을 위하여 노력하여야 하며, 만일 회사가 면책되지 못한 경우는 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.`,
        subtitle24:'제24조 (면책조항)',
        content24:`
            ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.<br>	 
            ② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.<br>	 
            ③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.	<br> 
            ④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.`,
        subtitle25:'제25조 (관할법원)',
        content25:'요금 등 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.',
        title7:`[부칙]`,
        content27:`(시행일) 이 약관은 2022년 07월 30일부터 시행합니다.`
    }
];

