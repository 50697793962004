<template>
    <div id="manage_Root">
        <Header />
        <SideMenu />
        <div class="manage_Root">
            <div class="manage_Wrapper">
                <div class="manage_Content">
                    <div class="memberInfo_Wrapper">
                        <h4>회원 관리</h4>
                        <div class="manage_Tit">
                            <div class="manage_Search">
                                <p>전체 명: {{ totalMembers }}</p>
                                <input type="search" placeholder="검색" @input="searchMember($event)">
                            </div>
                            <div class="term_date">
                                <input type="date" v-model="startDate" @change="changeStartDate" :max=today>
                                <label>~</label>
                                <input type="date" v-model="endDate" @change="changeEndDate" :max=today>
                            </div>
                            <div class="manage_Btn">
                                <input type="button" id="downloadExcel" @click="clickExcelButton">
                                <label for="downloadExcel">엑셀다운로드</label>
                            </div>
                        </div>
                        <table>
                            <!-- <colgroup>
                                <col width="30px">
                                <col width="170px">
                                <col width="100px">
                                <col width="150px">
                                <col width="150px">
                                <col width="150px">
                                <col width="80px">
                                <col width="120px">
                                <col width="120px">
                                <col width="100px">
                                <col width="100px">
                                <col width="140px">
                                <col width="140px">
                            </colgroup> -->
                            <thead>
                                <th width="40px"></th>
                                <th width="180px">이메일</th>
                                <th width="100px">이름</th>
                                <th width="120px">생년월일</th>
                                <th width="120px">전화번호</th>
                                <th width="150px">주소</th>
                                <th width="100px">코드</th>
                                <th width="110px">경로</th>
                                <th width="110px">관심</th>
                                <th width="35px">SNS동의</th>
                                <th width="35px">메일동의</th>
                                <th width="120px">회원가입일</th>
                                <th width="120px">최근접속일</th>
                            </thead>
                            <tbody
                                v-for="(item, idx) in memberTables?.slice(pageStartIdx, pageStartIdx + ITEM_PER_PAGE)"
                                :key="idx">
                                <tr>
                                    <td @click="clickAdmin(item)" :title="item.isadmin">{{ item.isadmin }}</td>
                                    <td :title="item.email">{{ item.email }}</td>
                                    <td :title="item.name">{{ item.name }}</td>
                                    <td :title="item.birth">{{ item.birth }}</td>
                                    <td :title="item.phone">{{ item.phone }}</td>
                                    <td class="t_address" :title="item.address">{{ item.address }}</td>
                                    <td :title="item.code">{{ item.code }}</td>
                                    <td class="t_path" :title="item.path">{{ item.path }}</td>
                                    <td class="t_interest" :title="item.interest">{{ item.interest }}</td>
                                    <td :title="item.receive_s">{{ item.receive_s }}</td>
                                    <td :title="item.receive_e">{{ item.receive_e }}</td>
                                    <td :title="item.regdate">{{ item.regdate }}</td>
                                    <td :title="item.visitedate">{{ item.visitedate }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="Paging_Div">
                            <Pagination :list="memberTables" v-bind="{ ITEM_PER_PAGE, PAGE_PER_SECTION }"
                                @change-page="onChangePage" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@import url('../assets/css/manage.css');
</style>

<script>
import Pagination from './essential/pagination.vue';
import { ref, computed } from 'vue';
import Cookies from 'js-cookie';
import axios from 'axios';
import SideMenu from '../components/essential/SideMenu.vue';
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import Header from '../components/essential/headerTemplate.vue'

export default {
    components: { Pagination, SideMenu, Header },
    setup() {
        onBeforeRouteLeave((to, from) => {
            Cookies.remove('manage_token');
        })
    },
    data() {
        return {
            userName: "",
            userIdx: "",
            totalMembers: "",
            memberTables: [],
            ITEM_PER_PAGE: 11,
            PAGE_PER_SECTION: 5,
            curPage: 1,
            today: new Date(),
            keyword: ''
        }
    },
    computed: {
        pageStartIdx() {
            return (this.curPage - 1) * this.ITEM_PER_PAGE;
        }
    },
    async created() {
        await this.getManageUserInfo('', '', '');
    },
    async mounted() {
        var date = new Date();
        var year = date.getFullYear();
        var month = ("0" + (1 + date.getMonth())).slice(-2);
        var day = ("0" + date.getDate()).slice(-2);
        this.today = year + "-" + month + "-" + day;
    },
    methods: {
        changeStartDate() {
            if (this.endDate == undefined) {
                //alert("마지막 날짜를 선택하세요");
            } else if (this.startDate > this.endDate) {
                alert("기간을 올바르게 선택하세요");
            }
            else {
                this.getManageUserInfo(this.keyword, this.startDate, this.endDate)
            }
        },
        changeEndDate() {
            if (this.startDate == undefined) {
                alert("시작 날짜를 선택하세요");
            } else if (this.startDate > this.endDate) {
                alert("기간을 올바르게 선택하세요");
                this.endDate = '';
            }
            else {
                this.getManageUserInfo(this.keyword, this.startDate, this.endDate)
            }
        },
        searchMember(event) {
            this.keyword = event.target.value;
            console.log("start end", this.startDate, this.endDate);
            this.getManageUserInfo(this.keyword, this.startDate, this.endDate);
        },
        navigateToPage(page) {
            this.currentPage = page;
        },
        onChangePage(data) {
            this.curPage = data;
        },
        async getUserInfo() {
            console.log("getUserInfo");
            const res = await axios.post("/server/get_mypage",
                `{ "user_idx":"${this.userIdx}" }`,{
                    headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-origin' : '*',
                    'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
                    'Access-Control-Allow-Credentials' : 'true'
                }
                })
            //(res => {
            this.userName = res.data._data[0]["NAME"];
            this.userEmail = res.data._data[0]["USER_EMAIL"];
            this.userPhone = res.data._data[0]["PHONE"];
            this.userPhone1 = this.userPhone.slice(0, 3);
            this.userPhone2 = this.userPhone.slice(3, 7);
            this.userPhone3 = this.userPhone.slice(7);
            this.userDate = res.data._data[0]["REGDATE"].split('T')[0];
            this.userCode = res.data._data[0]["CODE"];
            //})
        },
        async getManageUserInfo(keyword, startDate, endDate) {

            let data = {
                i_opjob: "GET_MEMBER_LIST",
                token: "",
                hashPw: "",
                keyword: keyword == '' ? '' : keyword,
                startDate: (startDate === undefined || startDate === '') ? '2024-01-01' : startDate,
                endDate: (endDate === undefined || endDate === '') ? this.today : endDate,
                idx: ""
            };
            const res = await axios.post("/server/MANAGE_MEMBER", JSON.stringify(data), {
                headers: { 'Content-Type': 'application/json' }
            });
            this.totalMembers = res.data._data["length"];
            var tempArr = new Array(this.totalMembers);
            for (var i = 0; i < tempArr.length; i++) {
                tempArr[i] = new Array(7); //컬럼 수 만큼
            }
            for (let i = 0; i < this.totalMembers; i++) {
                tempArr[i]["email"] = res.data._data[i]["USER_EMAIL"] == '' ? ' ' : res.data._data[i]["USER_EMAIL"];
                tempArr[i]["name"] = res.data._data[i]["NAME"];
                tempArr[i]["birth"] = res.data._data[i]["BIRTH"];
                tempArr[i]["phone"] = res.data._data[i]["PHONE"];
                if (res.data._data[i]["CODE"] == 0) {
                    tempArr[i]["code"] = "일반";
                }
                else if (res.data._data[i]["CODE"] == 1) {
                    tempArr[i]["code"] = "카카오";
                }
                else {
                    tempArr[i]["code"] = "네이버";
                }
                tempArr[i]["gender"] = res.data._data[i]["GENDER"];
                tempArr[i]["address"] = res.data._data[i]["ADDRESS"];
                tempArr[i]["receive_s"] = res.data._data[i]["RECEIVE_S"];
                tempArr[i]["receive_e"] = res.data._data[i]["RECEIVE_E"];
                tempArr[i]["regdate"] = res.data._data[i]["REGDATE"];
                tempArr[i]["visitedate"] = res.data._data[i]["VISITEDATE"];
                tempArr[i]["path"] = res.data._data[i]["PATH"];
                tempArr[i]["interest"] = res.data._data[i]["INTEREST"];
                tempArr[i]["isadmin"] = res.data._data[i]["ISADMIN"] == 1 ? "A" : "";
                tempArr[i]["idx"] = res.data._data[i]["idx"];
            }
            this.memberTables = tempArr;
            
        },
        logout() {
            alert("로그아웃 합니다");
            if (this.userCode == 1) {
                console.log("카카오로그아웃");
                this.kakaoLogout();
            }
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
            Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
            this.$router.push('/member/login');
            console.log("로그아웃합니다");
        },
        async clickExcelButton() {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet("테스트시트");

            worksheet.columns = [
                { header: "이메일", key: "email", width: 32 },
                { header: "이름", key: "name", width: 20 },
                { header: "생년월일", key: "birth", width: 20 },
                { header: "전화번호", key: "phone", width: 20 },
                { header: "코드", key: "code", width: 10 },
                { header: "경로", key: "path", width: 20 },
                { header: "회원가입일", key: "regdate", width: 20 },
                { header: "최근접속일", key: "visitedate", width: 20 },
            ];

            for (var i = 0; i < this.totalMembers; i++) {
                worksheet.addRow({ email: this.memberTables[i]["email"], name: this.memberTables[i]["name"], birth: this.memberTables[i]["birth"], phone: this.memberTables[i]["phone"], code: this.memberTables[i]["code"], path: this.memberTables[i]["path"], regdate: this.memberTables[i]["regdate"], visitedate: this.memberTables[i]["visitedate"] });
            }

            //엑셀 다운로드
            var blobType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            await workbook.xlsx.writeBuffer().then(data => {
                const blob = new Blob([data], { type: blobType });
                const today = new Date();
                const year = today.getFullYear();
                const month = (today.getMonth() + 1).toString().padStart(2, '0');
                const day = today.getDate().toString().padStart(2, '0');
                const date = `${year}-${month}-${day}`;
                const str_date = date +"_회원목록"
                //saveAs(blob, 'test.xlsx');
                saveAs(blob, str_date+'.xlsx');
            });
        },
        async clickAdmin(item) {
            if(item.isadmin == ""){ //관리자 아닐 때
                if(confirm("[" + item.name +"] 회원을 관리자로 지정하시겠습니까?")) {
                    //관리자로 지정
                    let data = {
                        i_opjob: "AUTH_ADMIN",
                        token: "",
                        hashPw: "",
                        keyword: "",
                        startDate: "",
                        endDate: "",
                        idx: item.idx
                    }
                    const res = await axios.post("/server/MANAGE_MEMBER", JSON.stringify(data), {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if(res.data._result[0]["RESULTCD"] == "1") {
                        alert("관리자가 변경되었습니다.");
                        await this.getManageUserInfo('', '', '');
                    }
                }
            } else { //관리자일때
                if(confirm("[" + item.name +"] 회원을 관리자에서 삭제하시겠습니까?")) {
                    //관리자 박탈
                    let data = {
                        i_opjob: "AUTH_ADMIN",
                        token: "",
                        hashPw: "",
                        keyword: "",
                        startDate: "",
                        endDate: "",
                        idx: item.idx
                    }
                        const res = await axios.post("/server/MANAGE_MEMBER", JSON.stringify(data), {
                        headers: { 'Content-Type': 'application/json' }
                    });
                    if(res.data._result[0]["RESULTCD"] == "1") {
                        alert("관리자가 변경되었습니다.");
                        await this.getManageUserInfo('', '', '');
                    }
                }
            }
        }
    },



}
</script>