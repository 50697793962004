import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";

export default class SceneBase{
    constructor(canvas,viewer)
    {
        this.canvasElement = canvas;
        this.viewer = viewer;
        this.createEntity = null;
        this.colliders = [];
        this.engine = new BABYLON.Engine(canvas, true, { stencil: true });
        this.instance = this.createScene();
        this.isLoaded = true;
    }

    createScene()
    {
        const scene = new BABYLON.Scene(this.engine);
        scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
        scene.collisionsEnabled = true;

        const camera = new BABYLON.ArcRotateCamera("Camera", 0, 0, 1, new BABYLON.Vector3(0, 0, 0), scene);
        camera.setPosition(new BABYLON.Vector3(0, 80, -50));
        camera.setTarget(BABYLON.Vector3.Zero());
        camera.attachControl(this.canvasElement, true);
        camera.lowerRadiusLimit = 10;
        camera.upperRadiusLimit = 200;
        camera.minZ = 0;

        const light = new BABYLON.HemisphericLight("light", new Vector3(0, 1, 0), scene);
        light.intensity = 1;

        this.engine.setViewport(new BABYLON.Viewport(0, 0, 1, 1));
        camera.viewport = new BABYLON.Viewport(0, 0, 1, 1);
        
        //const axisViewer = new BABYLON.Debug.AxesViewer(scene,10);

        //Highlight layer
        this.previewHighlightLayer = new BABYLON.HighlightLayer("overLayer", scene, {
            blurTextureSizeRatio: 1,
            mainTextureRatio: 1,
            alphaBlendingMode: BABYLON.Engine.ALPHA_SUBTRACT
        });
        this.highlightLayer = new BABYLON.HighlightLayer("highlightLayer", scene, {
            blurTextureSizeRatio: 1,
            mainTextureRatio: 1,
            alphaBlendingMode: BABYLON.Engine.ALPHA_SUBTRACT
        });

        this.engine.runRenderLoop(() => {
            scene.render();
        });

        return scene;
    }

    createGround()
    {
        const width = 10000
        const height = 10000
        const subdivisions = 100
        const ground = BABYLON.MeshBuilder.CreateGround("ground", { width, height, subdivisions }, this.instance, false);
        ground.position = new Vector3(0,0,0);
        ground.material = this.instance.getMaterialByName("nomalGridMat");
        ground.updateFacetData();

        this.highlightLayer.addExcludedMesh(ground);
        this.previewHighlightLayer.addExcludedMesh(ground);

        const spotBox = BABYLON.MeshBuilder.CreateBox('spotBox',{size:1},this.instance);
        spotBox.isVisible = false;
    }
}