import { createWebHistory, createRouter, onBeforeRouteLeave } from "vue-router";
import ModuleMake from "../views/ModuleMake.vue";
import Edit from "../components/Edit.vue";
import myHome from "../components/myHome.vue";
import LogIn from "../components/Login/LogIn.vue"
import joinStep1 from "../components/Login/joinStep1.vue"
import joinStep2 from "../components/Login/joinStep2.vue"
import joinStep2Simple from "../components/Login/joinStep2Simple.vue"
import joinStep3 from "../components/Login/joinStep3.vue"
import findID from "../components/Login/findID.vue"
import findPW from "../components/Login/findPW.vue"
import findIDResult from "../components/Login/findIDResult.vue"
import LoginNCallback from "@/components/Login/LoginNCallback.vue";
import childPage from "@/components/Login/childPage.vue";
import JoinStepSub from "@/components/Login/joinStepSub.vue";
import findEmail from "../components/Login/findEmail.vue"
import findEmailResult from "@/components/Login/findEmailResult.vue";
import changePW2 from "@/components/Login/changePW2.vue";
import joinChild from "../components/Login/joinChild.vue"
import findEmailSub from "../components/Login/findEmailSub.vue"
import findChild from "../components/Login/findChild.vue"
import changePW1 from "@/components/Login/changePW1.vue";
import changePWSub from "@/components/Login/changePWSub.vue";
import changeChild from "@/components/Login/changeChild.vue";
import Cookies from 'js-cookie';
import myPage from "../components/myPage.vue";
import manageMember from '../components/manageMenber.vue';
import AdminLogin from "@/components/Login/AdminLogin.vue";
import axios from "axios";



function getToken() {
    const cookie = Cookies.get('token');
    let data = {
        token: cookie == undefined ? '' : cookie
    }
    const res = axios.post("/server/token_check", JSON.stringify(data), {
        headers: { 'Content-Type': 'application/json' }
    });
    if (data.token == '') { //토큰 자체가 없으면 걍 로그인 안된거

    }
    else if (res.data._data["length"] == 0) {
        alert("다른 계정 로그인으로 인해 로그아웃 됩니다.");
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/'});
        Cookies.remove('token', { domain: this.$DOMAIN, path: '/member'});
        this.$router.push('/member/login');
    }
    else if (res.status == 200) {
        this.userIdx = res.data._data[0]["idx"];
        this.getUserInfo();
    }
}
function getUserInfo() {
    console.log("getUserInfo");
    const res = axios.post("/server/get_mypage",{
        headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin' : '*',
        'Access-Control-Allow-Methods' : 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
        'Access-Control-Allow-Credentials' : 'true'
    }
    },
        `{ "user_idx":"${this.userIdx}" }`)
    //(res => {
    this.userName = res.data._data[0]["NAME"];
    this.userEmail = res.data._data[0]["USER_EMAIL"];
    this.userPhone = res.data._data[0]["PHONE"];
    this.userPhone1 = this.userPhone.slice(0, 3);
    this.userPhone2 = this.userPhone.slice(3, 7);
    this.userPhone3 = this.userPhone.slice(7);
    this.userDate = res.data._data[0]["REGDATE"].split('T')[0];
    this.userCode = res.data._data[0]["CODE"];
    //})
}
const requireLogin = () => (to, from, next) => {
    if (Cookies.get('token') != undefined) {
        next();
    } else {
        //alert('로그인 후 이용해주세요.');
        router.push({ path: '/member/login' })
        return;
    }
};
const requireManageLogin = () => (to, from, next) => {
    if (Cookies.get('manage_token') == Cookies.get('token')) {
        next();
    } else {
        alert('잘못된 접근 경로입니다.');
        //router.push({ path: '/member/login' })
        return;
    }
};
const loggedIn = () => (to, from, next) => {
    if(Cookies.get('token') == undefined) {
        next();
    } else {
        router.push({path: '/myhome'});
    }
}
const routes = [
    {
        path: "/myhome",
        name: "myHome",
        component: myHome,
        beforeEnter: requireLogin(),
    },
    {
        path: "/:typeName",
        name: "ModuleMake",
        component: ModuleMake,
    },
    {
        path: "/editTemp",
        name: "editTemp",
        component: Edit,
    },
    {
        path: "/",
        name: "edit",
        component: ModuleMake,
        //beforeEnter: requireLogin(),
    },

    {
        path: "/member/login",
        name: "LogIn",
        component: LogIn,
        beforeEnter: loggedIn(),
    },
    //회원가입
    {
        path: "/member/joinStep1",
        name: "joinStep1",
        component: joinStep1,
    },
    {
        path: "/member/joinStep2",
        name: "joinStep2",
        component: joinStep2,
    },
    //joinStep2 양식 간략화 한 버전
    {
        path: "/member/joinStep22",
        name: "joinStep2Simple",
        component: joinStep2Simple,
    },
    {
        path: "/member/joinStep3",
        name: "joinStep3",
        component: joinStep3,
    },
    {
        path: "/member/findID",
        name: "findID",
        component: findID,
    },
    {
        path: "/member/findPW",
        name: "findPW",
        component: findPW,
    },
    {
        path: "/member/findIDResult",
        name: "findIDResult",
        component: findIDResult,
    },
    {
        path: "/callback",
        name: "LoginNCallback",
        component: LoginNCallback,
    },
    {
        path: "/member/childPage",
        name: "childPage",
        component: childPage
    },
    {
        path: "/member/joinStepSub",
        name: "joinStepSub",
        component: JoinStepSub
    },
    {
        path: "/member/findEmail",
        name: "findEmail",
        component: findEmail,
    },
    {
        path: "/member/findEmailResult",
        name: "findEmailResult",
        component: findEmailResult,

    },
    {
        path: "/member/changePW2",
        name: "changePW2",
        component: changePW2,
    },
    {
        path: "/member/joinChild",
        name: "joinChild",
        component: joinChild,
    },
    {
        path: "/member/findEmailSub",
        name: "findEmailSub",
        component: findEmailSub,
    },
    {
        path: "/member/findChild",
        name: "findChild",
        component: findChild,
    },
    {
        path: "/member/changePW1",
        name: "changePW1",
        component: changePW1,
    },
    {
        path: "/member/changeChild",
        name: "changeChild",
        component: changeChild,
    },
    {
        path: "/member/changePWSub",
        name: "changePWSub",
        component: changePWSub,
    },
    {
        path: "/mypage",
        name: "myPage",
        component: myPage,
        beforeEnter: requireLogin(),
    },
    // 240520 회원관리 페이지 추가
    {
        path: "/manage",
        name: "manageMember",
        component: manageMember,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;