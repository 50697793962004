import EntityBase from "./EntityBase";
import { Vector3 } from "@babylonjs/core/Maths/math.vector";
import * as Material from '@babylonjs/materials'
import CsgHelper from "../Helper/CsgHelper"
import TopSpotItem from './../Items/TopSpotItem';

export default class VoidEntity extends EntityBase{
    constructor()
    {
        super();
        this.crushMesh = null;
        this.crushMesh1f = null;
        this.crushMesh2f = null;
        this.isSimbol = true;
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const info = mesh.info;
            if(info.ctgrName === 'Box')
            {
                const oriCsg = BABYLON.CSG.FromMesh(mesh);
                const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
                const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
                m1f.info = info;
                m2f.info = info;
                m1f.isVisible = false;
                m2f.isVisible = false;
                this.drawMeshes1f.push(m1f);
                this.drawMeshes2f.push(m2f);
            }
        });

        const v1f = this.createSimbol(this.drawScene1f.instance);
        v1f.info = this.info;
        v1f.entity = this;
        v1f.setEnabled(false);
        const v2f = this.createSimbol(this.drawScene2f.instance);
        v2f.info = this.info;
        v2f.entity = this;
        v2f.setEnabled(false);
        this.drawMeshes1f.push(v1f);
        this.drawMeshes2f.push(v2f);
    }

    createSimbol(sceneIns)
    {
        return this.createLine(sceneIns);
    }

    createLine(sceneIns)
    {
        const bb = this.viewMeshes[0].getBoundingInfo().boundingBox;
        const width = bb.extendSize.x *2;
        const height = bb.extendSize.z *2;

        const p1 = new BABYLON.Vector3(-width/2,height/2, 0);
        const p2 = new BABYLON.Vector3(width/2,height/2, 0);
        const p3 = new BABYLON.Vector3(width/2,-height/2, 0);
        const p4 = new BABYLON.Vector3(-width/2,-height/2, 0);

        const line = BABYLON.MeshBuilder.CreateLines(null,{points: [p1,p2,p3,p1,p4,p2,p3,p4]}, sceneIns);
        line.color = new BABYLON.Color4(0.439, 0.447, 0.455, 1);
        line.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
        line.position = this.viewMeshes[0].position.clone();
        
        return line;
    }

    createDashLine(sceneIns)
    {
        const bb = this.viewMeshes[0].getBoundingInfo().boundingBox;
        const width = bb.extendSize.x *2;
        const height = bb.extendSize.z *2;

        let dashNb = 20;
        const dashRate = 30;

        if(width > height)
        {
            dashNb = width*dashRate;
        }
        else
        {
            dashNb = height*dashRate;
        }

        const p1 = new BABYLON.Vector3(-width/2,height/2, 0);
        const p2 = new BABYLON.Vector3(width/2,height/2, 0);
        const p3 = new BABYLON.Vector3(width/2,-height/2, 0);
        const p4 = new BABYLON.Vector3(-width/2,-height/2, 0);

        const line = BABYLON.MeshBuilder.CreateDashedLines(null,{points: [p1,p2,p3,p1,p4,p2,p3,p4], dashSize: 1, gapSize: 1, dashNb: dashNb}, sceneIns);
        line.color = new BABYLON.Color4(0.439, 0.447, 0.455, 0.4);
        line.rotation = new BABYLON.Vector3(Math.PI / 2, 0, 0);
        line.position = this.viewMeshes[0].position.clone();
        return line;
    }

    getContextEntity()
    {
        return this.moduleEntity;
    }

    createCrushMesh(root = null)
    {
        let rootEnt = root ? root : this.moduleEntity;
        if(this.crushMesh)
        {
            return;
        }
        const crushMesh = this.viewMeshes.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh)
            return;
        crushMesh.material = this.viewScene.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh = this.crushMeshSetting(crushMesh,rootEnt.viewRootMesh);

        if(this.crushMesh1f)
        {
            return;
        }
        const crushMesh1f = this.drawMeshes1f.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh1f)
            return;
        crushMesh1f.material = this.drawScene1f.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh1f = this.crushMeshSetting(crushMesh1f,rootEnt.draw1fRootMesh);

        if(this.crushMesh2f)
        {
            return;
        }
        const crushMesh2f = this.drawMeshes2f.find(x=>x.info.ctgrName === "Box")?.clone();
        if(!crushMesh2f)
            return;
        crushMesh2f.material = this.drawScene2f.instance.getMaterialByName("redBoxMaterial");
        this.crushMesh2f = this.crushMeshSetting(crushMesh2f,rootEnt.draw2fRootMesh);
    }

    crushMeshSetting(crushMesh,rootMesh)
    {
        if(!crushMesh)
            return;

        CsgHelper.initialMatrix(crushMesh)
        CsgHelper.setParentSubtractLocation(crushMesh,rootMesh)
        crushMesh.parent = null;
        crushMesh.id = "crushMeshTop";
        crushMesh.originPos = crushMesh.position.clone();
        crushMesh.moduleEntity = this.moduleEntity;
        crushMesh.rootMesh = rootMesh;
        crushMesh.parent = rootMesh;
        crushMesh.entity = this;
        crushMesh.isVisible = false;
        crushMesh.setEnabled(true);
        crushMesh.spot = new TopSpotItem(crushMesh, crushMesh._scene,null, rootMesh._scene != this.viewScene.instance)
        crushMesh.computeWorldMatrix();

        return crushMesh;
    }

    async makeTempMesh(instance)
    {
        let isCrush = false;
        const tempScaling = this.crushMesh.scaling.clone();
        if(!tempScaling)
            return;

        this.crushMesh.scaling.x *= 1.0001;
        this.crushMesh.scaling.z *= 1.0001;
        this.crushMesh.computeWorldMatrix();

        const csg = BABYLON.CSG.FromMesh(this.crushMesh)
        
        if(this.crushMesh.spot.includedOnlyMeshes.length > 1)
            isCrush = true;

        if(!isCrush)
            return;

        const mat = instance.getMaterialByName('WallBackMat');
        const result = csg.toMesh('backMesh',mat,instance,true);
        result.parent = this.crushMesh.parent;
        result.flipFaces(true)

        this.crushMesh.scaling = tempScaling.clone();
        const spot = this.crushMesh.spot;
        spot.includedOnlyMeshes.push(result);
    }
}