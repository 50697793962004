import { Vector3 } from "@babylonjs/core";
import VectorHelper from "../Helper/VectorHelper"
import ContextItemBase from "./ContextItemBase";
import * as HistoryCommand from "../Entities/historyCommands"
import SaveHelper from "../Helper/SaveHelper";

export default class EntityBase extends ContextItemBase{
    constructor(){
        super();
        this.info = null;
        //Meshes
        this.drawMeshes1f = [];
        this.drawMeshes2f = [];
        this.viewMeshes = [];
        //Scenes
        this.drawScene1f = null;
        this.drawScene2f = null;
        this.viewScene = null;
        //======
        this.moduleEntity = null;
        this.isGhost = true;
        this.isModule = false;
        this.parent = null;
        this.locatedScene = null;
        this.isHistoryWork = false;
    }

    getCurrentScene(instance)
    {
        const scenes = [this.drawScene1f,this.drawScene2f,this.viewScene]
        return scenes.find(x=>x.instance === instance);
    }

    getCurrentMeshes(instance)
    {
        const meshes = this.getDrawMeshes().concat(this.viewMeshes);
        return meshes.filter(x=>x._scene === instance);
    }

    getDrawMeshes()
    {
        return this.drawMeshes1f.concat(this.drawMeshes2f);
    }

    getSceneRootMesh(scene)
    {
        return null;
    }

    getContextEntity()
    {
        return this;
    }

    getSceneMeshes(scene)
    {
        if(scene.is2d)
        {
            if(scene.floor === "1F")
            {
                return this.drawMeshes1f;
            }
            else
            {
                return this.drawMeshes2f;
            }
        }
        else
        {
            return this.viewMeshes;
        }
    }

    snapEntities(sceneEntity, prevPos, x, z, modules)
    {
        return null;
    }

    drawViewMesh()
    {
        this.viewMeshes.forEach(mesh =>{
            const oriCsg = BABYLON.CSG.FromMesh(mesh);
            const m1f = oriCsg.toMesh(null,null,this.drawScene1f.instance,true);
            const m2f = oriCsg.toMesh(null,null,this.drawScene2f.instance,true);
            
            m1f.info = mesh.info;
            m2f.info = mesh.info;

            if(mesh.material.diffuseTexture)
            {
                const matName = mesh.material.diffuseTexture.name.split('/').slice(-1)[0];

                const mat1f = this.drawScene1f.instance.getMaterialByName(matName);
                if(!mat1f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene1f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene1f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m1f.material = oriMat;
                }
                else
                {
                    m1f.material = mat1f;
                }

                const mat2f = this.drawScene2f.instance.getMaterialByName(matName);
                if(!mat2f)
                {
                    const oriMat = new BABYLON.StandardMaterial(matName,this.drawScene2f.instance);
                    const oriTex = new BABYLON.Texture(mesh.material.diffuseTexture.name,this.drawScene2f.instance);
                    oriMat.diffuseTexture = oriTex;
                    oriMat.specularColor = new BABYLON.Color3(0, 0, 0); 
                    oriMat.specularPower = 0;
                    m2f.material = oriMat;
                }
                else
                {
                    m2f.material = mat2f;
                }
            }
            this.drawMeshes1f.push(m1f);
            this.drawMeshes2f.push(m2f);
        });
    }

    setEnable(instance,value)
    {
        const meshes = this.getDrawMeshes().concat(this.viewMeshes);
        const targets = meshes.filter(x=>x._scene === instance);
        targets.forEach(mesh=>{
            mesh.setEnabled(value);
        })
    }

    setPosition(instance,value)
    {
        const meshes = this.getDrawMeshes();
        const targets = meshes.filter(x=>x._scene === instance);
        targets.forEach(mesh=>{
            mesh.position = value.clone();
        })

        this.viewMeshes.forEach(mesh=>{
            mesh.position = value.clone();
        })
    }

    getBoundingBox2d(scene)
    {
        var boundingBox = null;
        if(scene == null)
        {
            var meshes = this.viewMeshes;
        }
        else
        {
            if(scene.floor == "1F")
                var meshes = this.drawMeshes1f;
            else
                var meshes = this.drawMeshes2f;
        }

        meshes.forEach(mesh => {
            const box = mesh.getBoundingInfo().boundingBox;

            var minimumWorld = box.minimumWorld;
            var maximumWorld = box.maximumWorld;
            minimumWorld.y = 0;
            maximumWorld.y = 0;

            if(boundingBox != null)
            {
                var newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, minimumWorld);
                var newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, maximumWorld);
    
                boundingBox.reConstruct(newMin, newMax);
            }
            else
                boundingBox = new BABYLON.BoundingBox(minimumWorld, maximumWorld);
        });
        return boundingBox;
    }

    getOriginBoundingBox2d(scene)
    {
        var boundingBox = null;
        if(scene.floor == "1F")
            var meshes = this.drawMeshes1f;
        else
            var meshes = this.drawMeshes2f;

        meshes.forEach(mesh => {
            const box = mesh.getBoundingInfo().boundingBox;

            var minimum = box.minimum.add(mesh.position);
            var maximum = box.maximum.add(mesh.position);
            minimum.y = 0;
            maximum.y = 0;

            if(boundingBox != null)
            {
                var newMin = BABYLON.Vector3.Minimize(boundingBox.minimum, minimum);
                var newMax = BABYLON.Vector3.Maximize(boundingBox.maximum, maximum);
    
                boundingBox.reConstruct(newMin, newMax);
            }
            else
                boundingBox = new BABYLON.BoundingBox(minimum, maximum);
        });
        return boundingBox;
    }

    getAll2DRootMeshes()
    {
        return this.getDrawMeshes();
    }
    getAll3DRootMeshes()
    {
        return [...this.viewMeshes];
    }

    translateEntity(vector, value)
    {
        this.viewMeshes.concat(this.drawMeshes1f).concat(this.drawMeshes2f).forEach(mesh =>
        {
            mesh.setAbsolutePosition(mesh.absolutePosition.add(new BABYLON.Vector3(value, value, value).multiply(vector)));
        });
    }

    scaleEntity(moveVector, scaleVector, moveValue, scaleValue)
    {
        this.viewMeshes.concat(this.drawMeshes1f).concat(this.drawMeshes2f).forEach(mesh =>
        {
            var box = mesh.getBoundingInfo().boundingBox;
            var size = new BABYLON.Vector3(scaleValue, scaleValue, scaleValue).divide(box.extendSize).multiply(scaleVector);
            size.y = 0;

            var tempPos = mesh.absolutePosition.clone();  
            var tempParent = mesh.parent;

            var childs = mesh.getChildMeshes();
            mesh.setParent(null);
            childs.forEach(child => 
            {
                mesh.removeChild(child);
            });

            mesh.scaling = mesh.scaling.add(size);
            mesh.setParent(tempParent);
            childs.forEach(child => 
            {
                mesh.addChild(child);
            });
            
            mesh.setAbsolutePosition(tempPos.add(moveVector.scale(moveValue)));
        });
    }

    delete(scene, entity)
    {
        scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.DelteCommand(entity,SaveHelper.makeSaveData(entity.moduleEntity)));
        entity.deleteEntity(scene, entity);
        scene.updateWall();
    }

    deleteEntity(scene, entity)
    {
        entity.moduleEntity.deleteChild(scene, entity);
        entity.moduleEntity.isFixed = true;
        entity.dispose();
    }
}