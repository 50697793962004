import * as GUI from '@babylonjs/gui/2D';
import VectorHelper from "../Helper/VectorHelper";
import Enumerable from 'linq'
import * as HistoryCommand from "../Entities/historyCommands"
import SaveHelper from "../Helper/SaveHelper";
import { mrdlSliderThumbPixelShader } from '@babylonjs/gui/3D/materials/mrdl/shaders/mrdlSliderThumb.fragment';

export default class ContextItemBase{
    constructor()
    {
        this.contextPanels = [];
        this.boundingSideMeshes = [];
        this.boundingSideContexts = [];
        this.fontSize = 12;
    }

    disposeContextPanel()
    {
        this.contextPanels.forEach(context =>
        {
            context.dispose(); 
        });
        this.contextPanels = [];
        this.boundingSideMeshes.forEach(mesh =>
        {
            mesh.dispose(); 
        });
        this.boundingSideMeshes = [];
        this.boundingSideContexts.forEach(context =>
        {
            context.dispose();
        });
        this.boundingSideContexts = [];
    }

    createContextPanel(scene)
    {
        return null;
    }

    createToolTip(gui)
    {
        var tooltip = new GUI.Rectangle("tooltip");
        tooltip.background = (new BABYLON.Color4(0, 0, 0, 0.7)).toHexString();
        tooltip.color = "white";
        tooltip.adaptWidthToChildren = true;
        tooltip.adaptHeightToChildren = true;
        tooltip.zIndex = 100;
        tooltip.isVisible = false;
        gui.addControl(tooltip);
        var textBlock = new GUI.TextBlock("textTooltip", "");
        textBlock.fontSize = this.fontSize;
        textBlock.resizeToFit = true;
        textBlock.paddingTop = "5px";
        textBlock.paddingLeft = "15px";
        textBlock.paddingRight = "15px";
        textBlock.paddingBottom = "5px";
        tooltip.addControl(textBlock);

        this.contextPanels.push(tooltip);
    }

    getAdvancedTexture(sceneInstance)
    {
        return GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", true, sceneInstance);
    }

    getContextDefaultPanel()
    {
        var toolbar = this.createPanelContainer();

        return {
            toolbar: toolbar
        };
    }

    getButtonData()
    {
        return [];
    }

    createEntityBoundingSidePanel(advancedTexture, scene, dirs, axisAvailableTypes)
    {
        if(dirs.length <= 0)
            return;

        var box = this.getBoundingBox2d(scene, ["Floor"]);

        for(var i=0; i<dirs.length; i++)
        {
            var vector = dirs[i];

            var nearWall = null;
            this.Walls.forEach(wall =>
            {
                if(!wall.info.typeName.includes('Interior')) // 내벽은 제외
                {
                    var line = wall.getWorldWallLine();
                    var lineDir = BABYLON.Vector3.Normalize(line.end.subtract(line.start));
                    if(lineDir.multiply(vector).equalsWithEpsilon(BABYLON.Vector3.Zero(), 0.0001))
                    {
                        var floorPoint = VectorHelper.calcNearestPointOnLine(line.start, line.end, box.center);
                        var floorDir = BABYLON.Vector3.Normalize(floorPoint.subtract(box.center));
                        if(floorDir.equalsWithEpsilon(vector, 0.0001))
                        {
                            if(nearWall == null)
                                nearWall = wall;
                            else
                            {
                                var nearBox = nearWall.getBoundingBox2d(scene);
                                var wallPoint = VectorHelper.calcNearestPointOnLine(line.start, line.end, nearBox.center);
                                var dir = BABYLON.Vector3.Normalize(wallPoint.subtract(nearBox.center));
                                if(dir.equalsWithEpsilon(vector, 0.0001))
                                    nearWall = wall; 
                            }
                        }
                    }
                }
            })

            var position = box.center.add(vector.multiply(box.extendSize));

            if(nearWall != null)
            {
                var wallLine = nearWall.getWallLine();
                var lineCenter = VectorHelper.calcNearestPointOnLine(wallLine.start.add(box.center), wallLine.end.add(box.center), box.center);
                var internalDir = BABYLON.Vector3.Normalize(lineCenter.subtract(box.center));
    
                this.createSideButton(advancedTexture, scene, position, nearWall, axisAvailableTypes, vector, internalDir);
            }
        }
    }

    createSideButton(advancedTexture, scene, center, wall, axisAvailableTypes, worldDir, internalDir)
    {
        var tempMesh = new BABYLON.Mesh("tempMesh", scene.instance);
        tempMesh.position = center;

        var panel = new GUI.StackPanel();
        panel.adaptWidthToChildren = true;
        panel.adaptHeightToChildren = true;
        panel.spacing = 1;
        
        var dirDatas = [];
        var currentAxis = '';
        if(worldDir.z == 1)
        {
            currentAxis = BABYLON.Axis.Z;
            dirDatas.push({image: "arrow-up.png", buttonDir: worldDir, dir: internalDir, type: "increase"});
            dirDatas.push({image: "arrow-down.png", buttonDir: worldDir, dir: internalDir.negate(), type: "decrease"});
            panel.isVertical = true;
        }
        else if(worldDir.z == -1)
        {
            currentAxis = BABYLON.Axis.Z;
            dirDatas.push({image: "arrow-up.png", buttonDir: worldDir, dir: internalDir.negate(), type: "decrease"});
            dirDatas.push({image: "arrow-down.png", buttonDir: worldDir, dir: internalDir, type: "increase"});
            panel.isVertical = true;
        }
        else if(worldDir.x == 1)
        {
            currentAxis = BABYLON.Axis.X;
            dirDatas.push({image: "arrow-left.png", buttonDir: worldDir, dir: internalDir.negate(), type: "decrease"});
            dirDatas.push({image: "arrow-right.png", buttonDir: worldDir, dir: internalDir, type: "increase"});
            panel.isVertical = false;
        }
        else if(worldDir.x == -1)
        {
            currentAxis = BABYLON.Axis.X;
            dirDatas.push({image: "arrow-left.png", buttonDir: worldDir, dir: internalDir, type: "increase"});
            dirDatas.push({image: "arrow-right.png", buttonDir: worldDir, dir: internalDir.negate(), type: "decrease"});
            panel.isVertical = false;
        }

        var axisData = axisAvailableTypes.find(k => k.axis.multiply(currentAxis).length() == 1);
        if(!axisData)
            return;

        dirDatas = dirDatas.filter(o => axisData.availableTypes.some(k => k == o.type));

        var module = this;
        dirDatas.forEach(dirData =>
        {
            var buttonDir = dirData.buttonDir;
            var dir = dirData.dir;
            var type = dirData.type;
            var button = this.createImageButton(advancedTexture, scene, dirData.image, null, 256, false, function(evt)
            {
                if(evt.buttonIndex != 0)
                    return;
                
                module.scaleModule(scene, wall, buttonDir, dir, type);
    
                module.disposeContextPanel();
                setTimeout(() => {
                    if(scene.selectedEntity == module)
                        module.createContextPanel(scene);
                }, 200); // Wall이 모두 바뀔때까지 약간의 딜레이 필요
            });
            panel.addControl(button); 
        });

        advancedTexture.addControl(panel);
        panel.linkWithMesh(tempMesh);

        this.boundingSideMeshes.push(tempMesh);
        this.boundingSideContexts.push(panel);
    }

    createShadowedText(text)
    {
        var rect = new GUI.Rectangle();
        rect.adaptWidthToChildren = true;
        rect.adaptHeightToChildren = true;
        rect.cornerRadius = 10;
        rect.thickness = 1;
        rect.background = "white";
        rect.shadowColor = "gray";
        rect.shadowBlur = 5;
        rect.paddingTop = "5px";
        rect.paddingLeft = "5px";
        rect.paddingRight = "5px";
        rect.paddingBottom = "5px";

        var txtName = new GUI.TextBlock();
        txtName.text = text;
        txtName.color = "black";
        txtName.resizeToFit = true;
        txtName.fontSize = this.fontSize;
        txtName.paddingTop = "5px";
        txtName.paddingLeft = "5px";
        txtName.paddingRight = "5px"
        txtName.paddingBottom = "5px";
        rect.addControl(txtName);

        return rect;
    }

    createPanelContainer()
    {
        var spContainer = new GUI.StackPanel();
        spContainer.adaptWidthToChildren = true;
        spContainer.adaptHeightToChildren = true;
        spContainer.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
        spContainer.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM;
        spContainer.spacing = 5;
        spContainer.isVertical = true;
        spContainer.paddingBottom = "15px";

        return spContainer;
    }

    createButtonPanel(advancedTexture, scene, name, buttons)
    {
        var panel = new GUI.StackPanel();
        panel.adaptWidthToChildren = true;
        panel.adaptHeightToChildren = true;
        panel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
        panel.isVertical = false;

        var nameText = this.createShadowedText(name);
        panel.addControl(nameText);

        buttons.forEach(data =>
        {
            var type = data.type;
            var image = null;
            var tooltip = null;
            var isToggled = false;
            if(data.isToggled)
                isToggled = data.isToggled;

            switch(type)
            {
                case "module":
                    image = "module.png";
                    tooltip = "모듈 선택";
                    break;
                case "rotate":
                    image = "rotate.png"
                    tooltip = "오른쪽으로 회전";
                    break;
                case "rotate-ccw":
                    image = "rotate-ccw.png"
                    tooltip = "왼쪽으로 회전";
                    break;
                case "opening":
                    image = "opening.png"
                    tooltip = "오프닝 선택";
                    break;
                case "cut":
                    image = "scissors.png"
                    tooltip = "삭제";
                    break;
                case "mirror-v":
                    image = "mirror-v.png";
                    tooltip = "세로로 뒤집기";
                    break;
                case "mirror-h":
                    image = "mirror-h.png";
                    tooltip = "가로로 뒤집기";
                    break;
                case "door-mirror-v":
                    image = "door-mirror-v.png";
                    tooltip = "벽을 기준으로 뒤집기";
                    break;
                case "hide-roof":
                    image = "hide-roof.png";
                    tooltip = "지붕 숨기기";
                    break;
                case "door-mirror-h":
                    image = "door-mirror-h.png";
                    tooltip = "객체 기준으로 뒤집기";
                    break;
                case "copy":
                    image = "copy.png";
                    tooltip = "복사";
                    break;
                case "door-swap":
                    image = "door-swap.png";
                    tooltip = "문 교체";
                    break;
                case "delete":
                    image = "delete.png";
                    tooltip = "삭제";
                    break;
                default:
                    break;
            }

            if(image != null)
            {
                var entity = this;
                var button = this.createImageButton(advancedTexture, scene, image, tooltip, 5, isToggled, function(evt)
                {
                    if(evt.buttonIndex != 0)
                        return;
                        
                    data.press(scene, entity);
                    entity.disposeContextPanel();

                    if(data.type.includes("mirror") || data.type.includes("rotate") || data.type.includes('hide-roof'))
                    {
                        setTimeout(() => {
                            if(scene.selectedEntity == entity)
                                entity.createContextPanel(scene);
                        }, 200); // Wall이 모두 바뀔때까지 약간의 딜레이 필요
                    }
                });
                panel.addControl(button);
            }
        });

        return panel;
    }

    createImageButton(advancedTexture, scene, imageSrc, tooltip, cornerRadius, isToggled, pressFunction)
    {
        var defaultSrc = "../../icons/";

        var button = new GUI.Button();
        button.adaptWidthToChildren = true;
        button.adaptHeightToChildren = true;
        button.color = 'rgba(0, 0, 0, 0)'

        var rect = new GUI.Rectangle();
        rect.adaptWidthToChildren = true;
        rect.adaptHeightToChildren = true;
        rect.cornerRadius = cornerRadius
        rect.thickness = 0;
        rect.shadowColor = "gray";
        rect.shadowBlur = 5;
        rect.background = isToggled ? "#d9d9d9" : "white";
        rect.paddingTop = "3px";
        rect.paddingLeft = "3px";
        rect.paddingRight = "3px"
        rect.paddingBottom = "3px";

        var image = new GUI.Image("image", defaultSrc + imageSrc);
        image.width = "28px";
        image.height = "28px";
        image.paddingTop = "3px";
        image.paddingLeft = "3px";
        image.paddingRight = "3px"
        image.paddingBottom = "3px";

        button.onPointerEnterObservable.add(function()
        {
            if(scene != null)
            {
                var phlLayer = scene.previewHighlightLayer;
                phlLayer.removeAllMeshes();
            }

            if(tooltip != null)
            {
                var rectTooltip = advancedTexture.getControlByName("tooltip");
                rectTooltip.isVisible = true;
                
                var tbTooltip = advancedTexture.getControlByName("textTooltip");
                tbTooltip.text = tooltip;

                var width = scene.engine.getRenderWidth();
                var height = scene.engine.getRenderHeight();

                rectTooltip.leftInPixels = button.centerX - (width / 2.0);
                rectTooltip.topInPixels = button.centerY - (height / 2.0) - rectTooltip.heightInPixels;
            }
        });
        button.onPointerOutObservable.add(function()
        {
            var tooltip = advancedTexture.getControlByName("tooltip");
            tooltip.isVisible = false;
        });
        button.onPointerClickObservable.add(pressFunction);
        button.onWheelObservable.add(function(vector)
        {
            scene.handleMouseWheel(-vector.y);
        })

        button.addControl(rect);
        rect.addControl(image);

        return button;
    }

    createTextButton(scene, entity, text, cornerRadius, paddingHorizontal, pressFunction)
    {
        var button = new GUI.Button();
        button.adaptWidthToChildren = true;
        button.adaptHeightToChildren = true;
        button.color = 'rgba(0, 0, 0, 0)'

        var rect = new GUI.Rectangle();
        rect.adaptWidthToChildren = true;
        rect.adaptHeightToChildren = true;
        rect.cornerRadius = cornerRadius
        rect.thickness = 1;
        rect.shadowColor = "gray";
        rect.shadowBlur = 5;
        rect.background = "white";
        rect.paddingTop = "3px";
        rect.paddingLeft = "5px";
        rect.paddingRight = "5px"
        rect.paddingBottom = "3px";

        var txtName = new GUI.TextBlock();
        txtName.text = text;
        txtName.color = "black";
        txtName.resizeToFit = true;
        txtName.fontSize = this.fontSize;
        txtName.paddingTop = "5px";
        txtName.paddingLeft = paddingHorizontal;
        txtName.paddingRight = paddingHorizontal;
        txtName.paddingBottom = "5px";
        rect.addControl(txtName);

        button.onPointerEnterObservable.add(function()
        {
            if(scene != null)
            {
                var phlLayer = scene.previewHighlightLayer;
                phlLayer.removeAllMeshes();
            }
        });
        button.onPointerClickObservable.add(function(evt)
        {
            if(evt.buttonIndex != 0)
                return;
                
            pressFunction(scene, entity);
        });
        button.onWheelObservable.add(function(vector)
        {
            scene.handleMouseWheel(-vector.y);
        })

        button.addControl(rect);

        return button;
    }

    selectModule(scene, entity)
    {
        scene.selectEntity(entity.moduleEntity);
    }

    rotate(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.RotateCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        meshes2d.forEach(o => 
        {
            var rotation = o.rotation.y;
            if(rotation <= -Math.PI*2 || rotation >= Math.PI*2)
                rotation = 0;

            o.rotation.y = rotation + BABYLON.Tools.ToRadians(90);
        });
        meshes3d.forEach(o => 
        {
            var rotation = o.rotation.y;
            if(rotation <= -Math.PI*2 || rotation >= Math.PI*2)
                rotation = 0;

            o.rotation.y = rotation + BABYLON.Tools.ToRadians(90);
        });

        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    rotateccw(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.RotateccwCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        meshes2d.forEach(o => 
        {
            var rotation = o.rotation.y;
            if(rotation <= -Math.PI*2 || rotation >= Math.PI*2)
                rotation = 0;

            o.rotation.y = rotation + BABYLON.Tools.ToRadians(-90);
        });
        meshes3d.forEach(o => 
        {
            var rotation = o.rotation.y;
            if(rotation <= -Math.PI*2 || rotation >= Math.PI*2)
                rotation = 0;

            o.rotation.y = rotation + BABYLON.Tools.ToRadians(-90);
        });

        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    cut(scene, entity)
    {
        
    }

    mirrorv(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorvCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        if(entity.isModule)
        {
            const meshes = [entity.viewRootMesh, entity.draw1fRootMesh, entity.draw2fRootMesh]
            const rot = entity.viewRootMesh.rotation.y;
            const sin = Math.abs(Math.sin(rot).toFixed(2))
            
            if(sin === 1)
            {
                meshes.forEach(o => 
                {
                    o.scaling.x = -o.scaling.x;
                });
            }
            else
            {
                meshes.forEach(o => 
                {
                    o.scaling.z = -o.scaling.z;
                });
            }
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.z = -o.scaling.z;
            });
        }
        
        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    mirrorh(scene, entity)
    {
        if(!entity.isHistoryWork)
            scene.viewer.$SceneLoader.makeHistory(new HistoryCommand.MirrorhCommand(entity));
        var meshes2d = entity.getAll2DRootMeshes();
        var meshes3d = entity.getAll3DRootMeshes();

        if(entity.isModule)
        {
            const meshes = [entity.viewRootMesh, entity.draw1fRootMesh, entity.draw2fRootMesh]
            const rot = entity.viewRootMesh.rotation.y;
            const sin = Math.abs(Math.sin(rot).toFixed(2))
            
            if(sin === 1)
            {
                meshes.forEach(o => 
                {
                    o.scaling.z = -o.scaling.z;
                });
            }
            else
            {
                meshes.forEach(o => 
                {
                    o.scaling.x = -o.scaling.x;
                });
            }
        }
        else
        {
            meshes2d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
            meshes3d.forEach(o => 
            {
                o.scaling.x = -o.scaling.x;
            });
        }

        scene.updateEntireRoof();
        setTimeout(() => {
            scene.updateWall()
        }, 200);
    }

    copy(scene, entity)
    {
        
    }

    doorSwap(scene, entity)
    {
        
    }

    delete(scene, entity)
    {
        
    }

    getSnapData(boxes, entities, sceneEntity, prevSnapData, mouseX, mouseZ, availableAxis)
    {
        if(!availableAxis)
            availableAxis = ['x', 'z'];

        //var offset = sceneEntity.zoomRate / 10;
        var offset = 0.3;
        var allResults = [];

        var prevSnapDirs = [];
        if(prevSnapData != null)
        {
            if(prevSnapData.X != null)
                //prevSnapDirs.push(BABYLON.Vector3.Normalize(new BABYLON.Vector3(prevSnapData.X - mouseX, 0, 0)));
                prevSnapDirs.push('x');
            if(prevSnapData.Z != null)
                prevSnapDirs.push('z');
        }

        for(var f=0; f<boxes.length; f++)
        {
            var box = boxes[f];

            var positions = [];
            if(availableAxis.includes('z'))
            {
                positions.push({value:box.maximumWorld.x, mousePos: mouseX, axis:'z'});
                positions.push({value:box.minimumWorld.x, mousePos: mouseX, axis:'z'});
            }
            if(availableAxis.includes('x'))
            {
                positions.push({value:box.maximumWorld.z, mousePos: mouseZ, axis:'x'});
                positions.push({value:box.minimumWorld.z, mousePos: mouseZ, axis:'x'});
            }

            for(var i=0; i<entities.length; i++)
            {
                var entity = entities[i];
                var info = entity.info;

                if(info.ctgrName == "Window")
                {
                    if(!sceneEntity.viewer.$SceneLoader.isSnappedWindow)
                        continue;
                }
                else if(info.ctgrName == "Floor")
                {
                    if(!sceneEntity.viewer.$SceneLoader.isSnappedFloor)
                        continue;
                }
                else if(info.ctgrName == "Wall")
                {
                    if(!sceneEntity.viewer.$SceneLoader.isSnappedWall)
                        continue;
                }
                else if(info.ctgrName == "Door")
                {
                    if(!sceneEntity.viewer.$SceneLoader.isSnappedDoor)
                        continue;
                }
                else
                    continue;

                var targetBox = entity.getBoundingBox2d(sceneEntity);
                positions.forEach(position =>
                {
                    var values = [];
                    var linePos = [];
                    if(position.axis == 'x')
                    {
                        values.push(targetBox.minimum.z);
                        values.push(targetBox.maximum.z);
                        var lineDir = BABYLON.Vector3.Normalize(new BABYLON.Vector3(targetBox.minimum.x - box.minimumWorld.x, 0, 0));
                        linePos.push(targetBox.centerWorld.x + (lineDir.x * (targetBox.extendSize.x + 0.3 * 2)));
                        linePos.push(box.centerWorld.x - (lineDir.x * (box.extendSize.x + 0.3 * 2)));
                    }
                    else
                    {
                        values.push(targetBox.minimum.x);
                        values.push(targetBox.maximum.x);
                        var lineDir = BABYLON.Vector3.Normalize(new BABYLON.Vector3(0, 0, targetBox.minimum.z - box.minimumWorld.z));
                        linePos.push(targetBox.centerWorld.z + (lineDir.z * (targetBox.extendSize.z + 0.3 * 2)));
                        linePos.push(box.centerWorld.z - (lineDir.z * (box.extendSize.z + 0.3 * 2)));
                    }
    
                    values.forEach(value =>
                    {
                        var posDist = position.value - value;
                        var absDist = Math.abs(posDist);
                        if(prevSnapDirs.includes(position.axis) ? absDist <= 0.1 : absDist <= offset)
                        {
                            if(position.minDist == null || position.minDist >= absDist)
                            {
                                position.minPos = value;
                                position.posDist = posDist;
                                position.minDist = absDist;
                                position.linePos = linePos;
                            }
                        }
                    });
                });
            }

            var results = positions.filter(o => o.minDist != null);
            if(results.length < 1)
                continue;
            else
                allResults.push(...results);
        }

        if(allResults.length < 1)
            return;

        var result = {};
        var xItems = Enumerable.from(allResults.filter(o => o.axis == 'z'));
        var zItems = Enumerable.from(allResults.filter(o => o.axis == 'x'));
        if(xItems.count() > 0)
        {
            var minGroup = xItems.groupBy(o => o.minDist).orderBy(o => o.key).first();
            result.minx = minGroup.key();
            result.xItems = minGroup.toArray();
        }
        if(zItems.count() > 0)
        {
            var minGroup = zItems.groupBy(o => o.minDist).orderBy(o => o.key).first();
            result.minz = minGroup.key();
            result.zItems = minGroup.toArray();
        }

        return result;
    }
}